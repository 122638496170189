import React, {Component} from "react";
import {connect} from "react-redux";
import MaskedInput from "react-maskedinput";
import axios from "axios";
import {APIURL} from "../../../config/config";

import {AlertSuccess, AlertWarning} from "../../Alert/Alert";
import {format} from "date-fns";


import {ThaiDatePicker} from "thaidatepicker-react";
import '../../../css/thai-date-picker.css';


class CustomerOldForm extends Component{
    constructor (props) {
        super(props)

        this.state = {
            user: {},
            lists: [],
            load_data: true,
            customer_id: '',
            customer: {},
            bank: [],
            customer_select: false,
            customer_list: [],
            is_customer_edit: false,
            insurance_end_date: null,
            car_date_tax: null,
            car_select: false,
            car_list: [],
            old_car_departure_date: null,
            is_car_edit: false,
            province_new_id: 0,
            banks:0,
            car: {},
            car_id: '',

        }

        this.getDataCustomerSearch = this.getDataCustomerSearch.bind(this);
        this.onCustomerSelect = this.onCustomerSelect.bind(this);
        this.onResetCustomer = this.onResetCustomer.bind(this);
        this.onSubmitCustomer = this.onSubmitCustomer.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);

        this.getDataCarSearch = this.getDataCarSearch.bind(this);
        this.onCarSelect = this.onCarSelect.bind(this);
        this.onResetCar = this.onResetCar.bind(this);
        this.onSubmitCarOld = this.onSubmitCarOld.bind(this);
        this.handleOnChangeCar = this.handleOnChangeCar.bind(this);

    }

    componentDidMount () {
        let actions = this.props.action

        //console.log(actions)
        //console.log(customerOld)
        if(actions === 'แก้ไข'){
            let customerOld = this.props.customerOld

            //console.log('customerOld=',customerOld)

            this.setState({
                customer_select:true,
                customer_id:customerOld.customer_id,
                customer_name:customerOld.customer_name,
                customer_lastname:customerOld.customer_lastname,
                customer_card_id:customerOld.customer_card_id,
                customer_mobile:customerOld.customer_mobile,

                car_select:true,
                car_id:customerOld.car_id,
                car_name:customerOld.car_name,
                car_license_plate_new:customerOld.car_license_plate_new,
                province_new_id:customerOld.province_new_id,
                old_car_departure_date:customerOld.old_car_departure_date,
                bank_id:customerOld.old_car_finance_id,
                car_date_tax:customerOld.car_date_tax,
                insurance_end_date:customerOld.insurance_end_date,
                old_car_note:customerOld.old_car_note,
            })
        }
    }

    handleOnChange(e) {
        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [name]: value
        })
    }

    loadCustomer(customer_id) {
        axios.get(APIURL + '/customer/'+customer_id)
            .then(res => {
                if(res.status === 200){
                    var customer = res.data[0]
                    this.setState({
                        customer_select: true,
                        customer: customer,
                        customer_id: customer.customer_id,
                        customer_name: customer.customer_name,
                        customer_mobile: customer.customer_mobile
                    })
                }
            })
            .catch(err => console.log(err))
    }

    searchCustomer () {
        let search_text = this.refs.search_customer_name.value
        //alert(search_text);
        if (search_text === '') {
            if (this.state.customer_list.length > 0) {
                this.setState({customer_list: [] })
            }
            return
        }

        if (search_text.length < 3) {
            return
        }

        this.getDataCustomerSearch(search_text)
    }

    getDataCustomerSearch (search_text) {
        axios.get(APIURL + '/customer/search/' + search_text)
            .then(res => {
                this.setState({customer_list: res.data})
            }).catch(error => {
            this.setState({customer_list: []})
        })
    }

    onCustomerSelect (customer, event) {
        this.setState({
            customer_select: true,
            customer: customer,
            customer_id: customer.customer_id,
            customer_name: customer.customer_name,
            customer_lastname: customer.customer_lastname,
            customer_card_id: customer.customer_card_id,
            customer_mobile: customer.customer_mobile,
        })
    }

    onResetCustomer() {
        this.setState({
            customer_select: false,
            customer: {},
            customer_id: '',
            customer_name: '',
            customer_lastname: '',
            customer_card_id: '',
            customer_mobile: '',
            customer_list: []
        });
    }

    onSubmitCustomer(event) {
        event.preventDefault()
        let customer_name = event.target.customer_name.value
        let customer_lastname = event.target.customer_lastname.value
        let customer_card_id = event.target.customer_card_id.value
        let customer_mobile = event.target.customer_mobile.value
        let customer_id = this.state.customer_id

        if(customer_name===''){
            AlertWarning('กรุณากรอกข้อมูลลูกค้า');
            return ;
        }

        let data = {
            customer_name: customer_name,
            customer_lastname: customer_lastname,
            customer_card_id : customer_card_id,
            customer_mobile: customer_mobile,
        }

        if (customer_id === '') {
            // add new customer
            axios.post(APIURL + '/customer/', data)
                .then(res => {
                    let customer = res.data;

                    this.setState({
                        customer_select: true,
                        customer: customer,
                        customer_id: customer.customer_id,
                        customer_name: customer.customer_name,
                        customer_card_id: customer.customer_card_id,
                        customer_mobile: customer.customer_mobile,
                    });

                    //this.props.onToggle()
                    //this.props.onLoadCustomerOld()
                })
                .catch(function (error) {
                    //alert("error:"+error)
                    console.log(error)
                })

        } else {

            data = {...data, customer_id: customer_id, "status_field": "show"}

            axios.put(APIURL + '/customer/customer_car_old/'+customer_id, data)
                .then(res => {
                    if (res.status === 200) {
                        AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
                        this.props.onToggle()
                        this.props.onLoadCustomerOld()
                    }
                })
        }
    }

    //ข้อมูลรถ
    handleOnChangeCar(e) {
        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [name]: value
        })
    }

    loadCar(car_id) {
        axios.get(APIURL + '/car/'+car_id)
            .then(res => {
                if(res.status === 200){
                    let car = res.data[0]
                    let car_date_tax = car.car_date_tax === null ? null : format(new Date(car.car_date_tax),'yyyy-MM-dd')
                    let insurance_end_date = car.insurance_end_date === null ? null : format(new Date(car.insurance_end_date),'yyyy-MM-dd')

                    this.setState({
                        car_select: true,
                        car: car,
                        car_id: car.car_id,
                        car_name: car.car_name,
                        car_license_plate_new: car.car_license_plate_new,
                        province_new_id: car.province_new_id,
                        province_name_new: car.province_name_new,
                        car_date_tax:car_date_tax,
                        insurance_end_date:insurance_end_date,
                    })
                }
            })
            .catch(err => console.log(err))
    }

    //searchCar
    searchCar () {
        let search_text = this.refs.search_car_name.value
        //alert(search_text);
        if (search_text === '') {
            if (this.state.car_list.length > 0) {
                this.setState({car_list: [], car_id: '' })
            }
            return
        }

        if (search_text.length < 3) {
            return
        }

        this.getDataCarSearch(search_text)
    }

    getDataCarSearch (search_text) {
        axios.get(APIURL + '/car/search/' + search_text)
            .then(res => {
                this.setState({car_list: res.data})
            }).catch(error => {
            this.setState({car_list: []})
        })
    }

    //onCarSelect
    onCarSelect (car, event) {
        //console.log(car)

        let car_date_tax = car.car_date_tax === null ? null : format(new Date(car.car_date_tax),'yyyy-MM-dd')
        let insurance_end_date = car.insurance_end_date === null ? null : format(new Date(car.insurance_end_date),'yyyy-MM-dd')

        this.setState({
            car_select: true,
            car: car,
            car_id: car.car_id,
            car_name: car.car_name,
            car_license_plate_new: car.car_license_plate_new,
            province_new_id: car.province_new_id === null ? 0 : car.province_new_id,
            province_name_new: car.province_name_new,
            car_date_tax:car_date_tax,
            insurance_end_date:insurance_end_date,
        })
    }

    onResetCar() {
        this.setState({
            car_select: false,
            car: {},
            car_id: '',
            car_name: '',
            car_license_plate_new: '',
            province_new_id: '',
            province_name_new: '',
            car_date_tax: '',
            insurance_end_date: '',
            old_car_departure_date: '',
            old_car_finance_id: '',
            old_car_note: '',
            car_list: []
        });
    }

    onSubmitCarOld(event) {
        event.preventDefault()

        let car_id = this.state.car_id
        let car_name = event.target.car_name.value
        let car_license_plate_new = event.target.car_license_plate_new.value
        let province_new_id = event.target.province_id.value
        let old_car_departure_date = this.state.old_car_departure_date
        let old_car_finance_id = event.target.old_car_finance_id.value
        let car_date_tax = this.state.car_date_tax
        let insurance_end_date = this.state.insurance_end_date
        let old_car_note = event.target.old_car_note.value
        let car_status_id = 21; //car_status_name = ข้อมูลรถเก่า
        let customer_old_id = this.state.customer_id

        /*console.log('car_name=',car_name)
        console.log('car_license_plate_new=',car_license_plate_new)

        console.log('old_car_departure_date=',old_car_departure_date)

        console.log('car_date_tax=',car_date_tax)
        console.log('insurance_end_date=',insurance_end_date)
        console.log('old_car_note=',old_car_note)
        console.log('car_status_id=',car_status_id)
        console.log('customer_old_id=',customer_old_id)*/
        /*console.log('car_id=',car_id)
        console.log('old_car_finance_id=',old_car_finance_id)
        console.log('province_new_id=',province_new_id)*/

        if(car_name===''){
            AlertWarning('กรุณากรอกชื่อรถ');
            return ;
        }

        if(car_license_plate_new===''){
            AlertWarning('กรุณากรอกทะเบียนรถ');
            return ;
        }

        let data = {
            car_name: car_name,
            car_license_plate_new: car_license_plate_new,
            province_new_id : province_new_id,
            old_car_departure_date: old_car_departure_date,
            old_car_finance_id: old_car_finance_id,
            car_date_tax: car_date_tax,
            insurance_end_date: insurance_end_date,
            old_car_note: old_car_note,
            car_status_id : car_status_id,
            customer_old_id : customer_old_id
        }

        //console.log(data)
        //return;

        if (car_id === '') {
            // add new car_old
            axios.post(APIURL + '/car/car_old/', data)
                .then(res => {
                    let car = res.data;
                    //console.log(car)
                    this.setState({
                        car_select: true,
                        car: car,
                        car_id: car.car_id,
                        car_name: car.car_name,
                        car_license_plate_new: car.car_license_plate_new,
                        province_new_id : car.province_new_id,
                        old_car_departure_date: car.old_car_departure_date,
                        old_car_finance_id: car.old_car_finance_id,
                        car_date_tax: car.car_date_tax,
                        insurance_end_date: car.insurance_end_date,
                        old_car_note: car.old_car_note,
                        car_status_id: car.car_status_id,
                        customer_old_id: car.customer_old_id
                    });
                    //console.log('car_name:' , car_name)
                    this.props.onToggle()
                    this.props.onLoadCustomerOld()
                    //this.loadCar()
                })
                .catch(function (error) {
                    //alert("error:"+error)
                    console.log(error)
                })

        } else {

            data = {...data, car_id: car_id, "status_field": "show"}

           // console.log('car_id',data)

            axios.put(APIURL + '/car/car_old/' + car_id, data)
                .then(res => {
                    if (res.status === 200) {
                        AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
                        //this.loadCar()
                        this.props.onToggle()
                        this.props.onLoadCustomerOld()
                    }
                })
        }
    }

    render(){

        let search_customer_list = this.state.customer_list.map((customer_list, index) => {
            return (
                <tr key={index}>
                    <td><strong>ชื่อ</strong> &nbsp;&nbsp; {customer_list.customer_name}</td>
                    <td><strong>นามสกุล</strong> &nbsp;&nbsp; {customer_list.customer_lastname}</td>
                    <td><strong>เลขประจำตัวประชาชน</strong> &nbsp;&nbsp; {customer_list.customer_card_id}</td>
                    <td><strong>เบอร์โทรศัพท์</strong> &nbsp;&nbsp; {customer_list.customer_mobile}</td>
                    <td>
                        <button onClick={() => this.onCustomerSelect(customer_list, this)} type="button"
                                className="btn btn-primary">
                            <i className="fa fa-hand-pointer-o"/>&nbsp;&nbsp; เลือก
                        </button>
                    </td>
                </tr>
            )
        })

        /*let search_car_list = this.state.car_list.map((car_list, index) => {
            return (
                <tr key={index}>
                    <td><strong>ชื่อรถ</strong> &nbsp;&nbsp; {car_list.car_name}</td>
                    <td><strong>ทะเบียนรถ</strong> &nbsp;&nbsp; {car_list.car_license_plate_new}</td>
                    <td><strong>จังหวัด</strong> &nbsp;&nbsp; {car_list.province_name_new}</td>
                    <td>
                        <button onClick={() => this.onCarSelect(car_list, this)} type="button"
                                className="btn btn-primary">
                            <i className="fa fa-hand-pointer-o"/>&nbsp;&nbsp; เลือก
                        </button>
                    </td>
                </tr>
            )
        })*/

        let province_list = this.props.province.map((province, index) => {
            return (
                <option key={index+1} value={province.province_id}>{province.province_name}</option>
            )
        })

        let banks_list = this.props.banks.map((banks, index) => {
            return (
                <option key={index+1} value={banks.bank_id}>{banks.bank_name}</option>
            )
        })


        return (
            <div>

                <div className="row">
                    <div className="col-lg-12">
                        {/*ค้นหาชื่อลูกค้า / เพิ่มลูกค้า*/}
                        <form action="" method="post" onSubmit={this.onSubmitCustomer}>
                            <div className="card">
                                <div className="card-header">
                                    <strong className="text-title">ข้อมูลลูกค้า</strong> &nbsp;&nbsp;

                                    <button
                                        type="button"
                                        className="btn btn-sm btn-head-bar ml-2"
                                        style={styles.buttonHeight}
                                        onClick={this.onResetCustomer}
                                    >
                                        <i className="icon-refresh"/>
                                    </button>

                                </div>
                                <div className="card-block">
                                    <div className="row">
                                        <div className="col-lg-12">

                                            {/* ค้นหาลูกค้า */}

                                            <div className={!this.state.customer_select ? 'row ' : ' hidden'}>
                                                <div className="controls col-md-12">
                                                    <div className="input-group ">
                                                        <input onChange={this.searchCustomer.bind(this)}
                                                               ref="search_customer_name"
                                                               placeholder="ค้นหาชื่อลูกค้าที่เคยลงทะเบียนไว้ ถ้าเป็นลูกค้าใหม่ให้กรอกข้อมูลด้านล่าง ค้น 3 ตัวอักษรขึ้นไป"
                                                               className="form-control"
                                                               size="16"
                                                               type="text"/>

                                                        <span className="input-group-btn ">
                                                             <button className="btn btn-success"
                                                                     type="button"
                                                                     onClick={this.searchCustomer.bind(this)}>ค้นหา
                                                             </button>
                                                        </span>
                                                    </div>

                                                    <div id="show_customer" className="row">
                                                        <div className="controls col-md-12">
                                                            <table className="table table-striped">
                                                                <tbody>
                                                                {search_customer_list}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={ false ? 'row bg-faded' : ' hidden'}>

                                                <div className="card-header controls col-md-12 hidden bg-faded">
                                                    <strong className="text-title">ข้อมูลลูกค้า</strong>
                                                </div>

                                                <div className="card-block">

                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className=" form-group row" style={styles.bottomLine}>
                                                                <label className="col-md-2"><strong> ชื่อลูกค้า </strong></label>

                                                                <div className="col-md-4">
                                                                    <p className="text-justify text-primary">
                                                                        <strong>{this.state.customer.customer_name}</strong></p>
                                                                </div>

                                                                <label className="col-md-2"><strong> นามสกุล </strong></label>

                                                                <div className="col-md-4">
                                                                    <p className="text-justify text-primary">
                                                                        <strong> {this.state.customer.customer_lastname} </strong></p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12">
                                                            <div className=" form-group row" style={styles.bottomLine}>
                                                                <label className="col-md-2"><strong> เลขบัตรประชาชน </strong></label>

                                                                <div className="col-md-4">
                                                                    <p className="text-justify text-primary">
                                                                        <strong>{this.state.customer.customer_card_id}</strong></p>
                                                                </div>

                                                                <label className="col-md-2"><strong> เบอร์โทรศัพท์ </strong></label>

                                                                <div className="col-md-4">
                                                                    <p className="text-justify text-primary">
                                                                        <strong> {this.state.customer.customer_mobile} </strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-group col-sm-3">
                                                    <label htmlFor="customer_name">ชื่อ</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           id="customer_name"
                                                           name="customer_name"
                                                           value={this.state.customer_name || ''}
                                                           onChange={this.handleOnChange}
                                                           placeholder=""
                                                    />
                                                </div>

                                                <div className="form-group col-sm-3">
                                                    <label htmlFor="customer_lastname">นามสกุล</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           id="customer_lastname"
                                                           name="customer_lastname"
                                                           value={this.state.customer_lastname  || ''}
                                                           onChange={this.handleOnChange}
                                                           placeholder=""
                                                    />
                                                </div>

                                                <div className="form-group col-sm-3">
                                                    <label htmlFor="customer_card_id">เลขบัตรประชาชน</label>

                                                    <MaskedInput
                                                        mask="1-1111-11111-11-1"
                                                        className="form-control"
                                                        id="customer_card_id"
                                                        name="customer_card_id"
                                                        value={this.state.customer_card_id  || ''}
                                                        onChange={this.handleOnChange}
                                                        maxLength="20"
                                                        placeholder=""
                                                    />
                                                </div>

                                                <div className="form-group col-sm-3">
                                                    <label htmlFor="customer_mobile">เบอร์โทรศัพท์</label>

                                                    <MaskedInput
                                                        mask="111-111-1111"
                                                        className="form-control"
                                                        id="customer_mobile"
                                                        name="customer_mobile"
                                                        value={this.state.customer_mobile  || ''}
                                                        onChange={this.handleOnChange}
                                                        placeholder=""
                                                    />
                                                </div>

                                                <div className="col-sm-12"><hr/></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div  className={!this.state.customer_select ? 'card-footer text-right ' : ' hidden'}>
                                    <button type="submit"
                                            className="btn btn-sm btn-primary"
                                            disabled={this.state.customer_id !=='' ? true : false}
                                    >
                                        <i className="fa fa-save"/> &nbsp;บันทึกข้อมูลลูกค้า
                                    </button>
                                </div>

                                {
                                    this.state.customer_select === true ? (
                                        <div  className={this.state.customer_select === true ? 'card-footer text-right ' : ' hidden'}>
                                            <button
                                                type="submit"
                                                className="btn btn-sm btn-warning ml-2"
                                                style={styles.buttonHeight}
                                                onClick={() => {
                                                    this.setState({
                                                        is_customer_edit: true
                                                    })

                                                }}
                                            >
                                                <i className="icon-pencil"/> แก้ไขข้อมูลลูกค้า
                                            </button>
                                        </div>
                                    ) : null
                                }

                            </div>
                        </form>

                    </div>
                </div>

                {/*ค้นหารถ / เพิ่มรถ*/}
                <form action="" method="post" onSubmit={this.onSubmitCarOld}>
                    <div className="card">
                        <div className="card-header">
                            <strong className="text-title">ข้อมูลรถ</strong> &nbsp;&nbsp;

                            <button
                                type="button"
                                className="btn btn-sm btn-head-bar ml-2"
                                style={styles.buttonHeight}
                                onClick={this.onResetCar}
                            >
                                <i className="icon-refresh"/>
                            </button>

                        </div>
                        <div className="card-block">
                            <div className="row">
                                <div className="col-lg-12">

                                    {/* ค้นหารถ */}

                                    {/*<div className={!this.state.car_select ? 'row ' : ' hidden'}>
                                        <div className="controls col-md-12">
                                            <div className="input-group ">
                                                <input
                                                    onChange={this.searchCar.bind(this)}
                                                    ref="search_car_name"
                                                    placeholder="ค้นหาทะเบียนรถ 3 ตัวขึ้นไป"
                                                    className="form-control"
                                                    size="16"
                                                    type="text"/>

                                                <span className="input-group-btn ">
                                                             <button className="btn btn-success"
                                                                     type="button"
                                                                     onClick={this.searchCar.bind(this)}>ค้นหา
                                                             </button>
                                                        </span>
                                            </div>

                                            <div id="show_customer" className="row">
                                                <div className="controls col-md-12">
                                                    <table className="table table-striped">
                                                        <tbody>
                                                        {search_car_list}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}

                                    {/*<div className={ false ? 'row bg-faded' : ' hidden'}>

                                        <div className="card-header controls col-md-12 hidden bg-faded">
                                            <strong className="text-title">ข้อมูลรถ</strong>
                                        </div>

                                        <div className="card-block">

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className=" form-group row" style={styles.bottomLine}>
                                                        <label className="col-md-2"><strong> ชื่อรถ </strong></label>

                                                        <div className="col-md-4">
                                                            <p className="text-justify text-primary">
                                                                <strong>{this.state.car_name}</strong></p>
                                                        </div>

                                                        <label className="col-md-2"><strong> ทะเบียนรถ </strong></label>

                                                        <div className="col-md-4">
                                                            <p className="text-justify text-primary">
                                                                <strong> {this.state.car_license_plate_new} </strong></p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className=" form-group row" style={styles.bottomLine}>
                                                        <label className="col-md-2"><strong> จังหวัด </strong></label>

                                                        <div className="col-md-4">
                                                            <p className="text-justify text-primary">
                                                                <strong>{this.state.province_name_new}</strong></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}

                                    <div className="row">
                                        <div className="form-group col-sm-6">
                                            <label htmlFor="car_name">ชื่อรถ</label>
                                            <input type="text"
                                                   className="form-control"
                                                   id="car_name"
                                                   name="car_name"
                                                   value={this.state.car_name  || ''}
                                                   onChange={this.handleOnChangeCar}
                                                   placeholder=""
                                            />
                                        </div>

                                        <div className="form-group col-sm-3">
                                            <label htmlFor="car_license_plate_new">ทะเบียนรถ</label>
                                            <input type="text"
                                                   className="form-control"
                                                   id="car_license_plate_new"
                                                   name="car_license_plate_new"
                                                   value={this.state.car_license_plate_new  || ''}
                                                   onChange={this.handleOnChangeCar}
                                                   placeholder=""
                                            />
                                        </div>

                                        <div className="form-group col-sm-3">
                                            <label htmlFor="province">จังหวัด</label>
                                            <select className="form-control"
                                                    id="province_id"
                                                    name="province_id"
                                                    value={this.state.province_new_id}
                                                    onChange={(e) => {
                                                        let val = e.target.value
                                                        this.setState({
                                                            province_new_id: Number(val)
                                                        })
                                                    }}
                                            >
                                                <option key={0} value={0}>ไม่ระบุ</option>
                                                {province_list}
                                            </select>
                                        </div>

                                        <div className="form-group col-sm-3">
                                            <label htmlFor="old_car_departure_date">วันออกรถ</label>

                                            <ThaiDatePicker value={this.state.old_car_departure_date}
                                                            id="old_car_departure_date"
                                                            name="old_car_departure_date"
                                                            displayFormat={"ddd, DD MMMM YYYY"}
                                                            onChange={(christDate, buddhistDate)=>{
                                                                //console.log(christDate);
                                                                //console.log(buddhistDate);
                                                                this.setState({
                                                                    old_car_departure_date : christDate,
                                                                })
                                                            }} />

                                        </div>

                                        <div className="form-group col-sm-3">
                                            <label htmlFor="old_car_finance_id">จัดไฟแนนซ์กับธนาคาร</label>

                                            <select className="form-control"
                                                    id="old_car_finance_id"
                                                    name="old_car_finance_id"
                                                    value={this.state.bank_id}
                                                    onChange={(e) => {
                                                        let val = e.target.value
                                                        this.setState({
                                                            bank_id: Number(val)
                                                        })
                                                    }}
                                            >
                                                <option key={0} value={0}>ไม่ระบุ</option>
                                                {banks_list}
                                            </select>

                                        </div>

                                        <div className="form-group col-sm-3">
                                            <label htmlFor="car_date_tax">วันครบกำหนดต่อภาษี</label>

                                            <ThaiDatePicker value={this.state.car_date_tax}
                                                            id="car_date_tax"
                                                            name="car_date_tax"
                                                            displayFormat={"ddd, DD MMMM YYYY"}
                                                            onChange={(christDate, buddhistDate)=>{
                                                //console.log(christDate);
                                                //console.log(buddhistDate);
                                                this.setState({
                                                    car_date_tax : christDate,
                                                })
                                            }} />
                                        </div>

                                        <div className="form-group col-sm-3">

                                            <label htmlFor="insurance_end_date">วันครบกำหนดต่อประกัน</label>

                                            <ThaiDatePicker value={this.state.insurance_end_date}
                                                            id="insurance_end_date"
                                                            name="insurance_end_date"
                                                            displayFormat={"ddd, DD MMMM YYYY"}
                                                            onChange={(christDate, buddhistDate)=>{
                                                                //console.log(christDate);
                                                                //console.log(buddhistDate);
                                                                this.setState({
                                                                    insurance_end_date : christDate,
                                                                })
                                                            }} />
                                        </div>

                                        <div className="form-group col-sm-12">
                                            <label htmlFor="old_car_note">หมายเหตุ</label>

                                            <input type="text"
                                                   className="form-control"
                                                   id="old_car_note"
                                                   name="old_car_note"
                                                   value={this.state.old_car_note  || ''}
                                                   onChange={this.handleOnChangeCar}
                                                   placeholder=""
                                            />
                                        </div>

                                        <div className="col-sm-12"><hr/></div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div  className={!this.state.car_select ? 'card-footer text-right ' : ' hidden'}>
                            <button type="submit"
                                    className="btn btn-sm btn-primary"
                                    disabled={this.state.car_id !=='' ? true : false}
                            >
                                <i className="fa fa-save"/> &nbsp;บันทึกข้อมูลรถ
                            </button>
                        </div>

                        {
                            this.state.car_select === true ? (
                                <div  className={this.state.car_select === true ? 'card-footer text-right ' : ' hidden'}>
                                    <button
                                        type="submit"
                                        className="btn btn-sm btn-warning ml-2"
                                        style={styles.buttonHeight}
                                        onClick={() => {
                                            this.setState({
                                                is_car_edit: true
                                            })
                                        }}
                                    >
                                        <i className="icon-pencil"/> แก้ไขข้อมูลรถ
                                    </button>
                                </div>
                            ) : null
                        }
                    </div>
                </form>
            </div>

        )
    }
}

const styles = {
    inputSearch: {
        marginLeft: 20,
        width: 300,
        display: 'inline'
    },

    buttonHeight: {
        height: 36,
        paddingTop:0,
        paddingBottom:0,
        display: 'inline',
        marginTop: '-0.35rem',
        borderRadius:5,
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CustomerOldForm);