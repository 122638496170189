import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import axios from "axios";
import {APIURL} from "../../config/config";
import {AlertError, AlertSuccess} from "../Alert/Alert";
import BDatePicker from "../BDatePicker";


class CustomerCreditForm extends Component{
    constructor (props) {
        super(props)

        this.state = {
            customer_list: [],
            customer_select: false,
            customer: {},
            customer_id: '',
            bank: [],
            finance_id: 0,
            finance_credit_result: '',
            finance_check_date: null,
        }

        // onSubmitCustomerCredit
        this.searchCustomer = this.searchCustomer.bind(this);
        this.getDataCustomerSearch = this.getDataCustomerSearch.bind(this);
        this.onCustomerSelect = this.onCustomerSelect.bind(this);
        this.loadBankDataIsLeasing = this.loadBankDataIsLeasing.bind(this);
        this.onSubmitCustomerCredit = this.onSubmitCustomerCredit.bind(this);
    }

    componentDidMount () {
        this.loadBankDataIsLeasing()
        this.loadInstallmentHistory()

        let form_status = this.props.form_status
        if(form_status === 'edit'){
            let customer_credit = this.props.customer_credit_edit
            let customer = {
                customer_id: customer_credit.customer_id,
                customer_name: customer_credit.customer_name,
                customer_lastname: customer_credit.customer_lastname,
                customer_mobile: customer_credit.customer_mobile,
                customer_card_id: customer_credit.customer_card_id,
                customer_address: customer_credit.customer_address
            };

            this.setState({
                customer_id: customer_credit.customer_id,
                finance_id: customer_credit.finance_id,
                finance_credit_result: customer_credit.finance_credit_result,
                finance_check_date: customer_credit.finance_check_date,
                customer_select: true,
                customer: customer
            });
        }else {
            let customer_id = this.props.customer_id
            let customer = this.props.customer
            this.setState({
                customer_select: true,
                customer_id: customer_id,
                customer: customer
            })
        }
    }

    searchCustomer () {
        let search_text = this.refs.search_customer_name.value
        //alert(search_text);
        this.getDataCustomerSearch(search_text)
    }

    getDataCustomerSearch (search_text) {
        axios.get(APIURL + '/customer/search/' + search_text)
            .then(res => {
                this.setState({customer_list: res.data})
                // alert (res.data)
            }).catch(error => {
            this.setState({customer_list: []})
        })
    }

    onCustomerSelect (customer, event) {
        this.setState({
            customer_select: true,
            customer: customer,
            customer_id: customer.customer_id,
            customer_list: []
        });
    }

    loadBankDataIsLeasing () {
        // axios.get(APIURL + '/bank/')
        axios.get(APIURL + '/bank/get/is_leasing')
            .then(res => {
                this.setState({bank: res.data})
                //alert (res.data)
            }).catch(error => {
            this.setState({bank: []})
        })
    }

    loadInstallmentHistory(){
        axios.get(APIURL + '/installment_history')
            .then(res => {
                this.setState({installment_history: res.data})
                //alert (res.data)
            }).catch(error => {
            this.setState({installment_history: []})
        })
    }

    onSubmitCustomerCredit(e) {
        e.preventDefault();

        let finance_id = this.state.finance_id
        let customer_id = this.state.customer_id
        let finance_credit_result = this.state.finance_credit_result
        let finance_check_date = this.state.finance_check_date
        let username = this.props.username

        if(customer_id === '') {
            AlertError('กรุณาเลือกลูกค้า');
            return
        }

        if(finance_id === 0) {
            AlertError('กรุณาเลือกไฟแนนซ์');
            return
        }

        if(finance_check_date === null || finance_check_date === '') {
            AlertError('กรุณาเลือกวันที่เช็คเครดิต');
            return
        }

        if(finance_credit_result === '') {
            AlertError('กรุณากรอกผลการเช็คเครดิต');
            return
        }

        let data = {
            finance_id,
            customer_id,
            finance_credit_result,
            finance_check_date,
            username
        }

        let form_status = this.props.form_status

        if(form_status === 'add'){
            let url = APIURL +'/customer_credit'
            axios.post(url, data).then(res => {
                let result = res.data;
                if(result === 'success') {
                    AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
                    this.props.closeAndReload()
                }
            })
        } else if(form_status === 'edit') {
            let customer_credit_id = this.props.customer_credit_id
            data = {...data, customer_credit_id: customer_credit_id}
            let url = APIURL +'/customer_credit'
            axios.put(url, data).then(res => {
                let result = res.data;
                if(result === 'success') {
                    AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
                    this.props.closeAndReload()
                }
            })
        }

    }

    render() {
        let width = this.props.width

        let search_customer_list = this.state.customer_list.map((customer_list, index) => {
            return (
                <tr key={index}>
                    <td><strong>ชื่อ</strong> &nbsp;&nbsp; {customer_list.customer_name}</td>
                    <td><strong>นามสกุล</strong> &nbsp;&nbsp; {customer_list.customer_lastname}</td>
                    <td><strong>เลขประจำตัวประชาชน</strong> &nbsp;&nbsp; {customer_list.customer_card_id}</td>
                    <td><strong>เบอร์โทรศัพท์</strong> &nbsp;&nbsp; {customer_list.customer_mobile}</td>
                    <td>
                        <button onClick={() => this.onCustomerSelect(customer_list, this)} type="button"
                                className="btn btn-primary">
                            <i className="fa fa-hand-pointer-o"/>&nbsp;&nbsp; เลือก
                        </button>
                    </td>
                </tr>
            )
        });

        let bank_finance_list = this.state.bank.map((bank, index) => {
            return (
                <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
            )
        });


        return (
            <>
             <Modal
                 isOpen={this.props.is_modal}
                 toggle={this.props.toggle}
                 className={'modal-lg '}
                 style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
             >
                 <ModalBody>
                     <div className="card card-accent-primary ">
                         <div className="card-header">
                             <strong className="text-title">{this.props.form_status==='add'? ' เพิ่ม' : ' แก้ไข'}ประวัติการเช็คเครดิต</strong>
                         </div>

                         <form onSubmit={this.onSubmitCustomerCredit}>

                             <div className="card-block">

                             <div className={this.state.customer_select === false ? 'row ' : ' hidden'}>
                                 <div className="controls col-md-12">
                                     <div className="input-group has-success ">
                                         <input
                                             ref="search_customer_name"
                                             placeholder="ค้นหาชื่อลูกค้า"
                                             className="form-control"
                                             size="16"
                                             type="text"/>

                                         <span className="input-group-btn ">
                                   <button className="btn btn-success"
                                           type="button"
                                           onClick={this.searchCustomer.bind(this)}>ค้นหา
                                   </button>
                              </span>
                                     </div>
                                     <div id="show_customer" className="row">
                                         <div className="controls col-md-12">
                                             <table className="table table-striped">
                                                 <tbody>
                                                 {search_customer_list}
                                                 </tbody>
                                             </table>
                                         </div>
                                     </div>
                                 </div>
                             </div>

                             <div className={this.state.customer_select ? 'row ' : ' hidden'}>

                                 <div className="card-header controls col-md-12 hidden ">
                                     <strong className="text-title">ข้อมูลลูกค้า</strong>
                                 </div>

                                 <div className="card-block has-success">
                                     <div className="row">
                                         <div className="col-lg-12">
                                             <div className=" form-group row" style={styles.bottomLine}>
                                                 <label className="col-md-3"><strong> ชื่อลูกค้า </strong></label>
                                                 <div className="col-md-3">
                                                     <p className="text-justify text-success ">
                                                         <strong>{this.state.customer.customer_name}</strong></p>
                                                 </div>

                                                 <label className="col-md-3"><strong> นามสกุล </strong></label>
                                                 <div className="col-md-3">
                                                     <p className="text-justify text-success">
                                                         <strong> {this.state.customer.customer_lastname} </strong></p>
                                                 </div>
                                             </div>
                                         </div>

                                         <div className="col-lg-12">
                                             <div className=" form-group row" style={styles.bottomLine}>
                                                 <label className="col-md-3"><strong>
                                                     เลขบัตรประชาชน </strong></label>
                                                 <div className="col-md-3">
                                                     <p className="text-justify text-success">
                                                         <strong>{this.state.customer.customer_card_id}</strong></p>
                                                 </div>

                                                 <label className="col-md-3"><strong>
                                                     เบอร์โทรศัพท์ </strong></label>
                                                 <div className="col-md-3">
                                                     <p className="text-justify text-success">
                                                         <strong> {this.state.customer.customer_mobile} </strong></p>
                                                 </div>
                                             </div>
                                         </div>

                                         <div className="col-lg-12">
                                             <div className=" form-group row" style={styles.bottomLine}>
                                                 <label className="col-md-3"><strong>
                                                     ที่อยู่ปัจจุบัน </strong></label>
                                                 <div className="col-md-9">
                                                     <p className="text-justify text-success">
                                                         <strong>{this.state.customer.customer_address}</strong></p>
                                                 </div>
                                             </div>
                                         </div>

                                     </div>
                                 </div>
                             </div>



                             <div className="row">
                                 <div className="form-group col-md-6">
                                     <label htmlFor="finance_id">ชื่อไฟแนนซ์</label>
                                     <select className="form-control"
                                             name="finance_id"
                                             value={this.state.finance_id}
                                            onChange={(e) => {
                                                this.setState({
                                                    finance_id: e.target.value
                                                })
                                            }}
                                     >
                                         <option key={0} value={0}>--กรุณาเลือก--</option>
                                         {bank_finance_list}
                                     </select>
                                 </div>
                                 <div className="form-group col-md-6">
                                     <BDatePicker
                                         isDateFill={this.props.isDateFill}
                                         name={'finance_check_date'}
                                         value={this.state.finance_check_date}
                                         label={'วันที่เช็คเครดิต '}
                                         onUpdateDate={(buddhist_date, christ_date) => {
                                             this.setState({
                                                 finance_check_date: christ_date
                                             })
                                         }}
                                     />
                                 </div>
                             </div>

                             <div className="row">
                                 <div className="form-group col-md-6">
                                     <label htmlFor="finance_credit_result">ผลการเช็คเครดิต </label>
                                     <input type="text"
                                            className="form-control"
                                            name="finance_credit_result"
                                            placeholder=""
                                            maxLength={50}
                                            value={this.state.finance_credit_result}
                                            onChange={(e) => {
                                                this.setState({
                                                    finance_credit_result: e.target.value
                                                })
                                            }}
                                     />
                                 </div>
                                 <div className="form-group col-md-6">

                                 </div>
                             </div>

                         </div>

                             <div className="card-footer text-right">

                                 {
                                     this.props.form_status === 'add' ?
                                         <button type="submit" className="btn btn-success">
                                             <i className="fa fa-save"/> &nbsp;&nbsp; เพิ่ม &nbsp;&nbsp;
                                         </button>
                                         :
                                         <button type="submit" className="btn btn-warning">
                                             <i className="fa fa-pencil"/> &nbsp;&nbsp; แก้ไข &nbsp;&nbsp;
                                         </button>
                                 }

                                 <button type="button"
                                         onClick={()=> this.props.toggle()}
                                         className="btn btn-danger ml-2">
                                     <i className="fa fa-close"/>  &nbsp;&nbsp; ปิด &nbsp;&nbsp;
                                 </button>
                             </div>
                         </form>
                     </div>
                 </ModalBody>
             </Modal>
            </>
        )
    }
}

const styles = {
    bottomLine: {
        borderBottomColor: '#ccc',
        borderBottomStyle: 'solid',
        borderWidth: 0.5,
    }
}

export default CustomerCreditForm
