import React, { Component } from 'react';
import CarInfo from '../../../components/Cars/CarInfo';
import {connect} from 'react-redux';
import axios from 'axios';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane
} from 'reactstrap';
import classnames from 'classnames';
import { APIURL, PORT } from '../../../config/config';
import CarRepairNewForm from '../../../components/CarRepair/CarRepairNewForm';
import PreviewImage from '../../../views/PreviewImage/';
import CarRepairListItem from '../CarRepairListItem/CarRepairListItem';
import CarRepairEditForm from '../CarRepairEditForm'
import { AlertSuccess } from '../../Alert/Alert'
import CarRepairFile from '../CarRepairFile'
import {checkGroupPermission, getGroupPermission} from '../../../services/grouppermission'
import Loading from "../../Loading";
import CarCheckColor from '../../CarCheck/CarCheckColor'

class CarRepairNew extends Component{

  constructor (props){
    super(props)
    this.state = {
      menuID: [23, 3, 302],
      grouppermissions: [],
       car_select_id: '',
       car: {},
       action: "บันทึก",
       user: {} ,
       lists: [],
       not_found: false,
       car_id: '',
       toggle_add: false,
       preview_image_url: '',
       preview_image_select: false,
       delete: false,
       car_repair: {},
       width: 0,
       height: 0,
      is_after_sell: 0,
      //
      toggle_car_repair_edit: false,
      toggle_car_repair_file: false,
      company_setting: null,
      is_date_fill: 0,
      activeTab: '1',
    }

    this.loadGroupPermission = this.loadGroupPermission.bind(this);
    //
    this.toggleCarRepairAdd = this.toggleCarRepairAdd.bind(this);
    this.togglePreviewImage = this.togglePreviewImage.bind(this);
    this.onCarRepairDelete  = this.onCarRepairDelete.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    //
    this.onCarRepairEditSelected = this.onCarRepairEditSelected.bind(this);
    this.toggleCarRepairEdit = this.toggleCarRepairEdit.bind(this);
    this.updateCarRepairNoFile = this.updateCarRepairNoFile.bind(this);
    //
    this.toggleCarRepairFile = this.toggleCarRepairFile.bind(this);
    this.onCarRepairUploadFile = this.onCarRepairUploadFile.bind(this);
    this.loadCompanySetting = this.loadCompanySetting.bind(this);
    this.loadCarRepairByRepairType = this.loadCarRepairByRepairType.bind(this);
    this.toggle = this.toggle.bind(this);
    this.reloadAfterApproveCarColorRepair = this.reloadAfterApproveCarColorRepair.bind(this);

  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  loadCarRepairByRepairType(repair_type_id){
    let is_after_sell = 0;
    let car_id = this.state.car_id;
    let data = {
      repair_type_id: repair_type_id,
      car_id: car_id,
      is_after_sell: is_after_sell
    }

    this.setState({
      isLoading: true
    });
    // console.log('repair_type_id=', repair_type_id)
    axios.post(APIURL + '/car_repair/repair_type', data)
        .then(res => {
          this.setState({lists: res.data, isLoading: false})
        }).catch(error => {
      // alert("error"+error)
      this.setState({lists: []})
    })
  }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    // componentWillUnmount() {
    //     window.removeEventListener('resize', this.updateWindowDimensions);
    // }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    });
  }

  async componentDidMount() {

    this.loadGroupPermission();

    this.mounted = true
    const queryString = require('query-string');
    let car = queryString.parse(this.props.location.search);

    if(this.mounted) {
      let is_after_sell = car.is_after_sell === undefined ? 0 : Number(car.is_after_sell)

      this.setState({
        car_id: car.car_id,
        is_after_sell: is_after_sell,
      }, async () => {

        await this.loadCompanySetting();
        await this.loadCarRepairData()
        await this.loadCarById()

        if(car.toggle==='new'){
          this.setState({
            toggle_add: true,
          });
        }
      });
    }

  }

  async loadCompanySetting() {
    await axios.get(APIURL + '/company_setting/1').then(res => {
      let data = res.data
      let company_setting = data.company_setting
      const is_date_fill = company_setting.is_date_fill;
      this.setState({
        company_setting: company_setting,
        is_date_fill: is_date_fill
      })
    })
  }

  componentWillUnmount() {
      this.mounted = false
      this.updateWindowDimensions();
      window.removeEventListener('resize', this.updateWindowDimensions);
  }

  loadGroupPermission() {
    let type = this.props.type
    let menuID = this.state.menuID
    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  async loadCarById(){
    await axios.get(APIURL + '/car/'+this.state.car_id)
      .then(res => {
        if(this.mounted) {
          this.setState({ car: res.data[0] })
        }
      }).catch(error => {
        //alert("error"+error)
        if(this.mounted) {
          this.setState({ car: {} })
        }
      })
  }

  async loadCarRepairData() {
    let car_id = this.state.car_id
    let is_after_sell = this.state.is_after_sell
    // await axios.get(APIURL + '/car_repair/car/'+car_id)
    await axios.get(APIURL + '/car_repair/car/'+car_id+'/'+is_after_sell)
      .then(res => {
        if(this.mounted) {
          if (res.data.length === 0) {
            this.setState({ lists: res.data, not_found: true })
          } else {
            this.setState({ lists: res.data, not_found: false })
          }
        }
      }).catch(error => {
        // alert("error"+error)
        if(this.mounted) {
          this.setState({ lists: [] })
        }
      })
  }

  toggleCarRepairAdd() {
    this.setState({
        toggle_add: !this.state.toggle_add
    });
  }

  addCarRepair(event, car_repair_data){
    event.preventDefault();
    let that = this ;
    let car_repair_name = event.target.car_repair_name.value
    let center_id = event.target.center_id.value
    // let car_repair_cost = event.target.car_repair_cost.value
    // let car_repair_send = event.target.car_repair_send.value
    // let car_repair_complete = event.target.car_repair_complete.value
    let car_repair_cost = car_repair_data.car_repair_cost
    let car_repair_send = car_repair_data.car_repair_send
    let car_repair_complete = car_repair_data.car_repair_complete
    let is_pickup = car_repair_data.is_pickup
    let pickup_date = car_repair_data.pickup_date
    let repair_type_id = car_repair_data.repair_type_id

    // console.log('center_id=', center_id)
    // console.log('car_repair_name=', car_repair_name)
    // console.log(car_repair_data)
    // return

    let car_id = this.state.car_id
    // console.log('state car_id=', car_id)
    // car_repair_data
    // car_repair_complete:"2023-07-06"
    // car_repair_cost:0
    // car_repair_send:"2023-07-06"
    // is_pickup:0
    // pickup_date: null

    let car_repair_note = event.target.car_repair_note.value
    let is_after_sell = event.target.is_after_sell.value

    if (this.state.is_search_car === true) {
      car_id = event.target.car_id.value
      console.log('input car_id=', car_id)
    }


    const data = new FormData();

    data.append('file', event.target.car_repair_file.files[0], event.target.car_repair_file.files[0].name);
    data.append('center_id', center_id);
    data.append('car_repair_name', car_repair_name);
    data.append('car_repair_cost', car_repair_cost);
    data.append('car_repair_send', car_repair_send);
    data.append('car_repair_complete', car_repair_complete);
    data.append('car_id', car_id);
    data.append('user_created', this.state.user.user);
    data.append('car_repair_note', car_repair_note);
    data.append('is_after_sell', is_after_sell);
    data.append('is_pickup', is_pickup);
    data.append('pickup_date', pickup_date);
    data.append('repair_type_id', repair_type_id);

    axios.post(APIURL+'/car_repair', data)
      .then(function(response){
          that.setState({
            toggle_add: !that.state.toggle_add
          });
          that.loadCarById()
          that.loadCarRepairData()
      })
      .catch(function(error){
         alert('มีข้อผิดพลาด : '+error)
      });

  }

  reloadAfterApproveCarColorRepair() {
    this.loadCarById()
    this.loadCarRepairData()
  }

  addCarRepairNoFile(event, car_repair_data){
     event.preventDefault();
    let that = this ;
    let car_repair_name = event.target.car_repair_name.value
    let center_id = event.target.center_id.value

    // let car_repair_cost = event.target.car_repair_cost.value
    // let car_repair_send = event.target.car_repair_send.value
    // let car_repair_complete = event.target.car_repair_complete.value

    let car_repair_cost = car_repair_data.car_repair_cost
    let car_repair_send = car_repair_data.car_repair_send
    let car_repair_complete = car_repair_data.car_repair_complete
    let is_pickup = car_repair_data.is_pickup
    let pickup_date = car_repair_data.pickup_date
    // repair_type_id: repair_type_id
    let repair_type_id = car_repair_data.repair_type_id

    let car_id = this.state.car_id

    let car_repair_note = event.target.car_repair_note.value
    let is_after_sell = event.target.is_after_sell.value

    let data = {
      car_repair_name: car_repair_name,
      car_repair_cost: car_repair_cost,
      center_id: center_id,
      car_repair_send: car_repair_send,
      car_repair_complete: car_repair_complete,
      car_id: car_id,
      user_created: this.state.user.user,
      car_repair_note : car_repair_note,
      is_after_sell: is_after_sell,
      is_pickup: is_pickup,
      pickup_date: pickup_date,
      repair_type_id: repair_type_id
    }
    // console.log(data)
    // return

    axios.post(APIURL+'/car_repair/nofile/', data)
      .then(function(response){
        that.setState({
          toggle_add: !that.state.toggle_add
        });
        that.loadCarById()
        that.loadCarRepairData()
      })
      .catch(function(error){
        console.log('มีข้อผิดพลาด : ', error)
      });

  }

  updateCarRepairNoFile(event, car_repair_data){
    event.preventDefault();

    let that = this ;

    let car_repair_id = event.target.car_repair_id.value
    let car_repair_name = event.target.car_repair_name.value
    let center_id = event.target.center_id.value

    // let car_repair_cost = event.target.car_repair_cost.value
    // let car_repair_send = event.target.car_repair_send.value
    // let car_repair_complete = event.target.car_repair_complete.value

    let car_id = this.state.car_id

    let car_repair_cost = car_repair_data.car_repair_cost
    let car_repair_send = car_repair_data.car_repair_send
    let car_repair_complete = car_repair_data.car_repair_complete
    let is_pickup = car_repair_data.is_pickup
    let pickup_date = car_repair_data.pickup_date
    let repair_type_id = car_repair_data.repair_type_id

    let car_repair_note = event.target.car_repair_note.value

    let data = {
      car_repair_id: car_repair_id,
      car_repair_name: car_repair_name,
      car_repair_cost: car_repair_cost,
      center_id: center_id,
      car_repair_send: car_repair_send,
      car_repair_complete: car_repair_complete,
      car_id: car_id,
      user_updated: this.state.user.user,
      car_repair_note : car_repair_note,
      is_pickup: is_pickup,
      pickup_date: pickup_date,
      repair_type_id: repair_type_id
    }
    // console.log(data)
    // return

    axios.put(APIURL+'/car_repair/edit/'+car_repair_id, data).then(res => {
      let result = res.data.result
      if(result === 'success'){
        AlertSuccess("แก้ไขการซ่อมเรียบร้อยแล้ว")
        this.setState({
          toggle_car_repair_edit: false
        }, () => {
          that.loadCarById();
          that.loadCarRepairData();
        })
      }
    }).catch(err => {
      console.log('error: ', err)
    })
  }

  updateCarRepair(){

  }

  changeCarRepair(){

  }

  onPreviewSelected(url){
     this.setState({
        preview_image_url: url,
        preview_image_select: true
     });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  onCarRepairDelete(car_repair){
    this.setState({car_repair: car_repair}, () => this.toggleDelete ())
  }

  toggleDelete () {
    this.setState({
      delete: !this.state.delete
    })
  }

  onCarRepairEditSelected(car_repair) {
    // let car_repair_id = car_repair.car_repair_id
    this.setState({car_repair: car_repair}, () => this.toggleCarRepairEdit())
  }

  toggleCarRepairEdit() {
    this.setState({
      toggle_car_repair_edit: !this.state.toggle_car_repair_edit
    })
  }

  onCarRepairUploadFile(car_repair) {
    this.setState({car_repair: car_repair}, () => this.toggleCarRepairFile())
  }

  toggleCarRepairFile() {
    let toggle = !this.state.toggle_car_repair_file
    this.setState({
      toggle_car_repair_file: toggle
    }, () => {
      if(toggle === false) {
        this.setState({
          car_repair: {}
        })
      }
    })
  }

  carRepairDeleteConfirm (car_repair) {
     //alert("carRepairDeleteConfirm="+id)
     var that = this ;
     axios.put(APIURL + '/car_repair/'+ car_repair.car_repair_id,{
       car_id: car_repair.car_id,
       car_repair_cost: car_repair.car_repair_cost,
       car_repair_file: car_repair.car_repair_file
     }).then(res => {
      if (res.status === 200) {
        that.loadCarById()
        that.loadCarRepairData()
        that.toggleDelete ()
      }
     })
  }

  render(){
     const {
       is_after_sell,
       car,
       user
     } = this.state

     let width = this.state.width

     return (
       <div className="card">
         <div className="card-block">

           {
             Object.keys(this.state.car).length > 0 && is_after_sell === 0 ? (
               <CarInfo
                   car={this.state.car}
                   userType={this.state.user.type}
               />
             ) : null
           }


           <Loading isLoading={this.state.isLoading} />

           <div className="row">
             <div className="col-lg-12">
               <Nav tabs>

                 {
                   checkGroupPermission(3, this.state.grouppermissions).viewed ? (
                       <NavItem>
                         <NavLink
                             className={classnames({ active: this.state.activeTab === '1' })}
                             onClick={() => { this.toggle('1'); }}
                         >
                           <i className="icon-check"/> ซ่อม/ปรับแต่ง
                         </NavLink>
                       </NavItem>
                   ) : null
                 }

                 {
                   checkGroupPermission(302, this.state.grouppermissions).created ? (
                       <NavItem>
                         <NavLink
                             className={classnames({ active: this.state.activeTab === '2' })}
                             onClick={() => { this.toggle('2'); }}
                         >
                           <i className="icon-check"/> ประเมินทำสีรถ
                         </NavLink>
                       </NavItem>
                   ) : null
                 }


               </Nav>
             </div>
           </div>

           <div className="row">
             <div className="col-lg-12">
               <TabContent activeTab={this.state.activeTab}>
                 <TabPane tabId="1">
                   <div className="card">
                     <div className="card-block">
                       <div className="row">

                         <div className="col-md-12">

                           <div className="card">
                             <div className="card-header">
                               <strong className="text-title">

                                 {is_after_sell === 0 ? 'รายการซ่อมบำรุง':
                                   Object.keys(this.state.car).length > 0 ? 'รายการซ่อมบำรุง(หลังขาย):: '+ this.state.car.car_detail+' ทะเบียน: ' +this.state.car.car_license_plate_new : 'รายการซ่อมบำรุง(หลังขาย)'}

                               </strong>


                               {
                                 /* PORT === 9040 && this.state.user.type !== 'admin'? 'hidden' : "btn btn-info float-right" */
                                 checkGroupPermission(3, this.state.grouppermissions).created ? (
                                     <button
                                         className={"btn btn-info float-right"}
                                         onClick={this.toggleCarRepairAdd} >
                                       <i className="icon-plus"/>&nbsp; เพิ่มข้อมูลการซ่อม
                                     </button>
                                 ) : null
                               }


                             </div>
                             <div className="card-block">

                               <CarRepairListItem
                                 lists={this.state.lists}
                                 onCarRepairDelete={this.onCarRepairDelete}
                                 onCarRepairEditSelected={this.onCarRepairEditSelected}
                                 onCarRepairUploadFile={this.onCarRepairUploadFile}
                                 user_type={this.state.user.type}
                                 menuID={23}
                                 grouppermissions={this.state.grouppermissions}
                                 onSearchByRepairType={this.loadCarRepairByRepairType}
                                 sell_status_check={false}
                               />

                             </div>
                           </div>

                         </div>


                       </div>
                     </div>
                   </div>
                 </TabPane>
                 <TabPane tabId="2">
                   {
                     Object.keys(car).length === 0 ?
                       'Loading...' :
                       <CarCheckColor
                         car={car}
                         user={user}
                         reloadCarRepair={this.reloadAfterApproveCarColorRepair}
                       />
                   }
                 </TabPane>
               </TabContent>
             </div>
           </div>





       </div>

         <Modal isOpen={this.state.toggle_add} toggle={this.toggleCarRepairAdd}
                className={'modal-lg '}
                style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
         >
           <ModalBody>

             <CarRepairNewForm
                onToggle={this.toggleCarRepairAdd}
                onCarRepairSubmit={
                  {
                    add: this.addCarRepair.bind(this),
                    addNoFile: this.addCarRepairNoFile.bind(this),
                    cancel: this.toggleCarRepairAdd
                  }
                }
                car={this.state.car}
                is_date_fill={this.state.is_date_fill}
                is_after_sell={this.state.is_after_sell === null ? 0 : this.state.is_after_sell}
                //
                is_search_car = {false}
                setIsAfterSell={(is_sell) => {
                  this.setState({is_after_sell: is_sell})
                }}
                setCar={(car) => {
                  this.setState({car: car})
                }}
                //
             />

           </ModalBody>
         </Modal>


         {
           this.state.toggle_car_repair_edit
           &&
           <Modal isOpen={this.state.toggle_car_repair_edit} toggle={this.toggleCarRepairEdit}
                  className={'modal-lg '}
                  style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
           >
             <ModalBody>
               <CarRepairEditForm
                 is_date_fill={this.state.is_date_fill}
                 onToggle={this.toggleCarRepairEdit}
                 onCarRepairSubmit={
                   {
                     edit: this.updateCarRepairNoFile.bind(this),
                     cancel: this.toggleCarRepairEdit
                   }
                 }
                 car={this.state.car}
                 car_repair={this.state.car_repair}
               />
             </ModalBody>
           </Modal>
         }


         {
           Object.keys(this.state.car_repair).length === 0 ? null : (
             <CarRepairFile
               toggle = {this.state.toggle_car_repair_file}
               onToggle = {this.toggleCarRepairFile}
               width={width}
               car_repair={this.state.car_repair}
               onReload={() => {
                 this.loadCarById();
                 this.loadCarRepairData();
               }}
             />
           )
         }

         <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
           <ModalBody>
              <PreviewImage
                imageUrl={this.state.preview_image_url}
              />
           </ModalBody>
         </Modal>

         <Modal isOpen={this.state.delete} toggle={this.toggleDelete.bind(this)}
                className={'modal-danger '}>
           <ModalHeader toggle={this.toggleDelete.bind(this)}>ลบรายการซ่อมบำรุง</ModalHeader>
           <ModalBody>
             <strong> คุณต้องการลบข้อมูล ใช่หรือไม่ ?? </strong>
             <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
           </ModalBody>
           <ModalFooter>
             <Button color="primary"
                     onClick={this.carRepairDeleteConfirm.bind(this, this.state.car_repair)}>ตกลง</Button>{' '}
             <Button color="secondary" onClick={this.toggleDelete.bind(this)}>ยกเลิก</Button>

           </ModalFooter>
         </Modal>


       </div>
     )
   }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  type: state.auth.type,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarRepairNew);
