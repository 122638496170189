import React, { Component } from 'react';

class PreviewImage extends Component {
  render() {
    return (
        <img
          src={this.props.imageUrl}
          className="col-md-12"
          alt="preview"
        />
    )
  }
}

export default PreviewImage;

