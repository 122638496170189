import React, { Component } from "react";
import axios from "axios";
import { save } from "save-file";
import { APIURL, PORT } from "../../../config/config";
import { NumberUtil } from '../../../utils/number-util';
import Loading from '../../Loading'
// import { da } from "date-fns/locale";

class CarCost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      car_id: null,
      customer_id: null,
      car_sell_id: null,
      car: null,
      is_preview: true,
      headers: [],
      details: [],
      vatInfo: null,
      isLoading: false
    };
    this.loadCarById = this.loadCarById.bind(this);
    this.carCostExcel = this.carCostExcel.bind(this);
    this.carCostPreview = this.carCostPreview.bind(this);
  }

  componentDidMount() {
    const queryString = require("query-string");
    let car = queryString.parse(this.props.location.search);
    let car_id = car.car_id;
    let customer_id = car.customer_id === undefined ? "" : car.customer_id;
    let car_sell_id = car.car_sell_id === undefined ? "" : car.car_sell_id;

    this.setState(
      {
        car_id: car_id,
        customer_id: customer_id,
        car_sell_id: car_sell_id,
      },
      () => {
        // load car data
        this.loadCarById();
        this.carCostPreview();
        // load car cost for preview
      }
    );
  }

  loadCarById() {
    axios
      .get(APIURL + "/car/" + this.state.car_id)
      .then((res) => {
        let car = res.data[0];
        this.setState({
          car: car,
        });
      })
      .catch((error) => {
        console.log("error:", error);
        this.setState({ car: null });
      });
  }

  carCostPreview() {
    const { car_id, customer_id, car_sell_id } = this.state;
    let reqData = {
        car_id: car_id,
        customer_id: customer_id,
        car_sell_id: car_sell_id,
      };

      this.setState({
        isLoading: true,
      });

      let uri = APIURL + "/car_cost_excel/preview_at2";
       axios
       .post(uri, reqData)
       .then(async (res) => {

            let data = res.data;

            this.setState({
                isLoading: false,
                headers: data.headers,
                details: data.details,
                vatInfo: data.vatInfo,
            });
       })
       .catch((error) => {
        console.log("error:", error);
      });

  }

  carCostExcel() {
    // call api for gen ต้นทุนรายคัน
    const { car_id, customer_id, car_sell_id } = this.state;

    let reqData = {
      car_id: car_id,
      customer_id: customer_id,
      car_sell_id: car_sell_id,
    };

    this.setState({
      isLoading: true,
    });

      let uri = APIURL + "/car_cost_excel";

      if (PORT === 9043) {
        uri = APIURL + "/car_cost_excel/at2";
      }

      setTimeout(()=> {
        axios
          .post(uri, reqData)
          .then(async (res) => {

            // save excel file
            const excelBuffer = res.data.excelBuffer;
            const currentDatetime = res.data.currentDatetime;
            const carLicenseShort = res.data.carLicenseShort;
            const fileName = "ต้นทุนรายคัน_" + carLicenseShort + "_" + currentDatetime + ".xlsx";

            this.setState({
              isLoading: false,
            });

            await save(excelBuffer, fileName);
          })
          .catch((error) => {
            console.log("error:", error);
          });
      }, 1200)



  }

  render() {
    const { car, headers, details, vatInfo } = this.state;

    // 2 สีดำ , 1 สีฟ้า
    // มี vat เป็นสีดำ
    // person_vat_id === 2 ? "#000000" : "#07a4e7",
    let headerRows = headers.map((item, index) => {
        let person_vat_id = vatInfo.person_vat_id
        let vat_buy = vatInfo.vat_buy

        let myStyle = styles.textBlueHeader
        if (vat_buy > 0 ) {
            myStyle = styles.textHeader
        }

        return (
            <tr key={index}>
                <td style={{...myStyle, width: 140}} >{item.col1}</td>
                <td style={myStyle} >{item.col2}</td>
                <td style={myStyle} >{item.col3}</td>
                <td style={myStyle} >{item.col4}</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
        )
    });


    let vat_buy = vatInfo === null ? 0 : vatInfo.vat_buy
    let sumVat = 0
    let sumIncome = 0
    let sumExpense = 0
    let profit = 0
    let detailRows = details.map((item, index) => {
        let myStyle = styles.textTable
        if(item.row === 10){
            myStyle = styles.textBoldTable
        }else if (item.row >=12 && item.row <=15) {
            myStyle = styles.textBlueTable
        }

        let col3 =  isNaN(item.col3) ? item.col3 : NumberUtil.addCommas(item.col3)
        let col4 =  isNaN(item.col4) ? item.col4 : NumberUtil.addCommas(item.col4)
        let col5 =  isNaN(item.col5) ? item.col5 : NumberUtil.addCommas(item.col5)
        let col6 =  isNaN(item.col6) ? item.col6 : NumberUtil.addCommas(item.col6)
        let col7 =  isNaN(item.col7) ? item.col7 : NumberUtil.addCommas(item.col7)

        if(!isNaN(item.col3) && item.row > 11 ) {
            sumVat += Number(item.col3)
        }

        if(!isNaN(item.col4)) {
            sumIncome += Number(item.col4)
        }
        if(!isNaN(item.col5)) {
            sumExpense += Number(item.col5)
        }
        if(!isNaN(item.col6)) {
            profit += Number(item.col6)
        }

        return (
            <tr key={index}>
                <td style={myStyle} >{item.col1}</td>
                <td style={myStyle} >{item.col2}</td>
                <td style={{...myStyle, width: 120, textAlign: 'right'}} >{col3}</td>
                <td style={{...myStyle, width: 120, textAlign: 'right'}} >{col4}</td>
                <td style={{...myStyle, width: 120, textAlign: 'right'}} >{col5}</td>
                <td style={{...myStyle, width: 120, textAlign: 'right'}} >{col6}</td>
                <td >{col7}</td>
                <td >{item.col8}</td>
            </tr>
        )
    });

    let myStyle = styles.textBoldTable
    let vatSumTotal = sumVat - vat_buy
    let profitTotal = sumIncome - sumExpense
    let tableLastRow = <tr>
        <td style={myStyle} > &nbsp; </td>
        <td style={myStyle} > ***รวม </td>
        <td style={{...myStyle, width: 120, textAlign: 'right'}} >{NumberUtil.addCommas(vatSumTotal)} </td>
        <td style={{...myStyle, width: 120, textAlign: 'right'}} >{NumberUtil.addCommas(sumIncome)} </td>
        <td style={{...myStyle, width: 120, textAlign: 'right'}} >{NumberUtil.addCommas(sumExpense)} </td>
        <td style={{...myStyle, width: 120, textAlign: 'right'}} >{NumberUtil.addCommas(profitTotal)} </td>
        <td >&nbsp;</td>
        <td >&nbsp;</td>
    </tr>

    return (
      <div className="card card-accent-primary">

        <Loading isLoading={this.state.isLoading} />

        <div className="card-header">
          <div className="row">
            <div className="col-10">
              <strong className="text-title">ต้นทุนรายคัน</strong>
            </div>
            <div className="col-2">
              <button
                onClick={() => this.carCostExcel()}
                type="button"
                style={{ width: 150 }}
                className={"btn btn-success  btn-sm btn-block"}
              >
                <i className="icon-cloud-download">&nbsp;Excel ต้นทุนรายคัน</i>
              </button>
            </div>
          </div>
        </div>

        <div className="card-block">
          <div className="row">
             <div className="col-12">
              <table>
                <tbody>
                    {headerRows}
                    <tr><td colSpan={9}> &nbsp; </td></tr>
                    {detailRows}
                    {tableLastRow}
                </tbody>
              </table>
             </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
    bottomLine: {
        borderBottomColor: '#ccc',
        borderBottomStyle: 'solid',
        borderWidth: 0.5,
    },
    textHeader: {
        height: 36,
        fontSize: 16,
        fontWeight: 'bold',
        color: '#000000',
    },
    textBlueHeader: {
        height: 36,
        fontSize: 16,
        fontWeight: 'bold',
        color: '#07a4e7',
    },
    textBoldTable: {
        height: 36,
        fontSize: 16,
        color: '#000',
        borderBottomColor: '#ccc',
        fontWeight: 'bold',
        textAlign: 'center',
        border: '1px solid'
    },
    textTable: {
        height: 36,
        fontSize: 16,
        color: '#000',
        borderBottomColor: '#ccc',
        border: '1px solid'
    },
    textBlueTable: {
        height: 36,
        fontSize: 16,
        border: '1px solid',
        color: '#02b7db',
        borderColor: 'black'
    }
}


export default CarCost;
