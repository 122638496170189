import React, { Component } from 'react'
import {checkGroupPermission} from "../../../services/grouppermission";

class CustomerFindCarListRow extends Component {
  render () {
    let customerList = this.props.customer_list.map((customer,index)=>{
      return (
        <tr key={index}>
          <td className="text-center">{index+1}</td>
          <td>
           ชื่อ-นามสกุล: { customer.customer_name } { customer.customer_lastname } <br/>
           ที่อยู่: {customer.customer_address} <br/>
           มือถือ: { customer.customer_mobile } <br/>
           แหล่งที่มา: { customer.source_customer } <br/>
          </td>
          <td>
           ยี่ห้อ: {customer.car_brand_name}  รุ่น:  {customer.car_model_name}<br/>
           เกียร์: {customer.car_gear_name}  สี: {customer.car_color_name}
           ปีรถเริ่ม:  {customer.car_year_start_name} <br/>
           ปีรถสิ้นสุด:  {customer.car_year_final_name} <br/>
          </td>
          <td className="text-center">

              {
                  checkGroupPermission(601, this.props.grouppermissions).modified ? (
                      <>
                          <button className="btn btn-sm btn-warning btn-md-width"
                                  onClick={this.props.customerCallbacks.edit.bind(null,customer)}
                          >
                              <i className="fa fa-edit"/> แก้ไข</button>
                          <br/>
                      </>
                  ) : null
              }

              {
                  checkGroupPermission(601, this.props.grouppermissions).deleted ? (
                      <button className="btn btn-sm btn-danger btn-md-width mt-2"
                              onClick={this.props.customerCallbacks.delete.bind(null,customer)}
                      >
                          <i className="fa fa-remove"/> ลบ</button>
                  ) : null
              }

              {
                  checkGroupPermission(601, this.props.grouppermissions).modified === 0
                  && checkGroupPermission(601, this.props.grouppermissions).deleted === 0
                      ? <i className="icon-close"/> : null
              }

          </td>
        </tr>
      )
    });

    let tbEmpty = (
      <tr><td colSpan={4} className="text-danger text-center">ไม่พบข้อมูล</td></tr>
    )

    let tbBody = this.props.customer_list.length===0 ? tbEmpty : customerList

    return (
      <div className="card-block">
        <table className="table table-striped">
          <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">ข้อมูลลูกค้า</th>
            <th className="text-center">ข้อมูลรถที่หา</th>
            <th className="text-center" style={{width: 100}}>จัดการ</th>
          </tr>
          </thead>
          <tbody>
           {tbBody}
          </tbody>
        </table>
      </div>
    )
  }
}

export default CustomerFindCarListRow