import React, { Component } from 'react';
import axios from 'axios';
import DatePicker from "react-datepicker";
import {APIURL} from "../../../config/config";
import moment from "moment";
import {AlertSuccess} from "../../Alert/Alert";


class AppointmentEdit extends Component{

    constructor(props){
        super(props);
        this.state = {
            lists: [],
            load_data: true,
            danger: false,
            appointment_list:[],
            appointmentSelect: null,
            appointment:'',
            appointment_id: 0,
            appointment_detail : '',
            appointment_date : new Date(),
            appointment_list_id: 0
        }
        this.handleDateAppoinment = this.handleDateAppoinment.bind(this);
        this.handleOnSubmitAddAppointment = this.handleOnSubmitAddAppointment.bind(this);
    }

    componentDidMount() {
        this.loadAppointmentListData()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        let toggle_edit = this.props.toggle_edit

        if (toggle_edit === true) {
            if (this.props.appointmentSelect !== null) {
                let appointmentSelect = this.props.appointmentSelect
                let appointment_id = appointmentSelect.appointment_id
                let curr_appointment_id = parseInt(this.state.appointment_id, 10)

                if (appointment_id !== curr_appointment_id) {

                    let appointment_id = appointmentSelect.appointment_id
                    let appointment_detail = appointmentSelect.appointment_detail
                    let date = appointmentSelect.appointment_date
                    let appointment_date = moment(date).format('YYYY-MM-DD')
                    let date_new = new Date(appointment_date)
                    let appointment_list_id = appointmentSelect.appointment_list_id
                    this.refs.appointment_list_id.value = appointment_list_id

                    this.setState({
                        appointment_id: appointment_id,
                        appointment_detail: appointment_detail,
                        appointment_date: date_new,
                        appointment_list_id: appointment_list_id
                    })
                }
            }
        }
    }

    handleDateAppoinment(date) {
        this.setState({
            appointment_date: date
        });
    }

    handleOnSubmitAddAppointment(event){
        event.preventDefault()

        let appointment_list_id = parseInt(this.refs.appointment_list_id.value)
        let appointment_date = moment(this.state.appointment_date).format('YYYY-MM-DD')
        let appointment_detail = this.refs.appointment_detail.value
        let user = this.props.user

        if(appointment_list_id === ''){
            alert('กรุณาเลือกรายการนัดหมาย')
            return;
        }

        let appointment_id = this.state.appointment_id
        const  data = {
            appointment_list_id,
            appointment_date,
            appointment_detail,
            user,
            appointment_id
        }

        axios.put(APIURL + '/appointment', data)
            .then(response => {
                let result = response.data.result
                if(result === 'success'){
                    this.props.onToggle();
                    AlertSuccess("แก้ไขการนัดหมายเรียบร้อยแล้ว")
                    this.props.onReload();
                }

            })
            .catch(function (error) {
                console.log('error: ', error)
            })
    }

    loadAppointmentListData () {
        axios.get(APIURL + '/appointment_list/')
            .then(res => this.setState({appointment_list: res.data}))
            .catch(error => {
                this.setState({appointment_list: []})
            })
    }

    render(){

        let appointment_list = this.state.appointment_list.map((appointment_list,index)=>{
            return(
                <option key={index} value={appointment_list.appointment_list_id}>{appointment_list.appointment_list_name}</option>
            )
        })

        return(
            <div className="card card-accent-info">
                <form action="" method="post"  onSubmit={this.handleOnSubmitAddAppointment.bind(this)}>
                    <div className="card-header">
                        <strong className="text-title">แก้ไขข้อมูลนัดหมาย</strong>
                    </div>

                    <div className="card-block">
                        <div className="row">
                            <div className="form-group col-sm-3">
                                <label htmlFor="appointment_date">วันที่นัดหมาย :</label>
                            </div>
                            <div className="form-group col-sm-9">
                                <DatePicker
                                    selected={this.state.appointment_date}
                                    onChange={this.handleDateAppoinment}
                                    dateFormat="yyyy-MM-dd"
                                    type="text"
                                    className="form-control"
                                    id="appointment_date"
                                    ref="appointment_date"
                                    name="appointment_date"
                                    placeholder=""
                                />
                            </div>

                            <div className="form-group col-sm-3">
                                <label htmlFor="appointment_list_id">รายการนัดหมาย :</label>
                            </div>
                            <div className="form-group col-sm-6">
                                <select
                                    className="form-control"
                                    id="appointment_list_id"
                                    ref="appointment_list_id"
                                    name="appointment_list_id"
                                    value={this.state.appointment_list_id}
                                    onChange={(e) => {
                                        let id = parseInt(e.target.value, 10)
                                        this.setState({
                                            appointment_list_id: id
                                        })
                                    }}
                                >
                                    {appointment_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-3"></div>

                            <div className="form-group col-sm-3">
                                <label htmlFor="appointment_detail">ข้อความนัดหมาย :</label>
                            </div>
                            <div className="form-group col-sm-6">
                                <textarea className="form-control"
                                          placeholder="ใส่รายละเอียดเช่น ชื่อลูกค้า / รายละเอียดรถ"
                                          id="appointment_detail"
                                          ref="appointment_detail"
                                          name="appointment_detail"
                                          value={this.state.appointment_detail}
                                          onChange={(e) => {
                                              let value = e.target.value
                                              this.setState({
                                                  appointment_detail: value
                                              })
                                          }}
                                          rows={8}
                                >
                                </textarea>
                            </div>

                            <div className="col-sm-12 text-center">
                                <div className="card-block">
                                    <button type="reset"
                                            className="btn  btn-danger mr-3" style={styles.btnWidth}
                                            onClick={this.props.onToggle}
                                    >
                                        <i className="fa fa-refresh"></i> ยกเลิก</button>

                                    <button type="submit" className="btn btn-warning"  style={styles.btnWidth} >
                                        <i className="icon-check"></i>  แก้ไขนัดหมาย</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const styles = {
    bottomLine: {
        borderBottomColor: '#ccc',
        borderBottomStyle: 'solid',
        borderWidth: 0.5,
    },
    btnWidth: {
        width: 170,
    }
}


export default (AppointmentEdit);