/**
 * Created by devmaster on 7/15/2017 AD.
 */
import React, { Component } from 'react'
import {IMAGE_FULL_URL, IMAGE_NOPHOTO_URL} from "../../../config/config";
import {Modal, ModalBody} from "reactstrap";
import PreviewImage from "../../../views/PreviewImage";

export  default class MaintenanceCenterListRow extends Component{
    constructor (props){
        super(props)

        this.state = {
            preview_image_url: '',
            preview_image_select: false,
        }
        this.togglePreviewImage = this.togglePreviewImage.bind(this)
    }

    togglePreviewImage() {
        this.setState({
            preview_image_select: !this.state.preview_image_select
        });
    }

    onPreviewSelected(url){
        this.setState({
            preview_image_url: url,
            preview_image_select: true
        });
    }


    render(){

      let centerList;

      if (this.props.center_list.length === 0){
          centerList = <tr>
              <td colSpan={5} className="text-center"><span className="text-danger">ไม่พบข้อมูล</span></td>
          </tr>
      }else {

          centerList = this.props.center_list.map((center, index) => {

              let image_url = ''
              if( center.center_file === 'nophoto.png') {
                  image_url = IMAGE_NOPHOTO_URL
              } else {
                  // check https
                  if (center.center_file !== null) {
                      if (center.center_file.includes('https')) {
                          image_url = center.center_file
                      }else{
                          image_url = IMAGE_FULL_URL + center.center_file
                      }
                  }else{
                      image_url = IMAGE_NOPHOTO_URL
                  }
              }

              return (
                  <tr key={index}>
                    <td>{ index+1 }</td>
                    <td>{ center.center_name }</td>

                    <td className="text-left ">
                      ที่อยู่: { center.center_address }
                      <br/>
                      โทร: { center.center_tel }

                      {
                        center.repair_center_id !== '0' ? (
                          <>
                            <br/>
                            <br/>
                           <span className="text-danger">*** ลบไม่ได้เนื่องจากมีการใช้งานศูนย์ซ่อมที่ข้อมูลการซ่อม</span>
                          </>
                        ) : null
                      }

                    </td>

                      <td className="text-left text-nowrap">
                          <img
                              src={image_url}
                              style={{'cursor' : 'pointer', 'width': '120px', 'height': '100px'}}
                              onClick={()=>this.onPreviewSelected(image_url)}
                              alt="เอกสาร"
                          />
                      </td>
                    <td className="text-right" style={{width: 100}} >
                          <button className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                                  style={{width: 100}}
                                  onClick={this.props.centersCallbacks.edit.bind(null,center)}
                          >
                              <i className="fa fa-edit"/> แก้ไข
                          </button>

                        <button className="btn btn-sm btn-success btn-md-width mr-2 mb-2"
                                style={{width: 100}}
                                onClick={()=>this.props.onCenterUploadFile(center)}
                        >
                            <i className="fa fa-file"/> แนบไฟล์
                        </button>

                      {
                        center.repair_center_id === '0' ? (
                            <button className="btn btn-sm btn-danger btn-md-width mr-2 mb-2"
                                    style={{width: 100}}
                                    onClick={this.props.centersCallbacks.delete.bind(null,center)}
                            >
                              <i className="fa fa-remove"/> ลบ
                            </button>
                        ) : (
                          <button className="btn btn-sm btn-danger btn-md-width mr-2 mb-2"
                                  style={{width: 100}}
                                  onClick={()=> {

                                  }}
                                  disabled={true}
                          >
                            <i className="fa fa-remove"/> ลบ
                          </button>
                        )
                      }

                    </td>
                  </tr>
              )
          });
      }

    return (
        <div>
            <table className="table table-striped table-responsive">
                <thead>
                <tr>
                    <th className="text-left">#</th>
                    <th className="text-left text-nowrap">ศูนย์ซ่อมบำรุง</th>
                    <th className="text-center text-nowrap">ที่อยู่ / เบอร์โทรศัพท์</th>
                    <th className="text-center text-nowrap">ไฟล์ภาพ</th>
                    <th className="text-center" style={{width: 100}}>จัดการ</th>
                </tr>
                </thead>
                <tbody>
                { centerList }
                </tbody>
            </table>

            <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
                <ModalBody>
                    <PreviewImage
                        imageUrl={this.state.preview_image_url}
                    />
                </ModalBody>
            </Modal>
        </div>



    );
  }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    },
    buttonFile: {
        borderRadius:5,
        height: 36,
        width: 90
    }
}