import React, { Component } from 'react'
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import { Modal, ModalBody } from 'reactstrap'
import classnames from 'classnames'
import { APIURL } from '../../../config/config'
import axios from 'axios/index'
import CustomerFindCarAdd from '../../../components/CustomerFindCar/CustomerFindCarAdd'
import CustomerFindCarListRow from '../CustomerFindCarListRow'
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";
import {checkGroupPermission, getGroupPermission} from "../../../services/grouppermission";
import {connect} from "react-redux";

class CustomerFindCarList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      menuID: [601],
      grouppermissions: [],
      user: JSON.parse(this.props.user),
      activeTab: '1',
      lists: [],
      toggleCustomerFindCar: false,
      customer: {},
      width: 0,
      height: 0
    }
    this.loadCustomerFindCar = this.loadCustomerFindCar.bind(this);
    this.toggleCustomerFindCar = this.toggleCustomerFindCar.bind(this);
    //
    this.addCustomerFindCar = this.addCustomerFindCar.bind(this);
    this.deleteCustomer = this.deleteCustomer.bind(this);
    this.editCustomer = this.editCustomer.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.loadGroupPermission = this.loadGroupPermission.bind(this);
  }

  loadGroupPermission() {
    let type = this.state.user.type
    let menuID = this.state.menuID
    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidMount() {
    CarsUtil.removeCarSelect()
    CarSellUtil.removeCarSellSelect()

    this.loadGroupPermission();
    this.loadCustomerFindCar();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  loadCustomerFindCar() {
    axios.get(APIURL + '/customer_find_car')
      .then(res => {
        this.setState({lists: res.data});
      })
  }

  toggleCustomerFindCar() {
    this.setState({
      toggleCustomerFindCar: !this.state.toggleCustomerFindCar,
      actionType: 'บันทึก',
      customer: {}
    });
  }

  onSearch() {
    var search_text = this.refs.input_search.value

    if (search_text === '') return;

    axios.get(APIURL + '/customer_find_car/search/'+search_text)
      .then(res => {
        this.setState({lists: res.data});
      })

  }

  addCustomerFindCar(CustomerFindCar) {
    if(CustomerFindCar.actionType === 'บันทึก') {
      axios.post(APIURL + '/customer_find_car/', CustomerFindCar)
        .then(res => {
          if (res.status === 200) {
            this.loadCustomerFindCar()
            this.toggleCustomerFindCar()
          }
        })
    }else if(CustomerFindCar.actionType === 'แก้ไข') {
      axios.post(APIURL + '/customer_find_car/edit/', CustomerFindCar)
        .then(res => {
          if (res.status === 200) {
            this.loadCustomerFindCar()
            this.toggleCustomerFindCar()
          }
        })
    }
  }

  deleteCustomer(CustomerFindCar) {
    if(window.confirm('ลบข้อมูลลูกค้าหารถ ?')) {
      var data = {
        customer_id: CustomerFindCar.customer_id,
        customer_find_car_id: CustomerFindCar.customer_find_car_id
      }
      axios.post(APIURL + '/customer_find_car/delete/', data)
        .then(res => {
          if (res.status === 200) {
            this.loadCustomerFindCar()
          }
        })
    }
  }

  editCustomer(customer) {
    this.setState({
      toggleCustomerFindCar: !this.state.toggleCustomerFindCar,
      actionType: 'แก้ไข',
      customer: customer
    });
  }

  render () {
    let width = this.state.width

    return (
      <div className="col-md-12 mb-4">
        <Nav tabs>
          {
            checkGroupPermission(601, this.state.grouppermissions).viewed ? (
                <NavItem>
                  <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.toggle('1'); }}
                  >
                    <i className="icon-energy"></i> ลูกค้าหารถ
                  </NavLink>
                </NavItem>
            ) : null
          }
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="card card-accent-success">
                  <div className="card-header card-customer">
                    <strong className="text-title"> รายชื่อลูกค้า </strong>
                    <input
                      onChange={this.onSearch}
                      ref="input_search"
                      style={styles.inputSearch}
                      className="form-control"
                      placeholder="ค้นหาตามชื่อ หรือ เบอร์โทร"
                      type="text"
                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {
                      checkGroupPermission(601, this.state.grouppermissions).created ? (
                          <button type="button"
                                  onClick={this.toggleCustomerFindCar}
                                  className="btn btn-head-bar float-right mt-1"
                          >
                            <i className="icon-user-follow"></i>&nbsp;เพิ่มลูกค้าหารถ
                          </button>
                      ) : null
                    }
                  </div>

                  <CustomerFindCarListRow
                    grouppermissions = {this.state.grouppermissions}
                    customer_list={this.state.lists}
                    customerCallbacks={
                      {
                        delete: this.deleteCustomer,
                        edit: this.editCustomer,
                      }
                    }
                  />

                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>

        <Modal isOpen={this.state.toggleCustomerFindCar} toggle={this.toggleCustomerFindCar}
               className={'modal-lg ' + this.props.className}
               style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
        >
          <ModalBody>
            <CustomerFindCarAdd
              onToggle={this.toggleCustomerFindCar}
              onSubmit={this.addCustomerFindCar}
              actionType={this.state.actionType}
              customer={this.state.customer}
            />
          </ModalBody>
        </Modal>

      </div>
    )
  }
}

const styles = {
  inputSearch: {
    marginLeft: 20,
    width: 300,
    display: 'inline'
  },
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  type: state.auth.type,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

// export default CustomerFindCarList;
export default connect(mapStateToProps)(CustomerFindCarList)