import React, { Component } from 'react';
import MaskedInput from 'react-maskedinput';
// Button, ModalHeader ModalFooter
import { Modal, ModalBody } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/th';
import axios from 'axios';
import { APIURL } from '../../../config/config';
import { AlertSuccess, AlertWarning } from '../../Alert/Alert';
moment.locale('th');

class CarOwnerForm extends Component{
  constructor (props) {
    super(props);
    this.state = {
      car_owner_id: null, // for edit
      owner_order: '',
      owner_name: '',
      owner_address: '',
      owner_phone: '',
      owner_registration: '',
      owner_registration_mask: '',
      car_license: '',
      province_id: '', // จว ทะเบียนรถ

      startDateCustomerNew1: moment().format('YYYY-MM-DD'),
      // load
      provinces: [],
      width: 0,
      height: 0

    }
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleDateRegister = this.handleDateRegister.bind(this);
    //
    this.loadProvinceData = this.loadProvinceData.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onMaskedDateRegister = this.onMaskedDateRegister.bind(this);
    this.convertCCtoBB = this.convertCCtoBB.bind(this);
    this.convertBBtoCC = this.convertBBtoCC.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidMount () {
    this.loadProvinceData()

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    // console.log('ก่อนหน้า prevProps.action=', prevProps.action)
    // console.log('ปัจจุบัน this.props.action=', this.props.action)
    if (this.props.action !== prevProps.action || this.props.carOwner !== prevProps.carOwner) {
      if (this.props.action === 'add') {
        this.setState({
          car_owner_id: '',
          owner_order: '',
          owner_name: '',
          owner_address: '',
          owner_phone: '',
          car_license: '',
          province_id: '',
          owner_registration: '',
          owner_registration_mask: ''
        })
      } else if (this.props.action === 'edit') {
        let carOwner = this.props.carOwner
        console.log(carOwner)
        // console.log('carOwner.owner_registration=', carOwner.owner_registration)
        let owner_registration = ''
        let owner_registration_mask = ''

        if (carOwner.owner_registration !== null && carOwner.owner_registration !== '0000-00-00') {
          owner_registration = moment(carOwner.owner_registration).format('YYYY-MM-DD')
          owner_registration_mask = this.convertCCtoBB(owner_registration)
          this.setState({
            car_owner_id: carOwner.car_owner_id,
            owner_order: carOwner.owner_order,
            owner_name: carOwner.owner_name,
            owner_address: carOwner.owner_address,
            owner_phone: carOwner.owner_phone,
            car_license: carOwner.car_license,
            province_id: carOwner.province_id,
            owner_registration: owner_registration,
            owner_registration_mask: owner_registration === '' ? null : owner_registration_mask
          })
        } else {
          this.setState({
            car_owner_id: carOwner.car_owner_id,
            owner_order: carOwner.owner_order,
            owner_name: carOwner.owner_name,
            owner_address: carOwner.owner_address,
            owner_phone: carOwner.owner_phone,
            car_license: carOwner.car_license,
            province_id: carOwner.province_id,
            // owner_registration: owner_registration,
            // owner_registration_mask: owner_registration === '' ? null : owner_registration_mask
          })
        }

        console.log('owner_registration=', owner_registration)
        console.log('owner_registration_mask=', owner_registration_mask)

      }
    }
  }

  loadProvinceData () {
    axios.get(APIURL + '/province/')
      .then(res => {
        this.setState({provinces: res.data})
        //console.log(res.data)
        //alert (res.data)
      }).catch(error => {
      this.setState({provinces: []})
    })
  }

  handleOnSubmit(e) {
    e.preventDefault()
    let car_id = this.props.car_id
    let user = this.props.user

    let car_owner_id = this.state.car_owner_id
    let owner_order = this.state.owner_order
    let owner_name = this.state.owner_name
    let owner_address = this.state.owner_address
    let owner_phone = this.state.owner_phone
    let car_license = this.state.car_license
    let province_id = this.state.province_id
    let owner_registration = this.state.owner_registration

    if (owner_order === '') {
      AlertWarning('กรุณากรอกลำดับที่')
      return
    }

    if (owner_name === '') {
      AlertWarning('กรุณากรอกชื่อเจ้าของเดิม')
      return
    }

    if (car_license === '') {
      AlertWarning('กรุณากรอกทะเบียนรถ')
      return
    }

    if (province_id === '') {
      AlertWarning('กรุณาเลือกจังหวัด')
      return
    }

    console.log('car_id=', car_id)
    let data = {
      car_id: car_id,
      user: user,
      car_owner_id: car_owner_id,
      owner_order: owner_order,
      owner_name: owner_name,
      owner_address: owner_address,
      owner_phone: owner_phone,
      car_license: car_license,
      province_id: province_id,
      owner_registration: owner_registration,
    }
    console.log('data=', data)
    if (this.props.action === 'add') {
      let url = APIURL + '/car_owner/'
      axios.post(url, data)
        .then(res => {
          if (res.data.result === 'success') {
            AlertSuccess('เพิ่มข้อมูลเจ้าของเดิมเรียบร้อยแล้ว')
            this.props.toggle();
            this.props.reload();
          }

        }).catch(error => {
        // console.log(error)
      })
    } else if (this.props.action === 'edit') {
      let url = APIURL + '/car_owner/'+car_owner_id
      axios.put(url, data)
        .then(res => {
          if (res.data.result === 'success') {
            AlertSuccess('แก้ไขข้อมูลเจ้าของเดิมเรียบร้อยแล้ว')
            this.props.toggle();
            this.props.reload();
          }

        }).catch(error => {
        // console.log(error)
      })
    }
  }

  onMaskedDateRegister (e){
    let buddhist_date = e.target.value;
    // console.log('buddhist_date=', buddhist_date)
    // owner_registration_buddhist
    // alert('date='+date);
    if(buddhist_date.length === 10){
      let cc_date = this.convertBBtoCC(buddhist_date)
      this.handleDateRegister(cc_date)
    }else if(buddhist_date.length === 0) {
      this.setState({
        owner_registration: '0000-00-00'
      })
    }
  }

  handleDateRegister(date) {
    let owner_registration = moment(date).format('YYYY-MM-DD')
    // console.log('owner_registration=', owner_registration)
    this.setState({
      owner_registration: owner_registration
    });
  }

  onInputChange(e) {
    console.log(e.target.name, e.target.value)
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  convertCCtoBB(cc_date) { // "1972-10-31"
    let date_arr=cc_date.split('-');
    let dd = date_arr[2];
    let mm = date_arr[1];
    let yyyy = Number(date_arr[0])+543;

    if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
      //alert(cc_date);
      return dd+'-'+mm+'-'+yyyy ;
    }
    return moment() ;
  }

  convertBBtoCC(buddhist_date){ //dd-mm-yyyy to yyyy-mm-dd
    let date_arr=buddhist_date.split('-');
    let dd = date_arr[0];
    let mm = date_arr[1];
    let yyyy = Number(date_arr[2])-543;
    let cc_date= yyyy+'-'+mm+'-'+dd ;
    if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
      //alert(cc_date);
      return cc_date ;
    }
    return moment() ;
  }

  render() {

    let width = this.state.width
    let province_list = this.state.provinces.map((province,index)=>{
      return(
        <option key={index} value={province.province_id}>{province.province_name}</option>
      )
    })

    return (
      <Modal isOpen={this.props.isModal}
             toggle={this.props.toggle}
             className={'modal-lg '}
             style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}>
        <ModalBody>
          <div className={this.props.action === 'add' ? "card card-accent-info" : "card card-accent-warning"} >
            <form action="" method="post" onSubmit={this.handleOnSubmit.bind(this)}>

              <div className="card-header">
                <strong className="text-title">{this.props.action==='add' ? 'เพิ่ม' : 'แก้ไข'}ข้อมูลเจ้าของรถ</strong>
              </div>

              <div className="card-block">
                <div className="row">
                  <div className="form-group col-sm-2">
                    <label htmlFor="customer_name">ลำดับที่</label>
                    <input type="number"
                           className="form-control"
                           name="owner_order"
                           placeholder=""
                           value={this.state.owner_order}
                           onChange={this.onInputChange}
                    />
                  </div>
                  <div className="form-group col-sm-10">
                    <label htmlFor="customer_lastname">ชื่อเจ้าของเดิม</label>
                    <input type="text"
                           className="form-control"
                           name="owner_name"
                           placeholder=""
                           value={this.state.owner_name}
                           onChange={this.onInputChange}
                    />
                  </div>

                  <div className="form-group col-sm-12">
                    <label htmlFor="customer_address">ที่อยู่ปัจจุบัน</label>
                    <input type="text"
                           className="form-control"
                           name="owner_address"
                           placeholder=""
                           value={this.state.owner_address}
                           onChange={this.onInputChange}
                    />
                  </div>

                  <div className="form-group col-sm-6">
                    <label htmlFor="customer_card_id">หมายเลขโทรศัพท์</label>
                    <input type="text"
                           className="form-control hidden"
                           name="owner_phone_old-bk"
                           maxLength="50"
                           placeholder=""
                           // value={this.state.owner_phone}
                           // onChange={this.onInputChange}
                    />

                    <MaskedInput
                        mask="111-111-1111"
                        name="owner_phone"
                        maxLength="50"
                        placeholder=""
                        className="form-control"
                        value={this.state.owner_phone}
                        onChange={this.onInputChange}
                    />
                  </div>

                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <hr />
                  </div>
                  <div className="col-sm-12">
                    <strong>ข้อมูลรถ</strong>
                  </div>

                  <div className="form-group col-sm-6" style={{marginTop: 20}}>
                    <label htmlFor="car_license">ทะเบียนรถ</label>
                    <input type="text"
                           className="form-control"
                           name="car_license"
                           placeholder="ทะเบียนรถ"
                           value={this.state.car_license}
                           onChange={this.onInputChange}
                    />
                  </div>

                  <div className="form-group col-sm-6" style={{marginTop: 20}}>
                    <label htmlFor="guarantor_address">จังหวัด</label>
                    <select className="form-control"
                            name="province_id"
                            value={this.state.province_id}
                            onChange={this.onInputChange}
                    >
                      <option value=""> เลือกจังหวัด </option>
                      {province_list}
                    </select>
                  </div>

                  <div className="form-group col-sm-6">
                    <label htmlFor="customer_birth_text">วันที่จดทะเบียน (พ.ศ.)</label>
                    <MaskedInput
                      mask="11-11-1111"
                      name="owner_registration_mask"
                      placeholder="dd-mm-yyyy"
                      onChange={this.onMaskedDateRegister}
                      className="form-control"
                      value={this.state.owner_registration_mask}
                    />
                    <input className="form-control hidden"
                           type="text"
                           name="owner_registration"
                           value={this.state.owner_registration}
                           onChange={()=>{}}
                    />

                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <button type="button"
                        className="btn btn-sm btn-danger mr-2"
                        onClick={this.props.toggle}
                >
                  <i className="icon-close"/> ยกเลิก
                </button>

                {
                  this.props.action === 'add' ?
                      <button type="submit" className="btn btn-primary">
                        <i className="icon-plus"/>  บันทึกข้อมูล
                      </button> :
                      <button type="submit" className="btn btn-warning">
                        <i className="icon-pencil"/>  แก้ไขข้อมูล
                      </button>
                }
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default CarOwnerForm
