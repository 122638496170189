/**
 * Created by devmaster on 7/15/2017 AD.
 */

import React, { Component } from 'react'
import moment from 'moment'

class MaintenanceCenterListForm extends Component{
  constructor (props){
        super(props)
        this.state = {
            //center_name: '',
            //center_address: '',
            //center_tel: '',
            center: this.props.center,
            startDate: moment()
        }
       this.handleDateChange = this.handleDateChange.bind(this)
       this.changeCenter = this.changeCenter.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if( JSON.stringify(nextProps.center) !== JSON.stringify(this.props.center) ) {
      this.setState({ center: nextProps.center });
    }
  }

  handleDateChange(date) {
    this.setState({
      startDate: date
    });
  }

  changeCenter(event){
    event.preventDefault();
    //alert (event.target.name);
    let xname = event.target.name
    if(xname==="center_name"){
      this.setState ({center:{
          ...this.state.center,
          center_name: event.target.value
        }})
    }else if(xname==="center_address"){
      this.setState ({center:{
          ...this.state.center,
          center_address: event.target.value
        }})
    }else if(xname==="center_tel"){
      this.setState ({center:{
          ...this.state.center,
          center_tel: event.target.value
        }})

    }
  }

  render(){
    return (
      <div className="card card-accent-success">
        <div className="card-header card-customer">
          <strong className="text-title">เพิ่มศูนย์ซ่อมบำรุง</strong>
        </div>
          <form action="" method="post"
                onSubmit={ (this.props.actionType==="แก้ไข")? this.props.onCenterSubmit.update :this.props.onCenterSubmit.add  }>

            <div className="card-block">
              <div className="form-group">
                  <label htmlFor="center_name">ศูนย์ซ่อมบำรุง </label>
                  <input name="center_name"
                         value={this.state.center.center_name || ''}
                         type="text"
                         className="form-control"
                         placeholder="กรอกศูนย์ซ่อมบำรุง"
                         style={styles.inputSearch}
                         onChange={this.changeCenter}/>
              </div>
              <div className="form-group">
                  <label htmlFor="center_address">ที่อยู่ </label>
                  <input name="center_address"
                         value={this.state.center.center_address || ''}
                         type="text"
                         className="form-control"
                         placeholder="กรอกที่อยู่ศูนย์ซ่อมบำรุง"
                         style={styles.inputSearch}
                         onChange={this.changeCenter}/>

              </div>
              <div className="form-group">
                  <label htmlFor="center_tel">เบอร์โทรศัพท์ </label>
                  <input name="center_tel"
                         value={this.state.center.center_tel || ''}
                         type="text" className="form-control"
                         placeholder="กรอกเบอร์โทรศัพท์"
                         style={styles.inputSearch}
                         onChange={this.changeCenter}/>

              </div>

            </div>

          <div className="card-footer text-right">
              <button type="reset"
                      className="btn btn-sm btn-danger mr-2"
                      style={styles.button}
                      onClick={()=>{
                          this.setState({
                              center:{}
                          })
                      }}
              >
                  <i className="fa fa-refresh"/> ยกเลิก</button>
              <button type="submit"
                      className={this.props.actionType==="แก้ไข"? "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}
                      style={styles.button}>
                  <i className={this.props.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/> {this.props.actionType}</button>
          </div>
          </form>
      </div>
    );
  }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    },

    inputSearch: {
        borderRadius:5,
        display: 'inline'
    }
}
export  default MaintenanceCenterListForm;