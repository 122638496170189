/**
 * Created by Tukta on 15/7/2560.
 */
import React, { Component } from 'react'
import BankForm from '../../../components/Bank/BankForm'
import BankListRow from '../../../components/Bank/BankListRow'
import axios from 'axios'
import { APIURL } from '../../../config/config'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  NavItem,
  NavLink,
  Nav,
  TabPane,
  TabContent
} from 'reactstrap'
import { connect } from 'react-redux'
import RetailerForm from "../../SparePart/RetailerForm";
import CompanySetting from "../../CompanySetting";
import classnames from 'classnames';
import BankListRowSetting from "../BankListRowSetting/BankListRowSetting";
import Loading from "../../Loading";


class BankList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      lists: [],
      load_data: true,
      danger: false,
      bank_id: 0,
      action: "บันทึก",
      bank: '' ,
      user: {} ,
      activeTab: '1',
      isLoading: false,
      is_search: false,
      isBankUpdate: false
    }
    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  /*toggle(tab) {
    // console.log(tab)
    // console.log(typeof tab)
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      },()=>{
        if(this.state.activeTab === '2' ){
          this.loadData();
        }
      });
    }
  }*/

  loadData () {
      axios.get(APIURL + '/bank/')
          .then(res => {
            this.setState({lists: res.data, load_data: false, bank_id: 0, danger: false, bank:{}})
          }).catch(error => {
        this.setState({lists: [], load_data: true, bank_id: 0, danger: false})
      })
  }

  /*loadData () {
    this.setState({
      isLoading: true
    }, ()=>{
      axios.get(APIURL + '/bank/')
          .then(res => {
            this.setState({lists: res.data, load_data: false, bank_id: 0, danger: false, bank:{}, isLoading: false})
          }).catch(error => {
        this.setState({lists: [], load_data: true, bank_id: 0, danger: false, isLoading: false})
      })
    })
  }*/

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    });
  }

  componentDidMount () {
     this.loadData()
  }

  toggleDanger () {
    this.setState({
      danger: !this.state.danger
    })
  }

  toggleDangerDelete (id) {
    //alert("toggleDangerDelete="+id)
    axios.delete(APIURL + '/bank/' + id).then(res => {
      if (res.status === 200) {
        this.loadData()
        this.setState({action: "บันทึก"});
      }
    })
  }

  deleteBank (bank) {
    this.setState({bank_id: bank.bank_id, bank:bank})
    //alert("bank_id="+bank.bank_id);
    this.toggleDanger()
  }

  editBank (bank) {
    //var id = bank.bank_id
    this.setState({action: "แก้ไข", bank: bank, bank_id: bank.bank_id});
  }

  addBank(event){
    event.preventDefault()
    let that = this ;
    let bank_name = event.target.bank_name.value
    let bank_name_full = event.target.bank_name_full.value
    let bank_address_line1 = event.target.bank_address_line1.value
    let bank_address_line2 = event.target.bank_address_line2.value
    let bank_tax_id = event.target.bank_tax_id.value

    if(bank_name===''){
      return ;
    }
    event.target.bank_name.value = ""
    axios.post(APIURL + '/bank/', {
      bank_name: bank_name,
      bank_name_full: bank_name_full,
      bank_address_line1: bank_address_line1,
      bank_address_line2: bank_address_line2,
      bank_tax_id: bank_tax_id,
      username: this.state.user.user
    })
      .then(function (response) {
        //console.log(response)
        that.loadData()
        that.setState({
          bank: '',
          bank_id: 0,
          action: "บันทึก",
          isBankUpdate: true
        })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  updateBank(event){
    event.preventDefault()
    let that = this ;
    let bank_name = event.target.bank_name.value
    let bank_name_full = event.target.bank_name_full.value
    let bank_address_line1 = event.target.bank_address_line1.value
    let bank_address_line2 = event.target.bank_address_line2.value
    let bank_tax_id = event.target.bank_tax_id.value
    let bank_id = this.state.bank_id ;
    //alert("bank_id="+bank_id+"  :: "+bank_name);

    if(bank_name===''){
      return ;
    }
    event.target.bank_name.value = ""
    axios.put(APIURL + '/bank/'+bank_id, {
      bank_id: bank_id,
      bank_name: bank_name,
      bank_name_full: bank_name_full,
      bank_address_line1: bank_address_line1,
      bank_address_line2: bank_address_line2,
      bank_tax_id: bank_tax_id,
      status_field: 'show',
      username: this.state.user.user
    })
      .then(res => {
        // console.log(response)
        that.loadData()
        that.setState({ bank: {}, bank_id: 0, action: "บันทึก", isBankUpdate: true})
      })
      .catch(function (error) {
        console.log(error)
      })

  }

  onSearch(){
    var search_text = this.refs.input_search.value

    if(search_text==='')
      return;

    axios.get(APIURL + '/bank/search/'+search_text)
      .then(res => {
        // console.log(res)
        this.setState({lists: res.data, load_data: false, bank_id: 0, danger: false, is_search: true})
      }).catch(error => {
      // console.log(error)
        this.setState({lists: [], load_data: true, bank_id: 0, danger: false})
    })
  }

  onRefresh(){
    this.refs.input_search.value = ''
    this.setState({
      is_search: false
    })
    this.loadData()
  }

  render () {
    return (
        <div className="col-md-12 mb-4">
          {/*<Loading isLoading={this.state.isLoading} />*/}

          <Nav tabs>
            <NavItem>
              <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
              >
                <i className="icon-plus"/> &nbsp;เพิ่มธนาคาร/ลิสซิ่ง
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
              >
                <i className="icon-settings"/> &nbsp;ตั้งค่าการใช้งานธนาคาร/ลิสซิ่ง
              </NavLink>
            </NavItem>
          </Nav>


          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-sm-7">
                      <div className="card card-accent-info">
                        <div className="card-header">
                          <strong className="text-title">ธนาคาร</strong>
                          <input
                              onChange={this.onSearch.bind(this)}
                              ref="input_search"
                              style={styles.inputSearch}
                              className="form-control"
                              placeholder="ค้นหาธนาคารตามชื่อ"
                              type="text"
                          />&nbsp;&nbsp;&nbsp;
                          <button className="btn btn-head-bar"
                                  style={styles.buttonHeight}
                                  onClick={this.onRefresh.bind(this)}

                          >
                            <i className="icon-refresh"/>
                          </button>
                        </div>

                        <div className="card-block" style={styles.overflowY}>
                          <BankListRow
                              bank_list={this.state.lists}
                              bankCallbacks={
                                {
                                  delete: this.deleteBank.bind(this),
                                  edit: this.editBank.bind(this)
                                }
                              }
                              is_search={this.state.is_search}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-5">
                      <BankForm
                          onBankSubmit={
                            {
                              add: this.addBank.bind(this),
                              update: this.updateBank.bind(this),
                            }
                          }
                          actionType={this.state.action}
                          bank={this.state.bank}
                      />
                    </div>
                  </div>
                    <Modal isOpen={this.state.danger} toggle={this.toggleDanger.bind(this)}
                           className={'modal-danger '}>
                      <ModalHeader toggle={this.toggleDanger.bind(this)}>ลบข้อมูล</ModalHeader>
                      <ModalBody>
                        <strong> คุณต้องการลบข้อมูล {this.state.bank.bank_name} ใช่หรือไม่ ?? </strong>
                        <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary"
                                onClick={this.toggleDangerDelete.bind(this, this.state.bank_id)}>ตกลง</Button>{' '}
                        <Button color="secondary" onClick={this.toggleDanger.bind(this)}>ยกเลิก</Button>
                      </ModalFooter>
                    </Modal>
                </div>
              </div>
            </TabPane>
          </TabContent>


          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="2">
              <BankListRowSetting
                isBankUpdate={this.state.isBankUpdate}
                onSetBankUpdate={(is_update)=> {
                  this.setState({isBankUpdate: is_update});
                }}
              />
            </TabPane>
          </TabContent>
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

const styles = {
  inputSearch: {
    marginLeft: 15,
    width: 250,
    borderRadius:5,
    display: 'inline'
  },
  buttonHeight: {
    height: 36,
    paddingTop:0,
    paddingBottom:0,
    borderRadius:5,
    display: 'inline',
    marginTop: '-0.35rem'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

export default connect(mapStateToProps)(BankList);
