/**
 * Created by devmaster on 13/12/2017 AD.
 */
import React,{Component} from 'react'
import axios from 'axios';
import {APIURL, PORT, WEBSITEURL} from '../../../config/config';
import {CopyToClipboard} from 'react-copy-to-clipboard';


class CarShare extends Component {

  constructor (props){
    super(props)
    // http://bosscarsoft.com/bdemo/totyta/yaris/11/xxxxx.html
    let car_share = this.props.car.car_share

    var input_text_share = this.getShareUrl(car_share)
    var share_active = true

    if(car_share==="hidden"){
      share_active = false
    }

    this.state={
      url: input_text_share,
      share_active: share_active,
      car_share: car_share,
      copied: false,
      copied_short: false,
      short_url: '',
    }

  }

  componentDidMount() {
    // console.log('WEBSITEURL=', WEBSITEURL)
  }

  getShareUrl(car_share){
    if(car_share==="show") {
      let car = this.props.car
      let car_name_start = car.car_name.replace('/','_')
      let name_remove_not_ascii = car_name_start.replace(/[^A-Za-z 0-9 .,?""!@#$%^&*()-_=+;:<>/\\|}{[]`~]*/g, '')
      let car_name = encodeURIComponent(name_remove_not_ascii); // xxx.replace(' ','_')

      // console.log('WEBSITEURL=', WEBSITEURL)
      // let share_url = WEBSITEURL + car.car_brand_name + "/" + car_name + "/" + car.car_id + "/" + car.car_upload_code + ".html"
      // let share_url = WEBSITEURL + "car_view" + "/" + car_name + "/" + car.car_id + "/" + car.car_upload_code
      // this.getShortUrl(share_url)
        if(PORT === 9025 || PORT === 9053) {
            // nalicarr and nalicarceo
            return WEBSITEURL + "car_view2/" + car_name + "/" + car.car_id + "/" + car.car_upload_code+"/"+PORT;
        }

      return WEBSITEURL + "car_view/" + car_name + "/" + car.car_id + "/" + car.car_upload_code;
    }else{
      return "กดปุ่มแชร์ไปเว็บเพื่อแชร์ข้อมูลรถ"
    }
  }

  getShortUrl(share_url){

      let car_id = this.props.car.car_id ;

      axios.post(APIURL + '/short_url/',{
         full_url: share_url,
         car_id: car_id,
      })
        .then(res=>{
          let short_url = res.data.short_url
          this.setState({
            short_url: short_url
          });
        })
  }

  onCarShareUpdate(share_status){
    let car_id = this.props.car.car_id ;
    axios.put(APIURL + '/car_share/'+car_id,{
      car_id: car_id,
      car_share: share_status,
    })
    .then(res => {
        //console.log(res)
        //alert(res.status)
        if(res.status===200){
           this.setState({
             car_share: share_status,
             url: this.getShareUrl(share_status),
             share_active: share_status==="show"
           })

          if(share_status==="show"){
               this.updateCarShareUrl();
          }
        }

    }).catch(error => {

    })
  }

  updateCarShareUrl(){

  }

  render(){
    let car = this.props.car
    let car_share = this.state.car_share
    return(
      <div className="card card-accent-info">
        <div className="card-header">
          <strong className="text-title">แชร์รถคันนี้</strong>
        </div>

        <div className="card-block">

          <div className="row">
            <div className="form-group col-md-12 h5">
              {car.car_name}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6 h6">
              สถานะรถ: <span className="badge"
                                   style={{backgroundColor: car.car_status_color}} >{car.car_status_name}</span>
            </div>
            <div className="form-group col-md-6 h6">
              สถานะการแชร์: <span className={car_share==="show"? "badge badge-success": "badge badge-danger"}  >{car_share}</span>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-12">
              <label className="form-control-label" htmlFor="prependedInput">
                url สำหรับแชร์
              </label>
              <div className="controls">
                <div className="input-prepend input-group">
                  <span className="input-group-addon">url</span>
                  <input id="url"
                         className="form-control"
                         type="text"
                         value={this.state.url}
                         disabled={!this.state.share_active}
                  />
                  <span className="input-group-btn">
                       <CopyToClipboard text={this.state.url}
                                        disabled={!this.state.share_active}
                                        onCopy={() => this.setState({copied: true})}>
                        <button className="btn btn-warning" type="button">Copy</button>
                      </CopyToClipboard>
                  </span>
                </div>
                <p className="help-block">กดแชร์ไปยังเว็บไซต์ แล้ว copy url ไปใช้งานได้เลย  {this.state.copied ? <span style={{color: 'red'}}>Copied.</span> : null}</p>

              </div>

              <div className={this.state.share_active ? "controls hidden": "row hidden"}>

                  <div className="input-prepend input-group">
                    <span className="input-group-addon">short url</span>
                    <input id="url"
                           className="form-control"
                           type="text"
                           value={this.state.short_url}
                           disabled={!this.state.share_active}
                    />
                    <span className="input-group-btn">
                         <CopyToClipboard text={this.state.short_url}
                                          disabled={!this.state.share_active}
                                          onCopy={() => this.setState({copied_short: true})}>
                          <button className="btn btn-warning" type="button">Copy</button>
                        </CopyToClipboard>
                    </span>
                  </div>
                  <p className="help-block">กดแชร์ไปยังเว็บไซต์ แล้ว copy url ไปใช้งานได้เลย  {this.state.copied_short ? <span style={{color: 'red'}}>Copied.</span> : null}</p>

              </div>


            </div>
          </div>

          {/* <div className="row">

            <div className="form-group col-md-4 text-center">
              <button
                  type="button"
                  className="btn btn-lg btn-html5 text"
                  style={{width:120}}
                  onClick={()=>{
                      this.onCarShareUpdate("show")
                  }}
              ><span>แชร์ไปเว็บ</span></button>
            </div>*/}

            {/*<div className="form-group col-md-4 text-center">

              <a  href={this.state.share_active && this.state.short_url!==''? "https://www.facebook.com/sharer.php?u="+this.state.short_url : "no-script-url:0"}
                  target="_blank" rel="noopener noreferrer">

                <button type="button"
                      className="btn btn-lg btn-facebook text"
                      disabled={!this.state.share_active && this.state.short_url===''}
                      >
                <span>Facebook</span>
              </button></a>
            </div>*/}

            {/*<div className="form-group col-md-4 text-center">

              <a  href={this.state.share_active && this.state.short_url!==''? "https://lineit.line.me/share/ui?url="+this.state.short_url : "no-script-url:0"}
                  target="_blank" rel="noopener noreferrer">
              <button type="button"
                      className="btn btn-lg btn-vimeo text"
                      style={{width:120}}
                      disabled={!this.state.share_active && this.state.short_url===''}

              >
                Line
              </button>
              </a>

            </div>

          </div> */}

          {
            WEBSITEURL !== undefined && WEBSITEURL !== '' ? (
              <div className={this.state.share_active ? "row mt-3": "row"}>
                <div className="form-group col-md-6 text-center">
                  <button
                    type="button"
                    className="btn btn-lg"
                    style={{backgroundColor: '#aad450', width: 200, height:50, color: 'white'}}
                    onClick={()=>{
                      this.onCarShareUpdate("show")
                    }}
                  ><span>แชร์รถ</span></button>
                </div>
                <div className="form-group col-md-6 text-center">
                  <button type="button"
                          className={this.state.share_active ? "btn btn-lg" : "btn btn-lg" }

                          style={{backgroundColor: '#b00', width: 200, height:50, color: 'white'}}

                          disabled={this.state.share_active === false}
                          onClick={()=>{
                            this.onCarShareUpdate("hidden")
                          }}
                  ><span>&nbsp;&nbsp;&nbsp;ยกเลิกการแชร์&nbsp;&nbsp;&nbsp;</span></button>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="form-group col-md-12 text-lg-center text-center">
                  ***เฉพาะ package website ที่สามารถแชร์ข้อมูลรถได้
                </div>
              </div>
            )
          }


        </div>
        <div className="card-footer text-right">
          <button type="reset"
                  onClick={this.props.onHandle.cancel}
                  className="btn btn-sm btn-danger">
            <i className="fa fa-close"></i> ปิดหน้านี้
          </button>
          <button type="submit"
                  className="btn btn-sm btn-primary hidden">
            <i className="fa fa-save"></i> บันทึก
          </button>

          <input type="hidden" id="car_id" name="car_id" value={this.props.car.car_id}/>

        </div>
      </div>
    )
  }

}

export default CarShare ;