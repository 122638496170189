import React, { Component } from 'react'
import axios from 'axios'
import { APIURL } from '../../../config/config'
import { connect } from 'react-redux'
import MaskedInput from "react-maskedinput";
import { AlertWarning } from '../../Alert/Alert'

class CarEditOldOwner extends Component {
  constructor (props) {
    super(props)
    //this.handleOnSubmitCustomer.bind(this);
    this.state = {
      user: {},
      lists: [],
      load_data: true,
      danger: false,
      car: this.props.car,
      province: [],
      car_name_owner_old: this.props.car.car_name_owner_old,
      car_lastname_owner_old: this.props.car.car_lastname_owner_old,
      car_id_card: this.props.car.car_id_card,
      car_license_plate_old: this.props.car.car_license_plate_old,
      province_old_id: this.props.car.province_old_id,
      car_telephone_old: this.props.car.car_telephone_old,
      car_address_old: this.props.car.car_address_old,
      car_other: this.props.car.car_other,
      car_owner_old_order: this.props.car.car_owner_old_order,
    }

    this.onInputChange = this.onInputChange.bind(this)
    this.handleOnSubmitCarEditOldOwner = this.handleOnSubmitCarEditOldOwner.bind(this)

  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    })
  }

  handleOnSubmitCarEditOldOwner (event) {
    event.preventDefault()
    //alert(event.target.customer_name.value);
    let that = this
    let car_id = this.state.car.car_id
    let car_name_owner_old = event.target.car_name_owner_old.value
    let car_lastname_owner_old = event.target.car_lastname_owner_old.value
    let car_id_card = event.target.car_id_card.value
    let car_license_plate_old = event.target.car_license_plate_old.value
    let province_old_id = event.target.province_old_id.value
    let car_telephone_old = event.target.car_telephone_old.value
    let car_address_old = event.target.car_address_old.value
    let car_other = event.target.car_other.value
    let car_owner_old_order = event.target.car_owner_old_order.value
    let user_updated = this.state.user.user

    if (car_name_owner_old === '') {
      AlertWarning('กรุณากรอกข้อมูลให้ครบถ้วน')
      return
    }
    event.target.car_name_owner_old.value = ''
    event.target.car_lastname_owner_old.value = ''
    event.target.car_id_card.value = ''
    event.target.car_license_plate_old.value = ''
    event.target.province_old_id.value = ''
    event.target.car_telephone_old.value = ''
    event.target.car_address_old.value = ''
    event.target.car_other.value = ''
    event.target.car_owner_old_order.value = ''

    axios.put(APIURL + '/car/car_old_owner/' + car_id, {
      car_id: car_id,
      car_name_owner_old: car_name_owner_old,
      car_lastname_owner_old: car_lastname_owner_old,
      car_id_card: car_id_card,
      car_license_plate_old: car_license_plate_old,
      province_old_id: province_old_id,
      car_telephone_old: car_telephone_old,
      car_address_old: car_address_old,
      car_other: car_other,
      user_updated: user_updated,
      car_owner_old_order: car_owner_old_order
    })
      .then(response => {
        // console.log(response)
        if (response.status === 200) {
          that.props.onToggle()
          that.props.onLoadCarById()
        }
      })
      .catch(function (error) {
        alert('พบข้อผิดพลาด : ' + error)
      })
  }

  onInputChange (event) {
    event.preventDefault()
    //var name = ''
    if (event.target.id === 'car_name_owner_old') {
      // name = this.refs.car_name_owner_old.val
      this.setState({car_name_owner_old: event.target.value})
    } else if (event.target.id === 'car_lastname_owner_old') {
      // name = this.refs.car_lastname_owner_old.val
      this.setState({car_lastname_owner_old: event.target.value})
    } else if (event.target.id === 'car_id_card') {
      //name = this.refs.car_id_card.val
      this.setState({car_id_card: event.target.value})
    } else if (event.target.id === 'car_license_plate_old') {
      //name = this.refs.car_license_plate_old.val
      this.setState({car_license_plate_old: event.target.value})
    } else if (event.target.id === 'province_old_id') {
      //name = this.refs.province_old_id.val
      this.setState({province_old_id: event.target.value})
    } else if (event.target.id === 'car_telephone_old') {
      //name = this.refs.car_telephone_old.val
      this.setState({car_telephone_old: event.target.value})
    } else if (event.target.id === 'car_address_old') {
      //name = this.refs.car_address_old.val
      this.setState({car_address_old: event.target.value})
    } else if (event.target.id === 'car_other') {
      //name = this.refs.car_other.val
      this.setState({car_other: event.target.value})
    } else if (event.target.id === 'car_owner_old_order') {
      this.setState({car_owner_old_order: event.target.value})
    }

  }

  loadProvinceData () {
    axios.get(APIURL + '/province/')
      .then(res => {
        this.setState({province: res.data})
        //console.log(res.data)
        //alert (res.data)
      }).catch(error => {
      this.setState({province: []})
    })
  }

  loadData () {
    axios.get(APIURL + '/car/')
      .then(res => {
        this.setState({lists: res.data, load_data: false, car_id: 0, danger: false})
      }).catch(error => {
      this.setState({lists: [], load_data: true, car_id: 0, danger: false})
    })
  }

  componentDidMount () {
    //if(this.state.load_data)
    // this.setState({branch_name: this.props.car.branch_name })
    this.loadData()
    this.loadProvinceData()
  }

  render () {

    var province_old_list = this.state.province.map((province, index) => {
      return (
        <option key={index} value={province.province_id}>{province.province_name}</option>
      )
    })

    return (
      <form action="" method="post" onSubmit={this.handleOnSubmitCarEditOldOwner}>
        <div className="row">
          <div className="col-sm-12">
            <div className="card card-accent-warning">
              <div className="card-header card-edit">
                <strong className="text-title">แก้ไขข้อมูลเจ้าของเดิม</strong>
              </div>

              <div className="card-block">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row">

                      <div className="form-group col-sm-3">
                        <label htmlFor="name">ลำดับที่</label>
                        <input type="text"
                               className="form-control"
                               id="car_owner_old_order"
                               name="car_owner_old_order"
                               placeholder=""
                               value={this.state.car_owner_old_order}
                               onChange={this.onInputChange}
                        />
                      </div>

                      <div className="form-group col-sm-3">
                        <label htmlFor="name">ชื่อ</label>
                        <input type="text"
                               className="form-control"
                               id="car_name_owner_old"
                               name="car_name_owner_old"
                               placeholder=""
                               value={this.state.car_name_owner_old}
                               onChange={this.onInputChange}
                        />
                      </div>

                      <div className="form-group col-sm-3">
                        <label htmlFor="lastname">นามสกุล</label>
                        <input type="text"
                               className="form-control"
                               id="car_lastname_owner_old"
                               name="car_lastname_owner_old"
                               placeholder=""
                               value={this.state.car_lastname_owner_old}
                               onChange={this.onInputChange}
                        />
                      </div>

                      <div className="form-group col-sm-3">
                        <label htmlFor="id_card">เลขบัตรประจำตัวประชาชน</label>
                       {/* <input type="text"
                               className="form-control hidden"
                               name="car_id_card_old-bk"
                               id="car_id_card_old-bk"
                               placeholder=""
                               maxLength="20"
                               // value={this.state.car_id_card}
                               // onChange={this.onInputChange}
                        />*/}

                        <MaskedInput
                            mask="1-1111-11111-11-1"
                            id="car_id_card"
                            name="car_id_card"
                            placeholder=""
                            className="form-control"
                            value={this.state.car_id_card}
                            onChange={this.onInputChange}
                        />
                      </div>

                      <div className="form-group col-sm-6">
                        <label htmlFor="license-plate-old">ป้ายทะเบียนเดิม *</label>
                        <input type="text"
                               className="form-control"
                               id="car_license_plate_old"
                               name="car_license_plate_old"
                               placeholder=""
                               value={this.state.car_license_plate_old}
                               onChange={this.onInputChange}
                        />
                      </div>

                      <div className="form-group col-sm-3">
                        <label htmlFor="province">จังหวัด</label>
                        <select className="form-control"
                                id="province_old_id"
                                name="province_old_id"
                                value={this.state.province_old_id}
                                onChange={this.onInputChange}
                        >
                          {province_old_list}
                        </select>
                      </div>

                      <div className="form-group col-sm-3">
                        <label htmlFor="telephone-old">เบอร์โทรศัพท์</label>
                        <input type="text"
                               className="form-control hidden"
                               id="car_telephone_old_old-bk"
                               name="car_telephone_old_old-bk"
                               placeholder=""
                               // value={this.state.car_telephone_old}
                               // onChange={this.onInputChange}
                        />

                        <MaskedInput
                            mask="111-111-1111"
                            id="car_telephone_old"
                            name="car_telephone_old"
                            placeholder=""
                            className="form-control"
                            value={this.state.car_telephone_old}
                            onChange={this.onInputChange}
                        />
                      </div>

                      <div className="form-group col-sm-6">
                        <label htmlFor="address-old">ที่อยู่ตามทะเบียนบ้าน</label>
                        <input type="text"
                               className="form-control"
                               id="car_address_old"
                               name="car_address_old"
                               placeholder=""
                               value={this.state.car_address_old}
                               onChange={this.onInputChange}/>
                      </div>

                      <div className="form-group col-sm-6">
                        <label htmlFor="other">หมายเหตุ</label>
                        <input type="text"
                               className="form-control"
                               id="car_other"
                               name="car_other"
                               placeholder=""
                               value={this.state.car_other}
                               onChange={this.onInputChange}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="card-footer text-right">
                <button type="reset"
                        className="btn btn-sm btn-danger mr-2"
                        onClick={this.props.onToggle}
                >
                  <i className="icon-close"/> ยกเลิก
                </button>
                <button type="submit"
                        className="btn btn-sm btn-primary">
                  <i className="fa fa-save"/> บันทึก
                </button>
              </div>

            </div>
          </div>
        </div>
      </form>


    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarEditOldOwner)
