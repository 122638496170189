import React, {Component} from 'react';
// import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import SidebarRole from './SidebarRole'
import {PORT} from "../../config/config";
import SidebarRoddee from "./SidebarRoddee";
import Sidebar365car from "./Sidebar365car/Sidebar365car";

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            user_type: '',
        };

        this.getSidebarRole = this.getSidebarRole.bind(this)
    }

    handleClick(e) {
        e.preventDefault();
        e.target.parentElement.classList.toggle('open');
    }

    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
    }

    componentWillMount() {
      // const queryString = require('query-string');
      let user = JSON.parse(this.props.user);

      if(user!==null){
        this.setState({
          user: user,
          user_type: user.type
        })
      }
    }

    componentDidMount(){

    }

    /*componentWillReceiveProps (prevProps, prevState, snapshot) {
      // console.log(prevProps.type, this.props.type)
      if (this.props.type !==undefined) {
        if (this.props.type !== this.state.user_type) {
          // console.log(this.props.type, this.state.user_type)
          this.setState({
            user_type: this.props.type
          })
        }
      }
    }*/

  // secondLevelActive(routeName) {
    //   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
    // }

    getSidebarRole() {
        if(PORT === 9008){
            return <SidebarRoddee
                user_type={this.props.type === undefined ? this.state.user_type : this.props.type}
                user={this.state.user.user}
            />
        }else if(PORT === 9056){
            return <Sidebar365car
                user_type={this.props.type === undefined ? this.state.user_type : this.props.type}
                user={this.state.user.user}
            />
        }else {
            return <SidebarRole
                user_type={this.props.type === undefined ? this.state.user_type : this.props.type}
                user={this.state.user.user}
            />
        }
    }

    render() {
        // console.log('this.props.type=', this.props.type)
        return (
            <div className="sidebar">
                <nav className="sidebar-nav">

                    {
                       this.getSidebarRole()
                    }

                </nav>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    type: state.auth.type,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(Sidebar)
