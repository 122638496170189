import React, { Component } from 'react';
import * as moment from 'moment';
import 'moment/locale/th';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DateUtil } from '../../utils/dateUtil';
import { NumberUtil } from '../../utils/number-util';
import stringUtil from '../../utils/stringUtil'

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

class DeliverCarPDF extends Component {
  constructor (props) {
    super(props);
    this.state = {

    }
    this.makePDF = this.makePDF.bind(this)
  }

  componentDidMount () {
    this.makePDF()
  }

  makePDF() {
    const { car_sell } = this.props

    let cardIdImage64 = this.props.cardIdImage64
    let carDeliverImage64 = this.props.carDeliverImage64

    const customer_name = car_sell.customer_name + ' ' + car_sell.customer_lastname
    const customer_card_id = car_sell.customer_card_id === null ? '-' : car_sell.customer_card_id
    const customer_mobile = car_sell.customer_mobile

    const car_brand_name = car_sell.car_brand_name
    const car_model = car_sell.car_model_name
    const car_name = car_brand_name+' '+car_model

    let car_license_plate = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.car_license_plate_new : car_sell.car_license_plate_old
    let province_name = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.province_name_new : car_sell.province_name_old

    let car_license = car_license_plate+' '+province_name
    let bank_finance_name = car_sell.bank_finance_name_new

    let customer_name_pad = stringUtil.addSpacePaddingFill(customer_name, 34)
    let customer_card_pad = stringUtil.addSpacePaddingFill(customer_card_id, 34)
    let customer_mobile_pad = stringUtil.addSpacePaddingFill(customer_mobile, 34)
    let car_name_pad = stringUtil.addSpacePaddingFill(car_name, 40)
    let car_license_pad = stringUtil.addSpacePaddingFill(car_license, 40)
    let bank_finance_name_pad = stringUtil.addSpacePaddingFill(bank_finance_name, 40)

    let blockCustomer = [
      {
        columns: [
          { width: 68, style: 'normal',
            text: 'ชื่อผู้เช่าซื้อ' ,
          },
          { width: '32%', style: ['normalLeft', 'bottomLine'],
            text:  customer_name_pad,
          },
          { width: 100, style: 'normal',
            text: 'เลขบัตรประชาชน' ,
          },
          { width: 'auto', style: ['normalLeft', 'bottomLine'],
            text: customer_card_pad,
          },
        ],
        columnGap: 2,
        lineHeight:1
      },
      {
        columns: [
          { width: 58, style: 'normal',
            text: 'เบอร์โทร' ,
          },
          { width: '32%', style: ['normalLeft', 'bottomLine'],
            text:  customer_mobile_pad,
          },
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 16, 0, 0],
      }
    ]

    let blockCar = [
      {
        columns: [
          { width: 76, style: 'normal',
            text: 'เช่าซื้อรถยนต์' ,
          },
          { width: '32%', style: ['normalLeft', 'bottomLine'],
            text:  car_name_pad,
          },
          { width: 100, style: 'normal',
            text: 'หมายเลขทะเบียน' ,
          },
          { width: 'auto', style: ['normalLeft', 'bottomLine'],
            text: car_license_pad,
          },
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 16, 0, 0],
      },
      {
        columns: [
          { width: 110, style: 'normal',
            text: 'จัดไฟแนนซ์ธนาคาร' ,
          },
          { width: '32%', style: ['normalLeft', 'bottomLine'],
            text:  bank_finance_name_pad,
          }
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 16, 0, 0],
      },
      {
        columns: [
          { width: '100%', style: 'normal',
            text: 'ได้ตรวจสอบสภาพรถ และรับรถสภาพเรียบร้อยแล้ว' ,
          },
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 16, 0, 0],
      },
    ]

    let tableSign = {
      margin: [0, 45, 0, 10],
      layout: 'noBorders', // optional
      table: {
        widths: ['25%', '25%', '50%'],
        body: [
          [
            { text: '' },
            { text: '' },
            { text: 'ลงชื่อผู้เช่าซื้อ...............................................................................', lineHeight:1.5 },
          ],
          [
            { text: '' },
            { text: '' },
            { text: 'ได้รับรถวันที่.................../.............................../..........................', lineHeight:1.5 },
          ],
        ]
      }
    }

    let blockImage = []
    if (cardIdImage64 !== null && carDeliverImage64 !== null) {
      blockImage = [
        {
          columns: [
            {
              width: 260,
              //text: 'image'
              image: cardIdImage64
            },
            {
              width: 260,
              //text: 'image'
              image: carDeliverImage64
            },
          ],
          columnGap: 10,
          lineHeight: 1.3,
          margin: [ 0, 30, 0, 0 ], // margin: [left, top, right, bottom]
        },
      ]
    }



    const docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [40, 50, 40, 40],
      /*header: function (currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element
        return [
          {
            margin: [30, 30, 30, 0],
            layout: 'noBorders', // optional
            table: {
              widths: ['100%'],
              body: [
                [
                  {
                    layout: 'noBorders',
                    table: {
                      widths: ['80%', '20%'],
                      body: [
                        [
                          { text: 'Printed Date : ' + printDate },
                          { text: 'Page ' + currentPage + ' of ' + pageCount, style: 'right' }
                        ]
                      ]
                    }
                  }
                ]
              ]
            }
          }
        ]
      },*/

      content: [
        { text: 'ใบรับรถ-ส่งมอบรถ', style: 'header', margin: [0, 0, 0, 20], },
        blockCustomer,
        blockCar,
        tableSign,
        blockImage
      ],
      defaultStyle: {
        font: 'THSarabunNew',
        fontSize: 15,
      },

      styles: {
        header: {
          alignment: 'center',
          bold: true,
          fontSize: 20
        },
        normal: {
          fontSize: 16,
        },
        normalRight: {
          fontSize: 16,
          alignment: 'right'
        },
        normalLeft: {
          fontSize: 16,
          alignment: 'left',
        },
        left22: {
          marginLeft: 22
        },
        topic: {
          bold: true,
          fontSize: 16
        },
        noBorder: {
          bold: true,
          alignment: 'right'
        },
        lineVertical: {
          marginTop: -5
        },
        textStatus: {
          marginTop: -5,
          color: 'red'
        },
        headTable: {
          fontSize: 12,
          padding: '0 10 0 10'
        },
        table: {
          fontSize: 12
        },
        underline: {
          decoration: 'underline'
        },
        bottomLine: {
          decoration: 'underline',
          decorationStyle: 'dotted',
        }
      }
    };

    pdfMake.createPdf(docDefinition).open()
  }

  render () {
    return <></>
  }

}

export default DeliverCarPDF
