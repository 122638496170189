import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import axios from 'axios/index';
import { APIURL, IMAGE_FULL_URL } from '../../../config/config';
import pdfMake from 'pdfmake/build/pdfmake';
import * as moment from 'moment';
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";
import {checkGroupPermission, getGroupPermission} from "../../../services/grouppermission";
import SalesClaimAdd from "../../Sales/SalesClaimAdd/SalesClaimAdd";
import {AlertWarning} from "../../Alert/Alert";

class CarClaimList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuID: [901],
      grouppermissions: [],
      user: JSON.parse(this.props.user),
      lists: [],
      modalCloseClaim: false,
      carClaim: {},
      carBase64Img: '',
      form_status: '',
      claim_status: '',
      modal: false,
      width: 0,
      height: 0,
      claim_edit: {},
      car_sell: {},
    }
    this.loadData = this.loadData.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.printClaim = this.printClaim.bind(this);
    this.loadCarImageBase64 = this.loadCarImageBase64.bind(this);
    this.redirectToClaimItems = this.redirectToClaimItems.bind(this);
    this.openClaimAdd = this.openClaimAdd.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.loadGroupPermission = this.loadGroupPermission.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.salesClaimAdd= this.salesClaimAdd.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  loadGroupPermission() {
    let type = this.state.user.type
    let menuID = this.state.menuID
    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  componentDidMount () {
    CarsUtil.removeCarSelect()
    CarSellUtil.removeCarSellSelect()

    this.loadGroupPermission()
    this.loadData()

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  onSearch() {
    let search_text = this.refs.input_search.value
    if(search_text===''){
      this.loadData()
      return;
    }

    axios.get(APIURL + '/car_claim/search/'+search_text.trim())
      .then(res => {
        // console.log(res)
        this.setState({lists: res.data})
      }).catch(error => {
        // console.log(error)
      this.setState({lists: []})
    })

  }

  onRefresh(){
    this.refs.input_search.value = ''
    this.loadData()
  }

  loadData() {
    axios.get(APIURL + '/car_claim/list')
      .then(res => {
        // console.log(res)
        this.setState({lists: res.data})
      }).catch(error => {
        // console.log('===>error');
        // console.log(error)
        this.setState({lists: []})
    })
  }

  convertClaimStatusName(statusValue) {
    let statusList = [
      {status: 'start', name: 'ออกใบเคลม'},
      {status: 'process', name: 'กำลังดำเินการซ่อม'},
      {status: 'success', name: 'ซ่อมเสร็จรอลูกค้ามารับ'},
      {status: 'complete', name: 'ปิดเคลม'},
    ];
    let result = statusList.find(status => {
      return status.status === statusValue
    })
    return result.name;
  }

  convertImgToBase64URL(url, callback, outputFormat){
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function(){
      var canvas = document.createElement('CANVAS'),
        ctx = canvas.getContext('2d'), dataURL;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
      canvas = null;
    };
    img.src = url;
  }

  openClaimAdd() {
    /* start : ออกใบเคลม
    process : กำลังดำเินการซ่อม
    success : ซ่อมเสร็จรอลูกค้ามารับ
    complete : ลูกค้ามารับเรียบร้อย */
    this.setState({
      claim_status: 'start',
      form_status: 'add'
    },()=>{
      this.toggleModal();
    });
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  salesClaimAdd (event, is_search_car, car_sell) {
    event.preventDefault();
    // var that = this;
    let claim_date = event.target.claim_date.value;
    let claim_name = event.target.claim_name.value;
    let claim_mobile = event.target.claim_mobile.value;
    let claim1_list1 = event.target.claim1_list1.value;
    let claim1_list2 = event.target.claim1_list2.value;
    let claim2 = event.target.claim2.value;
    let claim3 = event.target.claim3.value;
    let car_id = this.state.car_sell.car_id;
    let user = this.state.user.user;

    if (is_search_car === true) {
      car_id = car_sell.car_id
    } else {
      car_id = this.state.car_sell.car_id;
    }

    if(car_id === undefined || car_id === null) {
      AlertWarning('กรุณาเลือกรถ\nก่อนบันทึกการเคลม')
      return;
    }

    const data = {
      claim_date: claim_date,
      claim_name: claim_name,
      claim_mobile: claim_mobile,
      claim1_list1: claim1_list1,
      claim1_list2: claim1_list2,
      claim2: claim2,
      claim3: claim3,
      car_id: car_id,
      user: user
    };

    if(this.state.form_status === 'add') {
      axios.post(APIURL + '/car_claim', data)
          .then((res) => {
            if (res.status === 200) {
              this.toggleModal();
              this.loadData();
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
    /*else if(this.state.form_status === 'edit'){
      let car_claim_id = this.state.claim_edit.car_claim_id
      axios.put(APIURL + '/car_claim/'+car_claim_id, data)
          .then((res) => {

            if (res.status === 200) {
              this.setState({
                form_status: 'add',
              }, () => {
                this.toggleModal();
                this.loadCarClaim();
              });
            }

          }).catch((err) => {
        console.log(err)
      })
    }*/
  }

  printClaim(claimData, headerDocument) {
    // var car_claim_id = claimData.car_claim_id;

    let claim_date = moment(claimData.claim_date).locale('th').format('LL');
    let claim_name = moment(claimData.claim_name).locale('th').format('LL'); //claimData.claim_name;
    let claim_mobile = claimData.claim_mobile;
    let claim1_list1 = claimData.claim1_list1;
    let claim1_list2 = claimData.claim1_list2;
    //var claim2 = claimData.claim2;
    //var claim3 = claimData.claim3;
    // var car_name = carData.car_name;
    // var date_promise = carData.date_promise;
    let car_brand_name = claimData.car_brand_name
    let car_model_name = claimData.car_model_name
    let car_color_name = claimData.car_color_name
    let car_license_plate_new = claimData.car_license_plate_new
    let car_upload_filename = ( typeof claimData.car_upload_filename === undefined ) ? 'noimage.png' : claimData.car_upload_filename

    // var car_url = IMAGE_THUMB_URL+car_upload_filename
    let base64Img = this.state.carBase64Img

    // this.convertImgToBase64URL(car_url, function(base64Img){

      let docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [ 56, 30, 56, 30 ], // margin: [left, top, right, bottom]
      content: [
        { text: headerDocument, style: 'bodyhead', lineHeight:1.3},
        {
          columns: [
            { width: '20%',
              text: '', style: 'bodycompany'
            },
            { width: '40%', style: 'bodycompany',
              text: '',
            },
            { width: '20%',
              text: '', style: 'bodycompany'
            },
            { width: '20%', style: 'body',
              text: 'Sale........................',
            },

          ],
          columnGap: 20,
          lineHeight:1
        },
        {
          columns: [
            {
              width: 150,
              image: base64Img
              //text: 'ภาพรถ'
            },
            { width: '80%',
              text: 'วันที่ออกใบงาน...'+ claim_date+ '  \nลูกค้ารับรถ...'+claim_name+'   \nเบอร์โทร...' + claim_mobile+'...', style: 'body'
            },
          ],
          columnGap: 20,
          lineHeight:1.3,
          margin: [ 0, 8, 0, 0 ], // margin: [left, top, right, bottom]
        },
        {
          columns: [
            { width: '100%',
              text: 'รถยี่ห้อ...'+ car_brand_name+'  รุ่น...'+car_model_name+ '   สี...'+ car_color_name +'  ทะเบียน...'+car_license_plate_new, style: 'textcenter'
            },
          ],
          columnGap: 20,
          lineHeight: 1.3,
          margin: [ 0, 8, 0, 0 ],
        },
        {
          columns: [
            { width: '100%', text: 'รายการ:', style: 'body' },
          ],
          columnGap: 20,
          margin: [ 0, 8, 0, 0 ], // margin: [left, top, right, bottom]
        },
        {
          columns: [
            { width: '50%', text: claim1_list1 , style: 'body'},
            { width: '50%', text: claim1_list2 , style: 'body'},
          ],
          columnGap: 20,
          margin: [ 8, 0, 0, 0 ], // margin: [left, top, right, bottom]
        },
        {
          columns: [
            {
              width: '100%',
              text: 'อนุมัติ....................................   ช่าง:ตรวจ....................................   Sale:ตรวจ....................................   ',
              style: 'textcenter'
            },
          ],
          columnGap: 20,
          lineHeight: 1.3,
          margin: [ 0, 14, 0, 0 ],
        },
        [
          {text: '   '}, {text: '   '}, {text: '   '}, {text: '   '},
          { text: headerDocument, style: 'bodyhead', lineHeight:1.3},
          {
            columns: [
              { width: '20%',
                text: '', style: 'bodycompany'
              },
              { width: '40%', style: 'bodycompany',
                text: '',
              },
              { width: '20%',
                text: '', style: 'bodycompany'
              },
              { width: '20%', style: 'body',
                text: 'Sale........................',
              },

            ],
            columnGap: 20,
            lineHeight:1
          },
          {
            columns: [
              {
                width: 150,
                image: base64Img
                //text: 'ภาพรถ'
              },
              { width: '80%',
                text: 'วันที่ออกใบงาน...'+ claim_date+ '  \nลูกค้ารับรถ...'+claim_name+'   \nเบอร์โทร...' + claim_mobile+'...', style: 'body'
              },
            ],
            columnGap: 20,
            lineHeight:1.3,
            margin: [ 0, 8, 0, 0 ], // margin: [left, top, right, bottom]
          },
          {
            columns: [
              { width: '100%',
                text: 'รถยี่ห้อ...'+ car_brand_name+'  รุ่น...'+car_model_name+ '   สี...'+ car_color_name +'  ทะเบียน...'+car_license_plate_new, style: 'textcenter'
              },
            ],
            columnGap: 20,
            lineHeight: 1.3,
            margin: [ 0, 8, 0, 0 ],
          },
          {
            columns: [
              { width: '100%', text: 'รายการ:', style: 'body' },
            ],
            columnGap: 20,
            margin: [ 0, 8, 0, 0 ], // margin: [left, top, right, bottom]
          },
          {
            columns: [
              { width: '50%', text: claim1_list1 , style: 'body'},
              { width: '50%', text: claim1_list2 , style: 'body'},
            ],
            columnGap: 20,
            margin: [ 8, 0, 0, 0 ], // margin: [left, top, right, bottom]
          },
          {
            columns: [
              {
                width: '100%',
                text: 'อนุมัติ....................................   ช่าง:ตรวจ....................................   Sale:ตรวจ....................................   ',
                style: 'textcenter'
              },
            ],
            columnGap: 20,
            lineHeight: 1.3,
            margin: [ 0, 14, 0, 0 ],
          },

        ]

      ],
      styles: {
        bodyhead: {
          fontSize: 18,
          bold: true,
          alignment: 'center'
        },
        body: {
          fontSize: 16,
        },
        bodycompany: {
          fontSize: 16,
          bold: true,
        },
        textcenter: {
          fontSize: 16,
          alignment: 'center'
        },
      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    };
    //
      pdfMake.createPdf(docDefinition).open()
    // });
  }

  toggleCloseClaim() {
    this.setState({
      modalCloseClaim: !this.state.modalCloseClaim
    })
  }

  onCloseClaime(car_claim_id) {
    // alert('ปิดเคลม car_claim_id=' + car_claim_id);
    // this.toggleCloseClaim()
    axios.put(APIURL + '/car_claim/close/'+car_claim_id, {})
      .then((res) => {
        if (res.status === 200) {
          this.loadData()
          this.toggleCloseClaim()
        }

      }).catch((err) => {
      console.log(err)
    })
  }

  openModalCloseClaim(carClaim) {
    this.setState({
      carClaim: carClaim,
      modalCloseClaim: !this.state.modalCloseClaim
    })
  }

  async loadCarImageBase64(car_upload_id) {
    let uri =  APIURL + '/car/image/' + car_upload_id;
    await axios.get(uri)
        .then(res => {
          let body = res.data.body
          this.setState({ carBase64Img: body })
        }).catch(error => {
          console.log('error:', error)
        })
  }

  redirectToClaimItems(carClaim) {
    let target = '/car-claim/items/?claim_id=' + carClaim.car_claim_id;
    // return <Redirect push to={target}/>
    this.props.history.push(target)
  }

  render() {

    let width = this.state.width;

    let tbodyList = this.state.lists.map((carClaim, index) => {

      //

      let image_url = '';

      if (carClaim.car_upload_filename.includes('https')) {
        image_url = carClaim.car_upload_filename
      } else {
        image_url =  IMAGE_FULL_URL+carClaim.car_upload_filename
        image_url = ( typeof carClaim.car_upload_filename === undefined ) ? IMAGE_FULL_URL+'noimage.png' : image_url
      }
      // console.log(image_url)

      return (
        <tr key={index}>
          <td className="text-center" width="10%">{carClaim.car_claim_id}</td>
          <td>
            วันที่ออกใบเคลม : {carClaim.claim_date} <br/>
            ลูกค้ารับรถ : {carClaim.claim_name} <br/>
            เบอร์โทร : {carClaim.claim_mobile} <br/>
            <img id={'img_'+carClaim.car_claim_id}
                 src={image_url}
                 style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                 alt="car"
            />
          </td>
          <td>
            ทะเบียน : {carClaim.car_license_plate_new} <br/>
            รถยี่ห้อ {carClaim.car_brand_name} รุ่น {carClaim.car_model_name} สี {carClaim.car_color_name} <br/>
            รายการ : <br/>
            {carClaim.claim1_list1} <br/>
            {carClaim.claim1_list2} <br/>
            {/*<div className={carClaim.claim2===''? 'hidden': ''}>
              <hr/>
              เคลมที่ 2 : <br/>
              {carClaim.claim2} <br/>
            </div>
            <div className={carClaim.claim3===''? 'hidden': ''}>
              <hr/>
              เคลมที่ 3 : <br/>
              {carClaim.claim3} <br/>
            </div>*/}
          </td>
          <td width="10%">
            {this.convertClaimStatusName(carClaim.status)}
          </td>
          <td className="align-top text-center"  width="15%" >

            {
              checkGroupPermission(901, this.state.grouppermissions).printed ? (
                  <button type="button"
                          onClick={async () =>  {
                            await this.loadCarImageBase64(carClaim.car_upload_id)
                            this.printClaim(carClaim, 'ใบเคลม/ช่าง')
                          }}
                          className="btn btn-sm btn-primary"
                          style={styles.btnWidth}>
                    <i className="icon-printer"/> ใบเคลม
                  </button>
              ) : null
            }

            {/* <button type="button"
                    onClick={() => {
                      this.printClaim(carClaim, 'รายการปล่อยเคลมรถลูกค้า/เซลล์')
                    }}
                    className="btn btn-sm btn-success"
                    style={styles.btnWidth}>
              <i className="icon-printer"></i> ปล่อยรถลูกค้า
            </button> */}

            {
              checkGroupPermission(901, this.state.grouppermissions).modified ? (
                  <button type="button"
                          onClick={() => {
                            this.openModalCloseClaim(carClaim);
                          }}
                          className="btn btn-sm btn-warning"
                          style={styles.btnWidth}>
                    <i className="icon-disc"/> ปิดเคลม
                  </button>
              ) : null
            }


            {
              checkGroupPermission(901, this.state.grouppermissions).created
                &&
              checkGroupPermission(901, this.state.grouppermissions).modified
                &&
              checkGroupPermission(901, this.state.grouppermissions).deleted
                  ?
                  (
                      <button type="button"
                              onClick={() => {
                                this.redirectToClaimItems(carClaim)
                              }}
                              className="btn btn-sm btn-danger"
                              style={styles.btnWidth}>
                        <i className="icon-list"></i> รายการซ่อม
                      </button>
                  ) : null
            }

            {
              checkGroupPermission(901, this.state.grouppermissions).created === 0
              &&
              checkGroupPermission(901, this.state.grouppermissions).modified === 0
              &&
              checkGroupPermission(901, this.state.grouppermissions).deleted === 0
              &&
              checkGroupPermission(901, this.state.grouppermissions).printed === 0
                  ?
                  (
                      <i className="icon-close"/>
                  ) : null
            }

          </td>
        </tr>
      );
    });

    let tbodyEmpty = () => {
      return (
        <tr className="text-center">
          <td colSpan={5}><span className="text-danger">ไม่พบข้อมูล</span></td>
        </tr>
      )};

    return (
      <div className="card">

        <Modal isOpen={this.state.modal}
               toggle={this.toggleModal}
               className={'none modal-dialog' + this.props.className}
               style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
        >
          <ModalBody>
            <SalesClaimAdd
                is_search_car={true}
                user={this.props.user}
                car_sell={this.state.car_sell}
                claim_edit={this.state.claim_edit}
                form_status = {this.state.form_status}
                claim_status = {this.state.claim_status}
                onToggle={this.toggleModal}
                onSubmit={this.salesClaimAdd}
            />
          </ModalBody>
        </Modal>

        <div className="card-block">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="card card-accent-primary">
                <div className="card-header">
                  <strong className="text-title"> ข้อมูลการเคลม/ส่งรถ </strong>

                  <input
                    onChange={this.onSearch.bind(this)}
                    ref="input_search"
                    style={styles.inputSearch}
                    className="form-control"
                    placeholder="ค้นหาตามชื่อรถ ทะเบียน"
                    type="text"
                  />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                  <button className="btn btn-head-bar"
                          style={styles.buttonHeight}
                          onClick={this.onRefresh.bind(this)}

                  >
                    <i className="icon-refresh"/>
                  </button>

                  {
                    checkGroupPermission(901, this.state.grouppermissions).created ? (
                        <button
                            type="button"
                            onClick={this.openClaimAdd}
                            className="btn btn-info float-right mt-1"
                        >
                          <i className="icon-plus"/>&nbsp; เพิ่มเคลมรถ
                        </button>
                    ) : null
                  }

                </div>
                <div className="card-block">
                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center" width="20%">วันออกใบงาน</th>
                      <th>รายการ</th>
                      <th>สถานะ</th>
                      <th className="text-center">จัดการ</th>
                    </tr>
                    </thead>
                    <tbody>
                    {(tbodyList.length>0)? tbodyList: tbodyEmpty()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modalCloseClaim} toggle={this.toggleCloseClaim.bind(this)}
               className={'modal-warning'}>
          <ModalHeader toggle={this.toggleCloseClaim.bind(this)}>คุณต้องการปิดเคลม</ModalHeader>
          <ModalBody>
            <h6>
              <strong> คุณต้องการปิดเคลมรถ ทะเบียน: {this.state.carClaim.car_license_plate_new} ใช่หรือไม่ ?? </strong> <br />
            <strong>
              รถยี่ห้อ {this.state.carClaim.car_brand_name} รุ่น {this.state.carClaim.car_model_name} สี {this.state.carClaim.car_color_name} <br/>
            </strong> </h6>
          </ModalBody>
          <ModalFooter>
            <Button color="warning"
                    onClick={this.onCloseClaime.bind(this, this.state.carClaim.car_claim_id)}>ตกลง</Button>{' '}
            <Button color="secondary" onClick={this.toggleCloseClaim.bind(this)}>ยกเลิก</Button>

          </ModalFooter>
        </Modal>

      </div>
    )
  }

}

const styles = {
  inputSearch: {
    marginLeft: 20,
    width: 300,
    display: 'inline'
  },
  buttonHeight: {
    height: 36,
    paddingTop:0,
    paddingBottom:0,
    display: 'inline',
    marginTop: '-0.35rem'
  },
  btnWidth: {
    width: 120,
    marginBottom: '0.35rem'
  },
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  type: state.auth.type,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarClaimList)
