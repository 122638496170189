import React,{ Component } from 'react'
import Loading from '../Loading'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import { connect } from 'react-redux'
import CustomerFollowupCalendar from './CustomerFollowupCalendar'
import CustomerFollowupList from './CustomerFollowupList'
import CustomerFollowupAdmin from './CustomerFollowupAdmin'
import { checkGroupPermission, getGroupPermission } from '../../services/grouppermission'

class CustomerFollowupTab extends Component {
  constructor (props) {
    super(props);
    this.state = {
      user: JSON.parse(this.props.user),
      activeTab: '',
      isLoading: false,
      isReload: false,
      menuID: [3501, 3502, 3503],
      grouppermissions: [],
    }
    this.toggle = this.toggle.bind(this);
    this.loadGroupPermission = this.loadGroupPermission.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
  }

  loadGroupPermission() {
    let type = this.state.user.type
    let menuID = this.state.menuID

    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        }, () => {
          this.setActiveTab()
        })
      }
    })
  }

  setActiveTab() {
    let grouppermissions = this.state.grouppermissions
    let grouppermissionsFilter = grouppermissions.filter(grouppermission => grouppermission.viewed === 1)
    if(grouppermissionsFilter.length !== 0) {
      let firstTab = grouppermissionsFilter[0]
      // 3501, 3502, 3503
      if (firstTab.menu_id === 3501) {
        this.setState({activeTab: '1'})
      } else if (firstTab.menu_id === 3502) {
        this.setState({activeTab: '2'})
      } else {
        this.setState({activeTab: '3'})
      }
    }

  }

  componentDidMount () {
    this.loadGroupPermission()
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  render () {
    const { user, activeTab } = this.state
   // const user_type = user.type

    return (
      <div className="col-md-12 mb-4">
        <Loading isLoading={this.state.isLoading} />

        <Nav tabs>

          {
            checkGroupPermission(3501, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  <i className="icon-chart"></i>  สรุปการติดตาม
                </NavLink>
              </NavItem>
            ) : null
          }

          {
            checkGroupPermission(3502, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  <i className="icon-flag"></i>  ข้อมูลการติดตาม
                </NavLink>
              </NavItem>
            ) : null
          }

          {
            checkGroupPermission(3503, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}
                >
                  <i className="icon-calendar"></i> ปฏิทินติดตามลูกค้า
                </NavLink>
              </NavItem>
            ) : null
          }

        </Nav>

        <TabContent activeTab={this.state.activeTab}>

          <TabPane tabId="1">
            {activeTab === '1' &&  <CustomerFollowupAdmin />}
          </TabPane>
          <TabPane tabId="2">

            {activeTab === '2' && <CustomerFollowupList
              user={user}
              onCalendarReload={(is_reload) => {
                this.setState({
                  isReload: is_reload
                })
              }}
            /> }

          </TabPane>
          <TabPane tabId="3">

            {activeTab === '3' && <CustomerFollowupCalendar
              user={user}
              isReload={this.state.isReload}
              onStopReload={() => {
                this.setState({
                  isReload: false
                })
              }}
            />}

          </TabPane>
        </TabContent>

       </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CustomerFollowupTab);
