import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import Loading from '../Loading'
import axios from 'axios'
import { APIURL } from '../../config/config'
import CarCheckColor from './CarCheckColor'

class CarCheck extends Component {

  constructor (props) {
    super(props);

    this.state = {
      user: {},
      activeTab: '1',
      isLoading: false,
      car: {},
      car_id: null,
      user_type: ''
    }

    this.loadCarById = this.loadCarById.bind(this)
  }

  /*componentWillMount () {
    //const queryString = require('query-string');
    //  const queryString = require('query-string');
    // const car = queryString.parse(this.props.history.location.search);
    // console.log('queryString: ', car)
  }*/

  componentDidMount () {
    const queryString = require('query-string');
    const car = queryString.parse(this.props.location.search);

    let user = JSON.parse(this.props.user);

    this.setState({
      car_id: car.car_id,
      user: user ,
      user_type : user.type,
    }, () => {
      this.loadCarById()
    })
  }

  loadCarById(){
    // var that = this
    // let car_id = this.state.car_id
    const {car_id} = this.state

    if (car_id === null) return


    axios.get(APIURL + '/car/'+car_id)
      .then(res => {
        let car = res.data[0]
        this.setState({car: car })
      }).catch(error => {
      alert("error: "+error)
      this.setState({car: {} })
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const { car, user } = this.state

    return (
      <div className="animated fadeIn">

        <Loading isLoading={this.state.isLoading} />

        <div className="row">
          <div className="col-lg-12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  <i className="icon-check"/> ประเมินทำสีรถ
                </NavLink>
              </NavItem>

              {/*<NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  <i className="icon-check"/> ตรวจสภาพรถก่อน cleansing
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}
                >
                  <i className="icon-check"/> ตรวจสภาพรถก่อนขาย
                </NavLink>
              </NavItem>*/}

            </Nav>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                {
                  Object.keys(car).length === 0 ? 'Loading...' : <CarCheckColor car={car} user={user} />
                }
              </TabPane>

              <TabPane tabId="2">
                {
                  Object.keys(car).length === 0 ? 'Loading...' : 'ตรวจสภาพรถก่อน cleansing'
                }
              </TabPane>

              <TabPane tabId="3">
                {
                  Object.keys(car).length === 0 ? 'Loading...' : 'ตรวจสภาพรถก่อนขาย'
                }
              </TabPane>

            </TabContent>
          </div>
        </div>

      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarCheck);
