import axios from 'axios'
import { APIURL } from '../config/config'

export const getCompanySetting = async () => {
    // company_setting_id
    try{
        let result = await axios.get(APIURL + '/company_setting/1')
        return result.data.company_setting
    }catch (e) {
        console.log('error: ', e)
        return null
    }
}

export const getDocTypeConfig = (company_setting, doc_type) => {
    // docType RE , RT
    // RE = ใบเสร็จรับเงิน , RT = ใบเสร็จรับเงิน / ใบกำกับ
    // re_config: "manual"
    // re_last_number: null
    // rt_config: "auto"
    // rt_last_number: null
    // is_vat: 1

    if (company_setting !== null) {
        if (doc_type === 'RE') {
            return company_setting.re_config
        } else if (doc_type === 'RT') {
            return company_setting.rt_config
        }
        return 'manual'
    }
    return null;
}

export const getVatBathIncludeVat = (amount, vat) => {
    // amount = ยอดยรวม vat แล้ว
    let amount_num = Number(amount)
    let vat_num = Number(vat)
    let all = 100+vat_num
    let vat_bath = (amount_num*vat_num)/all
    let vat_str = String(vat_bath)
    return parseFloat(vat_str).toFixed(2)
}

export const getVatBathFromAmount = (amount, vat) => {
    // amount = ยอดยังไม่รวม vat
    // vat_bath = ยอด vat
    let amount_num = Number(amount)
    let vat_num = Number(vat)
    let all = 100
    let vat_bath = (amount_num*vat_num)/all
    let vat_str = String(vat_bath)
    return parseFloat(vat_str).toFixed(2)
}
