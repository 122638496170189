import React, { Component } from 'react';
import Loading from '../Loading';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';
import { APIURL } from '../../config/config';
import { save } from 'save-file';
import { AlertError, AlertWarning } from '../Alert/Alert'
import { NumberUtil } from '../../utils/number-util'

class DebtorsReport extends Component{
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),
      data_by: 'byDate', // all, byDate
      lists:[],
      branches: [],
      branch_id: '',
      banks: [],
      bank_id: '',
    }

    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.loadBranchData = this.loadBranchData.bind(this)
    this.loadDebtorsData = this.loadDebtorsData.bind(this)
    this.exportCarBookData = this.exportCarBookData.bind(this)
    this.loadBankData = this.loadBankData.bind(this);
  }

  componentDidMount () {
    this.loadBranchData()
    this.loadBankData()
  }

  handleOnChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadBranchData () {
    axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branches: res.data}, () => this.loadDebtorsData())
      }).catch(error => {
      this.setState({branches: []})
    })
  }

  loadBankData() {
    axios.get( APIURL + '/bank/').then(res => {
      this.setState({
        banks: res.data
      })
    }).catch(error => {
      console.log('error: ', error)
    })
  }

  loadDebtorsData() {
    if(this.state.date_start > this.state.date_end){
      AlertError('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    const data_by = this.state.data_by
    const branch_id = this.state.branch_id
    const bank_id = this.state.bank_id

    const data = {
      start_date: start_date,
      end_date: end_date,
      data_by: data_by,
      branch_id: branch_id,
      bank_id: bank_id
    }

    // console.log(data)
    // return

    this.setState({
      isLoading: true
    }, () => {
      axios.post(APIURL + '/report/debtors', data)
        .then(res => {
          // console.log(res.data)
          this.setState({
            isLoading: false,
            lists: res.data
          }, () => {
            // this.showBranchSummary()
          })

        }).catch(error => {
        console.log('error:', error)
      })
    })
    // axios.get(APIURL + '/report/carin/'+start_date+'/'+end_date)

  }

  async exportCarBookData() {
    // console.log()

    if(this.state.date_start > this.state.date_end){
      AlertError('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    const data_by = this.state.data_by
    const branch_id = this.state.branch_id
    const bank_id = this.state.bank_id

    const data = {
      start_date: start_date,
      end_date: end_date,
      data_by: data_by,
      branch_id: branch_id,
      bank_id: bank_id
    }

    this.setState({
      isLoading: true
    }, () => {

      axios.post(APIURL + '/report/debtorsexcel', data)
        .then(async res => {

          const excelBuffer = res.data.excelBuffer
          const currentDatetime = res.data.currentDatetime
          const fileName = 'รายงานลูกหนี้_export_'+currentDatetime+'.xlsx'

          this.setState({
            isLoading: false
          })

          await save(excelBuffer, fileName)

        }).catch(error => {
        console.log('error carinexport:', error)
      })

    })

  }

  render () {
    const {
      lists
    } = this.state

    let car_branch_list = this.state.branches.map((branch, index) => {
      return (
        <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    })

    let bank_list = this.state.banks.map((bank, index) => {
      return(
        <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
      )
    })

    const emptyBody = <tr><td colSpan={11} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    const listRows = lists.map((debtors, index) => {

      let date_pay_start = moment(debtors.date_pay_start).format('YYYY-MM-DD')
      let date_pay_last = debtors.date_pay_last === '-' ? '-' : moment(debtors.date_pay_last).format('YYYY-MM-DD')
      let amount_pay = NumberUtil.addCommas(String(debtors.amount_pay))
      // let car_license_plate = car.car_license_plate_new === '' ? car.car_license_plate_old : car.car_license_plate_new
      //
      // let car_book_status = '';
      // if (car.car_book_status_id === 1) { // 1 = จอง 2= ยกเลิก
      //   car_book_status = 'จอง'
      // } else {
      //   car_book_status = 'ยกเลิก'
      // }

      let remain =  debtors.amount_of_debt - debtors.amount_pay_sum ;
      // sumRemain += parseFloat(remain)
      // sumPaid += parseFloat(debtors.amount_pay_sum)
      // sumDebt += parseFloat(debtors.amount_of_debt)
      // // date_pay amount_pay

      return (
        <tr key={index}>
          <td className="text-center">{index+1}</td>
          <td className="text-center text-nowrap">{date_pay_last}</td>
          <td className="text-center text-nowrap">{amount_pay}</td>
          <td className="text-center text-nowrap">{date_pay_start}</td>
          <td className="text-center text-nowrap">{debtors.customer_name} &nbsp;  {debtors.customer_lastname}</td>
          <td className="text-left">
            {debtors.debtors_name}

            {/*{
              debtors.bank_name === null ? null : (
                <>
                 <hr/>
                 ช่องทางการรับชำระ(ธนาคาร): {debtors.bank_name}
                </>
              )
            }*/}

          </td>

          <td className="text-center text-nowrap">{remain.toLocaleString()} </td>
          <td className="text-center text-nowrap">
            {debtors.amount_pay_sum === null ? '-' : debtors.amount_pay_sum.toLocaleString()} </td>

          <td className="text-center text-nowrap">{debtors.amount_of_debt.toLocaleString()} </td>
          <td className="text-center text-nowrap">{debtors.fullname} </td>
          <td className="text-center">
            {debtors.bank_name === null ? '-' : debtors.bank_name}
          </td>

        </tr>
      )
    })

    const tableRows = lists.length === 0 ? emptyBody : listRows

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />
        <div className="card-block" style={styles.overflowY}>
          <div className="">
            <div className="card card-accent-primary">

              <div className="card-header">
                <div className="row col-12 mt-1">
                  <div className="col-6">
                    <strong className="text-title">รายงานลูกหนี้</strong>
                  </div>
                  <div className="col-3">

                  </div>

                  {
                    this.state.lists.length === 0 ? '': (
                      <div className="col-3 text-center pt-2 pb-1 bg-primary">
                        <h5>
                          พบ&nbsp; {this.state.lists.length} &nbsp;รายการ
                        </h5>
                      </div>
                    )
                  }

                </div>
              </div>

              <div className="card-block">

                <div className="row mt-1">
                  <div className="form-group col-3 mt-1 ml-3">
                    <input type="radio"
                           name="data_by"
                           value="byDate"
                           className="btn btn-primary"
                           checked={this.state.data_by === 'byDate'}
                           onChange={this.handleOnChange}
                    /> &nbsp;<span style={{marginTop: -4}}>ตามวันที่</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio"
                           name="data_by"
                           value="all"
                           className="btn btn-primary"
                           checked={this.state.data_by === 'all'}
                           onChange={this.handleOnChange}
                    /> &nbsp;<span style={{marginTop: -4}}>ทั้งหมด</span>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="mt-1 ml-4">
                    จาก
                  </div>

                  <div className="form-group col-2 ml-2">
                    <DatePicker
                      selected={this.state.date_start}
                      onChange={this.handleDateStart}
                      dateFormat="yyyy-MM-dd"
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  {/* <div className="col-1 text-center">
                      ถึง
                    </div>*/}
                  <div className="form-group col-2">
                    <DatePicker
                      selected={this.state.date_end}
                      onChange={this.handleDateEnd}
                      dateFormat="yyyy-MM-dd"
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>

                  <div className="form-group col-2">
                    <select className="form-control" id="branch_id" name="branch_id"
                            onChange={(e) => {
                              this.setState({
                                branch_id: e.target.value
                              })
                            }}
                    >
                      <option value=''> ตามสาขา </option>
                      {car_branch_list}
                    </select>
                  </div>

                  <div className="form-group col-2">
                    <select className="form-control" id="bank_id" name="bank_id"
                            onChange={(e) => {
                              this.setState({
                                bank_id: e.target.value
                              })
                            }}
                    >
                      <option value=''> ตามธนาคาร </option>
                      {bank_list}
                    </select>
                  </div>

                  <div className="form-inline col-3 text-right">
                    <button type="button"
                            style={{width: 80, marginRight: 8, marginTop: -14}}
                            className="btn btn-sm btn-primary"
                            onClick={() => this.loadDebtorsData()} > ค้นหา </button>

                    <button type="button"
                            style={{width: 80, marginTop: -14}}
                            className="btn btn-sm btn-success"
                            onClick={() => this.exportCarBookData()} > Excel </button>
                  </div>

                </div>

                {/*<div className="row mt-1">
                  <div className="col-12 text-center">
                    <button type="button" style={{width: 80, marginRight: 8}} className="btn btn-sm btn-primary" onClick={() => this.loadDebtorsData()} >
                        ค้นหา
                     </button>
                    <button type="button" style={{width: 80}} className="btn btn-sm btn-success" onClick={() => this.exportCarBookData()} > Excel </button>
                  </div>
                </div>*/}

                <div className="row mt-1">
                  <div className="col-12">

                    <table className="table table-striped table-responsive">
                      <thead>
                      <tr>
                        <th style={{width: 10}} className="text-center text-nowrap">ลำดับ</th>
                        <th style={{width: 80}} className="text-center text-nowrap">วันที่ชำระล่าสุด</th>
                        <th style={{width: 80}} className="text-center text-nowrap">ยอดชำระล่าสุด</th>
                        <th style={{width: 80}} className="text-center text-nowrap">วันเริ่มต้นชำระเงิน</th>
                        <th style={{width: 200}} className="text-center text-nowrap">ชื่อ-นามสกุล</th>
                        <th style={{width: 200}} className="text-center text-nowrap">รายการ</th>

                        <th style={{width: 100}} className="text-center text-nowrap">ยอดคงเหลือค้างชำระ</th>
                        <th style={{width: 100}} className="text-center text-nowrap">ยอดเงินที่ชำระแล้ว</th>

                        <th style={{width: 100}} className="text-center text-nowrap">ยอดหนี้ทั้งสิ้น</th>
                        <th style={{width: 100}} className="text-center text-nowrap">ผู้ทำรายการ</th>
                        <th style={{width: 150}} className="text-center">รับชำระผ่าน (ธนาคาร)</th>

                      </tr>
                      </thead>
                      <tbody>
                      {tableRows}
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

}

const styles = {
  overflowY: {
    overflow: 'scroll',
  }
}

export default DebtorsReport
