import React, { Component } from 'react'
import axios from 'axios'
import { APIURL } from '../../../config/config'
import { checkGroupPermission } from '../../../services/grouppermission'

class ProfileDetail extends Component {
  constructor (props) {
    super(props);
    this.state = {
      profile: null,
      profile_id: 0,
      profile_from: '',
      profile_address: '',
      profile_fn1: '',
      profile_fn2: '',
      profile_fn3: '',
      profile_name: '',
      profile_ncb1: '',
      profile_guarantor1: '',
      profile_guarantor_ncb1: '',
      profile_guarantor2: '',
      profile_guarantor_ncb2: '',
      // roddee
      finance_update: '',
      finance_approve_date: null,
      customer_sign_date: null,
      customer_outcar_date: null,
    }
    this.loadProfile = this.loadProfile.bind(this)
  }

  componentDidMount () {

    this.loadProfile()
  }

  componentWillReceiveProps (nextProps, nextContext) {
    if(nextProps.isProfileReload !== this.props.isProfileReload){
      if(nextProps.isProfileReload) {
        this.loadProfile()
      }
    }
  }

  loadProfile() {
    let customer_id = this.props.customer_id
    axios.get(APIURL + '/profile/customer/'+customer_id)
      .then(res => {

        if(res.data.length > 0) {
          let profile = res.data[0];

          this.setState({
            profile: profile,
            profile_id: profile.profile_id === null || profile.profile_id === '' ? 0 : profile.profile_id ,
            profile_from: profile.profile_from === null || profile.profile_from === '' ? '-' : profile.profile_from,
            profile_address: profile.profile_address === null ||  profile.profile_address === '' ? '-' : profile.profile_address ,
            profile_fn1: profile.profile_fn1 === null ||  profile.profile_fn1 === '' ? '-' : profile.profile_fn1,
            profile_fn2: profile.profile_fn2 === null ||  profile.profile_fn2 === '' ? '-' : profile.profile_fn2 ,
            profile_fn3: profile.profile_fn3 === null ||  profile.profile_fn3 === '' ? '-' : profile.profile_fn3,
            profile_name: profile.profile_name === null ||  profile.profile_name === '' ? '-' : profile.profile_name ,
            profile_ncb1: profile.profile_ncb1 === null ||  profile.profile_ncb1 === '' ? '-' : profile.profile_ncb1,
            profile_guarantor1: profile.profile_guarantor1 === null ||  profile.profile_guarantor1 === '' ? '-' : profile.profile_guarantor1,
            profile_guarantor_ncb1: profile.profile_guarantor_ncb1 === null ||  profile.profile_guarantor_ncb1 === '' ? '-' : profile.profile_guarantor_ncb1,
            profile_guarantor2: profile.profile_guarantor2 === null ||  profile.profile_guarantor2 === '' ? '-' : profile.profile_guarantor2,
            profile_guarantor_ncb2: profile.profile_guarantor_ncb2 === null ||  profile.profile_guarantor_ncb2 === '' ? '-' : profile.profile_guarantor_ncb2,
          }, () => {
            if (this.props.isProfileReload) {
              this.props.setProfileReloadComplete();
            }
          })
        }
      }).catch(error => {
      console.log('error:', error)
      // this.setState({profile_lists: []})
    })
  }

  render() {
    const {
      profile,
      profile_id,
      profile_from,
      profile_address,
      profile_fn1 ,
      profile_fn2 ,
      profile_fn3,
      profile_name,
      profile_ncb1,
      profile_guarantor1 ,
      profile_guarantor_ncb1 ,
      profile_guarantor2 ,
      profile_guarantor_ncb2
    } = this.state
    let customer = this.props.customer
    let grouppermissions = this.props.grouppermissions

    // if (profile === null) {
    //   return <div className="card-block"> ไม่พบข้อมูลโปรไฟล์ </div>
    // }

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
             <div className="card-header card-customer">
               <strong className="text-title">
                 โปรไฟล์ลูกค้า : {customer.customer_name}{' '}{customer.customer_lastname}
               </strong>

               {
                 profile_id === 0 && checkGroupPermission(402, grouppermissions).created ? (
                   <button type="button" onClick={()=>{
                      this.props.openCreateForm();
                   }} className="btn btn-head-bar float-right">
                     <i className="icon-user-follow"/> &nbsp;&nbsp;เพิ่มโปรไฟล์ลูกค้า
                   </button>
                 ) : null
               }

               {
                 profile_id !== 0 && checkGroupPermission(402, grouppermissions).modified ? (
                   <button type="button" onClick={()=>{
                     this.props.onProfileEdit(profile);
                   }} className="btn btn-head-bar btn-warning float-right">
                     <i className="fa fa-edit"/> &nbsp;&nbsp;แก้ไขโปรไฟล์ลูกค้า
                   </button>
                 ) : null
               }

             </div>

            {
               profile_id === 0 ? <div className="card-block"> ไม่พบข้อมูลโปรไฟล์ </div> :
                <div className="card-block">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className=" form-group row" style={styles.bottomLine}>
                        <label className="col-md-2 "><strong> ลูกค้าจาก </strong></label>
                        <div className="col-md-4">
                          <p className="text-justify text-primary">
                            <strong> {profile_from} </strong>
                          </p>
                        </div>

                        <label className="col-md-2 "><strong> ลูกค้ามาจาก(ที่อยู่) </strong></label>
                        <div className="col-md-4">
                          <p className="text-justify text-primary">
                            <strong> {profile_address} </strong>
                          </p>
                        </div>
                      </div>

                      <div className=" form-group row" style={styles.bottomLine}>
                        <label className="col-md-2 "><strong> F/N : </strong></label>
                        <div className="col-md-2">
                          <p className="text-justify text-primary">
                            <strong> {profile_fn1} </strong>
                          </p>
                        </div>

                        <label className="col-md-2 "><strong> F/N : </strong></label>
                        <div className="col-md-2">
                          <p className="text-justify text-primary">
                            <strong> {profile_fn2} </strong>
                          </p>
                        </div>

                        <label className="col-md-2 "><strong> F/N : </strong></label>
                        <div className="col-md-2">
                          <p className="text-justify text-primary">
                            <strong> {profile_fn3} </strong>
                          </p>
                        </div>
                      </div>

                      <div className=" form-group row" style={styles.bottomLine}>
                        <label className="col-md-2 "><strong> เครดิตลูกค้า/เครดิตบูโร NCB : </strong></label>
                        <div className="col-md-10">
                          <p className="text-justify text-primary">
                            <strong> {profile_ncb1} </strong>
                          </p>
                        </div>
                      </div>

                      <div className=" form-group row" style={styles.bottomLine}>
                        <label className="col-md-2 "><strong> โปรไฟล์ลูกค้า : </strong></label>
                        <div className="col-md-10">
                          <p className="text-justify text-primary">
                            <strong> {profile_name} </strong>
                          </p>
                        </div>
                      </div>

                      <div className=" form-group row" style={styles.bottomLine}>
                        <label className="col-md-2 "><strong> ผู้ค้ำประกัน 1 </strong></label>
                        <div className="col-md-4">
                          <p className="text-justify text-primary">
                            <strong> {profile_guarantor1} </strong>
                          </p>
                        </div>

                        <label className="col-md-2 "><strong> เครดิตผู้ค้ำประกัน 1 NCB </strong></label>
                        <div className="col-md-4">
                          <p className="text-justify text-primary">
                            <strong> {profile_guarantor_ncb1} </strong>
                          </p>
                        </div>
                      </div>

                      <div className=" form-group row" style={styles.bottomLine}>
                        <label className="col-md-2 "><strong> ผู้ค้ำประกัน 2 </strong></label>
                        <div className="col-md-4">
                          <p className="text-justify text-primary">
                            <strong> {profile_guarantor2} </strong>
                          </p>
                        </div>

                        <label className="col-md-2 "><strong> เครดิตผู้ค้ำประกัน 2 NCB </strong></label>
                        <div className="col-md-4">
                          <p className="text-justify text-primary">
                            <strong> {profile_guarantor_ncb2} </strong>
                          </p>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
            }

          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
}
export default ProfileDetail
