import React, { Component } from 'react'
import axios from 'axios'
import {APIURL, IMAGE_UPLOAD_ERROR, PORT} from '../../../config/config'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import NumberFormat from "react-number-format"
import moment from 'moment'
import Swal from 'sweetalert2'
import { AlertSuccess, AlertWarning } from '../../Alert/Alert'
import {save} from "save-file";
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";
import Loading from '../../Loading'
import {checkGroupPermission, getGroupPermission} from "../../../services/grouppermission";
import ExpenseAllListRow from "../ExpenseAllListRow/ExpenseAllListRow";

class ExpenseAll extends Component {

  constructor (props) {
    super(props)
    this.state = {
      menuID: [116, 1201],
      lists: [],
      load_data: true,
      danger: false,
      expense: '',
      toggle_expense: false,
      expense_id: 0,
      user: {},
      w_expense_image_file: false,
      month_year_list: [],
      month_list_thai: '',
      sum_expense: 0,
      date_start: new Date(),
      date_end: new Date(),
      width: 0,
      height: 0,
      expense_selected: null,
      toggle_file_modal: false,
      toggle_edit_modal: false,
      expense_image_file: '',
      expense_image_key: '',
      income_expense_item: [],
      income_expense_item_id: '',
      income_expense_item_name: '',
      is_load: false,
      sum_vat:0,
      is_date_fill: 0,
      company_setting: null,
      isLoading: false,
      grouppermissions: [],
      isFixCostOpen: false,
      cost_types: [],
      cost_type_id: '',
      form_control_expense_tents: [],
      account_id : '',
      accounts:[],
    }

    this.toggleExpense = this.toggleExpense.bind(this);
    this.onDeleteHandle = this.onDeleteHandle.bind(this);
    this.loadExpenseData = this.loadExpenseData.bind(this);
    this.loadExpenseAllDataMonth = this.loadExpenseAllDataMonth.bind(this);
    this.handleDateStart = this.handleDateStart.bind(this);
    this.handleDateEnd = this.handleDateEnd.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onSaveFileModal = this.onSaveFileModal.bind(this);
    this.onEditExpenseModal = this.onEditExpenseModal.bind(this);
    this.toggleFileModal = this.toggleFileModal.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.loadIncomeExpenseItemData = this.loadIncomeExpenseItemData.bind(this);
    this.handleIncomeExpenseItemChange = this.handleIncomeExpenseItemChange.bind(this);
    this.exportExpenseTentTotal = this.exportExpenseTentTotal.bind(this);
    this.loadCompanySetting = this.loadCompanySetting.bind(this);
    this.loadGroupPermission = this.loadGroupPermission.bind(this);
    this.loadCostTypeData = this.loadCostTypeData.bind(this);
    this.loadExpenseTentFormControls = this.loadExpenseTentFormControls.bind(this);
    // this.loadExpenseDataWithType = this.loadExpenseDataWithType.bind(this);
    this.loadAccountData = this.loadAccountData.bind(this)
    this.handleAccountListChange = this.handleAccountListChange.bind(this)
  }

  loadExpenseTentFormControls() {
    let tableName = "expense_tent";
    axios.get(APIURL + '/form_controls/'+tableName)
        .then(res => {
          this.setState({form_control_expense_tents: res.data});
        }).catch(error => {
      this.setState({form_control_expense_tents: []})
    })
  }

  async loadCompanySetting() {
    await axios.get(APIURL + '/company_setting/1').then(res => {
      let data = res.data
      let company_setting = data.company_setting
      const is_date_fill = company_setting.is_date_fill;
      this.setState({
        company_setting: company_setting,
        is_date_fill: is_date_fill
      })
    })
  }

  loadExpenseData () {
    this.loadExpenseAllDataMonth();
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    }, () => {
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    }, () => {
    })
  }

  loadExpenseAllDataMonth () {

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')
    let income_expense_item_id = this.state.income_expense_item_id === '' ? 'all' :  this.state.income_expense_item_id
    let cost_type_id = this.state.cost_type_id
    //let expense_type = this.state.expense_type_id = 2
    let user = this.state.account_id === '' ? 'all' : this.state.account_id
    //let car_id = this.state.car_id

    let expenseAll = {
      //expense_type,
      start_date,
      end_date,
      income_expense_item_id,
      cost_type_id,
      user
    }

    axios.post(APIURL + '/expense/expenseall', expenseAll)
        .then(res => {
          let sum_expense = 0
          let sum_vat = 0
          if(res.data.length > 0) {
            sum_expense =  res.data.reduce((acc, expense) => {
              if(expense.include_vat === 1) {
                // ยอด expense_amount รวม vat แล้ว
                return acc + expense.expense_amount
              } else {
                // expense_amount ยังไม่รวม vat
                return acc + expense.expense_amount + Number(expense.expense_amount_vat)
              }
            }, 0);

            sum_vat =  res.data.reduce((acc, next) => acc + next.expense_amount_vat, 0);

          }

          let sum_expense_fix2 = parseFloat(String(sum_expense)).toFixed(2)
          let sum_vat_fix2 = parseFloat(String(sum_vat)).toFixed(2)
          let lists = res.data

          this.setState({lists: lists, sum_expense : sum_expense_fix2, sum_vat : sum_vat_fix2, is_load: true});
        }).catch(error => {
      this.setState({lists: [], is_load: false})
    })
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    })
  }

  async componentDidMount () {
    this.loadGroupPermission();
    this.loadExpenseTentFormControls();
    await this.loadCompanySetting();

    this.loadCostTypeData();
    this.loadExpenseAllDataMonth();
    this.loadIncomeExpenseItemData();
    this.updateWindowDimensions();
    this.loadAccountData();

    window.addEventListener('resize', this.updateWindowDimensions);
  }

  loadGroupPermission() {
    let type = this.props.type
    let menuID = this.state.menuID

    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  loadIncomeExpenseItemData () {
    axios.get(APIURL + '/income_expense_item/get/expense')
        .then(res => {
          this.setState({income_expense_item: res.data})
        }).catch(error => {
      this.setState({income_expense_item: []})
    })
  }

  handleIncomeExpenseItemChange (event) {
    // var income_expense_item_id = event.target.value
    let income_expense_item_id = event.target.value
    let income_expense_item_name = event.target.options[event.target.selectedIndex].text

    this.setState({
      income_expense_item_id: income_expense_item_id,
      income_expense_item_name: income_expense_item_name,
      is_load: false
    })
  }

  loadAccountData() {
    axios.get(APIURL + '/account/expense/user')
        .then(res => {
          this.setState({accounts: res.data})
        }).catch(error => {
      this.setState({accounts: []})
    })
  }

  handleAccountListChange (event) {
    let account_id = event.target.value
    this.setState({
      account_id: account_id
    })
  }

  toggleExpense () {
    this.setState({
      toggle_expense: !this.state.toggle_expense
    })
    this.loadExpenseData()
  }

  onDeleteHandle(expense) {

    let expense_id = expense.expense_id
    let expense_image_file = expense.expense_image_file
    let expense_image_key = expense.expense_image_key
    let data = {
      expense_image_file: expense_image_file,
      expense_image_key: expense_image_key,
    }

    Swal.fire({
      title: 'ยืนยันการลบ ' + expense.expense_name + ' ',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        // expense_id = 2 => ค่าใช้จ่ายของเต๊นท์
        axios.put(APIURL + '/expense/' + expense_id, data)
            .then(res => {
              if (res.status === 200) {
                this.loadExpenseData()
              }
            }).catch(error => {
          console.log('error:', error)
        })
      }
    });

  }

  onSaveFileModal(expense) {
    // show modal for save update file
    this.setState({
      expense_selected: expense,
      toggle_file_modal: true,
      expense_image_file: expense.expense_image_file,
      expense_image_key: expense.expense_image_key,
    })

  }

  onEditExpenseModal(expense) {
    // show modal for edit
    this.setState({
      expense_selected: expense,
      toggle_edit_modal: true
    })
  }

  toggleFileModal() {
    this.setState({
      toggle_file_modal: !this.state.toggle_file_modal
    })
  }

  toggleEditModal() {
    this.setState({
      toggle_edit_modal: !this.state.toggle_edit_modal
    })
  }

  handleUploadFile(event) {

    let expense_selected = this.state.expense_selected
    let expense_image_key = this.state.expense_image_key
    let expense_id = expense_selected.expense_id

    let input_file_name = event.target.name

    this.refs.img_expense_image_file.src='img/loading.gif'

    const data = new FormData();
    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('expense_id', expense_id)
    data.append('expense_image_key', expense_image_key)
    data.append('expense_image_file', input_file_name)

    axios.put(APIURL+'/expense/file/'+expense_id, data).then((res) => {
      const resData = res.data;
      const filename = resData.image;
      const expense_image_key = resData.expense_image_key;
      let image_url = ''

      if(resData.image === undefined) {
        image_url = IMAGE_UPLOAD_ERROR
      } else if (resData.image === 'uploaderror') {
        image_url = IMAGE_UPLOAD_ERROR
      } else {
        image_url = filename
      }

      AlertSuccess('เพิ่มไฟล์เอกสารเรียบร้อยแล้ว');

      this.setState({
        expense_image_file: image_url,
        expense_image_key: expense_image_key,
        toggle_file_modal: false,
      }, () => this.loadExpenseDataAllMonth())

    })

  }

  exportExpenseTentTotal(){
    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')
    let income_expense_item_id = this.state.income_expense_item_id === '' ? 'all' : this.state.income_expense_item_id
    let user = this.state.account_id === '' ? 'all' : this.state.account_id
    //console.log('account_id===', user, typeof user)
    //return;

    this.setState({
      isLoading: true
    })

    let url = APIURL + '/reportrepair/expensetotal/'+start_date+'/'+end_date+'/'+income_expense_item_id
    if (PORT === 9043) {
      url = APIURL + '/reportrepair/expensetotalat/'+start_date+'/'+end_date+'/'+income_expense_item_id+'/'+user
    }

    axios.get(url)
        .then( async res => {
          const excelBuffer = res.data.excelBuffer
          const currentDatetime = res.data.currentDatetime
          const fileName = 'รายงานค่าใช้จ่ายรวม_'+currentDatetime+'.xlsx'

          setTimeout(()=> {
            this.setState({
              isLoading: false
            })
          }, 2000);

          await save(excelBuffer, fileName)

        }).catch(error => {
      console.log('error:', error)
      // this.setState({lists: []})
    })
  }

  loadCostTypeData () {
    axios.get(APIURL + '/cost_type')
        .then(res => {
          this.setState({cost_types: res.data})
        }).catch(error => {
      this.setState({cost_types: []})
    })
  }

  render () {

    const {
      width,
      is_load,
      income_expense_item_name,
      cost_types
    } = this.state

    let income_expense_item_list = this.state.income_expense_item.map((income_expense_item,index)=>{
      return(
          <option key={index} value={income_expense_item.income_expense_item_id}>{income_expense_item.income_expense_item_name}</option>
      )
    })

    let cost_type_list = cost_types.map((cost_type,index)=>{
      return(
          <option key={index} value={cost_type.cost_type_id}>{cost_type.cost_type_name}</option>
      )
    })

    let account_list = this.state.accounts.map((account,index)=>{
      return(
          <option key={index} value={account.user}>{account.fullname}</option>
      )
    })

    return (
        <div className="card">
          <Loading isLoading={this.state.isLoading} />
          <div className="card-block">
            <div className="row">
              <div className="col-sm-12 col-md-12">
                    <div className="card card-accent-warning">
                      <div className="card-header card-sparepart">
                        <strong className="text-title"> สรุปข้อมูลค่าซ่อมรถ / ค่าใช้จ่ายอื่นๆของรถ / ค่าใช้จ่ายอื่นๆของเต็นท์</strong>
                      </div>

                      <div className="card-block" style={styles.overflowY}>
                        <div className="row mb-4">
                          <div className="mt-1 ml-2">
                            จาก
                          </div>

                          <div className="col-2">
                            <DatePicker
                                selected={this.state.date_start}
                                onChange={this.handleDateStart}
                                dateFormat="yyyy-MM-dd"
                                type="text"
                                className="form-control"
                                placeholder=""
                            />
                          </div>

                          <div className="ml-1 mt-1">
                            ถึง
                          </div>

                          <div className="col-2">
                            <DatePicker
                                selected={this.state.date_end}
                                onChange={this.handleDateEnd}
                                dateFormat="yyyy-MM-dd"
                                type="text"
                                className="form-control"
                                placeholder=""
                            />
                          </div>

                          <div className="col-2 pt-0 ">
                            <select
                                className="form-control"
                                name="income_expense_item_id"
                                onChange={this.handleIncomeExpenseItemChange.bind(this)}
                            >
                              <option value="">เลือกหมวดค่าใช้จ่าย</option>
                              {income_expense_item_list}
                            </select>
                          </div>

                          <div className="col-2 pt-0">
                            <select
                                className="form-control"
                                name="cost_type_id"
                                value={this.state.cost_type_id}
                                onChange={(e) => this.setState({cost_type_id: e.target.value})}
                            >
                              <option value=''>เลือกประเภทต้นทุน</option>
                              {cost_type_list}
                            </select>
                          </div>

                              <div className="col-2 pt-0">
                                <select
                                    className="form-control"
                                    name="account_id"
                                    onChange={this.handleAccountListChange.bind(this)}
                                    value={this.state.account_id}
                                >
                                  <option value=''>เลือกผู้บันทึก</option>
                                  {account_list}
                                </select>
                              </div>

                          <div className="mr-2">
                            <button type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.loadExpenseAllDataMonth()}
                            > ตกลง </button>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-7 text-left">
                            <h6>
                              คชจ.ของเต็นท์รวม VAT =
                              {
                                income_expense_item_name !== '' && is_load === true ? '('+income_expense_item_name+')' : null
                              }
                              &nbsp;
                              <NumberFormat
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  value={this.state.sum_expense}/>
                              &nbsp;บาท
                            </h6>

                            <h6>
                              VAT คชจ.ของเต็นท์ =
                              {
                                income_expense_item_name !== '' && is_load === true ? '('+income_expense_item_name+')' : null
                              }
                              &nbsp;
                              <NumberFormat
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  value={this.state.sum_vat}/>
                              &nbsp;บาท
                            </h6>
                          </div>

                          <div className="col-5 text-right">
                            {
                              checkGroupPermission(1201, this.state.grouppermissions).printed ? (
                                  <button type="button"
                                          onClick={() => this.exportExpenseTentTotal()}
                                          className="btn btn-success float-right ml-2">
                                    Excel ค่าใช้จ่ายรวมทุกประเภท
                                  </button>
                              ) : null
                            }
                          </div>
                        </div>

                        <ExpenseAllListRow
                            expense_list={this.state.lists}
                            menuID={116}
                            grouppermissions={this.state.grouppermissions}
                            form_controls={this.state.form_control_expense_tents}
                            onDeleteHandle={this.onDeleteHandle}
                            onSaveFileModal={this.onSaveFileModal}
                            onEditExpenseModal={this.onEditExpenseModal}
                        />

                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
    )
  }
}

const styles = {
  fileInputWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: 180,
    width: 200,
  },
  btnFileInput: {
    height: 180,
    width: 200,
    display: 'inline-block',
    backgroundColor: "#D2DDE2",
    backgroundImage: "linear-gradient(to bottom, #D2DDE2, #FCF8FF)",
    backgroundRepeat: "repeat-x",
    color: "#ffffff",
    textShadow: "0 -1px 0 rgba(0, 0, 0, 0.25)"
  },
  btnFileInputImage:{
    position: 'absolute',
    top: 0,
    left: 0,
    height: 180,
    width: 200,
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
    height: 180,
    width: 200,
    cursor: 'pointer'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  type: state.auth.type,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ExpenseAll)
