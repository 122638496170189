import React,{Component} from 'react'
import axios from 'axios/index'
import { APIURL } from '../../../config/config'

class CarLeasingForm extends Component{
  constructor (props){
    super(props)
    this.state = {
      lists: [],
      car_id : this.props.car.car_id,
      // leasingName: ['SCB', 'GE', 'TA', 'KK', 'TIS'],
      leasingName: ['AYCAL', 'TBANK', 'TISCO', 'CIMB', 'SCB'],

      AYCALM: '',
      TBANKM: '',
      TISCOM: '',
      CIMBM: '',
      SCBM: '',

      AYCAL: '',
      TBANK: '',
      TISCO: '',
      CIMB: '',
      SCB: '',

      AYCAL2: '',
      TBANK2: '',
      TISCO2: '',
      CIMB2: '',
      SCB2: '',

    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.loadCarLeasing();
  }

  loadCarLeasing() {
    var uri =  APIURL + '/car_leasing/'+this.state.car_id;
    axios.get(uri)
      .then(res => {
        if(res.data.length===0){
          this.setState({lists: res.data, action: 'add'})
        }else{
          this.setState({lists: res.data, action: 'edit'},()=>{
            // console.log(res.data)
            this.state.lists.map((leasing) => {
              var targetName = leasing.car_leasing_name
              var median = leasing.car_leasing_price_median
              var value = leasing.car_leasing_price
              var no_credit = leasing.car_leasing_price_nc

              /*if(targetName === 'SCB'){
                this.setState({SCBM: median, SCB: value, SCB2: no_credit });
              }else if(targetName === 'GE') {
                this.setState({GEM: median , GE: value , GE2: no_credit});
              }else if(targetName === 'TA'){
                this.setState({TAM: median , TA: value , TA2: no_credit});
              }else if(targetName === 'KK'){
                this.setState({KKM: median , KK: value , KK2: no_credit});
              }else if(targetName === 'TIS'){
                this.setState({TISM: median, TIS: value, TIS2: no_credit});
              }else if(targetName === 'MPRICE'){
                // this.setState({MPRICE: value});
              }*/

              if(targetName === 'AYCAL'){
                this.setState({AYCALM: median, AYCAL: value, AYCAL2: no_credit });
              }else if(targetName === 'TBANK') {
                this.setState({TBANKM: median , TBANK: value , TBANK2: no_credit});
              }else if(targetName === 'TISCO'){
                this.setState({TISCOM: median , TISCO: value , TISCO2: no_credit});
              }else if(targetName === 'CIMB'){
                this.setState({CIMBM: median , CIMB: value , CIMB2: no_credit});
              }else if(targetName === 'SCB'){
                this.setState({SCBM: median, SCB: value, SCB2: no_credit});
              }else if(targetName === 'MPRICE'){
                // this.setState({MPRICE: value});
              }

              return undefined;
            });

          })
        }

      }).catch(error => {
      this.setState({lists: []})
    })
  }

  handleChange(event) {
    var targetName = event.target.name;
    var value = event.target.value;
     //console.log(targetName);
     //console.log(value);
     // console.log(this.$ref)
    if(targetName === 'AYCALM'){
      this.setState({AYCALM: value });
    }else if(targetName === 'TBANKM') {
      this.setState({TBANKM: value});
    }else if(targetName === 'TISCOM'){
      this.setState({TISCOM: value});
    }else if(targetName === 'CIMBM'){
      this.setState({CIMBM: value});
    }else if(targetName === 'SCBM'){
      this.setState({SCBM: value});
    }

    if(targetName === 'AYCAL'){
      this.setState({AYCAL: value });
    }else if(targetName === 'TBANK') {
      this.setState({TBANK: value});
    }else if(targetName === 'TISCO'){
      this.setState({TISCO: value});
    }else if(targetName === 'CIMB'){
      this.setState({CIMB: value});
    }else if(targetName === 'SCB'){
      this.setState({SCB: value});
    }


    if(targetName === 'AYCAL2'){
      this.setState({AYCAL2: value });
    }else if(targetName === 'TBANK2') {
      this.setState({TBANK2: value});
    }else if(targetName === 'TISCO2'){
      this.setState({TISCO2: value});
    }else if(targetName === 'CIMB2'){
      this.setState({CIMB2: value});
    }else if(targetName === 'SCB2'){
      this.setState({SCB2: value});
    }


    if(targetName === 'MPRICE') {
      this.setState({MPRICE: value});
    }

  }

  render() {
    var trLeasing = ''
    if(this.state.lists.length === 0){
      trLeasing = this.state.leasingName.map((leasing, index) => {
        var input2 = '';
        var inputM = '';
        // var mprice = '';
        if(leasing==='AYCAL'){
          inputM = (
            <input
              className="form-control"
              type="number"
              name="AYCALM"
            />
          )
          input2= ( <input
            className="form-control"
            type="number"
            name="AYCAL2"
          />)
        }else if(leasing==='TBANK'){
          inputM = (
            <input
              className="form-control"
              type="number"
              name="TBANKM"
            />
          )
          input2= ( <input
            className="form-control"
            type="number"
            name="TBANK2"
          />)
        }else if(leasing==='TISCO'){
          inputM = (
            <input
              className="form-control"
              type="number"
              name="TISCOM"
            />
          )
          input2= ( <input
            className="form-control"
            type="number"
            name="TISCO2"
          />)
        }else if(leasing==='CIMB'){
          inputM = (
            <input
              className="form-control"
              type="number"
              name="CIMBM"
            />
          )
          input2= ( <input
            className="form-control"
            type="number"
            name="CIMB2"
          />)
        }else if(leasing==='SCB'){
          inputM = (
            <input
              className="form-control"
              type="number"
              name="SCBM"
            />
          )
          input2= ( <input
            className="form-control"
            type="number"
            name="SCB2"
          />)
        }
         return (
           <tr key={index}>
             <td style={{width: 100}}>{leasing}</td>
             <td style={{width: 150}}>
               {inputM}
             </td>
             <td style={{width: 150}}>
               <input
                 className="form-control"
                 type="number"
                 id={leasing}
                 name={leasing}
               />
             </td>
             <td style={{width: 150}}>
               {input2}
             </td>
           </tr>
         )
      })
    }else{

      trLeasing = this.state.lists.map((leasing, index) => {
        var mprice = '';
        var input = '';
        var input2 = '';

         if(leasing.car_leasing_name==='AYCAL'){
          mprice = (
            <input
              className="form-control"
              type="number"
              name="AYCALM"
              value={this.state.AYCALM || ''}
              onChange={this.handleChange}
            />
          )

          input= ( <input
            className="form-control"
            type="number"
            id={leasing.car_leasing_name}
            name={leasing.car_leasing_name}
            value={this.state.AYCAL || ''}
            onChange={this.handleChange}
          />)

          input2= ( <input
            className="form-control"
            type="number"
            name="AYCAL2"
            value={this.state.AYCAL2 || ''}
            onChange={this.handleChange}
          />)

        }else if(leasing.car_leasing_name==='TBANK'){
          mprice = (
            <input
              className="form-control"
              type="number"
              name="TBANKM"
              value={this.state.TBANKM || ''}
              onChange={this.handleChange}
            />
          )

          input= ( <input
            className="form-control"
            type="number"
            id={leasing.car_leasing_name}
            name={leasing.car_leasing_name}
            value={this.state.TBANK || ''}
            onChange={this.handleChange}
          />)

          input2= ( <input
            className="form-control"
            type="number"
            name="TBANK2"
            value={this.state.TBANK2 || ''}
            onChange={this.handleChange}
          />)

        }else if(leasing.car_leasing_name==='TISCO'){
          mprice = (
            <input
              className="form-control"
              type="number"
              name="TISCOM"
              value={this.state.TISCOM || ''}
              onChange={this.handleChange}
            />
          )

          input= ( <input
            className="form-control"
            type="number"
            id={leasing.car_leasing_name}
            name={leasing.car_leasing_name}
            value={this.state.TISCO || ''}
            onChange={this.handleChange}
          />)

          input2= ( <input
            className="form-control"
            type="number"
            name="TISCO2"
            value={this.state.TISCO2 || ''}
            onChange={this.handleChange}
          />)

        }else if(leasing.car_leasing_name==='CIMB'){
          mprice = (
            <input
              className="form-control"
              type="number"
              name="CIMBM"
              value={this.state.CIMBM || ''}
              onChange={this.handleChange}
            />
          )

          input= ( <input
            className="form-control"
            type="number"
            id={leasing.car_leasing_name}
            name={leasing.car_leasing_name}
            value={this.state.CIMB || ''}
            onChange={this.handleChange}
          />)

          input2= ( <input
            className="form-control"
            type="number"
            name="CIMB2"
            value={this.state.CIMB2 || ''}
            onChange={this.handleChange}
          />)
        }else if(leasing.car_leasing_name==='SCB'){

           mprice = (
             <input
               className="form-control"
               type="number"
               name="SCBM"
               value={this.state.SCBM || ''}
               onChange={this.handleChange}
             />
           )

           input= ( <input
             className="form-control"
             type="number"
             id={leasing.car_leasing_name}
             name={leasing.car_leasing_name}
             value={this.state.SCB || ''}
             onChange={this.handleChange}
           />)

           input2= ( <input
             className="form-control"
             type="number"
             name="SCB2"
             value={this.state.SCB2 || ''}
             onChange={this.handleChange}
           />)

         }

        if(leasing.car_leasing_name!=='MPRICE') {

          return (
            <tr key={index}>
              <td style={{width: 100}}>{leasing.car_leasing_name}</td>
              <td style={{width: 150}}>
                {mprice}
              </td>
              <td style={{width: 150}}>
                {input}
              </td>
              <td style={{width: 150}}>
                {input2}
              </td>
            </tr>
          )
        }else{
          return null;
        }

      })

      /*if(mprice === ''){
        var index = 99
        mprice = (
          <tr key={index}>
            <td style={{width: 100}}>ราคากลาง</td>
            <td colSpan={2} style={{width: 300}}>
              <input
                className="form-control"
                type="number"
                name="MPRICE"
                value={this.state.MPRICE}
                onChange={this.handleChange}
              />
            </td>
          </tr>)
      }*/

    }

    return (
      <div className="card card-accent-primary">
        <form action="" method="post" onSubmit={this.props.onSubmit}>
          <div className="card-header">
            <strong>บันทึกยอดจัด</strong>
          </div>
          <div className="card-block">
            <div className="row text-center">
              <table className="tableWhitesmoke" style={{marginTop: 8}}>
                <thead>
                  <tr>
                    <th className="text-center" >ลิสซิ่ง</th>
                    <th className="text-center" >ราคากลาง</th>
                    <th className="text-center" >เครดิต</th>
                    <th className="text-center" >ไม่เครดิต</th>
                  </tr>
                </thead>
                <tbody>
                {trLeasing}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer text-right">
            <button type="reset"
                    onClick={this.props.onHandle.cancel}
                    className="btn btn-sm btn-danger">
              <i className="fa fa-refresh"/> ยกเลิก
            </button>

            <button type="submit" className={this.state.action==='add'? "btn btn-sm btn-primary" : "btn btn-sm btn-warning" }>
              <i className="fa fa-save"/>
               {this.state.action==='add'? " บันทึก" : " แก้ไข" }
            </button>
            <input type="hidden" name="action" defaultValue={this.state.action}  />
          </div>
        </form>
      </div>
    )
  }
}

export default CarLeasingForm