import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { IMAGE_FULL_URL, IMAGE_NOPHOTO_URL  } from '../../../config/config';
import PreviewImage from '../../../views/PreviewImage/';
import * as moment from 'moment';
import 'moment/locale/th';
import {checkGroupPermission} from "../../../services/grouppermission";


class CustomerDocList extends Component {
  constructor (props){
    super(props)
    this.state = {
      preview_image_url: '',
      preview_image_select: false
    }
    this.togglePreviewImage = this.togglePreviewImage.bind(this)
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  onCustomerDocDelete(customer_doc){
    this.props.onDelete(customer_doc)
  }


render() {

    let car_doc_list = this.props.customer_docs.map((customer_doc,index)=>{
      return(
        <tr key={index} >
          <td className="text-center">{customer_doc.customer_doc_id}</td>
          <td className="text-center">
            <img
              src={customer_doc.doc_file===''? IMAGE_NOPHOTO_URL : IMAGE_FULL_URL + customer_doc.doc_file}
              style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
              onClick={()=>{
                if(customer_doc.doc_file!=='')
                  this.onPreviewSelected(IMAGE_FULL_URL + customer_doc.doc_file)
              }}
              alt="เอกสาร"
            />
          </td>
          <td>

            {customer_doc.doc_detail}<br/>
            [ ชื่อ-นามสกุล: {customer_doc.customer_name} {customer_doc.customer_lastname} &nbsp;&nbsp; {customer_doc.customer_mobile} ]
            </td>
          <td className="text-center">
            {customer_doc.user_created}<br/>
            {moment(customer_doc.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}
          </td>
          <td className="text-center">

              {
                  checkGroupPermission(403, this.props.grouppermissions).modified ? (
                     <>
                         <button className="btn btn-sm btn-warning btn-md-width"
                                 onClick={()=>this.props.onEdit(customer_doc)}
                         >
                             <i className="fa fa-edit"/> แก้ไข</button>
                         <br/>
                     </>
                  ) : null
              }

              {
                  checkGroupPermission(403, this.props.grouppermissions).deleted ? (
                      <button className="btn btn-sm btn-danger btn-md-width mt-2"
                              onClick={()=>this.onCustomerDocDelete(customer_doc)}
                      >
                          <i className="fa fa-remove"/> ลบ
                      </button>
                  ) : null
              }

              {
                  checkGroupPermission(403, this.props.grouppermissions).modified === 0
                  && checkGroupPermission(403, this.props.grouppermissions).deleted === 0
                  ? <i className="icon-close"/> : null
              }

          </td>
        </tr>
      )
    })

    return (
      <div>

        <table className={(this.props.customer_docs.length>0)?'table table-striped ' : 'table table-striped hidden'}>
          <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">ไฟล์เอกสาร</th>
            <th className="text-center">ชื่อเอกสาร</th>
            <th className="text-center">ผู้ทำรายการ</th>
            <th className="text-center">จัดการ</th>
          </tr>
          </thead>
          <tbody>
          {car_doc_list}
          </tbody>
        </table>

        <div className={this.props.customer_docs.length===0?' text-center  ' : 'hidden'} >
          <strong className="text-danger">ไม่พบรายการเอกสารลูกค้า</strong>
        </div>

        <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
          <ModalBody>
            <PreviewImage
              imageUrl={this.state.preview_image_url}
            />
          </ModalBody>
        </Modal>

      </div>
    )
  }
}

export default  CustomerDocList
