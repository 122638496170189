import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import CarInReport from './CarInReport'
import CarInBuyReport from './CarInBuyReport'
import CarInCostSummaryReport from "./CarInCostSummaryReport";
import CarMerchantAccount from "./CarMerchantAccount";
import {PORT} from "../../config/config";
import {checkGroupPermission, getGroupPermission} from "../../services/grouppermission";

// 9043 = atcarsale
//  activeTab: PORT === 9043 ? '4': '1',
class CarInReportTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      activeTab: PORT === 9043 ? '3': '1',
      menuID: [117, 118, 128],
      grouppermissions: [],
    }

    this.toggle = this.toggle.bind(this);
    this.loadGroupPermission = this.loadGroupPermission.bind(this);
  }

  loadGroupPermission() {
    let type = this.props.type
    let menuID = this.state.menuID

    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user),
    });
  }

  async componentDidMount() {
    this.loadGroupPermission();
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render () {
    return(
      <div className="col-md-12">
        <Nav tabs>
          {
            checkGroupPermission(117, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  <i className="icon-options-vertical"/> รายงานสต๊อกรถ
                </NavLink>
              </NavItem>
            )  : null
          }

          {/*<NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              <i className="icon-options-vertical"/> รายงานการซื้อรถ
            </NavLink>
          </NavItem>*/}

          {
            checkGroupPermission(118, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                    className={classnames({ active: this.state.activeTab === '3' })}
                    onClick={() => { this.toggle('3'); }}
                >
                  <i className="icon-paper-plane"/> รายงานต้นทุนอย่างย่อ
                </NavLink>
              </NavItem>
            )  : null
          }

          {
            checkGroupPermission(128, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}
                >
                  <i className="icon-paper-clip"/> บัญชีผู้ทำการค้าของเก่า
                </NavLink>
              </NavItem>
            )  : null
          }

        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          {
            checkGroupPermission(117, this.state.grouppermissions).viewed ? (
          <TabPane tabId="1">
            {
              this.state.activeTab === '1' &&
              <CarInReport
                  menuID={117}
                  grouppermissions={this.state.grouppermissions}
              />
            }
          </TabPane>
           )  : null
          }

          <TabPane tabId="2">
            {
              this.state.activeTab === '2' && <CarInBuyReport />
            }
          </TabPane>

          {
            checkGroupPermission(118, this.state.grouppermissions).viewed ? (
          <TabPane tabId="3">
            {
              this.state.activeTab === '3' &&
              <CarInCostSummaryReport
                  menuID={118}
                  grouppermissions={this.state.grouppermissions}

              />
            }
          </TabPane>
            )  : null
          }

          {
            checkGroupPermission(128, this.state.grouppermissions).viewed ? (
              <TabPane tabId="4">
                {
                  this.state.activeTab === '4' &&
                  <CarMerchantAccount
                    menuID={128}
                    grouppermissions={this.state.grouppermissions}

                  />
                }
              </TabPane>
            )  : null
          }

        </TabContent>
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  type: state.auth.type,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarInReportTabs);
