import React, {Component} from "react";
import {connect} from "react-redux";
import Loading from "../../Loading";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
import axios from "axios";
import {APIURL} from "../../../config/config";
import CustomerOldForm from "../CustomerOldForm/CustomerOldForm";
import CustomerOldListRow from "../CustomerOldListRow/CustomerOldListRow";
import {Link} from "react-router-dom";
import {save} from "save-file";
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";


class CustomerOldList extends Component{
    constructor (props) {
        super(props)

        this.state = {
            lists: [],
            load_data: true,
            user: JSON.parse(this.props.user),
            isLoading: false,
            page: 1,
            activeTab: '1',
            is_search: false,
            width: 0,
            height: 0,
            province_new_id: 0,
            province: [],
            banks:[],
            customer_old_list: [],
            danger: false,
            action: "บันทึก",
            customerOld:{}
        }

        this.loadDataPage = this.loadDataPage.bind(this);
        this.togglePrimary= this.togglePrimary.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.loadCustomerOldCar = this.loadCustomerOldCar.bind(this);

        this.toggleDangerDelete = this.toggleDangerDelete.bind(this);

    }

    loadCustomerOldCar(customer_id) {
        axios.get(APIURL + '/customer/get_customer_car_old/'+ customer_id)
            .then(res => {
                this.setState({customer_old_list: res.data});
        })
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidMount () {
        CarsUtil.removeCarSelect()
        CarSellUtil.removeCarSellSelect()
        //if(this.state.load_data)
        // this.loadData()
        this.loadCustomerOldCar();
        this.loadDataPage(1)
        this.loadProvinceData()
        this.loadBankData()

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    loadProvinceData () {
        axios.get(APIURL + '/province/')
            .then(res => {
                //console.log(res)
                this.setState({province: res.data})
            }).catch(error => {
            this.setState({province: []})
        })
    }

    loadBankData () {
        axios.get(APIURL + '/bank/')
            .then(res => {
                this.setState({banks: res.data})
            }).catch(error => {
            this.setState({banks: []})
        })
    }

    loadData () {
        axios.get(APIURL + '/customer/')
            .then(res => {
                //alert (res.data.length)
                //console.log(res)
                this.setState({lists: res.data, load_data: false, customer_id: 0, danger: false})
            }).catch(error => {
            //alert (error)
            //console.log(error)
            this.setState({lists: [], load_data: true, customer_id: 0, danger: false})
        })
    }

    loadDataPage (page) {
        // customers: customers,
        // customer_all: customer_all
        // console.log('loadDataPage page=', page)
        this.setState({
            isLoading: true
        }, () => {
            axios.get(APIURL + '/customer/page/'+page)
                .then(res => {
                    // alert (res.data.length)
                    // console.log(res)
                    let customers =  res.data.customers
                    let customer_all =  res.data.customer_all

                    // console.log('customer_all=', customer_all)
                    // console.log('customers=', customers.length, typeof customers)

                    this.setState({
                        lists: customers,
                        customer_all: customer_all,
                        load_data: false,
                        customer_id: 0,
                        danger: false,
                        isLoading: false,
                        page: page
                    })
                }).catch(error => {
                //alert (error)
                //console.log(error)
                this.setState({lists: [], load_data: true, customer_id: 0, danger: false, isLoading: false})
            })
        })
    }

    onRefresh(){
        this.refs.input_search.value = ''
        // this.loadData()
        this.setState({
            is_search: false
        })
        this.loadDataPage(1)
    }

    onSearch(){
        let search_text = this.refs.input_search.value;

        if(search_text==='')
            return;

        axios.get( APIURL + '/customer/search/'+search_text )
            .then(res => {
                this.setState({
                    lists: res.data, load_data: false, customer_id: 0, danger: false, is_search: true })
            }).catch(error => {
            this.setState({lists: [], load_data: true, customer_id: 0, danger: false})
        })

    }

    togglePrimary() {
        let isClose = !this.state.primary

        this.setState({
            primary: isClose
        },()=>{
            if (isClose === false) {
                let page = this.state.page
                this.loadDataPage(page);
            }
        });
    }

    toggle(tab) {
        // console.log(tab)
        // console.log(typeof tab)
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            },()=>{
                if(this.state.activeTab === '2' ){
                    // this.loadProfileData();
                    //this.loadProfileDataPage(1)
                }
            });
        }
    }

    /*deleteCustomerOld (customer) {
        this.setState({customer_id: customer.customer_id, customer:customer})
        this.toggleDanger()
    }*/

    toggleDanger () {
        this.setState({
            danger: !this.state.danger
        })
    }

    toggleDangerDelete (id) {
        axios.delete(APIURL + '/customer/' + id).then(res => {
            if (res.status === 200) {
                let page = this.state.page
                // this.loadData()
                this.loadDataPage(page)
                this.setState({action: "บันทึก"});
            }
        })
    }

    showCustomerOld(customer){
        // console.log(customer)
        this.setState({
            primary:true,
            action:'แก้ไข',
            customer_id:customer.customer_id,
            car_id:customer.car_id,
            customerOld:customer

        })


    }

    async exportCustomerOldReport() {
        this.setState({
            isLoading: true
        }, () => {
            axios.post(APIURL + '/report/customeroldexcel')
                .then(async res => {

                    const excelBuffer = res.data.excelBuffer
                    const currentDatetime = res.data.currentDatetime
                    const fileName = 'รายงานลูกค้าเดิม_export'+'.xlsx'

                    this.setState({
                        isLoading: false
                    })

                    await save(excelBuffer, fileName)

                }).catch(error => {
            })
        })
    }


    render(){
        let width = this.state.width

        return (
            <div className="col-md-12 mb-4">
                <Loading isLoading={this.state.isLoading} />

                <Nav tabs>

                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                        >
                            <i className="icon-list"/>&nbsp; ข้อมูลลูกค้าเดิม
                        </NavLink>
                    </NavItem>

                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="card card-accent-success">
                                    <div className="card-header card-customer">
                                        <strong className="text-title"> รายชื่อลูกค้าเดิม </strong>

                                        <input
                                            ref="input_search"
                                            style={styles.inputSearch}
                                            className="form-control mr-1"
                                            placeholder="ค้นหาตามชื่อ หรือ เบอร์โทร"
                                            type="text"
                                        />

                                        <button
                                            className="btn btn-sm btn-head-bar mr-1"
                                            onClick={this.onSearch.bind(this)}
                                            style={styles.buttonHeight}
                                        >
                                            <i className="icon-search"/>ค้นหา
                                        </button>

                                        <button className="btn btn-head-bar"
                                                style={styles.buttonHeight}
                                                onClick={this.onRefresh.bind(this)}
                                        >
                                            <i className="icon-refresh"/>
                                        </button>

                                        {
                                            this.state.user.type === 'admin' || this.state.user.type === 'manager'
                                            || this.state.user.type === 'account'
                                            || this.state.user.type === 'accountx'
                                                ? (
                                                <button type="button" className="btn btn-success float-right mt-1 ml-1" onClick={() => this.exportCustomerOldReport()}>
                                                    <i className="icon-doc"/>&nbsp; รายงานลูกค้าเดิม
                                                </button>
                                            ) : null
                                        }

                                        <button type="button" onClick={()=>{
                                            this.setState({
                                                customer_old_status: 'add',
                                                action: 'บันทึก'
                                            },() => this.togglePrimary())
                                        }}
                                                className="btn btn-head-bar float-right mt-1" style={styles.buttonHeight}>
                                            <i className="icon-plus"/>&nbsp;&nbsp;เพิ่มข้อมูลลูกค้าเดิม
                                        </button>

                                        {/*<button type="button" onClick={this.togglePrimary}
                                                className="btn btn-head-bar float-right mt-1" style={styles.buttonHeight}>
                                            <i className="icon-plus"/>&nbsp;&nbsp;เพิ่มข้อมูล
                                        </button>*/}
                                    </div>

                                    {/*เพิ่มข้อมูลลูกค้าเดิม*/}
                                    <Modal isOpen={this.state.primary} toggle={this.togglePrimary}
                                           className={'modal-lg ' + this.props.className}
                                           style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}>
                                        <ModalBody>
                                            <CustomerOldForm
                                                onToggle={this.togglePrimary}
                                                username={this.state.user.user}
                                                province={this.state.province}
                                                banks={this.state.banks}
                                                customer_id={this.state.customer_id}
                                                car_id={this.state.car_id}
                                                action={this.state.action}
                                                customerOld={this.state.customerOld}
                                                onLoadCustomerOld={this.loadCustomerOldCar}
                                            />
                                        </ModalBody>
                                    </Modal>

                                    <CustomerOldListRow
                                        customer_list={this.state.customer_old_list}
                                        customerCallbacks={
                                            {
                                                //delete: this.deleteCustomerOld.bind(this),
                                                show: this.showCustomerOld.bind(this),
                                            }
                                        }
                                    />

                                    <Modal isOpen={this.state.danger} toggle={this.toggleDanger.bind(this)}
                                           className={'modal-danger'}>
                                        <ModalHeader toggle={this.toggleDanger.bind(this)}>ลบข้อมูล</ModalHeader>
                                        <ModalBody>
                                            <strong> คุณต้องการลบข้อมูล {this.state.customer_name} ใช่หรือไม่ ?? </strong>
                                            <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="primary"
                                                    onClick={this.toggleDangerDelete.bind(this, this.state.customer_id)}>ตกลง</Button>{' '}
                                            <Button color="secondary" onClick={this.toggleDanger.bind(this)}>ยกเลิก</Button>

                                        </ModalFooter>
                                    </Modal>

                                </div>
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
            </div>

        )
    }
}

const styles = {
    inputSearch: {
        marginLeft: 20,
        width: 300,
        display: 'inline'
    },

    buttonHeight: {
        height: 36,
        paddingTop:0,
        paddingBottom:0,
        display: 'inline',
        marginTop: '-0.35rem',
        borderRadius:5,
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CustomerOldList);