import React, {Component} from 'react';
import {Modal, ModalBody} from "reactstrap";
import axios from "axios";
import {APIURL} from "../../config/config";
import BDatePicker from "../BDatePicker";
import {MonthUtil} from "../../utils/monthUtil";
import {AlertError, AlertSuccess, AlertWarning} from "../Alert/Alert";
import NumberFormat from "react-number-format";
import Loading from "../Loading";

class FixCostAdd extends Component {
    constructor (props) {
        super(props)
        this.state = {
            branch_id: '',
            branch: [],
            expense_date: null,
            month_year_list: [],
            expense_month_year: '',
            fixcost_settings: [],
            is_expense_date_separate: 0,
            isLoading: false
        }
        this.loadExpenseMonthList = this.loadExpenseMonthList.bind(this)
        this.loadBranchData = this.loadBranchData.bind(this)
        this.loadFixCostData = this.loadFixCostData.bind(this)
        this.onChangeWithName = this.onChangeWithName.bind(this)
        this.onExpenseAmountChange = this.onExpenseAmountChange.bind(this)
        this.onChangeDate = this.onChangeDate.bind(this)
        this.saveExpenseFixCost = this.saveExpenseFixCost.bind(this)
    }

    componentDidMount() {
        this.loadBranchData();
        this.loadExpenseMonthList();
    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branch: res.data})
            }).catch(error => {
            this.setState({branch: []})
        })
    }

    loadExpenseMonthList() {
        let uri = APIURL + '/expense/list/month'
        axios.get(uri)
        .then(res => {
            let months = res.data
            let month_year_list = months.map(month => {
                let month_list_thai = MonthUtil.convertMonthYear(month.month_list)
                return {
                    ...month,
                    month_list_thai: month_list_thai
                }
            });

            this.setState({
                month_year_list: month_year_list
            })

        }).catch(error => {
              console.log('error:', error)
        })
    }

    loadFixCostData() {
        let branch_id = this.state.branch_id
        let expense_month_year = this.state.expense_month_year

        if(branch_id === '') {
            AlertWarning('กรุณาเลือกสาขา');
            return
        }

        if(expense_month_year === '') {
            AlertWarning('กรุณาเลือกเดือน');
            return
        }

        let expense_date = this.state.expense_date
        let month_year_list = this.state.month_year_list
        let month_year = month_year_list.filter(my => my.month_list === expense_month_year)
        let start_date = month_year[0].start_date
        let end_date = month_year[0].end_date

        let data = {
            branch_id,
            start_date,
            end_date,
            expense_date
        }

        axios.post(APIURL + '/fix_cost/month', data)
            .then(res => {
                this.setState({
                    fixcost_settings: res.data
                });
            }).catch(error => {
            console.log('error:', error)
        })


    }

    onChangeWithName(e, index, name) {
       let clone = [...this.state.fixcost_settings];
        let obj = clone[index];
        if(name === 'expense_name') {
            obj.expense_name = e.target.value;
        } else  if(name === 'expense_note') {
            obj.expense_note = e.target.value;
        }
        clone[index] = obj;
        this.setState({
            fixcost_settings: [...clone]
        })
    }

    onExpenseAmountChange(value, index) {
        let clone = [...this.state.fixcost_settings];
        let obj = clone[index];
        obj.expense_amount = value;

        if(Number(value) > 0) {
            let action = Number(obj.expense_id) === 0 ? 'add' : 'edit'
            obj.action = action
        } else if(Number(value) === 0) {
            let action = Number(obj.expense_id) === 0 ? 'created' : 'exist'
            obj.action = action
        }

        clone[index] = obj;
        this.setState({
            fixcost_settings: [...clone]
        })
    }

    onChangeDate(date, index) {
        let clone = [...this.state.fixcost_settings];
        let obj = clone[index];
        obj.expense_date = date;
        clone[index] = obj;
        this.setState({
            fixcost_settings: [...clone]
        })
    }

    saveExpenseFixCost() {
        // created => add
        // exist => edit
        let fixcost_settings = this.state.fixcost_settings
        let username = this.props.username;

       // console.log('---saveExpenseFixCost---')
       // console.log(fixcost_settings)
       let check_add_edit = fixcost_settings.filter(fixcost => (fixcost.action === 'add' && Number(fixcost.expense_amount) > 0 ) || (fixcost.action === 'edit' && Number(fixcost.expense_amount) > 0))
       if(check_add_edit.length === 0) {
            AlertWarning('กรุณากรอกรายการ Fixcost อย่างน้อย 1 รายการ');
            return
       }

       let data = {
           fixcost_settings: fixcost_settings,
           username: username
       }

       this.setState({
           isLoading: true
       }, () => {
           setTimeout(() => {
               axios.post(APIURL + '/fix_cost/save', data)
                   .then(res => {
                       console.log(res.data)
                       this.setState({
                           isLoading: false
                       })
                       if(res.status === 200){
                           AlertSuccess('บันทึกค่าใช้จ่ายแล้ว');

                           let expense_month_year = this.state.expense_month_year
                           let month_year_list = this.state.month_year_list
                           let month_year = month_year_list.filter(my => my.month_list === expense_month_year)
                           let start_date = month_year[0].start_date
                           let end_date = month_year[0].end_date
                           // ต้นทุนคงที่
                           let cost_type_id = 2
                           let reloadData = {
                               start_date,
                               end_date,
                               cost_type_id
                           }
                           // this.loadFixCostData();
                           this.props.reloadFixCost(reloadData);
                       }

                   }).catch(error => {
                       AlertError('ผิดพลาด: '+error);
                       console.log('error:', error);
               });
           }, 1500);

       })
    }

    render() {

        const {
            branch,
            month_year_list,
            fixcost_settings,
            is_expense_date_separate
        } = this.state;

        let width = this.props.width;
        let isFixCostOpen = this.props.isFixCostOpen;
        let isDateFill = this.props.isDateFill;
        let username = this.props.username;

        let branch_list = branch.map((branch,index)=>{
            return(
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        });

        let month_year_list_option = month_year_list.map((month_year, index) => (
          <option key={index} value={month_year.month_list}>{month_year.month_list_thai}</option>
        ));

        const emptyData = <tr><td colSpan={7} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>

        let fixcostSettingTr = fixcost_settings.map((fixcost, index) => {
            return (
                <tr key={index}>
                    <td className="text-center text-nowrap" style={{width: '5%'}}>{index+1}</td>
                    {
                        is_expense_date_separate === 1 ?
                            <td style={{width: 10}} >
                                <BDatePicker
                                    isDateFill={0}
                                    name={'expense_date'}
                                    value={fixcost.expense_date}
                                    label={''}
                                    onUpdateDate={(buddhist_date, christ_date) => {
                                        if(christ_date !== null) {
                                            this.onChangeDate(christ_date, index)
                                        }
                                    }}
                                />
                            </td>
                            : null
                    }
                    <td style={{width: 10}} >{fixcost.income_expense_item_name}</td>
                    <td style={{width: 20}} >
                        <input
                            className="form-control"
                            type='text'
                            value={fixcost.expense_name}
                            onChange={(e) => this.onChangeWithName(e, index, 'expense_name')}
                        />
                    </td>
                    <td style={{width: 20}} >
                        <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            allowNegative={false}
                            value={fixcost.expense_amount}
                            onValueChange = {(values) => {
                                const {value} = values;
                                this.onExpenseAmountChange(value, index)
                            }}
                        />
                    </td>
                    <td style={{width: 20}} >
                        <input
                            className="form-control"
                            type='text'
                            value={fixcost.expense_note}
                            onChange={(e) => this.onChangeWithName(e, index, 'expense_note')}
                        />
                    </td>
                </tr>
            );
        });

        const fixcostRows = fixcost_settings.length > 0 ? fixcostSettingTr : emptyData

        return (
            <>

                <Modal isOpen={isFixCostOpen}
                       toggle={this.props.toggleFixCost}
                       className={'modal-lg '}
                       style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
                >
                    <ModalBody>
                        <div className="card card-accent-primary">

                            <Loading isLoading={this.state.isLoading} />

                            <div className="card-header">
                                <strong className="text-title">เพิ่มข้อมูล Fix Cost</strong>
                            </div>
                            <div className="card-block">
                                <div className="row">

                                    <div className="form-group col-3">
                                        <label htmlFor="branch_id">สาขา</label>
                                        <select className="form-control"
                                                name="branch_id"
                                                ref="branch_id"
                                                value={this.state.branch_id}
                                                onChange={(e) => {
                                                    let val = e.target.value
                                                    // let fixcost_settings = this.state.fixcost_settings
                                                    if(val ===  '') {
                                                        this.setState({
                                                            branch_id: val
                                                        });
                                                    } else if(this.state.expense_month_year !== ''){
                                                        this.setState({
                                                            branch_id: val
                                                        }, () => this.loadFixCostData());
                                                    }else {
                                                        this.setState({
                                                            branch_id: val
                                                        });
                                                    }

                                                }}
                                        >
                                            <option value=''>เลือกสาขา</option>
                                            {branch_list}
                                        </select>
                                    </div>

                                    <div className="form-group col-3">
                                        <label htmlFor="expense_month_year">เลือกเดือน</label>
                                        <select
                                            className="form-control"
                                            name="expense_month_year"
                                            value={this.state.expense_month_year}
                                            onChange={(e) => {
                                                let month_list = e.target.value
                                                if(month_list !== '') {
                                                    let month_year = month_year_list.filter(my => my.month_list === month_list)
                                                    let end_date = month_year[0].end_date
                                                    this.setState({
                                                        expense_month_year: month_list,
                                                        expense_date: end_date
                                                    }, () => this.loadFixCostData());
                                                }
                                            }}
                                        >
                                            <option value=''>กรุณาเลือกเดือน</option>
                                            {month_year_list_option}
                                        </select>
                                    </div>

                                    <div className="form-group col-3">
                                        <BDatePicker
                                            isDateFill={isDateFill}
                                            name={'expense_date'}
                                            value={this.state.expense_date}
                                            label={'วันที่ใช้จ่าย '}
                                            onUpdateDate={(buddhist_date, christ_date) => {
                                                this.setState({
                                                    expense_date: christ_date
                                                })
                                            }}
                                        />
                                    </div>

                                    <div className="form-group col-1">
                                        <label htmlFor="button">&nbsp;</label>
                                        <br/>
                                        <button type="button"
                                                className="btn btn-sm btn-primary"
                                            onClick={() => {
                                                this.loadFixCostData();
                                            }}
                                        >
                                             ตกลง
                                        </button>
                                    </div>

                                    <div className="col-2">

                                        {
                                            fixcost_settings.length !== 0 ?
                                                <div className="form-inline pt-3">
                                                    <p className="mt-3">&nbsp;&nbsp; แยกวันที่ค่าใช้จ่าย &nbsp;&nbsp;</p>
                                                    <input
                                                        type="checkbox"
                                                        className="form-control"
                                                        name={'is_expense_date_separate'}
                                                        value={this.state.is_expense_date_separate}
                                                        checked={this.state.is_expense_date_separate === 1}
                                                        onChange={(e) => {
                                                            let val = Number(e.target.value) === 0 ? 1 : 0
                                                            // console.log(val, typeof val)
                                                            this.setState({
                                                                is_expense_date_separate: val
                                                            })
                                                        }}

                                                    />
                                                </div>
                                                : null
                                        }

                                    </div>

                                </div>
                                <div className="row">

                                    <div className="col-9">
                                        &nbsp;
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-responsive text-nowrap">
                                        <table
                                            className="table table-striped"

                                        >
                                            <thead>
                                            <tr>
                                                <th className="text-center text-nowrap" style={{width: '5%'}} >ลำดับ</th>

                                                {
                                                    is_expense_date_separate === 1 ?
                                                        <th className="text-center text-nowrap" style={{width: 10}}>วันที่ใช้จ่าย</th>
                                                        :
                                                        null
                                                }
                                                <th className="text-center text-nowrap" style={{width: 10}}>หมวดค่าใช้จ่าย</th>

                                                <th className="text-center " style={{width: 20}} >รายละเอียดการจ่าย</th>
                                                <th className="text-center text-nowrap" style={{width: 20}} >ยอดค่าใช้จ่าย</th>
                                                <th className="text-center text-nowrap" style={{width: 20}} >หมายเหตุ</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                             {fixcostRows}
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right">
                                <button type="reset"
                                        onClick={this.props.toggleFixCost}
                                        className="btn btn-sm btn-danger mr-2">
                                    <i className="fa fa-refresh"/> ยกเลิก
                                </button>

                                {
                                    fixcost_settings.length !== 0 ?
                                        <button type="button"
                                                className="btn btn-sm btn-primary"
                                                onClick={() => this.saveExpenseFixCost() }
                                        >
                                            <i className="fa fa-save"/> บันทึก
                                        </button>
                                        : null
                                }

                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

export default FixCostAdd
