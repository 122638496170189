import React, { Component } from 'react'
import { checkGroupPermission } from '../../services/grouppermission'
import { connect } from 'react-redux'
import CarRepairListItem from './CarRepairListItem'
import axios from 'axios'
import { APIURL } from '../../config/config'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import CarRepairEditForm from './CarRepairEditForm'
import { AlertSuccess } from '../Alert/Alert'
import CarRepairFile from './CarRepairFile'
import { save } from 'save-file'
import Loading from '../Loading'

class CarRepairAfterSell extends Component{
  constructor (props) {
    super(props);
    this.state = {
      user: {},
      car_repair_list: [],
      car_repair: {},
      toggle_car_repair_edit: false,
      toggle_car_repair_file: false,
    }

    this.loadCarRepairData=this.loadCarRepairData.bind(this);
    this.onCarRepairEditSelected=this.onCarRepairEditSelected.bind(this);
    this.toggleCarRepairEdit=this.toggleCarRepairEdit.bind(this);
    this.pushToCarRepairNew=this.pushToCarRepairNew.bind(this);
    this.exportCarRepairData=this.exportCarRepairData.bind(this);
    this.updateCarRepairNoFile=this.updateCarRepairNoFile.bind(this);
    this.onCarRepairUploadFile = this.onCarRepairUploadFile.bind(this);
    this.toggleCarRepairFile = this.toggleCarRepairFile.bind(this);
    this.onCarRepairDelete  = this.onCarRepairDelete.bind(this);
    this.toggleCarRepairDelete  = this.toggleCarRepairDelete.bind(this);
    this.carRepairDeleteConfirm  = this.carRepairDeleteConfirm.bind(this);
    this.loadCarRepairByRepairType  = this.loadCarRepairByRepairType.bind(this);

  }

  loadCarRepairByRepairType(repair_type_id){
    let is_after_sell = 1;
    let car_id = this.props.car_id
    let data = {
      repair_type_id: repair_type_id,
      car_id: car_id,
      is_after_sell: is_after_sell
    }

    this.setState({
      isLoading: true
    });
    // console.log('repair_type_id=', repair_type_id)
    axios.post(APIURL + '/car_repair/repair_type', data)
        .then(res => {

          this.setState({car_repair_list: res.data, isLoading: false})
        }).catch(error => {
      // alert("error"+error)
      this.setState({car_repair_list: []})
    })
  }

  componentDidMount () {
    this.loadCarRepairData()

    // this.updateWindowDimensions();
    // window.addEventListener('resize', this.updateWindowDimensions);

  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user),
    });
  }

  pushToCarRepairNew(){
    let is_after_sell = this.props.is_after_sell
    let car_id = this.props.car_id
    let target = "/car-repair/new?car_id="+car_id+"&toggle=new&is_after_sell="+is_after_sell ;
    this.props.history.push(target);
  }

  loadCarRepairData() {
    let is_after_sell = this.props.is_after_sell
    let car_id = this.props.car_id
    axios.get(APIURL + '/car_repair/car/'+car_id+'/'+is_after_sell)
      .then(res => {
        this.setState({car_repair_list: res.data})
      }).catch(error => {
      // alert("error"+error)
      this.setState({car_repair_list: []})
    })
  }

  exportCarRepairData() {

    let car_id = this.props.car_id
    // ก่อนขาย is_after_sell = 0 , is_after_sell = 1 หลังขาย
    let is_after_sell = this.props.is_after_sell
    // let center_id = this.state.center_id
    // let is_after_sell = 0

    this.setState({
      isLoading: true
    }, () => {
      axios.get(APIURL + '/reportrepair/carrepairbycarexport/'+car_id+'/'+is_after_sell)
        .then( async res => {
          const excelBuffer = res.data.excelBuffer
          const currentDatetime = res.data.currentDatetime
          const fileName = 'รายงานการซ่อมบำรุงรายคัน'+currentDatetime+'.xlsx'
          this.setState({
            isLoading: false
          })
          await save(excelBuffer, fileName)

        }).catch(error => {
        console.log('error:', error)
        // this.setState({lists: []})
      })
    })


  }

  onCarRepairDelete(car_repair){
    this.setState({car_repair: car_repair}, () => this.toggleCarRepairDelete())

  }

  toggleCarRepairDelete(){
    this.setState({toggle_delete_car_repair: !this.state.toggle_delete_car_repair})
  }

  carRepairDeleteConfirm (car_repair) {
    //alert("carRepairDeleteConfirm="+id)
    // let that = this ;
    let is_after_sell = Number(this.props.is_after_sell)
    // return
    axios.put(APIURL + '/car_repair/'+ car_repair.car_repair_id,{
      car_id: car_repair.car_id,
      car_repair_cost: car_repair.car_repair_cost,
      car_repair_file: car_repair.car_repair_file,
      is_after_sell: is_after_sell
    }).then(res => {
      if (res.status === 200) {
        this.loadCarRepairData()
        this.toggleCarRepairDelete ()
      }
    })
  }

  onCarRepairEditSelected(car_repair) {
    // let car_repair_id = car_repair.car_repair_id
    this.setState({car_repair: car_repair}, () => this.toggleCarRepairEdit())
  }

  toggleCarRepairEdit() {
    this.setState({
      toggle_car_repair_edit: !this.state.toggle_car_repair_edit
    })
  }

  onCarRepairUploadFile(car_repair) {
    this.setState({car_repair: car_repair}, () => this.toggleCarRepairFile())
  }

  toggleCarRepairFile() {
    let toggle = !this.state.toggle_car_repair_file
    this.setState({
      toggle_car_repair_file: toggle
    }, () => {
      if(toggle === false) {
        this.setState({
          car_repair: {}
        })
      }
    })
  }

  updateCarRepairNoFile(event, car_repair_data){
    event.preventDefault();
    let that = this;

    let car_repair_id = event.target.car_repair_id.value
    let car_repair_name = event.target.car_repair_name.value
    // let car_repair_cost = event.target.car_repair_cost.value
    let center_id = event.target.center_id.value
    // let car_repair_send = event.target.car_repair_send.value
    // let car_repair_complete = event.target.car_repair_complete.value
    // let car_repair_note = event.target.car_repair_note.value
    let car_id = this.props.car_id
    let is_after_sell = this.props.is_after_sell


    let car_repair_send = car_repair_data.car_repair_send
    let car_repair_complete = car_repair_data.car_repair_complete
    let car_repair_cost = car_repair_data.car_repair_cost
    let is_pickup = car_repair_data.is_pickup
    let pickup_date = car_repair_data.pickup_date
    let repair_type_id = car_repair_data.repair_type_id
    let car_repair_note = car_repair_data.car_repair_note

    let data = {
      car_repair_id: car_repair_id,
      car_repair_name: car_repair_name,
      car_repair_cost: car_repair_cost,
      center_id: center_id,
      car_repair_send: car_repair_send,
      car_repair_complete: car_repair_complete,
      car_repair_note: car_repair_note,
      car_id: car_id,
      user_updated: this.state.user.user,
      is_after_sell: is_after_sell,
      is_pickup,
      pickup_date,
      repair_type_id,
    }

    axios.put(APIURL+'/car_repair/edit/'+car_repair_id, data).then(res => {
      let result = res.data.result
      if(result === 'success'){
        AlertSuccess("แก้ไขการซ่อมเรียบร้อยแล้ว")
        this.setState({
          toggle_car_repair_edit: false
        }, () => {
          that.loadCarRepairData()
        })
      }
    }).catch(err => {
      console.log('error: ', err)
    })
  }

  render () {
    const {
      is_after_sell,
      grouppermissions,
      menuID,
      user_type
    } = this.props

    let width = this.props.width

    return (
      <>
        <Loading isLoading={this.state.isLoading} />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <strong className="text-title"> {is_after_sell === 1 ? 'ค่าซ่อม(หลังขาย)': 'ค่าซ่อมบำรุง' } </strong>

                {
                  checkGroupPermission(menuID, grouppermissions).created ? (
                    <button className="btn btn-info float-right"  onClick={this.pushToCarRepairNew} >
                      <i className="icon-plus"/>&nbsp; เพิ่มข้อมูลการซ่อม
                    </button>
                  ) : null
                }

                {
                  checkGroupPermission(menuID, grouppermissions).printed ? (
                    <button type="button" style={{width: 80}} className="btn btn-success float-right mr-2"
                            onClick={() => this.exportCarRepairData()}> Excel </button>
                  ) : null
                }

              </div>

              <div className="card-block">
                <CarRepairListItem
                  lists={this.state.car_repair_list}
                  onCarRepairDelete={this.onCarRepairDelete}
                  onCarRepairEditSelected={this.onCarRepairEditSelected}
                  onCarRepairUploadFile={this.onCarRepairUploadFile}
                  user_type={user_type}
                  menuID={menuID}
                  grouppermissions={grouppermissions}
                  onSearchByRepairType={this.loadCarRepairByRepairType}
                  sell_status_check={false}
                />
              </div>


              {
                Object.keys(this.state.car_repair).length === 0 ? null : (
                  <CarRepairFile
                    toggle = {this.state.toggle_car_repair_file}
                    onToggle = {this.toggleCarRepairFile}
                    width={width}
                    car_repair={this.state.car_repair}
                    onReload={() => {
                      this.loadCarById();
                      this.loadCarRepairData();
                    }}
                  />
                )
              }

              <Modal isOpen={this.state.toggle_car_repair_edit} toggle={this.toggleCarRepairEdit}
                     className={'modal-lg '}
                     style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
              >
                <ModalBody>
                  <CarRepairEditForm
                      is_date_fill={this.props.is_date_fill}
                    onToggle={this.toggleCarRepairEdit}
                    onCarRepairSubmit={
                      {
                        edit: this.updateCarRepairNoFile.bind(this),
                        cancel: this.toggleCarRepairEdit
                      }
                    }
                    car={this.props.car}
                    car_repair={this.state.car_repair}

                  />
                </ModalBody>
              </Modal>


              <Modal isOpen={this.state.toggle_delete_car_repair} toggle={this.toggleCarRepairDelete.bind(this)}
                     className={'modal-danger '}>
                <ModalHeader toggle={this.toggleCarRepairDelete.bind(this)}>ลบรายการซ่อม {is_after_sell === 1 ? 'หลังขาย' : 'บำรุง'} </ModalHeader>
                <ModalBody>
                  <strong> คุณต้องการลบข้อมูล ใช่หรือไม่ ?? </strong>
                  <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
                </ModalBody>
                <ModalFooter>
                  <Button color="primary"
                          onClick={this.carRepairDeleteConfirm.bind(this, this.state.car_repair)}>ตกลง</Button>{' '}
                  <Button color="secondary" onClick={this.toggleCarRepairDelete.bind(this)}>ยกเลิก</Button>

                </ModalFooter>
              </Modal>


            </div>
          </div>
        </div>
    </>)
  }

}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  type: state.auth.type,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarRepairAfterSell)
// export default CarRepairAfterSell
