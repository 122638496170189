import React, { Component } from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { APIURL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL, PORT } from '../../../config/config'
import axios from 'axios'
import Loading from '../../Loading'
import { Modal, ModalBody } from 'reactstrap'
import PreviewImage from '../../../views/PreviewImage'
import { Link } from 'react-router-dom'
import CarRepairNewForm from '../../CarRepair/CarRepairNewForm'
import { AlertWarning } from '../../Alert/Alert'
import Switch from 'react-switch'
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";
import {checkGroupPermission, getGroupPermission} from "../../../services/grouppermission";
import ReactToPrint, {PrintContextConsumer} from "react-to-print";

class ExpenseRepair extends Component{
  constructor (props) {
    super(props)
    this.state = {
      user: {} ,
      date_start: new Date(),
      date_end: new Date(),
      expenseCarRepairList: [],
      sumCarRepair: 0,
      isLoading: false,
      preview_image_url: '',
      preview_image_select: false,
      // add ค่าซ่อม
      toggle_add: false,
      is_after_sell: null,
      car: null,
      is_search_car: PORT === 9048 ? true : false,
      car_license_search: '',
      car_list: [],
      car_id: '',
      by_car: true,
      menuID: [1301, 3],
      grouppermissions: [],
      company_setting: null,
      is_date_fill: 0,
      car_status:[],
    }

    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd   = this.handleDateEnd.bind(this)
    this.loadExpenseCarRepair   = this.loadExpenseCarRepair.bind(this)
    this.togglePreviewImage = this.togglePreviewImage.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.toggleCarRepairAdd = this.toggleCarRepairAdd.bind(this)
    this.searchCar = this.searchCar.bind(this)
    this.onCarSelect = this.onCarSelect.bind(this)
    this.loadCarById = this.loadCarById.bind(this)
    this.addCarRepair = this.addCarRepair.bind(this)
    this.addCarRepairNoFile = this.addCarRepairNoFile.bind(this)
    this.loadGroupPermission = this.loadGroupPermission.bind(this);
    this.loadCompanySetting = this.loadCompanySetting.bind(this);
    this.loadCarStatusData = this.loadCarStatusData.bind(this);
  }

  async loadCompanySetting() {
    await axios.get(APIURL + '/company_setting/1').then(res => {
        let data = res.data
        let company_setting = data.company_setting
        const is_date_fill = company_setting.is_date_fill;
        this.setState({
            company_setting: company_setting,
            is_date_fill: is_date_fill
        })
    })
}

  loadGroupPermission() {
    let type = this.state.user.type
    let menuID = this.state.menuID
    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        //console.log(res.data.grouppermissions)
        this.setState({
          grouppermissions: res.data.grouppermissions
        }, () => {
           if(checkGroupPermission(1301, this.state.grouppermissions).created) {
              this.setState({
                is_search_car: true
              })
           }
        })
      }
    })
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    });
  }

  searchCar() {
    let car_license_search = this.state.car_license_search
    if (car_license_search.length === 0) {
      AlertWarning('กรุณากรอกทะเบียนรถ')
      return
    }

    let data = {
      search_license: car_license_search
    }

    axios.post(APIURL+'/car/search_license', data )
      .then(res => {
        if (res.status === 200) {
          if(res.data.length === 0) {
            AlertWarning('ไม่พบรถทะเบียน '+ car_license_search);
            return
          }
          this.setState({car_list: res.data})
        }
      }).catch(error => {
      console.log(error)
    })

  }

  loadCarStatusData () {
    axios.get(APIURL + '/car_status/sell/1')
        .then(res => {
          this.setState({car_status: res.data})
          //console.log('car_status==', res.data)
          //alert (res.data)
        }).catch(error => {
      console.log('error:', error)
      this.setState({car_status: []})
    })
  }

  onCarSelect(car) {
    //TODO : มี car_status_id อื่นๆด้วย
    let car_status = this.state.car_status
    let car_status_id = car.car_status_id
    let car_status_filter = car_status.filter(status => status.car_status_id === car_status_id)

    let is_after_sell = 0
    if(car_status_filter.length > 0){
      is_after_sell = 1
    }
    //console.log(is_after_sell)
    let car_id = car.car_id

    if(PORT === 9048){
      is_after_sell = 0
    }

    // this.props.setIsAfterSell(is_after_sell)
    this.setState({
      car_list: [],
      car_id: car_id,
      is_after_sell: is_after_sell
    }, () => {
      this.loadCarById(car_id)
      this.loadExpenseCarRepair()
    })
  }

  loadCarById(car_id){
    // console.log('car_id=', car_id)
    axios.get(APIURL + '/car/'+car_id)
      .then(res => {
        // console.log(res.data)
        let car = res.data[0]
        this.setState({
          car: car
        })
      }).catch(error => {
         console.log("error:", error)
         this.setState({car: null })
    })
  }

  componentDidMount() {
    CarsUtil.removeCarSelect()
    CarSellUtil.removeCarSellSelect()

    this.loadCompanySetting()
    this.loadGroupPermission()
    this.loadExpenseCarRepair()
    this.loadCarStatusData()

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  toggleCarRepairAdd() {
    this.setState({
      toggle_add: !this.state.toggle_add
    });
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
    // this.loadExpenseCarRepair()
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadExpenseCarRepair() {

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let car_id = this.state.car_id === '' ? 'all' : this.state.car_id
    let by_car = this.state.by_car

    let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
    let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')
    let url = APIURL+'/expense/car_repair/'+date_start+'/'+date_end+'/'+car_id+'/'+by_car

    this.setState({
      isLoading: true
    }, () => {

      axios.get(url).then(res => {
        if(res.status === 200){
          // console.log(res.data)
          // car_repair_cost | car_cost_repair
          let sumCarRepair = 0
          if(res.data.length > 0) {
            sumCarRepair =  res.data.reduce((acc, next) => acc + next.car_repair_cost, 0)
          }
          // console.log('sumCarRepair', sumCarRepair)
          this.setState({
            expenseCarRepairList: res.data,
            sumCarRepair: sumCarRepair,
            isLoading: false
          })
        }
      })
    })
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  addCarRepair(event, car_repair_data){
    event.preventDefault();
    //let that = this ;
    let car_repair_name = event.target.car_repair_name.value
    let car_repair_cost = car_repair_data.car_repair_cost
    let center_id = event.target.center_id.value
    let car_repair_send = car_repair_data.car_repair_send
    let car_repair_complete = car_repair_data.car_repair_complete
    let car_id = this.state.car_id
    let car_repair_note = event.target.car_repair_note.value
    let is_after_sell = event.target.is_after_sell.value

    // let car_repair_cost = event.target.car_repair_cost.value
    // let car_repair_send = event.target.car_repair_send.value
    // let car_repair_complete = event.target.car_repair_complete.value

    if (this.state.is_search_car === true) {
      car_id = event.target.car_id.value
    }

    const data = new FormData();

    data.append('file', event.target.car_repair_file.files[0], event.target.car_repair_file.files[0].name);
    data.append('center_id', center_id);
    data.append('car_repair_name', car_repair_name);
    data.append('car_repair_cost', car_repair_cost);
    data.append('car_repair_send', car_repair_send);
    data.append('car_repair_complete', car_repair_complete);
    data.append('car_id', car_id);
    data.append('user_created', this.state.user.user);
    data.append('car_repair_note', car_repair_note);
    data.append('is_after_sell', is_after_sell);

    axios.post(APIURL+'/car_repair', data)
      .then(res => {
        this.setState({
          toggle_add: !this.state.toggle_add
        });
        //this.loadCarById()
        this.loadExpenseCarRepair()
      })
      .catch(function(error){
        alert('มีข้อผิดพลาด : '+error)
      });
  }

  addCarRepairNoFile(event, car_repair_data){
    event.preventDefault();
    let that = this ;
    let car_repair_name = event.target.car_repair_name.value
    let car_repair_cost = car_repair_data.car_repair_cost
    let center_id = event.target.center_id.value
    let car_repair_send = car_repair_data.car_repair_send
    let car_repair_complete = car_repair_data.car_repair_complete


    let car_id = this.state.car_id
    let car_repair_note = event.target.car_repair_note.value
    let is_after_sell = event.target.is_after_sell.value

    let data = {
      car_repair_name: car_repair_name,
      car_repair_cost: car_repair_cost,
      center_id: center_id,
      car_repair_send: car_repair_send,
      car_repair_complete: car_repair_complete,
      car_id: car_id,
      user_created: this.state.user.user,
      car_repair_note : car_repair_note,
      is_after_sell: is_after_sell
    }

    axios.post(APIURL+'/car_repair/nofile/', data)
      .then(function(response){
        that.setState({
          toggle_add: !that.state.toggle_add
        });
        //that.loadCarById()
       // that.loadCarRepairData()
        that.loadExpenseCarRepair()
      })
      .catch(function(error){
        console.log('มีข้อผิดพลาด : ', error)
      });
  }

  render () {
    const { expenseCarRepairList } = this.state

    const emptyList = <tr>
      <td colSpan={8} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>

    const expenseCarRepairTR = expenseCarRepairList.map((expense, index) => {
      let image_url = ''
      if( expense.car_repair_file === 'nophoto.png') {
        image_url = IMAGE_NOPHOTO_URL
      } else {
        // check https
        if (expense.car_repair_file  !== null) {
          if (expense.car_repair_file .includes('https')) {
            image_url = expense.car_repair_file
          }else{
            image_url = IMAGE_FULL_URL + expense.car_repair_file
          }
        }else{
          image_url = IMAGE_NOPHOTO_URL
        }
      }

      const car_license_plate = expense.car_license_plate_new !== '' &&  expense.car_license_plate_new !== expense.car_license_plate_old ? expense.car_license_plate_new : expense.car_license_plate_old
      const province_name = expense.car_license_plate_new !== '' &&  expense.car_license_plate_new !==  expense.car_license_plate_old ? expense.province_name_new : expense.province_name_old

      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td className="text-center" width={120}>
            {moment(expense.car_repair_send).format('YYYY-MM-DD')}</td>
          <td className="text-left" width={250}>
            {expense.car_repair_name}
            <br/>
            อู่ : {expense.center_name}
          </td>
          <td className="text-left" width={250} >
            {expense.car_name}<br/>
            ทะเบียน : {car_license_plate} {province_name}<br/>
            สถานะรถ : {expense.car_status_name}
          </td>
          <td className="text-center">
            <img
              src={image_url}
              style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
              alt="เอกสาร"
              onClick={()=>this.onPreviewSelected(image_url)}
            />
          </td>
          <td className="text-center">
            <NumberFormat value={expense.car_repair_cost}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'฿'} />
          </td>
          <td className="text-center" width={150}>
            {expense.user_created} <br/>
            เมื่อ : {moment(expense.car_repair_created_at).format('DD-MM-YYYY HH:mm:ss')}
          </td>
        </tr>
      )
    })

    const expenseBody = expenseCarRepairList.length === 0 ? emptyList : expenseCarRepairTR;

    let width = this.state.width;
    let grouppermissions = this.state.grouppermissions;

    return (
      <div className="card">

        <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
          <ModalBody>
            <PreviewImage
              imageUrl={this.state.preview_image_url}
            />
          </ModalBody>
        </Modal>

        <Loading isLoading={this.state.isLoading} />

        {
          this.state.toggle_add === true ? (
            <Modal isOpen={this.state.toggle_add}
                   toggle={this.toggleCarRepairAdd}
                   className={'modal-lg '}
                   style={width<=980 ?{maxWidth:800}:{maxWidth:800}}
            >
              <ModalBody>
                <CarRepairNewForm
                  onToggle={this.toggleCarRepairAdd}
                  onCarRepairSubmit={
                    {
                      add: (e, car_repair_data) => { this.addCarRepair(e, car_repair_data) },
                      addNoFile: (e, car_repair_data) => { this.addCarRepairNoFile(e, car_repair_data) },
                      cancel: () => {
                        this.toggleCarRepairAdd();
                      },
                    }
                  }
                  car={this.state.car}
                  is_after_sell={this.state.is_after_sell}
                  is_search_car = {true}
                  is_date_fill = {this.state.is_date_fill}
                  setIsAfterSell={(is_sell) => {
                    this.setState({is_after_sell: is_sell})
                  }}
                  setCar={(car) => {
                    this.setState({car: car})
                  }}
                />
              </ModalBody>
            </Modal>
          ) : null
        }

        <div className="card-block">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="card card-accent-info">
                <div className="card-header">
                  <strong className="text-title">
                    ข้อมูลค่าใช้จ่ายซ่อมรถ
                  </strong>
                  &nbsp;&nbsp;
                  {
                    checkGroupPermission(1301, grouppermissions).printed ? (
                        <Link to={'/report/carrepairbycenter'} target='_blank'>
                          <button className="btn btn-success float-right">
                            <i className="icon-briefcase"/>&nbsp; สรุปค่าซ่อมตามอู่
                          </button>
                        </Link>
                    ) : null
                  }


                  {
                    checkGroupPermission(1301, grouppermissions).printed ? (
                        <Link to={'/report/carrepair'} target='_blank'>
                          <button className="btn btn-success float-right mr-1">
                            <i className="icon-briefcase"/>&nbsp; สรุปค่าซ่อมรายคัน
                          </button>
                        </Link>
                    ) : null
                  }


                  {
                    checkGroupPermission(1301, grouppermissions).printed ? (
                        <Link to={'/report/carrepairbyretailer'} target='_blank'>
                          <button className="btn btn-success float-right mr-1">
                            <i className="icon-briefcase"/>&nbsp; สรุปค่าซ่อมตามร้านอะไหล่
                          </button>
                        </Link>
                    ) : null
                  }

                </div>

                <div className="row mt-3 mb-4 col-12">
                  <div className="row col-7">
                    <div className="col-1 mt-1 ml-3">
                      จาก
                    </div>

                    <div className="col-4">
                      <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="col-1 mt-1 ml-3">
                      ถึง
                    </div>

                    <div className="col-4">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>

                    <div className="col-1">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.loadExpenseCarRepair()}
                      >
                        ค้นหา
                      </button>
                    </div>
                  </div>

                  <div className="col-5 text-right pt-2 pb-1">
                    <h6>
                      รวมค่าซ่อมรถ &nbsp;
                      <NumberFormat
                        displayType={'text'}
                        thousandSeparator={true}
                        value={this.state.sumCarRepair.toFixed(0)}/>
                      &nbsp;บาท
                    </h6>
                  </div>
                </div>

                {
                  this.state.is_search_car === false ?
                    null
                    :
                    <div className="card mt-0 mb-2 mr-4 ml-4">
                      <div className="card-body">
                        <div className="row p-3 col-12">
                          <div className="form-inline">
                            <label htmlFor="car_license_search" className="ml-2 mr-2">
                              ค้นหา
                            </label>
                            <input
                              className="form-control mr-2"
                              type="text"
                              name="car_license_search"
                              placeholder={'ตามทะเบียนเดิม/ใหม่'}
                              value={this.state.car_license_search}
                              onChange={(e) => {
                                let value = e.target.value
                                this.setState({car_license_search: value})
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-primary ml-1"
                              onClick={this.searchCar} >
                              ค้นหารถ
                            </button>
                            &nbsp;&nbsp;
                            <button
                              className="btn btn-sm btn-head-bar"
                              onClick={() => {
                                this.setState({
                                  car_license_search: '',
                                  car: null,
                                  car_id: '',
                                  car_list: [],
                                })
                              }}
                            >
                              <i className="icon-refresh"/>
                            </button>
                            &nbsp;&nbsp;

                            <button type="button"
                                    onClick={() => {
                                      if(this.state.car === null) {
                                        AlertWarning('กรุณาค้นหาและเลือกรถ ก่อนทำรายการ')
                                        return
                                      }
                                      this.toggleCarRepairAdd()
                                    }}
                                    className="btn btn-primary ml-2 mr-2"
                            >
                              <i className="icon-plus"/>&nbsp; เพิ่มค่าใช้จ่ายซ่อมรถ
                            </button>
                          </div>

                          <div className="form-inline ml-2 mr-2">
                            {
                              this.state.car_id !== ''
                              &&
                              <div className="form-inline pt-2">
                                <Switch height = {20} width ={48}
                                        onChange={(checked) => {
                                          // checked={item.is_banks === 1}
                                          this.setState({by_car: checked})
                                        }}
                                        checked={this.state.by_car}
                                />
                                <span className="ml-2">
                                  {
                                    this.state.by_car ? 'แสดงรายการซ่อมของรถทั้งหมด' : 'แสดงรายการซ่อมของรถตามวันที่'
                                  }

                                </span>
                              </div>
                            }
                          </div>
                        </div>
                        {
                          this.state.car_list.length === 0 ?
                            null
                            :
                            <div className="row pt-2 pr-3 pl-3">
                              <table className="table m-2">
                                <thead>
                                <tr>
                                  <th>ชื่อรถ</th>
                                  <th>ทะเบียนเดิม</th>
                                  <th>ทะเบียนใหม่</th>
                                  <th>สถานะรถ</th>
                                  <th>จัดการ</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                  this.state.car_list.map(car => {
                                    return (
                                      <tr key={car.car_id}>
                                        <td>{car.car_name}</td>
                                        <td>{car.car_license_plate_old} / {car.province_old_name}</td>
                                        <td>{car.car_license_plate_new} / {car.province_new_name}</td>
                                        <td>{car.car_status_name}</td>
                                        <td>
                                          <button type="button"
                                                  className="btn btn-success"
                                                  onClick={() => {
                                                    this.onCarSelect(car)
                                                  }}
                                          >
                                            เลือก
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                                </tbody>
                              </table>
                            </div>
                        }

                        {
                          this.state.car === null ?
                            null
                            :
                            <div className="row pt-2 mr-3 ml-3 mb-2">
                              <div className="row col-12">
                                <strong>
                                  {this.state.car.car_name} &nbsp;|&nbsp;
                                  เดิม : {this.state.car.car_license_plate_old} {this.state.car.province_name_old}
                                  /
                                  ใหม่ : {this.state.car.car_license_plate_new} {this.state.car.province_name_new}
                                  &nbsp;|&nbsp;
                                  สถานะ: {this.state.car.car_status_name}
                                </strong>
                              </div>
                            </div>
                        }
                      </div>
                    </div>
                }

                <table className="table table-striped">
                  <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center text-nowrap">วันที่ส่งซ่อม</th>
                    <th className="text-center text-nowrap">รายละเอียด</th>
                    <th className="text-center">ข้อมูลรถ</th>
                    <th className="text-center">ไฟล์เอกสาร</th>
                    <th className="text-center text-nowrap">ยอดจ่าย</th>
                    <th className="text-center">ผู้บันทึก</th>
                  </tr>
                  </thead>
                  <tbody>
                   {expenseBody}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  type: state.auth.type,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ExpenseRepair)
