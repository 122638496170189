/**
 * Created by devmaster on 7/14/2017 AD.
 */

import { combineReducers } from 'redux';
//import { routerStateReducer } from 'redux-router';
import { connectRouter } from 'connected-react-router'
//import flashMessages from './flashMessages';
import auth from './auth';
import sparepart from './sparepart';

/*export default combineReducers({
  //flashMessages,
  auth,
  sparepart,
  router: connectRouter
});*/

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  sparepart,
});