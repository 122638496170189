import React, { Component } from 'react'
import { getTaxDocTypeById } from '../../services/taxDocTypeService'

class TaxDocument extends Component {

  constructor (props) {
    super(props);
    this.state = {
      tax_doc_type: null
    }
    this.loadTaxDocType = this.loadTaxDocType.bind(this)
  }

  async componentDidMount () {
     let menu_id = this.props.menu_id
     // console.log('menu_id=', menu_id)
     await this.loadTaxDocType()
  }

  async loadTaxDocType() {
    let tax_doc_type_id = this.props.tax_doc_type_id
    console.log('tax_doc_type_id=', tax_doc_type_id)
    let tax_doc_type = await getTaxDocTypeById(tax_doc_type_id)
    if (tax_doc_type !== null) {
      console.log(tax_doc_type)
      this.setState({
        tax_doc_type: tax_doc_type[0]
      })
    } else {
      this.setState({
        tax_doc_type: null
      })
    }
  }

  render () {
    const {
      tax_doc_type
    } = this.state

    return <div className="row">
      <div className="col-sm-12 col-md-12">
        <div className="card card-accent-success">
          <div className="card-header card-customer">
            <div className="row">
              เอกสารภาษี : {tax_doc_type !== null && tax_doc_type.tax_doc_type_name}
            </div>
          </div>
          <div className="card-block">
            content
          </div>

        </div>
      </div>
    </div>
  }
}

export default TaxDocument
