import React, {Component} from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import NumberFormat from 'react-number-format';
import { APIURL, IMAGE_NOPHOTO_URL, IMAGE_UPLOAD_ERROR } from '../../config/config'
import {AlertWarning} from "../Alert/Alert";
import { format } from 'date-fns'
import { checkGroupPermission } from '../../services/grouppermission'
import PreviewImage from '../../views/PreviewImage'

class SparePartsCost extends Component {
  constructor (props){
    super(props)
    this.state = {
      date_start: new Date(),
      date_end: new Date(),
      stockOut: [],
      stock_details: [],
      stock_in_detail_selected: null,
      toggle_file_modal: false,
      slip_file1: IMAGE_NOPHOTO_URL,
      slip_file2: IMAGE_NOPHOTO_URL,
      slip_key1: '',
      slip_key2: '',
      is_slip_file1: false,
      is_slip_file2: false,
      preview_image_url: '',
      preview_image_select: false,
    }

    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.loadSparePartsCost = this.loadSparePartsCost.bind(this)
    this.onSaveFileModal = this.onSaveFileModal.bind(this)
    this.toggleFileModal = this.toggleFileModal.bind(this)
    this.handleUploadFile = this.handleUploadFile.bind(this)
    this.saveCarImage = this.saveCarImage.bind(this)
    this.onPreviewSelected = this.onPreviewSelected.bind(this)
    this.togglePreviewImage = this.togglePreviewImage.bind(this)
  }

  componentDidMount() {

    //if(this.props.viewType==='daily') {
      this.loadSparePartsCost();
    //}

  }
  //
  componentWillReceiveProps(nextProps){
    if(nextProps.reloadSparePartsCost !== this.props.reloadSparePartsCost){
      if(nextProps.reloadSparePartsCost){
        this.loadSparePartsCost();
      }
    }
  }

  onSaveFileModal(stock_in_detail) {
    // stock_in_detail , slip_file1  slip_key1
    console.log('--- onSaveFileModal---')
    console.log(stock_in_detail)
    let slip_file1 = stock_in_detail.slip_file1 === null ? IMAGE_NOPHOTO_URL : stock_in_detail.slip_file1
    let slip_file2 = stock_in_detail.slip_file2 === null ? IMAGE_NOPHOTO_URL : stock_in_detail.slip_file2

    this.setState({
      stock_in_detail_selected: stock_in_detail,
      toggle_file_modal: true,
      slip_file1: slip_file1,
      slip_file2: slip_file2,
      slip_key1: stock_in_detail.slip_key1,
      slip_key2: stock_in_detail.slip_key2,
      is_slip_file1: false,
      is_slip_file2: false,
    })
  }


  toggleFileModal() {
    let toggle_file_modal = !this.state.toggle_file_modal
    let is_slip_file1 = this.state.is_slip_file1
    let is_slip_file2 = this.state.is_slip_file2
    this.setState({
      toggle_file_modal: toggle_file_modal
    }, () => {
        if (toggle_file_modal === false) {
          if (is_slip_file1 === true || is_slip_file2 === true) {
            this.loadSparePartsCost()
          }
        }
    })
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadSparePartsCost() {

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let date_start = moment(this.state.date_start).format('YYYY-MM-DD');
    let date_end   = moment(this.state.date_end).format('YYYY-MM-DD');

    let dataReq = {
      date_start,
      date_end
    }

    let url = APIURL+'/stock_in/cost'

    axios.post(url, dataReq).then(res => {
      if(res.status === 200){
        let stock_details = res.data

        this.setState({
          stock_details: stock_details
        },()=>{
          this.props.onReloadSparePartsCostFinish();
        })

        /*this.setState({
          stockOut: res.data
        },()=>{
          if(this.props.reloadSparePartsCost){
            this.props.onReloadSparePartsCostFinish();
          }
        });*/
      }
    })
  }

  saveCarImage(e,urlImage){
    e.preventDefault();
    let a = document.createElement('a');
    a.href = urlImage;
    a.target = '_blank';
    a.download = urlImage;
    a.click();
    a.remove();
    return false;
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  handleUploadFile(event) {
    const stock_in_detail = this.state.stock_in_detail_selected
    const input_file_name = event.target.name

    let slip_key = ''

    if (input_file_name === 'slip_file1'){
      slip_key = this.state.slip_key1 === null ? '' : this.state.slip_key1
      this.refs.img_slip_file1.src='img/loading.gif'
      this.refs.img_slip_file1.height=32
    } else if (input_file_name === 'slip_file2') {
      slip_key = this.state.slip_key2 === null ? '' : this.state.slip_key2
      this.refs.img_slip_file2.src='img/loading.gif'
      this.refs.img_slip_file2.height=32
    }

    let stock_in_detail_id = stock_in_detail.stock_in_detail_id
    const data = new FormData();
    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('stock_in_detail_id', stock_in_detail_id)
    data.append('slip_key', slip_key)
    data.append('slip_file', input_file_name)

    axios.put(APIURL+'/stock_in/slip/'+stock_in_detail_id, data).then((res) => {
      const resData = res.data;
      const filename = resData.image;
      const slip_key = resData.slip_key;
      let image_url = ''

      if(resData.image === undefined) {
        image_url = IMAGE_UPLOAD_ERROR
      } else if (resData.image === 'uploaderror') {
        image_url = IMAGE_UPLOAD_ERROR
      } else {
        image_url = filename
      }

      if( input_file_name === 'slip_file1'){
        this.setState({
          slip_file1: image_url,
          slip_key1: slip_key,
          is_slip_file1: true
        }, () => {
          // this.loadSparePartsCost();
        })
      } else if (input_file_name === 'slip_file2') {
        this.setState({
          slip_file2: image_url,
          slip_key2: slip_key,
          is_slip_file2: true
        }, () => {
          // this.loadSparePartsCost();
        })
      }

    })


  }

  render() {

    let grouppermissions = this.props.grouppermissions
    let menuID = this.props.menuID

    let stockDetailList = this.state.stock_details.map((sparePart, index) => {

      // if(sparePart.quantity_out === 0) return null
      // ราคาทุนต่อหน่วย
      let costPerUnit = Number(sparePart.total)/Number(sparePart.quantity)

      let in_date = new Date(sparePart.in_date)
      let in_date_format = format(in_date, 'dd/MM/yyyy')

      return (
        <tr key={index+1}>
          <td>{index+1}</td>
          <td>
            {in_date_format}

            {
              sparePart.slip_file1 === null ? null : (
                <>
                  <br/>
                  <br/>
                  <label htmlFor="name">
                    <a rel="noopener noreferrer"
                       href={sparePart.slip_file1}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e, sparePart.slip_file1)}
                       className={sparePart.slip_file1===null? 'hidden' : ''}>
                      <span>Download</span>
                    </a>
                  </label>
                  <br/>
                  <img
                    src={sparePart.slip_file1}
                    style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                    onClick={()=>this.onPreviewSelected(sparePart.slip_file1)}
                    alt="เอกสาร 1"
                  />
                </>
              )
            }
          </td>
          <td>
            {sparePart.name}

            {
              sparePart.slip_file2 === null ? null : (
                <>
                  <br/>
                  <br/>
                  <label htmlFor="name">
                    <a rel="noopener noreferrer"
                       href={sparePart.slip_file2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e, sparePart.slip_file2)}
                       className={sparePart.slip_file2===null? 'hidden' : ''}>
                      <span>Download</span>
                    </a>
                  </label>
                  <br/>
                  <img
                    src={sparePart.slip_file2}
                    style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                    onClick={()=>this.onPreviewSelected(sparePart.slip_file2)}
                    alt="เอกสาร 1"
                  />
                </>
              )
            }

          </td>

          <td className="text-center">{sparePart.quantity}</td>
          <td className="text-center">{sparePart.unit}</td>
          <td className="text-center">
            <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale
              value={sparePart.total}
            />
          </td>
          <td className="text-center">
            <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale
              value={costPerUnit}
            />
          </td>
          <td className="text-center">
            <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale
              value={sparePart.cost}
            />
          </td>
          <td className="text-center">
            {
              checkGroupPermission(menuID, grouppermissions).printed ?
                <button
                  type="button"
                  className="btn btn-success btn-sm btn-block"
                  style={{width: 90}}
                  onClick={() => {
                    this.onSaveFileModal(sparePart)
                  }}
                >
                  <i className="icon-doc">&nbsp;แนบไฟล์</i>
                </button>
                : null
            }
          </td>

        </tr>
      )

    })

    return (
      <div className="row">


        <Modal className="modal-lg modal-dialog" isOpen={this.state.toggle_file_modal} toggle={this.toggleFileModal}>
          <ModalHeader toggle={this.toggleFileModal}>แนบไฟล์เอกสาร</ModalHeader>
          <ModalBody>
            <div className="row p-5">
              <div className="form-group col-sm-1">
              </div>

              <div className="form-group col-sm-5">
                <label htmlFor="name">1) ใบส่งของ/ใบเสร็จ
                  &nbsp;
                  <a rel="noopener noreferrer"
                     href={this.state.slip_file1}
                     target="_blank"
                     onClick={(e)=>this.saveCarImage(e,this.state.slip_file1)}
                     className={this.state.slip_file1===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                    <span>Download</span>
                  </a>
                </label>
                <div style={styles.fileInputWrapper} >

                  <button style={styles.btnFileInput}>
                    <img src={this.state.slip_file1}
                         style={styles.btnFileInputImage}
                         ref="img_slip_file1"
                         alt="ใบส่งของ/ใบเสร็จ"
                    />
                  </button>
                  <input
                    style={styles.fileInput}
                    type="file"
                    name="slip_file1"
                    onChange={this.handleUploadFile}
                  />
                </div>

              </div>

              <div className="form-group col-sm-5">
                <label htmlFor="name">2) ภาพรับสินค้า
                  &nbsp;
                  <a rel="noopener noreferrer"
                     href={this.state.slip_file2}
                     target="_blank"
                     onClick={(e)=>this.saveCarImage(e,this.state.slip_file2)}
                     className={this.state.slip_file2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                    <span>Download</span>
                  </a>
                </label>
                <div style={styles.fileInputWrapper} >
                  <button style={styles.btnFileInput}>
                    <img src={this.state.slip_file2}
                         style={styles.btnFileInputImage}
                         ref="img_slip_file2"
                         alt="ภาพรับสินค้า"
                    />
                  </button>
                  <input
                    style={styles.fileInput}
                    type="file"
                    name="slip_file2"
                    onChange={this.handleUploadFile}
                  />
                </div>
              </div>

              <div className="form-group col-sm-1">
              </div>

            </div>
          </ModalBody>

          <ModalFooter>
            <Button  color="secondary" onClick={this.toggleFileModal}>ยกเลิก</Button>
          </ModalFooter>

        </Modal>


        <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
          <ModalBody>
            <PreviewImage
              imageUrl={this.state.preview_image_url}
            />
          </ModalBody>
        </Modal>


        <div className="col-lg-12">
          <div className="card card-accent-warning">
            <div className="card-header card-sparepart">
              <strong className="text-title">รายการต้นทุนอะไหล่ </strong>
            </div>
            <div className="card-block">
              <div className={this.props.viewType==='daily' ? 'row  mb-2' : 'hidden'}>
                <div className="col-12">
                  <h4> รายการต้นทุนอะไหล่วันที่ {moment().locale('th').format('LL')} </h4>
                </div>
              </div>
              <div className={this.props.viewType==='range' ? 'row  mb-4' : 'hidden'}>
                  <div className="col-2 text-right pt-1">
                     วันที่เริ่ม
                  </div>
                  <div className="col-2">
                    <DatePicker
                      selected={this.state.date_start}
                      onChange={this.handleDateStart}
                      dateFormat="yyyy-MM-dd"
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>

                  <div className="col-2 text-right pt-1">
                    <span className="" > วันที่สิ้นสุด </span>
                  </div>
                  <div className="col-2">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                  </div>
                <div className="col-1 mb-2">
                  <Button color="success" size="sm" onClick={()=>this.loadSparePartsCost()}>
                    <i className="icon-magnifier"/>&nbsp; ตกลง
                  </Button>
                </div>
              </div>

              <div className={this.props.viewType==='range' ? 'row  mb-2' : 'hidden'}>
                <div className={moment(this.state.date_start).isSame(moment(this.state.date_end), 'date')? "hidden": 'col-12' }>
                  <h4> รายการต้นทุนอะไหล่วันที่ {moment(this.state.date_start).locale('th').format('LL')}  ถึง {moment(this.state.date_end).locale('th').format('LL')}</h4>
                </div>
                <div className={moment(this.state.date_start).isSame(moment(this.state.date_end), 'date')? "col-12": 'hidden' }>
                  <h4> รายการต้นทุนอะไหล่วันที่ {moment(this.state.date_start).locale('th').format('LL')} </h4>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <td>#</td>
                      <td>วันที่ซื้อเข้า (วัน/เดือน/ปี)</td>
                      <td>ชื่ออะไหล่รถ</td>

                      <td className="text-center">จำนวนซื้อ</td>
                      <td className="text-center">หน่วย</td>
                      <td className="text-center">ยอดซื้อ</td>
                      <td className="text-center">ราคาทุนต่อหน่วย <br/> (ณ วันที่เพิ่มสต๊อก)</td>
                      <td className="text-center">ราคาทุนต่อหน่วย <br/> (ราคากลาง)</td>
                      <td className="text-center">จัดการ</td>
                    </tr>
                    </thead>
                    <tbody>
                     {stockDetailList}
                     {
                       stockDetailList.length === 0 ?
                         <tr>
                           <td
                             colSpan={9}
                             className="text-danger text-center"
                           >
                            ไม่พบข้อมูล
                           </td>
                         </tr>
                         : null

                     }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  fileInputWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: 180,
    width: 200,
  },
  btnFileInput: {
    height: 180,
    width: 200,
    display: 'inline-block',
    backgroundColor: "#D2DDE2",
    backgroundImage: "linear-gradient(to bottom, #D2DDE2, #FCF8FF)",
    backgroundRepeat: "repeat-x",
    color: "#ffffff",
    textShadow: "0 -1px 0 rgba(0, 0, 0, 0.25)"
  },
  btnFileInputImage:{
    position: 'absolute',
    top: 0,
    left: 0,
    height: 180,
    width: 200,
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
    height: 180,
    width: 200,
    cursor: 'pointer'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

export default SparePartsCost;
