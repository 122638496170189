import React, { Component } from 'react'
import axios from 'axios'
import { APIURL } from '../../../config/config'
import { Modal, ModalBody } from 'reactstrap'
import { connect } from 'react-redux'
import ReceiptsForm from '../ReceiptsForm/ReceiptsForm'
import ReceiptsListRow from '../ReceiptsListRow/ReceiptsListRow'
import {Link} from "react-router-dom";
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";

class ReceiptsList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lists: [],
      load_data: true,
      danger: false,
      receipts: '',
      toggle_receipts: false,
      receipts_id: 0,
      user: {},
      w_receipts_image_file: false,
      width: 0,
      height: 0
    }
    this.toggleReceipts = this.toggleReceipts.bind(this);
    this.onDeleteHandle = this.onDeleteHandle.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  loadReceiptsData () {
    axios.get(APIURL + '/receipts/3') //3 = รายรับของเต็นท์รถ
      .then(res => {
        //console.log(res.data)
        this.setState({lists: res.data})
      }).catch(error => {
        this.setState({lists: []})
    })
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    })
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidMount () {
    CarsUtil.removeCarSelect()
    CarSellUtil.removeCarSellSelect()
    //if(this.state.load_data)
    this.loadReceiptsData()

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  toggleReceipts () {
    this.setState({
      toggle_receipts: !this.state.toggle_receipts
    },() => this.loadReceiptsData())
  }

  onDeleteHandle(receipt) {

    if(window.confirm('ยืนยันการลบรายการ '+receipt.receipts_name)){
      var receipts_id = receipt.receipts_id
      var receipts_image_file = receipt.receipts_image_file
      var data = {
        receipts_image_file: receipts_image_file
      }

      axios.put(APIURL+'/receipts/'+receipts_id, data)
        .then(res => {
          if(res.status === 200){
            this.loadReceiptsData()
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  render () {

    let width = this.state.width

    return (
      <div className="card">
        <div className="card-block">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="card card-accent-success">
                <div className="card-header card-customer">
                  <strong className="text-title"> ข้อมูลรายรับ </strong>
                  {
                    this.state.user.type === 'admin' || this.state.user.type === 'manager'
                    || this.state.user.type === 'accountx'
                    || this.state.user.type === 'account'
                        ? (
                        <Link to="/report/receipts">
                          <button className="btn btn-head-bar float-right ml-1 mr-1" style={{backgroundColor: '#4dbd74'}}>
                            <i className="icon-doc"></i>&nbsp; รายงานรายรับ
                          </button>
                        </Link>
                    ) : null
                  }


                  <button type="button"
                          onClick={this.toggleReceipts}
                          className="btn btn-head-bar float-right">
                    <i className="icon-plus"/>&nbsp; เพิ่มข้อมูลรายรับ
                  </button>

                  <Modal isOpen={this.state.toggle_receipts} toggle={this.toggleReceipts}
                         className={'modal-lg'}
                         style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
                  >
                    <ModalBody>
                      <ReceiptsForm
                        onToggle={this.toggleReceipts}
                      />
                    </ModalBody>
                  </Modal>
                </div>

                <ReceiptsListRow
                   receipts_list={this.state.lists}
                   onDeleteHandle={this.onDeleteHandle}
                />

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ReceiptsList)