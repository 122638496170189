/**
 * Created by devmaster on 16/12/2017 AD.
 */

import axios from 'axios'
import {APIURL} from './config'

export const LineNotify = {
  sendNotify(message){
    // return ;
    axios.post(APIURL + '/line_notify/', {
      message: message,
      token: '',
    })
      .then(res => {
        // console.log(res)
      })
      .catch(error => {
        console.log('line_notify error:', error)
        // alert("error"+error)
      })
  },
  sendDebtPayNotify(message){
    // return ;
    axios.post(APIURL + '/line_notify/debt_pay', {
      message: message,
      token: '',
    })
      .then(res => {
        // console.log(res)
      })
      .catch(error => {
        console.log('line_notify error:', error)
        // alert("error"+error)
      })
  }
}
