import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';
import { connect } from 'react-redux';
import {format} from "date-fns";
import * as XLSX from "xlsx-js-style";
import * as FileSaver from "file-saver";
import Loading from '../Loading';
import { APIURL, PORT } from '../../config/config';
import { AlertError, AlertSuccess, AlertWarning } from '../Alert/Alert'
import {NumberUtil} from "../../utils/number-util";
import {ExcelUtil} from "../../utils/excelUtil";

// import { save } from 'save-file';
// import {CarUtil} from "../../utils/carUtil";

class SellSummaryReport extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),
      lists: [],
      person_vat_id: '0',
      employee_id : '',
      employees:[],
      expenses: [],
      car_sell_pays: [],
      branches: [],
      branch_id: '',
      car_status: [],
      car_status_id: '',
      is_close_sell: false
    }
    this.handleDateStart = this.handleDateStart.bind(this);
    this.handleDateEnd = this.handleDateEnd.bind(this);
    this.loadSellData = this.loadSellData.bind(this);
    this.loadEmployeeData = this.loadEmployeeData.bind(this);
    this.exportExcelCarSellSummary = this.exportExcelCarSellSummary.bind(this);
    this.loadBranchData = this.loadBranchData.bind(this);
    this.loadCarStatusData = this.loadCarStatusData.bind(this);
    this.handleCarSellClose = this.handleCarSellClose.bind(this);
  }

  loadBranchData () {
    axios.get(APIURL + '/branch/')
        .then(res => {
          this.setState({branches: res.data})
        }).catch(error => {
      this.setState({branches: []})
    })
  }

  loadCarStatusData () {
    axios.get(APIURL + '/car_status/sell/1')
        .then(res => {
          this.setState({car_status: res.data})
          //alert (res.data)
        }).catch(error => {
      console.log('error:', error)
      this.setState({car_status: []})
    })
  }


  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

    componentDidMount() {
        this.mounted = true
        this.loadEmployeeData();
        this.loadBranchData();
        this.loadCarStatusData();
        let today = new Date()
        let start = format(today, 'yyyy-MM-01')
        this.setState({
          date_start: new Date(start)
        })
    }

  loadSellData() {

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    const employee_id = this.state.employee_id
    const person_vat_id = this.state.person_vat_id
    const branch_id = this.state.branch_id
    const car_status_id = this.state.car_status_id

      const data = {
          start_date : start_date,
          end_date : end_date,
          employee_id : employee_id,
          person_vat_id : person_vat_id,
          branch_id: branch_id,
          car_status_id: car_status_id
      }

    this.setState({
      isLoading: true
    })

      axios.post(APIURL + '/report/sell_summary/', data)
      .then(res => {

        let person_vat_id = this.state.person_vat_id
        let employee_id = this.state.employee_id
        let car_sells = res.data.car_sells
        let expenses = res.data.expenses
        let car_sell_pays = res.data.car_sell_pays

        // car_status_id 24 ขายแล้วเงินเข้าแล้ว
        // TODO: check
        let check_car_status24 = car_sells.filter(car_sell => car_sell.car_status_id !== 24)

        let is_close_sell = true
        if (check_car_status24.length !== 0) {
          // ถ้ามีสถานะอื่นๆ ที่ไม่ใช่ 24
          is_close_sell = false
        }

        this.setState({
          lists: car_sells,
          expenses: expenses,
          car_sell_pays: car_sell_pays,
          isLoading: false,
          person_vat_id: person_vat_id,
          employee_id: employee_id,
          is_close_sell: is_close_sell
        })
      }).catch(error => {
        console.log('error:', error)
       // this.setState({lists: []})
    })
  }

  loadEmployeeData() {
        axios.get(APIURL + '/employee/')
            .then(res => {
                if(res.status === 200) {
                    let data = res.data
                    if (data.length > 0) {
                        let dataFilter = data.filter(d => d.employee_position.indexOf('ขาย') !== -1)
                        this.setState({ employees: dataFilter })
                    } else {
                        this.setState({employees: [] })
                    }
                }
            }).catch(error => {
            console.log('error:', error);
            this.setState({employees: [] })
        })
    }

  getExpenseCountColumn() {
    if (PORT === 9005) {
      return 12
    } else  if (PORT === 9043) {
      return 24
    } else {
      return 23
    }
  }

  exportExcelCarSellSummary(tableExcels) {

    let car_sells = this.state.lists

    if(car_sells.length === 0){
      AlertError('ไม่พบข้อมูลยอดขาย');
      return
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let curr = format(new Date(), 'dd/MM/yyyy HH:mm:ss')

    const titleRow = [
      {
        A: 'รายงานสรุปยอดขาย'
      },
      {
        A: 'พิมพ์เมื่อ : ' + curr,
      },
      {
        A: 'ลำดับ',
        B: 'ทะเบียนรถ',
        C: 'ชื่อรถ',
        D: 'วันที่ขาย',
        E: 'ขายโดย',
        F: 'สถานะ',
        G: 'บ.สินเชื่อ',
        H: 'ยอดจัด',
        I: 'ราคาขาย',
        J: 'ราคาซิ้อ',
        K: 'Leasing+vat',
        L: 'ดาวน์',
        M: 'ค่าใช้จ่าย',
        N: 'กำไร'
      }
    ]

    // หาว่าแถวไหนไม่มี vat H: rate_finance,
    /*let arrRowNoVat= tableExcels.map(row => {
      // if (Number(row.H) === Number(row.K)){
      //   return row
      // }
      if (row.is_car_vat === true) {
        return row
      }
    });*/

    // vat สีดำ , novat สีฟ้า
    let arrRowNoVat= tableExcels.filter(row => row.is_car_vat === false)

    let tableNewExcels = tableExcels.map(row => {
       let newRow = row
       delete newRow.is_car_vat
       return newRow
    })

    const reportData = [...titleRow, ...tableNewExcels]

    this.setState({isLoading: true})

    setTimeout(() => {

      // const ws = XLSX.utils.aoa_to_sheet([reportData]);
      const ws = XLSX.utils.json_to_sheet(reportData, { origin: 'A1', skipHeader: true });
      let wscols = [
        {wch:8},
        {wch:35},
        {wch:35},
        {wch:20},
        {wch:16},
        {wch:16},
        {wch:20}, // สินเชื่อ
        {wch:14},
        {wch:14},
        {wch:14},
        {wch:14},
        {wch:14},
        {wch:14},
        {wch:14},
      ];

      let last_row = car_sells.length + 2
      let last_col = 14

      let wsrows = [];
      for(let  i=1 ; i <=last_row + 1; i++){
        if (i===3){
          wsrows.push({hpt: 40})
        }
        wsrows.push({hpt: 20})
      }

      ws['!cols'] = wscols;
      ws['!rows'] = wsrows;

      let rangeTitle = { s: {r:2, c:0}, e: {r:2, c: last_col} };
      ExcelUtil.setCellBoldWithBorder(ws, rangeTitle);

      let rangeOrder = { s: {r:3, c:0}, e: {r:last_row, c:0} };
      ExcelUtil.setCellCenterNormalWithBorder(ws, rangeOrder);

      last_row = last_row +1

      let rangeNumber = { s: {r:3, c:1}, e: {r:last_row, c: last_col} };
      ExcelUtil.setCellNumberFormatWIthBorder(ws, rangeNumber);

      let rangeSum = { s: {r:last_row, c:0}, e: {r:last_row, c: last_col} };
      ExcelUtil.setCellTextRightBoldWithBorder(ws, rangeSum);

      for(let i =0 ; i < arrRowNoVat.length ; i++) {
        let rowNoVatFinance = arrRowNoVat[i]

        if( rowNoVatFinance === undefined) continue;

        let rowOrder = rowNoVatFinance.A
        let row = 2+rowOrder
        let rangeBlue = { s: {r: row, c: 1}, e: {r:row, c:last_col} };
        ExcelUtil.setCellNumberFormatBlueWithBorder(ws, rangeBlue);
      }

      // ws[0].setStyle({ A3:'font-weight: bold;', B3:'background-color: yellow;' });

      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});

      let currFileName = format(new Date(), 'yyyy-MM-dd')
      let fileName = 'report_sell_summary'+'_'+currFileName
      FileSaver.saveAs(data, fileName + fileExtension);

      this.setState({isLoading: false})

    }, 1200)

  }

  handleCarSellClose() {
    const {
      is_close_sell,
      lists
    } = this.state

    let car_ids = lists.map(car => {
      return car.car_id
    })

    if (lists.length === 0) {
      AlertError('ไม่พบรายการ');
      return
    }

    // if (is_close_sell === false) {
    //   AlertError('ไม่สามารถปิดการขายได้\nเนื่องจากมีรายการที่ยังไม่ได้รับเงิน');
    //   return
    // }

    let dataReq = {
      car_ids: car_ids
    }

    this.setState({
      isLoading: true
    }, () => {
      let url = APIURL + '/car_sell/sell_status/close'

      axios.post(url, dataReq).then(res => {
        // let data = res.data
        let status = res.status

        if (status === 200) {
          AlertSuccess('บันทึกปิดการขายแล้ว')
          this.setState({isLoading: false})
        }


      }).catch(err => {
        AlertError('ผิดพลาด: ไม่สามารถปิดการขายได้')
        this.setState({isLoading: false})
      });
    });

  }

  render () {

    const {
      lists,
      expenses,
      car_sell_pays
    } = this.state

    let excelTables = []

    const emptyBody = <tr><td colSpan={16} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    let sumRateFinance = 0
    let sumRealSellPrice = 0
    let sumCarCost = 0
    let sumRateFinanceWithVat = 0
    let sumSumDown = 0
    let sumCostTotal = 0
    let sumProfitAfterSell = 0

    const listRows = lists.map((car, index) => {

      const car_sell_date = format(new Date(car.car_sell_date), 'dd/MM/yyyy')

      /* ต้นทุนซื้อเข้า */
      let car_cost = car.car_cost // ราคาซื้อ (ไม่รวม VAT)
      const car_cost_commissions = car.car_cost_commissions // คอมฯซื้อเข้า
      const car_cost_repair = car.car_cost_repair
      // ค่าใช้จ่าย พ.ร.บ./ค่าภาษีรถยนต์
      const car_cost_tax = car.car_cost_tax
      // ค่าภาษีรถยนต์
      const car_cost_act = car.car_cost_act
      const car_cost_other = car.car_cost_other

      const car_id = car.car_id
      const person_vat_id = Number(car.person_vat_id)
      const vat_buy = Number(car.vat_buy)
      const is_car_vat = vat_buy > 0 ? true : (person_vat_id === 2 ? true : false)

      const customer_id = car.customer_id
      let expense_by_car = expenses.filter(expense => expense.car_id===car_id)
      let car_cost_expenses_other_no_vat = expense_by_car.reduce((acc, expense)=>{
        if (expense.include_vat === 1) {
          let expense_amount_no_vat = expense.expense_amount - expense.expense_amount_vat
          return acc + expense_amount_no_vat
        }
        return acc + expense.expense_amount
      }, 0);


      let car_sell_pay_by_car = car_sell_pays.filter(car_sell_pay => car_sell_pay.car_id === car_id  && car_sell_pay.customer_id === customer_id)
      let car_sell_pay_sum = car_sell_pay_by_car.reduce((acc,car_sell_pay) => acc+car_sell_pay.car_sell_pay_count, 0);
      // ต้นทุนซื้อเข้า
      // let car_cost_in = car_cost + car.vat_buy

      // ค่าใช้จ่ายอื่นๆ ตอนซื้อเข้า
      let car_cost_other_sum = Number(car_cost_other)  + Number(car_cost_commissions) + Number(car_cost_act) + Number(car_cost_tax)
      // เพิ่มเติม
      let cost_operation = car.cost_operation; // ค่าดำเนินการ
      let cost_service = car.cost_service; // ค่าบริการ
      let cost_transport = car.cost_transport; // ค่าขนย้าย
      let cost_porobor_lan = car.cost_porobor_lan; // ค่า พรบ. จากลาน
      let cost_bangkhab_phasi_lan = car.cost_bangkhab_phasi_lan; // ค่าบังคับต่อภาษี จากลาน
      let cost_bangkhab_xon_lan = car.cost_bangkhab_xon_lan;  // ค่าบังคับโอนเข้าเต็นท์ จากลาน
      let cost_check_ton = car.cost_check_ton;  // ค่าเช็คต้น

      car_cost_other_sum += Number(car_cost_expenses_other_no_vat);
      car_cost_other_sum += Number(cost_operation);
      car_cost_other_sum += Number(cost_service);
      car_cost_other_sum += Number(cost_transport);
      car_cost_other_sum += Number(cost_porobor_lan);
      car_cost_other_sum += Number(cost_bangkhab_phasi_lan);
      car_cost_other_sum += Number(cost_bangkhab_xon_lan);
      car_cost_other_sum += Number(cost_check_ton);
      car_cost_other_sum += Number(car_cost_repair);

      // รวมค่าใช้จ่ายตอนซื้อเข้า + ค่าซ่อม+ ค่าใช้จ่ายอื่นๆ ของรถ => ไม่รวมค่ารถ กับ vat รถตอนซื้อ
      car_cost_other_sum = Number(car_cost_other_sum.toFixed(2))

      // ตอนซื้อเข้า
      // car.cost_operation + car.cost_service + car.cost_transport + car.cost_porobor_lan + car.cost_bangkhab_phasi_lan + car.cost_bangkhab_xon_lan + car.cost_check_ton +

      // ค่าใช้จ่ายตอนขาย old
      // const car_sell_cost_x =  car.car_external_commission + car.sales_commission + car.additional_commission + car.advertising_cost + car.delivery_fee + car.finance_fee + car.promotion_fee + car.transfer_fee + car.cost_after_sales_service + car.cost_phra + car.cost_vat_and_property_tax

      // ค่าใช้จ่ายการขาย
      const car_sell_cost = car.car_external_commission + car.sales_commission+car.additional_commission+car.advertising_cost+car.delivery_fee+car.promotion_fee+car.finance_fee+car.transfer_fee+car.cost_phra+car.cost_after_sales_service+car.cost_vat_and_property_tax

      // ค่าใช้จ่ายจากไฟแนนซ์
      const car_sell_finance = car.finance_pa + car.finance_car_insurance + car.finance_ew + car.finance_pay_advance + car.finance_health_insurance +car.finance_document_fee + car.finance_transfer_fee + car.finance_stamp_fee

      const car_sell_all = car_sell_cost + car_sell_finance
      // ค่าใช้จ่ายทั้งหมด ที่ไม่ใช่ค่ารถ
      const cost_total = car_cost_other_sum + car_sell_all

      // รายรับตตอนทำขาย
      let tax_commission_amount = car.tax_commission_amount !== 0 ? car.tax_commission_amount : car.finance_commission

        let car_license_plate_new = car.car_license_plate_new.trim()
        let car_license_plate_old = car.car_license_plate_old.trim()

        let car_license_old_new = car.car_license_plate_old +' ' + car.province_name_old+ ' / '+ car.car_license_plate_new+' '+ car.province_name_new

        if(car.car_license_plate_new===''){
            car_license_old_new = car.car_license_plate_old +' ' + car.province_name_old
        }

        let car_license = car_license_plate_new === car_license_plate_old ? car.car_license_plate_new+' '+car.province_name_new : car_license_old_new

        let car_brand_name = car.car_brand_name
        let car_model_name = car.car_model_name
        let car_year_name = car.car_year_name
        let car_color_name = car.car_color_name

        let car_name = car_brand_name+' '+car_model_name+' / '+car_year_name+' / '+car_color_name

        let sell_by = car.employee_name
        let car_status_name = car.car_status_name
        let bank_name = car.bank_name
        let rate_finance = car.rate_finance
        let real_sell_price = car.real_sell_price
        // vat ยอดจัดจริง
      let tax_invoice_vat = car.tax_invoice_vat
      let bank_finance_id = car.bank_finance_id
      let sell_status = car.sell_status
      let car_status_id = car.car_status_id
      let sell_status_check = sell_status === 'close' && car_status_id === 24 ? true : false

      let rate_finance_with_vat = Number(rate_finance) + Number(tax_invoice_vat)
        // let sum_down = Number(car.sum_down)
        // let total_outof_discount = Number(car.total_outof_discount)
        // sum_down = sum_down < 0 ? 0 : sum_down - total_outof_discount

      let sum_down = car_sell_pay_sum

      // กำไร = (ยอดจัด+down )-(ราคาซื้อ+ค่่าใช้จ่าย) จาก excel at
      // tax_commission_amount
      let profit_after_sell = (rate_finance + sum_down) - (car_cost + cost_total)

      const tax_invoice_amount = car.tax_invoice_amount > 0 ?  car.tax_invoice_amount : car.rate_finance//ยอดจัด

      //รถซื้อสด
      if(bank_finance_id === 1){
        //TODO : AT ถ้าเป็นรถที่มี VAT ซื้อ และขายเงินสด ช่องดาวน์ให้ลบ VAT ซื้อขายออก
        sum_down = tax_invoice_amount
        // profit_after_sell = (real_sell_price + sum_down) - (car_cost + cost_total)
        rate_finance_with_vat = 0
        // sum_down = ยอดรับชำระทั้งหมด ต้องออกใบเสร็จหรือใบกำกับ
        profit_after_sell = (sum_down) - (car_cost + cost_total)
        // ยอดคำนวณ
        // profit_after_sell = (real_sell_price) - (car_cost + cost_total)
      }

      profit_after_sell = Number(profit_after_sell.toFixed(2))

      // vat สีดำ , novat สีฟ้า

      sumRateFinance += rate_finance
      sumRealSellPrice += real_sell_price
      sumCarCost += car_cost
      sumRateFinanceWithVat += rate_finance_with_vat
      sumSumDown += sum_down
      sumCostTotal += cost_total
      sumProfitAfterSell += profit_after_sell

      sumProfitAfterSell = Number(sumProfitAfterSell.toFixed(2))

      /*<td className="text-right text-nowrap">{vat_sell === null || vat_sell === 0 ? tax_invoice_vat.toLocaleString() : vat_sell.toLocaleString()}</td>*/
      // {v: car_license, t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } }


      let dataRow = {
        A: index+1,
        B: car_license,
        C: car_name,
        D: car_sell_date,
        E: sell_by,
        F: car_status_name,
        G: bank_name,
        H: rate_finance,
        I: real_sell_price,
        J: car_cost,
        K: rate_finance_with_vat,
        L: sum_down,
        M: cost_total,
        N: profit_after_sell,
        is_car_vat: is_car_vat
      }

      excelTables.push(dataRow)

      // <i className="icon-check"/>

      return (
        <tr key={index} style={index%2===0? { backgroundColor: ''} : { backgroundColor: '#e4e5e6'}}>
          <td className="text-center text-nowrap">
            {index+1}
            &nbsp;
            {sell_status_check ? <strong><i className="icon-check text-success"/></strong> : null }
          </td>
          <td className="text-nowrap" style={{width: 200}}> {car_license} </td>
          <td className="text-nowrap" style={{width: 200}}>{car_name} </td>
          <td className="text-nowrap" style={{width: 110}}>{car_sell_date}</td>
          <td style={{width: 60}} className="text-center text-nowrap">{sell_by}</td>
          <td className="text-center text-nowrap">{car_status_name}</td>
          <td className="text-center text-nowrap">{bank_name}</td>

          <td className="text-center text-nowrap">{NumberUtil.addCommas(rate_finance)}</td>
          <td className="text-center text-nowrap">{NumberUtil.addCommas(real_sell_price)}</td>
          <td className="text-center text-nowrap">{NumberUtil.addCommas(car_cost)}</td>
          <td className="text-center text-nowrap">{NumberUtil.addCommas(rate_finance_with_vat)}</td>
          <td className="text-center text-nowrap">{NumberUtil.addCommas(sum_down)}</td>
          <td className="text-center text-nowrap">{NumberUtil.addCommas(cost_total)}</td>
          <td className="text-center text-nowrap">{NumberUtil.addCommas(profit_after_sell)}</td>
        </tr>
      )
    })

    const tableRows = lists.length === 0 ? emptyBody : listRows

    let dataRowSum = {
      A: '',
      B: '',
      C: '',
      D: '',
      E: '',
      F: '',
      G: 'รวม',
      H: sumRateFinance,
      I: sumRealSellPrice,
      J: sumCarCost,
      K: sumRateFinanceWithVat,
      L: sumSumDown,
      M: sumCostTotal,
      N: sumProfitAfterSell,
      is_car_vat: false
    }
    excelTables.push(dataRowSum)

    const tableSum = <tr>
                  <td className="text-right font-weight-bold" colSpan={7}>รวม</td>
                  <td className="text-right font-weight-bold">{NumberUtil.addCommas(sumRateFinance)}</td>
                  <td className="text-right font-weight-bold">{NumberUtil.addCommas(sumRealSellPrice)}</td>
                  <td className="text-right font-weight-bold">{NumberUtil.addCommas(sumCarCost)}</td>
                  <td className="text-right font-weight-bold">{NumberUtil.addCommas(sumRateFinanceWithVat)}</td>
                  <td className="text-right font-weight-bold">{NumberUtil.addCommas(sumSumDown)}</td>
                  <td className="text-right font-weight-bold">{NumberUtil.addCommas(sumCostTotal)}</td>
                  <td className="text-right font-weight-bold">{NumberUtil.addCommas(sumProfitAfterSell)}</td>
                </tr>

    let car_branch_list = this.state.branches.map((branch, index) => {
      return (
          <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    });

    let car_status_list = this.state.car_status.map((car_status, index) => {
      return (
          <option key={index} value={car_status.car_status_id}>{car_status.car_status_name}</option>
      )
    });

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />
        <div className="card-block" style={styles.overflowY}>
          <div className="row">
              <div className="card card-accent-primary">
                <div className="card-header">
                  <strong className="text-title">สรุปยอดขาย</strong>
                </div>
                <div className="card-block">
                  <div className="row mt-1">
                    <div className="mt-1 ml-3">
                      จาก
                    </div>

                    <div className="col-2">
                      <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="mt-1 ml-3">
                      ถึง
                    </div>
                    <div className="col-2 ml-3">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control d-block"
                        placeholder=""
                      />
                    </div>

                    <div className="form-group ml-1 mr-2">
                      <select className="form-control"
                              id="branch_id"
                              name="branch_id"
                              onChange={(e) => {
                                this.setState({
                                  branch_id: e.target.value
                                })
                              }}
                      >
                        <option value=''> ตามสาขา </option>
                        {car_branch_list}
                      </select>
                    </div>

                    <div className="form-group ml-1 mr-2">
                      <select className="form-control"
                              id="car_status_id"
                              name="car_status_id"
                              onChange={(e) => {
                                this.setState({
                                  car_status_id: e.target.value
                                })
                              }}
                      >
                        <option value=''> ตามสถานะ </option>
                        {car_status_list}
                      </select>
                    </div>

                    <div className="d-flex mr-3">
                      <button type="button" className="btn btn-sm btn-primary" onClick={() => this.loadSellData()} > ตกลง </button>
                    </div>

                    <div className="d-flex mr-3">
                      <button type="button"
                              className="btn btn-sm btn-success"
                              onClick={() => this.exportExcelCarSellSummary(excelTables)}
                      >
                        Excel
                      </button>
                    </div>

                    <div className="d-flex">
                      <button type="button"
                              className="btn btn-sm btn-warning"
                              onClick={() => this.handleCarSellClose()}
                      >
                        ปิดการขาย
                      </button>
                    </div>

                  </div>

                  <div className="row mt-3">
                    <div className="col-12">
                      <table className="table" border={1} style={{border: 1, width: '100%'}} >
                        <thead>
                        <tr>
                          <th style={styles.headerTable} className="text-center text-nowrap">ลำดับ</th>
                          <th style={{...styles.headerTable,...{width: 125}}}  className="text-center text-nowrap">ทะเบียนรถ</th>
                          <th style={{...styles.headerTable,...{width: 200}}}  className="text-center text-nowrap">ชื่อรถ</th>
                          <th style={styles.headerTable} className="text-center text-nowrap">วันที่ขาย</th>
                          <th style={styles.headerTable} className="text-center text-nowrap">ขายโดย</th>
                          <th style={styles.headerTable} className="text-center text-nowrap">สถานะ</th>
                          <th style={styles.headerTable} className="text-center text-nowrap">บ.สินเชื่อ</th>
                          <th style={styles.headerTable} className="text-center text-nowrap">ยอดจัด</th>
                          <th style={styles.headerTable} className="text-center text-nowrap">ราคาขาย</th>
                          <th style={styles.headerTable} className="text-center text-nowrap">ราคาซิ้อ</th>
                          <th style={styles.headerTable} className="text-center text-nowrap">Leasing+vat</th>
                          <th style={styles.headerTable} className="text-center text-nowrap">ดาวน์</th>
                          <th style={styles.headerTable} className="text-center text-nowrap">ค่าใช้จ่าย</th>
                          <th style={styles.headerTable} className="text-center text-nowrap">กำไร</th>
                        </tr>
                        </thead>
                        <tbody>
                         {tableRows}
                         {tableSum}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>

      </div>
    )
  }
}

// eeede8
const styles = {
  headerTable: {
    backgroundColor: '#eeede8',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowReceive: {
    backgroundColor: '#bfcad0',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowExpense: {
    backgroundColor: '#ede4e3',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowProfit: {
    backgroundColor: '#95E06C',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

// export default SellReport;
export default connect(mapStateToProps)(SellSummaryReport);
