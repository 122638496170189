import createConstants from '../constants';
import axios from 'axios'

export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE';
export const DELETE_FLASH_MESSAGE = 'DELETE_FLASH_MESSAGE';

export function loginUserSuccess(token, user, logo_image, type, branch_id) {
    localStorage.setItem('token', token);
    localStorage.setItem('user', user);
    localStorage.setItem('type', type);
    localStorage.setItem('logo_image', logo_image);
    localStorage.setItem('account_branch_id', branch_id);
    // let token_user = localStorage.getItem('token');
    // let user_login = localStorage.getItem('user');
    if (token !== null) {
        axios.defaults.headers.common['Authorization'] = token;
        //store.dispatch(loginUserSuccess(token,user));
    }
    return {
        type: createConstants.LOGIN_USER_SUCCESS,
        payload: {
            token: token,
            user: user,
            type: type,
            account_branch_id: branch_id,
            logo_image: logo_image
        }
    }
}

export function loginUserFailure(error) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('type');
    localStorage.removeItem('logo_image');
    localStorage.removeItem('account_branch_id');
    //status: error.response.status,
    //statusText: error.response.statusText
    return {
        type: createConstants.LOGIN_USER_FAILURE,
        payload: {
            status: 'failure',
            statusText: 'login failed'
        }
    }
}

export function changeUserType (type, user) {
    localStorage.setItem('type', type);
    localStorage.setItem('user', user);
    return {
        type: createConstants.CHANGE_USER_TYPE,
        payload: {
            type: type,
            user: user
        }
    }
}

export function sparePartTab (activeTab) {
    return {
        type: createConstants.SPARE_PART_TAB,
        payload: {
            activeTab: activeTab
        }
    }
}

export function loginUserRequest() {
    return {
        type: createConstants.LOGIN_USER_REQUEST
    }
}

export function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('type');
    localStorage.removeItem('logo_image');
    localStorage.removeItem('account_branch_id');
    return {
        type: createConstants.LOGOUT_USER
    }
}

export function logoutAndRedirect() {
    return (dispatch, state) => {
        dispatch(logout());
        //dispatch(pushState(null, '/login'));
    }
}

export function receiveProtectedData(data) {
    return {
        type: createConstants.RECEIVE_PROTECTED_DATA,
        payload: {
            data: data
        }
    }
}

export function fetchProtectedDataRequest() {
    return {
        type: createConstants.FETCH_PROTECTED_DATA_REQUEST
    }
}
