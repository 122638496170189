import React, { Component } from 'react';

class Switches extends Component {
  render() {
    return (
      <div className="animated fadeIn">

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                3d Switch
              </div>
              <div className="card-block">
                <label className="switch switch-3d switch-primary">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-3d switch-secondary">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-3d switch-success">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-3d switch-warning">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-3d switch-info">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-3d switch-danger">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch default
              </div>
              <div className="card-block">
                <label className="switch switch-default switch-primary">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-secondary">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-success">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-warning">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-info">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-danger">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch default - pills
              </div>
              <div className="card-block">
                <label className="switch switch-default switch-pill switch-primary">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-secondary">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-success">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-warning">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-info">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-danger">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch outline
              </div>
              <div className="card-block">
                <label className="switch switch-default switch-primary-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-secondary-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-success-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-warning-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-info-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-danger-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch outline - pills
              </div>
              <div className="card-block">
                <label className="switch switch-default switch-pill switch-primary-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-secondary-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-success-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-warning-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-info-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-danger-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch outline alternative
              </div>
              <div className="card-block">
                <label className="switch switch-default switch-primary-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-secondary-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-success-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-warning-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-info-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-danger-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch outline alternative - pills
              </div>
              <div className="card-block">
                <label className="switch switch-default switch-pill switch-primary-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-secondary-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-success-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-warning-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-info-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-default switch-pill switch-danger-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch with text
              </div>
              <div className="card-block">
                <label className="switch switch-text switch-primary">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-secondary">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-success">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-warning">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-info">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-danger">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch with text - pills
              </div>
              <div className="card-block">
                <label className="switch switch-text switch-pill switch-primary">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-secondary">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-success">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-warning">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-info">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-danger">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch with text outline
              </div>
              <div className="card-block">

                <label className="switch switch-text switch-primary-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-secondary-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-success-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-warning-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-info-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-danger-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch with text outline - pills
              </div>
              <div className="card-block">

                <label className="switch switch-text switch-pill switch-primary-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-secondary-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-success-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-warning-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-info-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-danger-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch with text outline alternative
              </div>
              <div className="card-block">
                <label className="switch switch-text switch-primary-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-secondary-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-success-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-warning-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-info-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-danger-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch with text outline alternative - pills
              </div>
              <div className="card-block">
                <label className="switch switch-text switch-pill switch-primary-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-secondary-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-success-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-warning-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-info-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-text switch-pill switch-danger-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="On" data-off="Off"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch with icon
              </div>
              <div className="card-block">
                <label className="switch switch-icon switch-primary">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-secondary">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-success">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-warning">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-info">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-danger">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch with icon - pills
              </div>
              <div className="card-block">
                <label className="switch switch-icon switch-pill switch-primary">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-secondary">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-success">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-warning">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-info">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-danger">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch with icon outline
              </div>
              <div className="card-block">
                <label className="switch switch-icon switch-primary-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-secondary-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-success-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-warning-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-info-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-danger-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch with icon outline - pills
              </div>
              <div className="card-block">
                <label className="switch switch-icon switch-pill switch-primary-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-secondary-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-success-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-warning-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-info-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-danger-outline">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch with icon outline alternative
              </div>
              <div className="card-block">
                <label className="switch switch-icon switch-primary-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-secondary-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-success-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-warning-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-info-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-danger-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Switch with icon outline alternative - pills
              </div>
              <div className="card-block">
                <label className="switch switch-icon switch-pill switch-primary-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-secondary-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-success-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-warning-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-info-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label className="switch switch-icon switch-pill switch-danger-outline-alt">
                  <input type="checkbox" className="switch-input" defaultChecked/>
                  <span className="switch-label" data-on="&#xf00c" data-off="&#xf00d"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                Sizes
              </div>
              <div className="card-block p-0">
                <table className="table table-hover table-striped table-align-middle mb-0">
                  <thead>
                    <tr>
                      <th>Size</th>
                      <th>Example</th>
                      <th>CSS Class</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Large
                      </td>
                      <td>
                        <label className="switch switch-lg switch-3d switch-primary">
                          <input type="checkbox" className="switch-input" defaultChecked/>
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </td>
                      <td>
                        Add following class <code>.switch-lg</code>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Normal
                      </td>
                      <td>
                        <label className="switch switch-3d switch-primary">
                          <input type="checkbox" className="switch-input" defaultChecked/>
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </td>
                      <td>
                        -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Small
                      </td>
                      <td>
                        <label className="switch switch-sm switch-3d switch-primary">
                          <input type="checkbox" className="switch-input" defaultChecked/>
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </td>
                      <td>
                        Add following class <code>.switch-sm</code>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Extra small
                      </td>
                      <td>
                        <label className="switch switch-xs switch-3d switch-primary">
                          <input type="checkbox" className="switch-input" defaultChecked/>
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </td>
                      <td>
                        Add following class <code>.switch-sm</code>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>


    )
  }
}

export default Switches;
