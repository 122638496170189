// carrepairbycenter
import React, { Component } from 'react';
import Loading from '../Loading'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import { APIURL } from '../../config/config'
import { save } from 'save-file'
import { NumberUtil } from '../../utils/number-util'
import { AlertWarning } from '../Alert/Alert'
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import NumberFormat from 'react-number-format';

class CarRepairByRetailer extends Component{
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),
      retailers: [],
      retailer_id: '',
      sparePartList: [],
      id: '',
      sum_separate: 0,
      carrepairseparatebyretailer:[],
      stockOut: []
    }

    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.loadRetailerData = this.loadRetailerData.bind(this)
    this.loadSparePart = this.loadSparePart.bind(this)
    this.loadSparePartByRetailerData = this.loadSparePartByRetailerData.bind(this)
    this.exportSparePartByRetailerData = this.exportSparePartByRetailerData.bind(this)


  }

  componentDidMount() {
    this.mounted = true
    this.loadSparePart();
    this.loadRetailerData();
    this.loadSparePartByRetailerData();
  }

  componentWillUnmount() {
    this.mounted = false
  }

  loadRetailerData() {
    axios.get(APIURL + '/retailer/')
        .then(res => {
          this.setState({
            retailers: res.data
          })
        })
  }

  loadSparePart() {
    axios.get(APIURL+'/spare_part')
        .then(res => {
          this.setState({
            sparePartList: res.data
          })
        })
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadSparePartByRetailerData() {
    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    const retailer_id = this.state.retailer_id

    const data = {
      start_date: start_date,
      end_date: end_date,
      retailer_id: retailer_id
    }

    this.setState({
      isLoading: true
    }, () => {
      axios.post(APIURL + '/reportrepair/carrepairseparatebyretailer', data)
          .then(res => {

            let carrepairseparatebyretailer = res.data

            let sum_separate = carrepairseparatebyretailer.reduce((acc, next) => acc +next.total , 0)
            sum_separate = parseFloat(sum_separate).toFixed(2)

            //let retailer_id =  res.data

            console.log(res.data)

            this.setState({
              isLoading: false,
              carrepairseparatebyretailer: carrepairseparatebyretailer,
              sum_separate:sum_separate,
            }, () => {
              // this.showBranchSummary()
            })

          }).catch(error => {
        console.log('error:', error)
      })
    })
  }

  exportSparePartByRetailerData(){
    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')
    let retailer_id = this.state.retailer_id === '' ? 'all' : this.state.retailer_id

    this.setState({
      isLoading: true
    })

    axios.get(APIURL + '/reportrepair/carrepairseparatebyretailerexport/'+start_date+'/'+end_date+'/'+retailer_id)
        .then( async res => {
          const excelBuffer = res.data.excelBuffer
          const currentDatetime = res.data.currentDatetime
          const fileName = 'รายงานสรุปค่าซ่อมตามร้านอะไหล่'+currentDatetime+'.xlsx'
          this.setState({
            isLoading: false
          })
          await save(excelBuffer, fileName)

        }).catch(error => {
      console.log('error:', error)
      // this.setState({lists: []})
    })
  }


  render() {

    const { carrepairseparatebyretailer, sum_separate } = this.state

    let retailer_list = this.state.retailers.map((retailer, index) => {
      return (
          <option key={index} value={retailer.retailer_id}>{retailer.retailer_name}</option>
      )
    })

    const emptyBody = <tr><td colSpan={6} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>


    const listRows = carrepairseparatebyretailer.map((spare_part, index) => {

      return (
          <tr key={index}  style={index%2===0? { backgroundColor: ''} : { backgroundColor: '#e4e5e6'}}>
            <td className="text-center">{index+1}</td>
            <td className="text-left" style={{width: 120}}>{spare_part.retailer_name}</td>
            <td className="text-left" style={{width: 150}}>{spare_part.name}</td>
            <td className="text-left" style={{width: 160}}>{spare_part.car_name} <br/> {spare_part.car_license_plate_new}</td>
            <td className="text-center" style={{width: 50}}>{spare_part.quantity} &nbsp; {spare_part.unit}</td>
            <td style={{width: 50, textAlign: 'center'}}>
              { NumberUtil.addCommas(String(spare_part.price))}
            </td>
            <td style={{width: 100, textAlign: 'center'}}>
              { NumberUtil.addCommas(String(spare_part.total))}
            </td>
          </tr>
      )
    })

    const tableRows = carrepairseparatebyretailer.length === 0 ? emptyBody : listRows

    return (

      <>
        <Loading isLoading={this.state.isLoading} />

        <div className="card-block" style={styles.overflowY}>
          <div className="">
            <div className="card card-accent-primary">
              <div className="card-header">
                <div className="row col-12">
                  <div className="col-9 text-left">
                    <strong className="text-title">สรุปค่าซ่อมตามร้านอะไหล่</strong>
                  </div>

                  <div className="col-3 text-right">
                    <strong>
                      รวมค่าอะไหล่&nbsp;
                      <NumberFormat
                          displayType={'text'}
                          thousandSeparator={true}
                          value={this.state.sum_separate}/>
                      &nbsp;บาท
                    </strong>
                  </div>
                </div>
              </div>
              <div className="card-block">

                <div className="row mt-1">
                  <div className="col-1 mt-1 text-right">
                    จาก
                  </div>

                  <div className="col-2">
                    <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                    />
                  </div>

                  <div className="mt-1 text-right">
                    ถึง
                  </div>

                  <div className="col-2 ml-3">
                    <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control d-block"
                        placeholder=""
                    />
                  </div>

                  <div className="form-group col-3">
                    <select className="form-control" id="center_id" name="retailer_id"
                            onChange={(e) => {
                              this.setState({
                                retailer_id: e.target.value
                              })
                            }}
                    >
                      <option value=''> เลือกร้านอะไหล่ </option>
                      {retailer_list}
                    </select>
                  </div>

                  <div className="d-flex mr-3">
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => this.loadSparePartByRetailerData()} > ตกลง </button>
                  </div>

                  <div className="d-flex">
                    <button type="button" className="btn btn-sm btn-success" onClick={() => this.exportSparePartByRetailerData()} > Excel </button>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-12 mb-2">
                    {
                      moment(this.state.date_start).format('YYYY-MM-DD')  ===  moment(this.state.date_end).format('YYYY-MM-DD') ? (
                          <span><strong>***ค่าใช้จ่ายซ่อมรถแยกตามร้านอะไหล่ วันที่ {moment(this.state.date_start).locale('th').format('LL')}</strong></span>
                      ) : (
                          <span><strong>
                          ***ค่าใช้จ่ายซ่อมรถแยกตามร้านอะไหล่ {moment(this.state.date_start).locale('th').format('LL')}
                            &nbsp; ถึงวันที่ &nbsp;  {moment(this.state.date_end).locale('th').format('LL')} </strong>
                        </span>
                      )
                    }
                  </div>
                  <div className="col-12 mt-1">
                    <div className="col-12">

                      <table className="table table-responsive">
                        <thead>
                        <tr>
                          <th style={{width: 20}} className="text-center text-nowrap">ลำดับ</th>
                          <th style={{width: 120}} className="text-center text-nowrap">ร้านอะไหล่</th>
                          <th style={{width: 150}} className="text-center text-nowrap">ชื่ออะไหล่</th>
                          <th style={{width: 160}} className="text-center text-nowrap">เบิกใช้กับ</th>
                          <th style={{width: 50}} className="text-center text-nowrap">จำนวนเบิก/หน่วย</th>
                          <th style={{width: 50}} className="text-center text-nowrap">ราคา/หน่วย</th>
                          <th style={{width: 100}} className="text-center text-nowrap">รวมยอดค่าอะไหล่</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableRows}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>


            </div>
          </div>
        </div>

      </>
    )

  }

}

const styles = {
  headerTable: {
    backgroundColor: '#eeede8',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowReceive: {
    backgroundColor: '#bfcad0',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowExpense: {
    backgroundColor: '#ede4e3',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowProfit: {
    backgroundColor: '#95E06C',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarRepairByRetailer);
