import React, { Component } from 'react';
import moment from 'moment';
//import BigCalendar from 'react-big-calendar';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import axios from 'axios';
import pdfMake from 'pdfmake/build/pdfmake';
import { APIURL} from '../../../config/config';
import { TabContent, TabPane, Nav, NavItem, NavLink, ModalBody, Modal, ModalFooter, ModalHeader } from 'reactstrap'
import classnames from 'classnames';
import Button from "reactstrap/es/Button";
import AppointmentForm from "../AppointmentForm/AppointmentForm";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import DatePicker from 'react-datepicker'
import {save} from "save-file";
import {format, lastDayOfMonth} from 'date-fns';
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";
import DebtCarLoanHoldDown from '../../Sales/DebtCarLoanHoldDown';
import Loading from "../../Loading";
import {checkGroupPermission, getGroupPermission} from "../../../services/grouppermission";

// const localizer = BigCalendar.momentLocalizer(moment)

/*BigCalendar.setLocalizer(
    BigCalendar.globalizeLocalizer(globalize)
);*/
const localizer = momentLocalizer(moment)
require ("react-big-calendar/lib/css/react-big-calendar.css");


class CalendarShowDetail extends Component {
    constructor (props){
        super(props);
        this.state ={

        }
        this.genTextFromTitle = this.genTextFromTitle.bind(this)
    }

    genTextFromTitle () {
        let title = this.props.title
        let arr_text = title.split('\n')
        let arrRenderText= arr_text.map((text, i) =>(
          <div key={i} className="pt-1"><span>{text}</span></div>
        ))

        return arrRenderText
    }

    render () {

        return (
          <>
              <Modal isOpen={this.props.is_calendar_modal}
                     toggle={this.props.toggleCalendarModal}
                     className="modal-dialog"
              >
                  <ModalHeader toggle={this.toggleCalendarModal}>{this.props.headerText}</ModalHeader>
                  <ModalBody>
                      <div className="card">
                          <div className="card-block">
                              {this.genTextFromTitle()}
                          </div>
                      </div>
                  </ModalBody>
                  <ModalFooter>
                      <button type="button" onClick={() => this.props.toggleCalendarModal()} className="btn btn-sm btn-danger mr-2">
                          <i className="fa fa-window-close-o"/> &nbsp; ปิด  &nbsp;</button>
                  </ModalFooter>
              </Modal>
          </>
        )
    }
}

class CalendarList extends Component{
    constructor (props){
        super(props);
        this.state ={
            menuID: [151, 152, 153],
            grouppermissions: [],
            data:[],
            calendarCustomer: [],
            calendarCarRepair: [],
            birth:[],
            car: '',
            carTax: [],
            carInsurance: [],
            activeTab: '2',
            user: {},
            user_type: '',
            date_start: new Date(),
            date_end: new Date(),
            width: 0,
            height: 0,
            customerDebtor:[],
            customer: '',
            title: '',
            headerText: '',
            isLoading: false,
            is_calendar_modal: false,
            repair_date_start: null,
            repair_date_end: null,
        }
        this.loadData = this.loadData.bind(this);
        this.loadCustomerCalendar = this.loadCustomerCalendar.bind(this);
        this.toggleAppointment = this.toggleAppointment.bind(this);
        this.onReload = this.onReload.bind(this);
        //
        this.printTax = this.printTax.bind(this);
        this.printInsurance = this.printInsurance.bind(this);
        this.loadCarTax = this.loadCarTax.bind(this);
        this.loadCarInsurance = this.loadCarInsurance.bind(this);
        this.handleDateStart = this.handleDateStart.bind(this);
        this.handleDateEnd   = this.handleDateEnd.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.loadCustomerDebtor = this.loadCustomerDebtor.bind(this);
        this.printCustomerDebtor = this.printCustomerDebtor.bind(this);
        this.loadCalendarCarRepair = this.loadCalendarCarRepair.bind(this);
        this.toggleCalendarModal = this.toggleCalendarModal.bind(this);
        this.setCarRepairDate = this.setCarRepairDate.bind(this);
        this.loadGroupPermission = this.loadGroupPermission.bind(this);
        this.setActiveTab = this.setActiveTab.bind(this);
    }

    loadGroupPermission() {
        let type = this.state.user_type
        let menuID = this.state.menuID
        getGroupPermission(type, menuID).then(res => {
            if(res.data.result === 'success') {

                this.setState({
                    grouppermissions: res.data.grouppermissions
                },()=>{
                    this.setActiveTab()
                })
            }
        })
    }

    setActiveTab(){
        let grouppermissions = this.state.grouppermissions
        let grouppermission_viewed = grouppermissions.filter(grouppermission=>grouppermission.viewed === 1)
        let menu_id = grouppermission_viewed[0].menu_id
        if(menu_id === 151){
            this.setState({
                activeTab: '1',
            })
        }else if (menu_id === 152){
            this.setState({
                activeTab: '2',
            })
        }else {
            this.setState({
                activeTab: '3',
            })
        }
    }

    toggleCalendarModal() {
        this.setState({
            is_calendar_modal: !this.state.is_calendar_modal
        })
    }

    setCarRepairDate(date) {

        const firstDateOfMonth = format(date, 'yyyy-MM-01')
        const lastDateOfMonth = format(lastDayOfMonth(date), 'yyyy-MM-dd')

        let repair_date_start = this.state.repair_date_start

        if (repair_date_start !== firstDateOfMonth) {
            this.setState({
                repair_date_start: firstDateOfMonth,
                repair_date_end: lastDateOfMonth,
                isLoading: true
            }, () => {
                this.loadCalendarCarRepair()
            });
        }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidMount() {
        CarsUtil.removeCarSelect()
        CarSellUtil.removeCarSellSelect()

        const today = new Date()
        this.setCarRepairDate(today)

        this.loadData()
        this.loadCustomerCalendar()
        this.loadGroupPermission()

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillMount () {
        let user = JSON.parse(this.props.user);
        this.setState({
            user: user ,
            user_type : user.type
        })
    }

    loadData () {
        axios.get(APIURL + '/calendar_car_license/forplan/')
            .then(res => {
                let data = res.data.filter(d => d.title !== null)
                this.setState({data: data})
            }).catch(error => {
            this.setState({data: []})
        })
    }

    loadCarTax () {

        if(this.state.date_start > this.state.date_end){
            alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
            return
        }
        let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
        let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')
        const data = {
            date_start,
            date_end
        }

        axios.post(APIURL + '/calendar_car_license/tax', data)
          .then(res => {
              if( res.data.length > 0) {
                  this.setState({carTax: res.data}, () => this.printTax())
              } else {
                  alert('ไม่พบข้อมูลการต่อภาษี ' + date_start + ' ถึง ' + date_end)
              }
          }).catch(error => {
            this.setState({carTax: []})
        })
    }

    loadCarInsurance () {

        if(this.state.date_start > this.state.date_end){
            alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
            return
        }

        let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
        let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')
        const data = {
            date_start,
            date_end
        }

        axios.post(APIURL + '/calendar_car_license/insurance', data)
          .then(res => {
              if( res.data.length > 0) {
                  this.setState({ carInsurance: res.data }, () => this.printInsurance())
              } else {
                  alert('ไม่พบข้อมูลการต่อประกัยภัยรถ ' + date_start + ' ถึง ' + date_end)
              }
          }).catch(error => {
            this.setState({carInsurance: []})
        })
    }

    loadCustomerDebtor(){

        if(this.state.date_start > this.state.date_end){
            alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
            return
        }
        let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
        let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')

        const data = {
            date_start,
            date_end
        }

        axios.post(APIURL + '/calendar_customer_debtor/all', data)
            .then(res => {
                if( res.data.length > 0) {

                    let _ = require('lodash');
                    let date_order = _.orderBy(res.data, ['start'], ['asc']);
                    this.setState({customerDebtor: date_order}, () => this.printCustomerDebtor())
                } else {
                    alert('ไม่พบข้อมูลลูกค้า/ลูกหนี้ ' + date_start + ' ถึง ' + date_end)
                }
            }).catch(error => {
            this.setState({customerDebtor: []})
        })
    }

    handleDateStart(date){
        this.setState({
            date_start: date
        })
    }

    handleDateEnd(date){
        this.setState({
            date_end: date
        })
    }

    loadCustomerCalendar() {
        axios.post(APIURL + '/calendar_customer', {})
          .then(res => {

              let data = res.data

              // let calendarCustomer = data.map(d => {
              //     let title = d.title
              //     let cut_position = title.length > 40 ? 40 : title.length - 1
              //     let title_short = title.substring(0, cut_position);

              //
              //     return {
              //         ...d,
              //         title: title_short,
              //         title_full: title
              //     }
              // })


              let calendarCustomers = []
              for (let i =0 ; i < data.length; i++) {
                  let custData = data[i]
                  let calendarCustomer = {
                      ...custData,
                      title: custData.title_short,
                      desc: custData.title
                  }
                  calendarCustomers.push(calendarCustomer)
              }


              this.setState({calendarCustomer: calendarCustomers})
          }).catch(error => {
            this.setState({calendarCustomer: []})
        })
    }

    loadCalendarCarRepair() {

        let repair_date_start = this.state.repair_date_start
        let repair_date_end = this.state.repair_date_end
        let data = {
            repair_date_start,
            repair_date_end
        }

        axios.post(APIURL + '/calendar_car_repair', data)
          .then(res => {
              this.setState({
                  calendarCarRepair: res.data,
                  isLoading: false
              })
          }).catch(error => {
            this.setState({
                calendarCarRepair: [],
                isLoading: false
            })
        })
    }

    onReload(){
        this.loadCustomerCalendar();
    }

    eventStyleGetter(event, start, end, isSelected) {
        let backgroundColor = '#' + event.hex_color;
        let style = {
            backgroundColor: backgroundColor,
            borderRadius: '5px',
            opacity: 0.8,
            color: 'white',
            border: '0px',
            display: 'block',
        };
        return {
            style: style
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleAppointment(){
        this.setState({
            toggle_appointment_add: !this.state.toggle_appointment_add
        })
        this.loadData()
    }

    printTax() {
        let carTaxRows = []
        let row = []
        let lenTax = this.state.carTax.length
        this.state.carTax.forEach((car, index) => {
            var carTitle = {
                text: (index +1) + " : " + car.title,
                style: 'bodyText'
            };

            if (index % 2 === 0) {
                // 0
                row = []
                row.push(carTitle)
                if(lenTax === (index + 1)) {
                    row.push({
                        text: '-',
                        style: 'bodyText'
                    })
                    carTaxRows.push(row)
                }
            } else {
                // 1
                row.push(carTitle)
                carTaxRows.push(row)
            }
        })

        /* const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
        const endOfMonth   = moment().clone().endOf('month').format('YYYY-MM-DD hh:mm');
        var futureMonth = moment(endOfMonth).add(2, 'M');
        var futureMonthEnd = moment(futureMonth).endOf('month').format('YYYY-MM-DD'); */

        let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
        let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')
        let headerText = 'แจ้งเตือนการต่อภาษีรถยนต์ 3 เดือน : ' + date_start + ' ถึง ' + date_end
        let widthTable = ['auto', 'auto']

        let docDefinition = {
            pageOrientation: 'portrait',
            pageSize: 'A4',
            pageMargins: [45, 30, 45, 30], // margin: [left, top, right, bottom]
            content: [
                {text: headerText, style: 'bodyHead', lineHeight: 1.3},
                {
                    table: {
                        widths: widthTable,
                        body: carTaxRows
                    }
                },
            ],
            styles: {
                bodyHead: {
                    fontSize: 16,
                    bold: true,
                    alignment: 'center'
                },
                bodyText: {
                    fontSize: 14,
                },
                bodyBold: {
                    fontSize: 14,
                    bold: true,
                    alignment: 'center'
                },
                textCenter: {
                    fontSize: 14,
                    alignment: 'center'
                },
            },
            defaultStyle: {
                font: 'THSarabunNew'
            }
        };

        pdfMake.createPdf(docDefinition).open()
    }

    printInsurance() {
        let carInsuranceRows = []
        let row = []
        let lenTax = this.state.carInsurance.length
        this.state.carInsurance.forEach((car, index) => {
            let carTitle = {
                text: (index +1) + " : " + car.title,
                style: 'bodyText'
            };

            if (index % 2 === 0) {
                // 0
                row = []
                row.push(carTitle)
                if(lenTax === (index + 1)) {
                    row.push({
                        text: '-',
                        style: 'bodyText'
                    })
                    carInsuranceRows.push(row)
                }
            } else {
                // 1
                row.push(carTitle)
                carInsuranceRows.push(row)
            }
        })

        /*const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
        const endOfMonth   = moment().clone().endOf('month').format('YYYY-MM-DD hh:mm');
        var futureMonth = moment(endOfMonth).add(2, 'M');
        var futureMonthEnd = moment(futureMonth).endOf('month').format('YYYY-MM-DD');*/

        let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
        let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')
        let headerText = 'แจ้งเตือนการต่อประกันภัยรถยนต์ จาก ' + date_start + ' ถึง ' + date_end
        let widthTable = ['auto', 'auto']

        let docDefinition = {
            pageOrientation: 'portrait',
            pageSize: 'A4',
            pageMargins: [45, 30, 45, 30], // margin: [left, top, right, bottom]
            content: [
                {text: headerText, style: 'bodyHead', lineHeight: 1.3},
                {
                    table: {
                        widths: widthTable,
                        body: carInsuranceRows
                    }
                },
            ],
            styles: {
                bodyHead: {
                    fontSize: 16,
                    bold: true,
                    alignment: 'center'
                },
                bodyText: {
                    fontSize: 14,
                },
                bodyBold: {
                    fontSize: 14,
                    bold: true,
                    alignment: 'center'
                },
                textCenter: {
                    fontSize: 14,
                    alignment: 'center'
                },
            },
            defaultStyle: {
                font: 'THSarabunNew'
            }
        };

        pdfMake.createPdf(docDefinition).open()
    }

    printCustomerDebtor() {
        let customerDebtorRows = [];
        let row = [];
        let lenTax = this.state.customerDebtor.length;
        this.state.customerDebtor.forEach((car,index) => {
            let customerDebtorTitle = {
                text: (index +1) + " : " + car.title,
                style: 'bodyText'
            };

            if (index % 2 === 0) {
                // 0
                row = []
                row.push(customerDebtorTitle)
                if(lenTax === (index + 1)) {
                    row.push({
                        text: '-',
                        style: 'bodyText'
                    })
                    customerDebtorRows.push(row)
                }
            } else {
                // 1
                row.push(customerDebtorTitle)
                customerDebtorRows.push(row)
            }
        })

        let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
        let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')

        let headerText = 'ปฏิทินนัดหมายประจำวันที่ : ' + date_start + ' ถึง ' + date_end
        let widthTable = ['*']
        let docDefinition = {
            pageOrientation: 'portrait',
            pageSize: 'A4',
            pageMargins: [20, 30, 20, 20], // margin: [left, top, right, bottom]
            content: [
                {text: headerText, style: 'bodyHead', lineHeight: 1.3},
                {
                    table: {
                        widths: widthTable,
                        body: [
                            [
                                customerDebtorRows
                            ],
                        ],
                    },
                },
            ],
            styles: {
                bodyHead: {
                    fontSize: 16,
                    bold: true,
                    alignment: 'center'
                },
                bodyText: {
                    fontSize: 14,
                },
                bodyBold: {
                    fontSize: 14,
                    bold: true,
                    alignment: 'center'
                },
                textCenter: {
                    fontSize: 14,
                    alignment: 'center'
                },
            },
            defaultStyle: {
                font: 'THSarabunNew'
            }
        };

        pdfMake.createPdf(docDefinition).open()
    }

    render(){

        let user = this.state.user.user

        let width = this.state.width

        return (
          <>

              <CalendarShowDetail
                is_calendar_modal={ this.state.is_calendar_modal }
                toggleCalendarModal={ this.toggleCalendarModal }
                headerText={this.state.headerText}
                title={this.state.title}
                width={width}
              />

              <Loading isLoading={this.state.isLoading} />

              <div className="mb-4">
                  <Nav tabs>

                      {
                          checkGroupPermission(152, this.state.grouppermissions).viewed ? (
                      <NavItem>
                          <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                          >
                              <i className="icon-calendar"/> ปฏิทินลูกค้า & ลูกหนี้
                          </NavLink>
                      </NavItem>
                              ) :
                              null
                      }

                      {
                          checkGroupPermission(151, this.state.grouppermissions).viewed ? (
                      <NavItem>
                          <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                          >
                              <i className="icon-calendar"/> ปฏิทินรถ ภาษี & ประกัน
                          </NavLink>
                      </NavItem>
                              ) :
                              null
                      }

                      {
                          checkGroupPermission(153, this.state.grouppermissions).viewed ? (
                      <NavItem>
                          <NavLink
                            className={classnames({ active: this.state.activeTab === '3' })}
                            onClick={() => { this.toggle('3'); }}
                          >
                              <i className="icon-calendar"/> ปฏิทินซ่อมรถ
                          </NavLink>
                      </NavItem>
                              ) :
                              null
                      }
                  </Nav>

                  <TabContent activeTab={this.state.activeTab}>
                      {
                          checkGroupPermission(151, this.state.grouppermissions).viewed ? (
                      <TabPane tabId="1">
                          <div className="row">
                              <div className="col-lg-12">
                                  <div className="card">
                                      <div className="card-block">
                                          <div className="row">
                                              <div className="col-sm-12 col-md-12">
                                                  <div className="card card-accent-info">
                                                      <div className="card-header">
                                                          <div className="row">
                                                              <div className="col-12">
                                                                  <strong className="text-title"> ภาษี & ประกัน & ฟลอร์แพลน </strong>
                                                              </div>
                                                          </div>
                                                          <div className="row">
                                                              <div className="col-7 mt-4">
                                                                  <div className="row">
                                                                      <div className="ml-5 mt-1 text-title">
                                                                          จาก
                                                                      </div>
                                                                      <div className="col-10 text-right">
                                                                          <div className="row ">
                                                                              <div className="col-5">
                                                                                  <DatePicker
                                                                                    selected={this.state.date_start}
                                                                                    onChange={this.handleDateStart}
                                                                                    dateFormat="yyyy-MM-dd"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    style={{zIndex: 99999}}
                                                                                    popperProps={{
                                                                                        positionFixed: true
                                                                                    }}
                                                                                  />
                                                                              </div>

                                                                              <div className="col-5 text-right">
                                                                                  <DatePicker
                                                                                    selected={this.state.date_end}
                                                                                    onChange={this.handleDateEnd}
                                                                                    dateFormat="yyyy-MM-dd"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                  />
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              {
                                                                  checkGroupPermission(151, this.state.grouppermissions).printed ? (
                                                              <div className="col-5 mt-4">
                                                                  <button className="btn btn-success float-right"
                                                                          onClick={() => this.loadCarTax()}
                                                                  >
                                                                      <i className="icon-printer"/>&nbsp; พิมพ์ต่อภาษี
                                                                  </button>

                                                                  <button
                                                                    className="btn btn-info float-right mr-3"
                                                                    onClick={() => this.loadCarInsurance()}
                                                                  >
                                                                      <i className="icon-printer"/>&nbsp; พิมพ์ประกันภัยรถ
                                                                  </button>
                                                              </div>
                                                                      ) :
                                                                      null
                                                              }

                                                          </div>
                                                      </div>

                                                      <div className="card-block">
                                                          {/*<BigCalendar
                                                            localizer={localizer}
                                                            selectable
                                                            style={{height: '960px' , backgroundColor:'#ffffff'}}
                                                            events={this.state.data}
                                                            defaultView='month'
                                                            defaultDate={new Date()}
                                                            onSelectEvent={event => alert(event.title)}
                                                            eventPropGetter={(this.eventStyleGetter)}
                                                          />*/}
                                                          <Calendar
                                                              localizer={localizer}
                                                              selectable
                                                              style={{height: '960px' , backgroundColor:'#ffffff'}}
                                                              events={this.state.data}
                                                              defaultView='month'
                                                              views={['month']}
                                                              defaultDate={new Date()}
                                                              onSelectEvent={event => alert(event.title)}
                                                              eventPropGetter={(this.eventStyleGetter)}
                                                          />

                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </TabPane>
                              ) :
                              null
                      }

                      {
                          checkGroupPermission(152, this.state.grouppermissions).viewed ? (
                      <TabPane tabId="2">
                          <div className="row">
                              <div className="col-lg-12">
                                  <div className="card">
                                      <div className="card-block">
                                          <div className="row">
                                              <div className="col-sm-12 col-md-12">
                                                  <div className="card card-accent-success">
                                                      <div className="card-header card-customer">
                                                          <div className="row">
                                                              <div className="col-12">
                                                                  <strong className="text-title"> ปฏิทินลูกค้า & ลูกหนี้ </strong>
                                                              </div>
                                                          </div>

                                                          <div className="row">
                                                              <div className="col-7 mt-4">
                                                                  <div className="row">
                                                                      <div className="ml-5 mt-1 text-title">
                                                                          จาก
                                                                      </div>
                                                                      <div className="col-10 text-right">
                                                                          <div className="row ">
                                                                              <div className="col-5">
                                                                                  <DatePicker
                                                                                      selected={this.state.date_start}
                                                                                      onChange={this.handleDateStart}
                                                                                      dateFormat="yyyy-MM-dd"
                                                                                      type="text"
                                                                                      className="form-control"
                                                                                      placeholder=""
                                                                                      style={{zIndex: 99999}}
                                                                                      popperProps={{
                                                                                          positionFixed: true
                                                                                      }}
                                                                                  />
                                                                              </div>

                                                                              <div className="col-5 text-right">
                                                                                  <DatePicker
                                                                                      selected={this.state.date_end}
                                                                                      onChange={this.handleDateEnd}
                                                                                      dateFormat="yyyy-MM-dd"
                                                                                      type="text"
                                                                                      className="form-control"
                                                                                      placeholder=""
                                                                                  />
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div className="col-5 mt-4">
                                                                  {
                                                                      checkGroupPermission(152, this.state.grouppermissions).printed ? (
                                                                  <button className="btn btn-success float-right mt-1 ml-3"
                                                                          onClick={() => this.loadCustomerDebtor()}
                                                                  >
                                                                      <i className="icon-printer"/>&nbsp; พิมพ์นัดหมาย
                                                                  </button>
                                                                          ) :
                                                                          null
                                                                  }

                                                                  {
                                                                      checkGroupPermission(152, this.state.grouppermissions).modified ? (
                                                                  <Link to="/calendar/manage" >
                                                                      <button className="btn btn-danger float-right mt-1">
                                                                          จัดการปฏิทินนัดหมาย
                                                                      </button>
                                                                  </Link>
                                                                          ) :
                                                                          null
                                                                  }

                                                                  {
                                                                      checkGroupPermission(152, this.state.grouppermissions).created ? (
                                                                  <Button className="btn btn-info float-right mt-1 mr-3" onClick={this.toggleAppointment}>
                                                                      เพิ่มนัดหมายลูกค้า
                                                                  </Button>
                                                                          ) :
                                                                          null
                                                                  }
                                                              </div>
                                                          </div>
                                                      </div>

                                                      <Modal isOpen={this.state.toggle_appointment_add}
                                                             toggle={this.toggleAppointment}
                                                             className={'modal-lg'}
                                                             style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
                                                      >
                                                          <ModalBody>
                                                              <AppointmentForm
                                                                 onToggle={this.toggleAppointment}
                                                                 user = {user}
                                                                 onReload={this.onReload}
                                                              />
                                                          </ModalBody>
                                                      </Modal>


                                                      <div className="card-block">
                                                          {/*<BigCalendar
                                                            localizer={localizer}
                                                            selectable
                                                            style={{height: '960px' , backgroundColor:'#ffffff'}}
                                                            events={this.state.calendarCustomer}
                                                            defaultView='month'
                                                            defaultDate={new Date()}
                                                            onSelectEvent={event => alert(event.title)}
                                                            eventPropGetter={(this.eventStyleGetter)}
                                                      />*/}

                                                          <Calendar
                                                              localizer={localizer}
                                                              selectable
                                                              style={{height: '960px' , backgroundColor:'#ffffff'}}
                                                              events={this.state.calendarCustomer}
                                                              defaultView='month'
                                                              views={['month']}
                                                              defaultDate={new Date()}
                                                              onSelectEvent={event => alert(event.desc)}
                                                              eventPropGetter={(this.eventStyleGetter)}
                                                              popup
                                                          />

                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </TabPane>
                              ) :
                              null
                      }

                      {
                          checkGroupPermission(153, this.state.grouppermissions).viewed ? (
                      <TabPane tabId="3">
                          <div className="row">
                              <div className="col-lg-12">
                                  <div className="card card-accent-warning">
                                      <div className="card-header card-customer">
                                          <strong className="text-title"> ปฏิทินซ่อมรถ</strong>
                                      </div>
                                      <div className="card-block">

                                          <Calendar
                                            localizer={localizer}
                                            selectable
                                            style={{height: '960px' , backgroundColor:'#ffffff'}}
                                            events={this.state.calendarCarRepair}
                                            defaultView='month'
                                            defaultDate={new Date()}
                                            views={['month']}
                                            onSelectEvent={event => {
                                                // alert(event.title)
                                                // views={['month', 'week', 'agenda']}
                                                let title = event.title
                                                this.setState({
                                                    title: title,
                                                    headerText: 'ปฏิทินซ่อมรถ',
                                                    is_calendar_modal: !this.state.is_calendar_modal
                                                }, () => {
                                                    // this.toggleCalendarModal()
                                                })
                                            }}
                                            eventPropGetter={(this.eventStyleGetter)}

                                            onNavigate={(date, view, action) => {

                                                // let start_date = format(date, 'yyyy-MM-dd')
                                                // view= month

                                                this.setCarRepairDate(date)

                                            }}

                                          />

                                      </div>
                                  </div>
                              </div>
                          </div>
                      </TabPane>
                              ) :
                              null
                      }

                  </TabContent>

              </div>


          </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CalendarList)
