import {createConstants} from '../utils';

export default createConstants(
  'LOGIN_USER_REQUEST',
  'LOGIN_USER_FAILURE',
  'LOGIN_USER_SUCCESS',
  'LOGOUT_USER',
  'FETCH_PROTECTED_DATA_REQUEST',
  'RECEIVE_PROTECTED_DATA',
  'SPARE_PART_TAB',
  'CHANGE_USER_TYPE',
);
