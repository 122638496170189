import axios from "axios";
import {APIURL} from "../config/config";


export const checkDocNo = async (doc_no) => {
    // company_setting_id
    try{
        let data = {doc_no}
        let result = await axios.post(APIURL + '/doc_log/doc_no', data)
        return result.data
    }catch (e) {
        console.log('error: ', e)
        return null
    }
}

export const setInputStyleDocNo = (check_doc_no) => {
    // && isExist === false
    // console.log('check_doc_no=', check_doc_no)
    if (check_doc_no === 'repeat'){
        return 'rgb(255,0,0)'
    } else if (check_doc_no === 'pass'){
        return 'rgb(60, 179, 113)'
    }
    return 'rgba(0, 0, 0, 0.15)'
}
