import React, { Component } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import axios from 'axios'
import { APIURL } from '../../config/config'

class OccupationModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      occupation_list: [],
    }

    this.loadOccupation = this.loadOccupation.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

  }

  componentDidMount () {
    this.loadOccupation();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  loadOccupation() {
    axios.get( APIURL + '/occupation').then(res => {
      this.setState({
        occupation_list: res.data
      })
    }).catch(error => {
      console.log('error: ', error)
    })
  }


  render () {
    let width = this.state.width
    const emptyData = <tr><td colSpan={3} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>

    const occupation_list = this.state.occupation_list.map((occupation, index) => {
      return (
        <tr key={index}>
          <td className="text-center">{ index + 1 }</td>
          <td className="text-nowrap">{ occupation.occupation_name }</td>
          <td className="text-center" >
            <button className="btn btn-sm btn-success"
                    onClick={() => this.props.onSelectOccupation(occupation)}
            >
              เลือก
            </button>
          </td>
        </tr>
      )
    })
    // onSelectOccupation
    const occupationList = this.state.occupation_list.length > 0 ? occupation_list : emptyData

    return (
      <>
        <Modal isOpen={this.props.isModal}
               className={'modal-lg ' + this.props.className}
               style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
        >
          <ModalBody>
            <div className={"card card-accent-primary"}>
              <div className="card-header">
                <strong className="text-title">เลือกอาชีพ</strong>
              </div>

              <div className="card-block">
                <div className="row">
                  <div className="col-12 mt-4">
                    <table className="table table-striped table-responsive">
                      <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-nowrap">อาชีพ</th>
                        <th className="text-center">เลือก</th>
                      </tr>
                      </thead>
                      <tbody>
                      {occupationList}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="card-footer text-right">
                <button type="button" onClick={() => this.props.closeModal()}
                        className="btn btn-sm btn-danger">
                  &nbsp;<i className="fa fa-close"/> &nbsp;&nbsp;ปิด&nbsp;&nbsp;
                </button>
              </div>

            </div>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

export default OccupationModal
