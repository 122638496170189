import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import { PORT } from '../../../config/config'

// const port = window.env.PORT;

class Sidebar365car extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_type: this.props.user_type,
        }
    }

    componentDidMount () {
        // let user = this.props.user
        // let type = this.props.type
        // console.log('user=', user, PORT, typeof PORT)
        // console.log('user type=', type, PORT, typeof PORT)
    }

    render() {
        // console.log('SidebarRole user_type=' + this.state.user_type);
        if(this.state.user_type === 'admin') {
            return (
                <ul className="nav">
                    <li className="nav-item">
                        <NavLink to={'/dashboard'} className="nav-link" activeClassName="active">
                            <i className="icon-grid"/> Dashboard <span
                            className="badge badge-info">NEW</span></NavLink>
                    </li>
                    <li className="nav-title">
                        เมนู
                    </li>
                    <li className="nav-item">
                        <NavLink to={'/cars/list/1'} className="nav-link" activeClassName="active">
                            <i className="icon-key"/> ข้อมูลรถ</NavLink>
                    </li>

                   <li className="nav-item">
                      <NavLink to={'/customer/followup/tab'} className="nav-link" activeClassName="active">
                          <i className="icon-user-follow"/> ติดตามลูกค้า </NavLink>
                  </li>

                    <li className="nav-item">
                        <NavLink to={'/sparepart'} className="nav-link" activeClassName="active">
                            <i className="icon-tag"/> ข้อมูลอะไหล่</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/car-repair/list'} className="nav-link" activeClassName="active">
                            <i className="icon-wrench"/> ข้อมูลส่งซ่อม</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={'/customer/list'} className="nav-link" activeClassName="active">
                            <i className="icon-user-follow"/> ข้อมูลลูกค้า</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/agent/list'} className="nav-link" activeClassName="active">
                            <i className="icon-user-female"/> ข้อมูลนายหน้า</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={'/customer/findcar'} className="nav-link" activeClassName="active">
                            <i className="icon-target"/> ข้อมูลลูกค้าหารถ</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={'/car-reservation/tabs'} className="nav-link" activeClassName="active">
                            <i className="icon-star"/> ข้อมูลการจองรถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/finance-pending/list'} className="nav-link" activeClassName="active">
                            <i className="icon-layers"/> ข้อมูลรอจัดไฟแนนซ์</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/sales/list'} className="nav-link" activeClassName="active">
                            <i className="icon-diamond"/> ข้อมูลการขาย</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/car-claim/list'} className="nav-link" activeClassName="active">
                            <i className="icon-event"/> ข้อมูลส่งเคลม/รับรถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/debtors/list'} className="nav-link" activeClassName="active">
                            <i className="icon-people"/> ข้อมูลลูกหนี้</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/income_expense_item'} className="nav-link" activeClassName="active">
                            <i className="icon-grid"/> หมวดรายรับ/ค่าใช้จ่าย</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/receipts/list'} className="nav-link" activeClassName="active">
                            <i className="icon-wallet"/> รายรับของเต็นท์รถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/expense/list'} className="nav-link" activeClassName="active">
                            <i className="icon-calculator"/> ค่าใช้จ่าย</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/expense/repair'} className="nav-link" activeClassName="active">
                            <i className="icon-doc"/> ค่าใช้จ่ายซ่อมรถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/expense/car/other'} className="nav-link" activeClassName="active">
                            <i className="icon-docs"/> ค่าใช้จ่ายอื่นๆ ของรถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/calendar/list'} className="nav-link" activeClassName="active">
                            <i className="icon-calendar"/> ปฏิทินเตือนความจำ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/setting'} className="nav-link" activeClassName="active">
                            <i className="icon-settings"/> ตั้งค่าระบบ</NavLink>
                    </li>
                </ul>
            );
        } else if(this.state.user_type === 'manager') {
            return (
                <ul className="nav">
                    <li className="nav-item">
                        <NavLink to={'/dashboard'} className="nav-link" activeClassName="active">
                            <i className="icon-speedometer"/> Dashboard <span
                            className="badge badge-info">NEW</span></NavLink>
                    </li>
                    <li className="nav-title">
                        เมนู
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/cars/list/1'} className="nav-link" activeClassName="active">
                            <i className="icon-key"/> ข้อมูลรถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/sparepart'} className="nav-link" activeClassName="active">
                            <i className="icon-wrench"/> ข้อมูลอะไหล่</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/car-repair/list'} className="nav-link" activeClassName="active">
                            <i className="icon-wrench"/> ข้อมูลส่งซ่อม</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/customer/findcar'} className="nav-link" activeClassName="active">
                            <i className="icon-energy"/> ข้อมูลลูกค้าหารถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/car-reservation/tabs'} className="nav-link" activeClassName="active">
                            <i className="icon-diamond"/> ข้อมูลการจองรถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/expense/list'} className="nav-link" activeClassName="active">
                            <i className="icon-wallet"/> ค่าใช้จ่าย</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/calendar/list'} className="nav-link" activeClassName="active">
                            <i className="icon-calendar"/> ปฏิทินเตือนความจำ</NavLink>
                    </li>
                </ul>
            )
        } else if (this.state.user_type === 'headsale') {
            return (
                <ul className="nav">
                    <li className="nav-item">
                        <NavLink to={'/dashboard'} className="nav-link" activeClassName="active">
                            <i className="icon-speedometer"/> Dashboard <span
                            className="badge badge-info">NEW</span></NavLink>
                    </li>

                    <li className="nav-title">
                        เมนู
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/cars/list/1'} className="nav-link" activeClassName="active">
                            <i className="icon-key"/> ข้อมูลรถ</NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink to={'/customer/followup/tab'} className="nav-link" activeClassName="active">
                          <i className="icon-user-follow"/> ติดตามลูกค้า </NavLink>
                  </li>

                    <li className="nav-item">
                        <NavLink to={'/car-repair/list'} className="nav-link" activeClassName="active">
                            <i className="icon-wrench"/> ข้อมูลส่งซ่อม</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/customer/list'} className="nav-link" activeClassName="active">
                            <i className="icon-user-following"/> ข้อมูลลูกค้า</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/agent/list'} className="nav-link" activeClassName="active">
                            <i className="icon-user-following"/> ข้อมูลนายหน้า</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/customer/findcar'} className="nav-link" activeClassName="active">
                            <i className="icon-energy"/> ข้อมูลลูกค้าหารถ</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={'/car-reservation/tabs'} className="nav-link" activeClassName="active">
                            <i className="icon-diamond"/> ข้อมูลการจองรถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/finance-pending/list'} className="nav-link" activeClassName="active">
                            <i className="icon-layers"/> ข้อมูลรอจัดไฟแนนซ์</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/sales/list'} className="nav-link" activeClassName="active">
                            <i className="icon-diamond"/> ข้อมูลการขาย</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/car-claim/list'} className="nav-link" activeClassName="active">
                            <i className="icon-wrench"/> ข้อมูลส่งเคลม/รับรถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/calendar/list'} className="nav-link" activeClassName="active">
                            <i className="icon-calendar"/> ปฏิทินเตือนความจำ</NavLink>
                    </li>
                </ul>
            )
        } else if (this.state.user_type === 'sale') {
            return (
                <ul className="nav">
                    <li className="nav-item">
                        <NavLink to={'/dashboard'} className="nav-link" activeClassName="active">
                            <i className="icon-speedometer"/> Dashboard <span
                            className="badge badge-info">NEW</span></NavLink>
                    </li>

                    <li className="nav-title">
                        เมนู
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/cars/list/1'} className="nav-link" activeClassName="active">
                            <i className="icon-key"/> ข้อมูลรถ</NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink to={'/customer/followup/tab'} className="nav-link" activeClassName="active">
                          <i className="icon-user-follow"/> ติดตามลูกค้า </NavLink>
                  </li>

                    <li className="nav-item">
                        <NavLink to={'/car-repair/list'} className="nav-link" activeClassName="active">
                            <i className="icon-wrench"/> ข้อมูลส่งซ่อม</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/customer/list'} className="nav-link" activeClassName="active">
                            <i className="icon-user-following"/> ข้อมูลลูกค้า</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/agent/list'} className="nav-link" activeClassName="active">
                            <i className="icon-user-following"/> ข้อมูลนายหน้า</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/customer/findcar'} className="nav-link" activeClassName="active">
                            <i className="icon-energy"/> ข้อมูลลูกค้าหารถ</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={'/car-reservation/tabs'} className="nav-link" activeClassName="active">
                            <i className="icon-diamond"/> ข้อมูลการจองรถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/finance-pending/list'} className="nav-link" activeClassName="active">
                            <i className="icon-layers"/> ข้อมูลรอจัดไฟแนนซ์</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/sales/list'} className="nav-link" activeClassName="active">
                            <i className="icon-diamond"/> ข้อมูลการขาย</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/car-claim/list'} className="nav-link" activeClassName="active">
                            <i className="icon-wrench"/> ข้อมูลส่งเคลม/รับรถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/calendar/list'} className="nav-link" activeClassName="active">
                            <i className="icon-calendar"/> ปฏิทินเตือนความจำ</NavLink>
                    </li>
                </ul>
            )
        } else if (this.state.user_type === 'account') {
                return (
                    <ul className="nav">
                        <li className="nav-item">
                            <NavLink to={'/dashboard'} className="nav-link" activeClassName="active">
                                <i className="icon-speedometer"/> Dashboard <span
                                className="badge badge-info">NEW</span></NavLink>
                        </li>

                        <li className="nav-title">
                            เมนู
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/cars/list/1'} className="nav-link" activeClassName="active">
                                <i className="icon-key"/> ข้อมูลรถ</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/sparepart'} className="nav-link" activeClassName="active">
                                <i className="icon-wrench"/> ข้อมูลอะไหล่</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/car-repair/list'} className="nav-link" activeClassName="active">
                                <i className="icon-wrench"/> ข้อมูลส่งซ่อม</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/customer/list'} className="nav-link" activeClassName="active">
                                <i className="icon-user-following"/> ข้อมูลลูกค้า</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/agent/list'} className="nav-link" activeClassName="active">
                                <i className="icon-user-following"/> ข้อมูลนายหน้า</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/car-reservation/tabs'} className="nav-link" activeClassName="active">
                                <i className="icon-diamond"/> ข้อมูลการจองรถ</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/finance-pending/list'} className="nav-link" activeClassName="active">
                                <i className="icon-layers"/> ข้อมูลรอจัดไฟแนนซ์</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/sales/list'} className="nav-link" activeClassName="active">
                                <i className="icon-diamond"/> ข้อมูลการขาย</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/car-claim/list'} className="nav-link" activeClassName="active">
                                <i className="icon-wrench"/> ข้อมูลส่งเคลม/รับรถ</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/debtors/list'} className="nav-link" activeClassName="active">
                                <i className="icon-people"/> ข้อมูลลูกหนี้ </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/income_expense_item'} className="nav-link" activeClassName="active">
                                <i className="icon-grid"/> หมวดรายรับ/ค่าใช้จ่าย</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/receipts/list'} className="nav-link" activeClassName="active">
                                <i className="icon-wallet"/> รายรับของเต็นท์รถ</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/expense/list'} className="nav-link" activeClassName="active">
                                <i className="icon-calculator"/> ค่าใช้จ่าย</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/expense/repair'} className="nav-link" activeClassName="active">
                                <i className="icon-doc"/> ค่าใช้จ่ายซ่อมรถ</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/expense/car/other'} className="nav-link" activeClassName="active">
                                <i className="icon-docs"/> ค่าใช้จ่ายอื่นๆ ของรถ</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={'/calendar/list'} className="nav-link" activeClassName="active">
                                <i className="icon-calendar"/> ปฏิทินเตือนความจำ</NavLink>
                        </li>
                    </ul>
                )
        } else { //if(this.state.user_type === 'technician'){
            return (
                <ul className="nav">
                    <li className="nav-item">
                        <NavLink to={'/dashboard'} className="nav-link" activeClassName="active">
                            <i className="icon-speedometer"/> Dashboard <span
                            className="badge badge-info">NEW</span></NavLink>
                    </li>
                    <li className="nav-title">
                        เมนู
                    </li>
                    <li className="nav-item">
                        <NavLink to={'/cars/list/1'} className="nav-link" activeClassName="active">
                            <i className="icon-key"/> ข้อมูลรถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/sparepart'} className="nav-link" activeClassName="active">
                            <i className="icon-wrench"/> ข้อมูลอะไหล่</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/car-repair/list'} className="nav-link" activeClassName="active">
                            <i className="icon-wrench"/> ข้อมูลส่งซ่อม</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/car-claim/list'} className="nav-link" activeClassName="active">
                            <i className="icon-wrench"/> ข้อมูลส่งเคลม/รับรถ</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={'/maintenance/list'} className="nav-link" activeClassName="active">
                            <i className="icon-speedometer"/> ข้อมูลศูนย์ซ่อมบำรุงรถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/expense/list'} className="nav-link" activeClassName="active">
                            <i className="icon-wallet"/> ค่าใช้จ่าย</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/expense/repair'} className="nav-link" activeClassName="active">
                            <i className="icon-doc"/> ค่าใช้จ่ายซ่อมรถ</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={'/calendar/list'} className="nav-link" activeClassName="active">
                            <i className="icon-calendar"/> ปฏิทินเตือนความจำ</NavLink>
                    </li>

                </ul>
            );
        }
    }

}

export default Sidebar365car;
