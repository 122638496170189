import React, { Component } from 'react';
import {APIURL, IMAGE_NOPHOTO_URL} from "../../config/config";
import axios from "axios";
import {LazyLoadImage} from "react-lazy-load-image-component";

class CoupleCarImages extends Component {

    constructor (props) {
        super(props)
        this.state = {
            car_id: 0,
            car_upload_code: 0,
            current_car_url: IMAGE_NOPHOTO_URL
        }
        this.loadCarCurrent = this.loadCarCurrent.bind(this)
    }

    componentDidMount () {
        this.mounted = true
        // this.loadCarDepositRefund()
        this.setState({
            car_id: 0,
            car_upload_code: 0,
            current_car_url: IMAGE_NOPHOTO_URL
        }, () => {
            this.loadCarCurrent()
        })
    }

    componentWillUnmount () {
        this.mounted = false
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.car_id !== this.props.car_id) {
            this.loadCarCurrent()
        }
    }

    loadCarCurrent() {
        // position/:upload_code/:position_name
        let car_id = this.props.car_id
        let car_upload_code = this.props.car_upload_code
        let position_name = 'current_car'

        let uri = APIURL +'/car_upload/position/'+car_upload_code+'/'+position_name;
        if (this.mounted) {
            axios.get(uri)
                .then(res => {
                    let car_images = res.data
                    if (car_images.length === 0) {
                        this.setState({ current_car_url: IMAGE_NOPHOTO_URL})
                    } else {
                        let car_image = car_images[0]
                        let car_upload_filename = car_image.car_upload_filename
                        this.setState({ current_car_url: car_upload_filename })
                    }

                }).catch(error => {
                this.setState({ current_car_url: IMAGE_NOPHOTO_URL})
            })
        }

    }

    render() {
        // let img_width_str = String(this.props.img_width)
        // img_width_str = img_width_str.replace("px", "")
        // let imgWidth = parseInt(String(Number(img_width_str)/2))-4

        let imgWidth = (this.props.img_width/2)-4
        let front_car_url = this.props.front_car_url
        let current_car_url = this.state.current_car_url

        return (
            <div className="row" style={{width: this.props.img_width}}>
                <div className="col-6">
                    <LazyLoadImage src={current_car_url}
                                   width={imgWidth}
                                   height={imgWidth}
                                   effect="blur"
                    />
                </div>
                <div className="col-6">
                    <LazyLoadImage src={front_car_url}
                                   width={imgWidth}
                                   height={imgWidth}
                                   effect="blur"
                    />
                </div>
            </div>
        )
    }
}

export default CoupleCarImages
