import React, { Component } from 'react'
import { APIURL } from '../config/config'
import axios from 'axios'
import { AlertSuccess } from './Alert/Alert'
import Switch from 'react-switch'
import Swal from 'sweetalert2'

class SettingCarStructure extends Component{
  constructor (props) {
    super(props);

    this.state = {
      actionType: "บันทึก",
      car_structures: [],
      car_structure_name: '',
      car_structure_group: '',
      car_structure_id: '',
    }

    this.loadCarStructure = this.loadCarStructure.bind(this);
    this.saveCarStructure = this.saveCarStructure.bind(this);
    this.setEditForm = this.setEditForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
    this.deleteCarStructure = this.deleteCarStructure.bind(this);

  }

  componentDidMount () {
    this.loadCarStructure()
  }

  loadCarStructure() {
    let url = APIURL+'/car_structure/admin'
    axios.get(url).then(res => {
      this.setState({
        car_structures: res.data
      })
    })
  }

  deleteCarStructure(car_structure){
    let car_structure_id = car_structure.car_structure_id
    let car_structure_name = car_structure.car_structure_name
    let username = this.props.username
    let data = {
      car_structure_id: car_structure_id,
      status_field: 'delete',
      username: username
    }

    Swal.fire({
      title: 'ยืนยันการลบ '+car_structure_name+' ?',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        let url = APIURL+'/car_structure/status'
        axios.post(url, data).then(res => {
          if(res.data === 'success') {
            AlertSuccess('ลบข้อมูลแล้ว')
            this.loadCarStructure()
          }
        })
      }
    });

  }

  saveCarStructure() {
    let username = this.props.username
    let actionType = this.state.actionType
    let car_structure_name = this.state.car_structure_name
    let car_structure_group = this.state.car_structure_group

    let data= {
      car_structure_name,
      car_structure_group,
      username
    }

    if(actionType === "บันทึก") {
      let url = APIURL+'/car_structure'
      axios.post(url, data).then(res => {
        if(res.data === 'success') {
          AlertSuccess('บันทึกข้อมูลแล้ว')
          this.clearForm()
          this.loadCarStructure()
        }
      })
    } else if (actionType === "แก้ไข") {
      let car_structure_id = this.state.car_structure_id
      data = {...data, car_structure_id: car_structure_id }
      let url = APIURL+'/car_structure/'+car_structure_id
      axios.put(url, data).then(res => {
        if(res.data === 'success') {
          AlertSuccess('แก้ไขข้อมูลแล้ว')
          this.clearForm()
          this.loadCarStructure()
        }
      })
    }
  }

  setEditForm(car_structure) {
    this.setState({
      actionType: 'แก้ไข',
      car_structure_name: car_structure.car_structure_name,
      car_structure_group: car_structure.car_structure_group,
      car_structure_id: car_structure.car_structure_id,
    })
  }

  clearForm() {
    this.setState({
      actionType: "บันทึก",
      car_structure_name: '',
      car_structure_group: '',
      car_structure_id: '',
    })
  }

  onChangeSwitch(checked, car_structure){
    let username = this.props.username
    let item_update = car_structure
    let car_structures = this.state.car_structures
    let item_index = this.state.car_structures.findIndex(data => data.car_structure_id=== car_structure.car_structure_id)

    if(checked === false){
      item_update.status_field = 'hidden'
    } else {
      item_update.status_field = 'show'
    }

    let data = {
      car_structure_id: item_update.car_structure_id,
      status_field: item_update.status_field,
      username: username
    }

    let url = APIURL+'/car_structure/status'
    axios.post(url, data).then(res => {

    })

    car_structures[item_index] = item_update
    this.setState({
      car_structures: car_structures
    })
  }

  render () {

    return (
      <div className="row">
        <div className="col-sm-7">
          <div className="card card-accent-info">
            <div className="card-header">
              <strong className="text-title">
                ลักษณะรถ
              </strong>
            </div>
            <div className="card-block" style={styles.overflowY}>
              <table className="table table-striped table-responsive">
                <thead>
                <tr>
                  <th className="text-left">#</th>
                  <th className="text-left">ชื่อลักษณะรถ</th>
                  <th className="text-left">กลุ่ม</th>
                  <th className="text-center">ใช้งาน</th>
                  <th className="text-center">จัดการ</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.car_structures.map((car_structure, index) => (
                    <tr key={index}>
                      <td style={{width: 10}}>{ index+1 }</td>
                      <td className="text-nowrap" style={{width: 80}}>{ car_structure.car_structure_name }</td>
                      <td className="text-nowrap" style={{width: 50}}>{ car_structure.car_structure_group }</td>
                      <td className="text-nowrap text-center" style={{width: 50}}>

                        { car_structure.status_field === 'show' ? 'ใช้งาน' : 'ไม่ใช้งาน' }

                        <br/>

                        <Switch height = {20} width ={48}
                                onChange={(checked) => this.onChangeSwitch(checked, car_structure)}
                                checked={car_structure.status_field === 'show'}
                        />

                      </td>
                      <td className="text-center" style={{width: 120}}>
                        <button type="button"
                                className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                                onClick={() => this.setEditForm(car_structure)}
                        >
                          <i className="fa fa-edit"/> แก้ไข
                        </button>
                        <button type="button"
                                className="btn btn-sm btn-danger btn-md-width mr-2 mb-2"
                                onClick={() => this.deleteCarStructure(car_structure)}
                        >
                          <i className="fa fa-remove"/> ลบ
                        </button>
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-5">
          <div className="card card-accent-success">
            <div className="card-header card-customer">
              <strong className="text-title">เพิ่ม/แก้ไข ลักษณะรถ</strong>
            </div>

            <div className="card-block">
              <div className="form-group">
                <label htmlFor="car_structure_name">ชื่อลักษณะรถ</label>
                <input
                  value={this.state.car_structure_name}
                  type="text"
                  className="form-control"
                  name="car_structure_name"
                  placeholder="ชื่อลักษณะรถ"
                  style={styles.inputSearch}
                  onChange={(e) => {
                    this.setState({
                      car_structure_name: e.target.value
                    })
                    // กระบะ เก๋ง แวน
                  }}
                />
              </div>

              <div className="form-group">
                <label htmlFor="car_structure_group">กลุ่ม</label>
                <select className="form-control"
                        id="car_structure_group"
                        name="car_structure_group"
                        value={this.state.car_structure_group}
                        onChange={(e) => {
                          this.setState({
                            car_structure_group: e.target.value
                          })
                        }}
                >
                  <option value="">-- กรุณาเลือก --</option>
                  <option value="กระบะ">กระบะ</option>
                  <option value="เก๋ง">เก๋ง</option>
                  <option value="แวน">แวน</option>
                  <option value="อื่นๆ">อื่นๆ</option>
                </select>
              </div>
            </div>

            <div className="card-footer text-right">

              <button type="button"
                      className="btn btn-sm btn-danger mr-2"
                      style={styles.button}
                      onClick={()=> this.clearForm()}
              >
                <i className="fa fa-refresh"/> ยกเลิก
              </button>

              <button type="button"
                      className={this.state.actionType==="แก้ไข"?  "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}
                      style={styles.button}
                      onClick={() => {
                        this.saveCarStructure()
                      }}
              >
                <i className={this.state.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/> {this.state.actionType}
              </button>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  button: {
    borderRadius:5,
    height: 36,
  },
  inputSearch: {
    borderRadius:5,
  },
  overflowY: {
    overflow: 'scroll',
  }
}

export default SettingCarStructure
