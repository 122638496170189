import React, {Component} from 'react'
import { Modal, ModalBody } from 'reactstrap'
import axios from 'axios'
import { APIURL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL } from '../../config/config'
import MaskedInput from "react-maskedinput";
import {AlertError, AlertSuccess, AlertWarning} from "../Alert/Alert";
import moment from "moment";
import {connect} from "react-redux";

class AgentModal extends Component{
  constructor (props){
    super(props)
    this.state = {
      agent_list: [],
      width: 0,
      height: 0,
      startDateAgentNew1: moment().format('YYYY-MM-DD'),
      agent_fullname: '',
      agent_address: '',
      agent_card_id: '',
      agent_mobile: '',
      agent_birth_text: '',
      agent_id: null,
      w_agent_file: false,
      user: JSON.parse(this.props.user),
      //
      actionType: 'บันทึก'
    }

    this.loadAgentData = this.loadAgentData.bind(this)
    // this.onSelectAgent = this.onSelectAgent.bind(this)
    this.onRefresh = this.onRefresh.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.handleOnSubmitAgent = this.handleOnSubmitAgent.bind(this)
    this.onMaskedDateAgent = this.onMaskedDateAgent.bind(this);
    this.convertBBtoCC = this.convertBBtoCC.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.clearAgentForm = this.clearAgentForm.bind(this);
  }

  componentDidMount () {
    this.loadAgentData()
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  handleOnChange(e) {
    e.preventDefault()
    const name = e.target.name
    const value = e.target.value
    this.setState({
      [name]: value
    })
  }

  /*componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.agentSelected !== null) {
      if(prevProps.agentSelected !==  this.props.agentSelected) {
        const agent = this.props.agentSelected
        // customer_birth_mask: this.convertCCtoBB(moment(this.props.customer.customer_birth).format("YYYY-MM-DD")),
        this.setState({
          agent_fullname: agent.agent_fullname,
          agent_address: agent.agent_address,
          agent_card_id: agent.agent_card_id,
          agent_mobile: agent.agent_mobile,
          agent_birth_text: this.convertCCtoBB(moment(agent.agent_birth).format("YYYY-MM-DD")),
          agent_id: agent.agent_id
        })
      }
    } else {
      if(this.props.actionType === 'บันทึก' && prevProps.actionType !==  this.props.actionType) {
        this.setState({
          agent_fullname: '',
          agent_address: '',
          agent_card_id: '',
          agent_mobile: '',
          agent_birth_text: '',
          agent_id: null,
        })
      }
    }
  }*/

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  onRefresh(){
    this.refs.input_search.value = ''
    this.loadAgentData()
  }

  onSearch(){
    var search_text = this.refs.input_search.value

    if(search_text==='') {
      return;
    }

    if(search_text.length < 3){
      return;
    }

    axios.get( APIURL + '/agent/search/'+search_text )
      .then(res => {
        this.setState({agent_list: res.data})
      }).catch(error => {
      this.setState({agent_list: []})
    })
  }

  loadAgentData() {
    axios.get( APIURL + '/agent/').then(res => {
      this.setState({
        agent_list: res.data
      })
    }).catch(error => {

    })
  }

  // onSelectAgent(agent) {

  // }

  handleOnSubmitAgent(e) {
    e.preventDefault()
    const actionType = this.state.actionType
    const agent_fullname = e.target.agent_fullname.value
    // const agent_birth_text = e.target.agent_birth_text.value
    const agent_birth = this.state.startDateAgentNew1
    const agent_address = e.target.agent_address.value
    const agent_birth_text = e.target.agent_birth_text.value
    const agent_card_id = e.target.agent_card_id.value
    const agent_mobile = e.target.agent_mobile.value

    if(agent_fullname === '') {
      AlertWarning('กรุณากรอกชื่อ-นามสกุล');
      return
    }

    /*if(agent_birth_text === '') {
      AlertWarning('กรุณากรอกวันเกิด');
      return
    }*/

    if(agent_mobile === '') {
      AlertWarning('กรุณากรอกเบอร์โทรศัพท์');
      return
    }

    let fileStatus = '';
    if(actionType==='บันทึก') {
      try {
        if (e.target.agent_file.files[0].name === undefined) {
          this.setState({
            w_agent_file: false
          });
          fileStatus = 'nofile'
        } else {
          var filename = e.target.agent_file.files[0].name;
          if (!(/\.(gif|jpg|jpeg|tiff|png)$/i).test(filename)) {
            AlertError('กรุณาเลือกไฟล์ภาพ jpg, jpeg, png')
            this.setState({
              w_agent_file: true
            });
            return
          }
          fileStatus = 'withfile'
        }
      } catch (ex) {
        fileStatus = 'nofile'
      }
    }
    
    let user_created = this.state.user.user;

    const data = new FormData();
    data.append('agent_fullname', agent_fullname);
    data.append('agent_birth', agent_birth);
    data.append('agent_address', agent_address);
    data.append('agent_card_id', agent_card_id);
    data.append('agent_mobile', agent_mobile);
    data.append('user_created', user_created);

    if(actionType==='บันทึก') {
      if(actionType==='บันทึก') {
        if (fileStatus === 'withfile') {
          data.append('file', e.target.agent_file.files[0], e.target.agent_file.files[0].name);
          axios.post(APIURL+'/agent2/file', data)
            .then(res => {
              if(res.status === 200) {
                AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
                // this.props.toggleAgentModal('success')
                this.clearAgentForm()
                this.loadAgentData()
              }
            })
        } else if (fileStatus === 'nofile') {
          let dataNoFile = {
            agent_fullname,
            agent_birth,
            agent_address,
            agent_card_id,
            agent_mobile,
            user_created
          }
          axios.post(APIURL+'/agent2/nofile', dataNoFile)
            .then(res => {
              if(res.status === 200) {
                AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
                // this.props.toggleAgentModal('success')
                this.clearAgentForm()
                this.loadAgentData()
              }
            })
        }

      }
    }

  }

  clearAgentForm() {
    this.setState({
      startDateAgentNew1: moment().format('YYYY-MM-DD'),
      agent_fullname: '',
      agent_address: '',
      agent_card_id: '',
      agent_mobile: '',
      agent_birth_text: '',
      agent_id: null,
      w_agent_file: false,
    });
  }

  handleDateAgent(date) {
    this.setState({
      startDateAgentNew1: moment(date).format('YYYY-MM-DD')
    });
  }

  onMaskedDateAgent (e){
    let buddhist_date = e.target.value ;
    //alert('date='+date);
    if(buddhist_date.length===10){
      let cc_date = this.convertBBtoCC(buddhist_date)
      this.handleDateAgent(cc_date)
    }
  }

  convertBBtoCC(buddhist_date){ //dd-mm-yyyy to yyyy-mm-dd
    let date_arr=buddhist_date.split('-');
    let dd = date_arr[0];
    let mm = date_arr[1];
    let yyyy = date_arr[2]-543;
    let cc_date= yyyy+'-'+mm+'-'+dd ;
    if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
      //alert(cc_date);
      return cc_date ;
    }
    return moment() ;
  }

  render () {

    let width = this.state.width

    const emptyData = <tr><td colSpan={7} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>
    const agent_list = this.state.agent_list.map((agent, index) => {

      let image_url = '';

      if (agent.agent_file===null) {
        image_url = IMAGE_NOPHOTO_URL
      } else {
        if(agent.agent_file.includes('https')) {
          image_url = agent.agent_file;
        } else {
          image_url = IMAGE_FULL_URL+agent.agent_file;
        }
      }

      return (
        <tr key={index}>
          <td className="text-center">{ agent.agent_id }</td>
          <td className="text-nowrap">
            <img
              src={image_url}
              style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
              onClick={()=>{
                if(agent.agent_file !=='')
                  this.onPreviewSelected(image_url)
              }}
              alt="เอกสาร"
            />
          </td>
          <td className="text-nowrap">{ agent.agent_fullname }</td>
          <td className="text-nowrap">{ agent.agent_card_id }</td>
          <td className="text-nowrap">{ agent.agent_address }</td>
          <td className="text-nowrap">{ agent.agent_mobile }</td>
          <td className="text-center" >
            <button className="btn btn-sm btn-success"
                    onClick={() => this.props.toggleAgentModal(false, agent)}
            >
              เลือก
            </button>
          </td>
        </tr>
      )
    })

    const agentList = this.state.agent_list.length > 0 ? agent_list : emptyData
    // toggle={this.props.toggleAgentModal}
    // <i className="fa icon-handbag"></i>

    return (
      <>
        <Modal isOpen={this.props.isAgentModal}
               className={'modal-lg ' + this.props.className}
               style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
        >
          <ModalBody>
            <div className={"card card-accent-primary"}>

              <div className="card-header">
                <strong className="text-title">เลือกตัวแทน</strong>
              </div>

              <div className="card-block">
                <div className="row">
                  <form action="" method="post" onSubmit={this.handleOnSubmitAgent}>
                    <div>

                      <div className="card-block">
                        <div className="row">

                          <div className="form-group col-sm-6">
                            <label htmlFor="agent_name">ชื่อ-นามสกุล*</label>
                            <input type="text"
                                   className="form-control"
                                   id="agent_fullname"
                                   name="agent_fullname"
                                   placeholder=""
                                   onChange={this.handleOnChange}
                                   maxLength={200}
                                   value={this.state.agent_fullname}
                            />
                          </div>

                          <div className="form-group col-sm-6">
                            <label htmlFor="agent_birth_text">วันเดือนปีเกิด (พ.ศ.)</label>
                            <MaskedInput
                                mask="11-11-1111"
                                name="agent_birth_text"
                                placeholder="dd-mm-yyyy"
                                onChange={this.onMaskedDateAgent}
                                className="form-control"
                                value={this.state.agent_birth_text}
                            />
                          </div>

                          <div className="form-group col-sm-12">
                            <label htmlFor="agent_address">ที่อยู่ปัจจุบัน</label>
                            <input type="text"
                                   className="form-control"
                                   id="agent_address"
                                   name="agent_address"
                                   placeholder=""
                                   maxLength={250}
                                   value={this.state.agent_address}
                                   onChange={this.handleOnChange}
                            />
                          </div>

                          <div className="form-group col-sm-6">
                            <label htmlFor="agent_card_id">เลขบัตรประชาชน</label>
                            {/*<input type="text"
                                   className="form-control hidden"
                                   id="agent_card_id_old-bk"
                                   name="agent_card_id_old-bk"
                                // maxLength="13"
                                   placeholder=""
                                   value={this.state.agent_card_id}
                                   onChange={this.handleOnChange}
                            />*/}

                            <MaskedInput
                                mask="1-1111-11111-11-1"
                                className="form-control"
                                id="agent_card_id"
                                name="agent_card_id"
                                maxLength="20"
                                placeholder=""
                                value={this.state.agent_card_id}
                                onChange={this.handleOnChange}
                            />
                          </div>

                          <div className="form-group col-sm-6">
                            <label htmlFor="agent_mobile">เบอร์โทรศัพท์*</label>
                            {/*<input type="text"
                                   className="form-control hidden"
                                   id="agent_mobile_old-bk"
                                   name="agent_mobile_old-bk"
                                   placeholder=""
                                // maxLength={20}
                                // value={this.state.agent_mobile}
                                // onChange={this.handleOnChange}
                            />*/}

                            <MaskedInput
                                mask="111-111-1111"
                                className="form-control"
                                id="agent_mobile"
                                name="agent_mobile"
                                placeholder=""
                                maxLength={20}
                                value={this.state.agent_mobile}
                                onChange={this.handleOnChange}
                            />
                          </div>

                          <div className="form-group col-md-12">
                            <label htmlFor="agent_file">ภาพนายหน้า </label>
                            <input type="file"
                                   id="agent_file"
                                   name="agent_file"
                                   accept="image/*"
                                   className="form-control"
                                   onChange={(e)=>{
                                     const { target } = e
                                     if(target.value.length > 0){
                                       this.setState({
                                         w_agent_file: false
                                       });
                                     } else {
                                       //target.reset();
                                       this.setState({
                                         w_agent_file: true
                                       });
                                     }

                                   }}
                            />
                            { this.state.w_agent_file ? <span  className="help-block text-danger">กรุณาเลือกไฟล์ ประเภท ( .jpg  .jpeg .png )</span>: null }

                          </div>

                        </div>
                      </div>

                      <div className="card-footer text-right">

                        <button type="submit" className={this.state.actionType==="แก้ไข"? "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}>
                          <i className={this.state.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/> {this.state.actionType}</button>

                      </div>
                    </div>
                  </form>


                  <div className="col-12 mt-4">

                      <input
                        onChange={this.onSearch.bind(this)}
                        ref="input_search"
                        style={styles.inputSearch}
                        className="form-control"
                        placeholder="ค้นหาชื่อหรือเบอร์โทรศัพท์นายหน้าที่เคยลงทะเบียนไว้ ถ้าเป็นนายหน้าใหม่ให้กรอกข้อมูลด้านล่าง"
                        type="text"
                      />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                      <button className="btn btn-head-bar"
                              style={styles.buttonHeight}
                              onClick={this.onRefresh.bind(this)}
                      >
                        <i className="icon-refresh"/>
                      </button>

                  </div>

                  <div className="col-12 mt-4">
                    <table className="table table-striped table-responsive">
                      <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-nowrap">รูปภาพ</th>
                        <th className="text-nowrap">ชื่อ-นามสกุล</th>
                        <th className="text-nowrap">เลขประจำตัวประชาชน</th>
                        <th className="text-nowrap">ที่อยู่ปัจจุบัน</th>
                        <th className="text-nowrap">เบอร์โทรศัพท์</th>
                        <th className="text-center">เลือก</th>
                      </tr>
                      </thead>
                      <tbody>
                      {agentList}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="card-footer text-right">
                <button type="button" onClick={() => this.props.toggleAgentModal(false, null)}
                        className="btn btn-sm btn-danger">
                  &nbsp;<i className="fa fa-close"/> &nbsp;&nbsp;ปิด&nbsp;&nbsp;
                </button>
              </div>

            </div>
          </ModalBody>
        </Modal>
      </>
    )
  }

}

const styles = {
  inputSearch: {
    marginLeft: 0,
    width: 600,
    display: 'inline'
  },

  buttonHeight: {
    height: 36,
    paddingTop:0,
    paddingBottom:0,
    display: 'inline',
    marginTop: '-0.35rem'
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(AgentModal)
