import React , { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import axios from 'axios';
// import { APIURL } from '../../../config/config';
import { checkGroupPermission, getGroupPermission } from '../../services/grouppermission'
import Loading from '../Loading'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import TaxDocument from './TaxDocument'

class TaxDocumentTabs extends Component{
  constructor (props) {
    super(props)
    this.state = {
      menuID: [3701, 3702, 3703, 3704, 3705, 3706],
      grouppermissions: [],
      user: JSON.parse(this.props.user),
      activeTab: '1',
      isLoading: false
    }

    this.loadGroupPermission = this.loadGroupPermission.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount () {
    this.loadGroupPermission()
  }

  loadGroupPermission() {
    let type = this.state.user.type
    let menuID = this.state.menuID
    // console.log('type=', type)
    // console.log('menuID=', menuID)
    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render () {

    return (
      <div className="col-md-12 mb-4">
        <Loading isLoading={this.state.isLoading} />

        <Nav tabs>

          {
            checkGroupPermission(3701, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  <i className="icon-options-vertical"/> ภพ.30
                </NavLink>
              </NavItem>
            ) : null
          }

          {
            checkGroupPermission(3702, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  <i className="icon-options-vertical"/> ภงด.3
                </NavLink>
              </NavItem>
            ) : null
          }

          {
            checkGroupPermission(3703, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}
                >
                  <i className="icon-options-vertical"/> ภงด.53
                </NavLink>
              </NavItem>
            ) : null
          }

          {
            checkGroupPermission(3704, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}
                >
                  <i className="icon-options-vertical"/> ภงด.1ก
                </NavLink>
              </NavItem>
            ) : null
          }

          {
            checkGroupPermission(3705, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '5' })}
                  onClick={() => { this.toggle('5'); }}
                >
                  <i className="icon-options-vertical"/> เงินสมทบประกันสังคม
                </NavLink>
              </NavItem>
            ) : null
          }

          {
            checkGroupPermission(3706, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '6' })}
                  onClick={() => { this.toggle('6'); }}
                >
                  <i className="icon-options-vertical"/> เงินเดือน
                </NavLink>
              </NavItem>
            ) : null
          }

        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            {
              this.state.activeTab === '1' && <TaxDocument
                tax_doc_type_id={1}
                grouppermissions={this.state.grouppermissions}
                menu_id={3701}
              />
            }
          </TabPane>

          <TabPane tabId="2">
            {
              this.state.activeTab === '2' &&  <TaxDocument
                tax_doc_type_id={2}
                grouppermissions={this.state.grouppermissions}
                menu_id={3702}
              />
            }
          </TabPane>

          <TabPane tabId="3">
            {
              this.state.activeTab === '3' && <TaxDocument
                tax_doc_type_id={3}
                grouppermissions={this.state.grouppermissions}
                menu_id={3703}
              />
            }
          </TabPane>

          <TabPane tabId="4">
            {
              this.state.activeTab === '4' && <TaxDocument
                tax_doc_type_id={4}
                grouppermissions={this.state.grouppermissions}
                menu_id={3704}
              />
            }
          </TabPane>

          <TabPane tabId="5">
            {
              this.state.activeTab === '5' && <TaxDocument
                tax_doc_type_id={5}
                grouppermissions={this.state.grouppermissions}
                menu_id={3705}
              />
            }
          </TabPane>

          <TabPane tabId="6">
            {
              this.state.activeTab === '6' && <TaxDocument
                tax_doc_type_id={6}
                grouppermissions={this.state.grouppermissions}
                menu_id={3706}
              />
            }
          </TabPane>

        </TabContent>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(TaxDocumentTabs)

