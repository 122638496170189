/**
 * Created by devmaster on 7/15/2017 AD.
 */

import React, { Component } from 'react'

export  default class BankListRow extends Component{

  render(){

      let bankList;

      if(this.props.bank_list.length === 0){
       bankList = <tr><td colSpan={3} className="text-center"><span className="text-danger">ไม่พบข้อมูล</span></td></tr>
    }else {

       bankList = this.props.bank_list.map((bank, index) => {
        return (
          <tr key={index}>
            <td>{ index+1 }</td>
            <td className="text-nowrap" style={{width: 80}}>{ bank.bank_name }</td>
            <td className="text-nowrap" style={{minWidth: 200, wordWrap: 'break-word'}} >
              <div style={{width: 200, wordWrap: 'break-word', overflowX: 'auto'}}>
                { bank.bank_name_full } <br/>
                { bank.bank_address_line1 } <br/>
                { bank.bank_address_line2 } <br/>
                { bank.bank_tax_id }
              </div>
            </td>
            <td className="text-right">
                <button className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                        style={styles.button}
                        onClick={this.props.bankCallbacks.edit.bind(null,bank)}
                >
                    <i className="fa fa-edit"/> แก้ไข
                </button>
                <button className="btn btn-sm btn-danger btn-md-width mr-2 mb-2"
                        style={styles.button}
                        onClick={this.props.bankCallbacks.delete.bind(null,bank)}
                >
                  <i className="fa fa-remove"/> ลบ
                </button>
            </td>
          </tr>
        )
      });
    }

    return (

          <table className="table table-striped table-responsive">
            <thead>
            <tr>
              <th className="text-left">#</th>
              <th className="text-left">ชื่อธนาคาร</th>
              <th className="text-center">ข้อมูลธนาคาร</th>
              <th className="text-center">จัดการ</th>
            </tr>
          </thead>
          <tbody>
            { bankList }
          </tbody>
          </table>

    );
  }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    }
}