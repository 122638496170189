import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap'
import { AlertError, AlertSuccess, AlertWarning } from '../Alert/Alert'
import axios from 'axios'
import { APIURL } from '../../config/config'

class IncomeExpenseItemForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      // width: 0,
      // height: 0,
      income_expense_item_id: null,
      income_expense_item_name: '',
      user: {},
    }

    // this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);

  }


  // updateWindowDimensions() {
  //   this.setState({ width: window.innerWidth, height: window.innerHeight });
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.updateWindowDimensions);
  // }

  componentDidMount () {
    // this.updateWindowDimensions();
    // window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillMount () {
    let user = JSON.parse(this.props.user);
    this.setState({
      user: user ,
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.incomeExpenseSelected !== null) {
      if(prevProps.incomeExpenseSelected !==  this.props.incomeExpenseSelected) {
        const item = this.props.incomeExpenseSelected
        this.setState({
          income_expense_item_id: item.income_expense_item_id,
          income_expense_item_name: item.income_expense_item_name
        })
      }
    } else {
      if(this.props.actionType === 'บันทึก' && prevProps.actionType !==  this.props.actionType) {
        this.setState({
          income_expense_item_id: null,
          income_expense_item_name: ''
        })
      }
    }
  }

  handleOnSubmit(e) {
    e.preventDefault()
    const actionType = this.props.actionType
    const income_expense_item_name = e.target.income_expense_item_name.value

    if(income_expense_item_name === '') {
      AlertWarning('กรุณากรอกชื่อรายการ');
      return
    }

    if(actionType==='บันทึก') {
      let username = this.state.user.user

      let dataAdd = {
        income_expense_item_name,
        username
      }

      axios.post(APIURL + '/income_expense_item/add', dataAdd)
        .then(res => {
          let affectedRows = res.data.affectedRows
          if (affectedRows === 1) {
            AlertSuccess('เพิ่มเรียบร้อยแล้ว')
            this.props.toggleModal()
            this.props.onReload()
          }
        }).catch(error => {
      })

    } else if (actionType==='แก้ไข') {
      const income_expense_item_id = this.state.income_expense_item_id

      let dataEdit = {
        income_expense_item_id,
        income_expense_item_name
      }

      axios.post(APIURL + '/income_expense_item/item_name', dataEdit)
        .then(res => {
          let affectedRows = res.data.affectedRows
          if (affectedRows === 1) {
            AlertSuccess('แก้ไขเรียบร้อยแล้ว')
            this.props.toggleModal()
            this.props.onReload()
          }
        }).catch(error => {
      })


    }

  }

  render () {

    // let width = this.state.width

    return (
      <>
        <Modal isOpen={this.props.isOpenModal}
               toggle={this.props.toggleModal}
               className={'modal-sm ' + this.props.className}
               style={{maxWidth:400}}
        >
          <ModalBody>
            <form action="" method="post" onSubmit={this.handleOnSubmit}>
              <div className={this.props.actionType === 'แก้ไข' ? "card card-accent-warning" : "card card-accent-primary" }>

                <div className="card-header">
                  <strong className="text-title">{this.props.actionType}ข้อมูลหมวดรายรับ/ค่าใช้จ่าย</strong>
                </div>

                <div className="card-block">
                  <div className="row">

                    <div className="form-group col-sm-12">
                      <label htmlFor="agent_name">ชื่อรายการ</label>
                      <input type="text"
                             className="form-control"
                             id="income_expense_item_name"
                             name="income_expense_item_name"
                             placeholder=""
                             onChange={(e) => {
                               this.setState({
                                 income_expense_item_name: e.target.value
                               })
                             }}
                             maxLength={45}
                             width={180}
                             value={this.state.income_expense_item_name}
                      />
                    </div>

                  </div>
                </div>

                <div className="card-footer text-right">

                  <button type="button" onClick={() => this.props.toggleModal()} className="btn btn-sm btn-danger mr-2">
                    <i className="fa fa-refresh"/> ยกเลิก</button>

                  <button type="submit" className={this.props.actionType==="แก้ไข"? "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}>
                    <i className={this.props.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/> {this.props.actionType}</button>

                </div>


              </div>
            </form>
          </ModalBody>

        </Modal>
      </>
    )
  }

}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(IncomeExpenseItemForm)