export const MonthUtil = {
   convertMonthYear(month_year) {
     let splitData = month_year.split('-');
     let month = parseInt(splitData[0], 10);
     let year = splitData[1];
     let yearBudd = parseInt(year, 10) + 543
     let monthName = ''
     switch (month) {
       case 1: monthName = 'มกราคม'; break;
       case 2: monthName = 'กุมภาพันธ์'; break;
       case 3: monthName = 'มีนาคม'; break;
       case 4: monthName = 'เมษายน'; break;
       case 5: monthName = 'พฤษภาคม'; break;
       case 6: monthName = 'มิถุนายน'; break;
       case 7: monthName = 'กรกฎาคม'; break;
       case 8: monthName = 'สิงหาคม'; break;
       case 9: monthName = 'กันยายน'; break;
       case 10: monthName = 'ตุลาคม'; break;
       case 11: monthName = 'พฤศจิกายน'; break;
       case 12: monthName = 'ธันวาคม'; break;
       default:
         monthName = ''
     }

     return monthName+' '+yearBudd
   },
   convertMonthThai(month) {
    // let splitData = month_year.split('-');
    // let month = parseInt(splitData[0], 10);
    // let year = splitData[1];
    // let yearBudd = parseInt(year, 10) + 543
    let monthName = ''
    switch (month) {
      case 1: monthName = 'มกราคม'; break;
      case 2: monthName = 'กุมภาพันธ์'; break;
      case 3: monthName = 'มีนาคม'; break;
      case 4: monthName = 'เมษายน'; break;
      case 5: monthName = 'พฤษภาคม'; break;
      case 6: monthName = 'มิถุนายน'; break;
      case 7: monthName = 'กรกฎาคม'; break;
      case 8: monthName = 'สิงหาคม'; break;
      case 9: monthName = 'กันยายน'; break;
      case 10: monthName = 'ตุลาคม'; break;
      case 11: monthName = 'พฤศจิกายน'; break;
      case 12: monthName = 'ธันวาคม'; break;
      default:
        monthName = ''
    }

    return monthName
  },
   convertMonthThaiToAbb(month_name) {
     let monthName = ''
     switch (month_name) {
       case 'มกราคม': monthName = 'ม.ค.'; break;
       case 'กุมภาพันธ์': monthName = 'ก.พ.'; break;
       case 'มีนาคม': monthName = 'มี.ค.'; break;
       case 'เมษายน': monthName = 'เม.ย.'; break;
       case 'พฤษภาคม': monthName = 'พ.ค.'; break;
       case 'มิถุนายน': monthName = 'มิ.ย.'; break;
       case 'กรกฎาคม': monthName = 'ก.ค.'; break;
       case 'สิงหาคม': monthName = 'ส.ค.'; break;
       case 'กันยายน': monthName = 'ก.ย.'; break;
       case 'ตุลาคม': monthName = 'ต.ค.'; break;
       case 'พฤศจิกายน': monthName = 'พ.ย.'; break;
       case 'ธันวาคม': monthName = 'ธ.ค.'; break;
       default:
         monthName = ''
     }
     return monthName
  }
}
