import React, {Component} from 'react'
import axios from 'axios';
import { APIURL } from '../../config/config';
import SelectSearch from 'react-select-search'
import { AlertWarning } from '../Alert/Alert'
import Loading from '../Loading'

class HistorySparePart extends Component {

  constructor (props) {
    super(props);
    this.state = {
      spare_parts: [],
      spare_part_id: 0,
      isLoading: false,
      //
      transactions: [],
      spare_part: null,
      retailers: [],
      retailer_id: 0,
      search_type: ''
    }
    this.loadRetailerData=this.loadRetailerData.bind(this);
    this.loadSpareParts=this.loadSpareParts.bind(this);
    this.loadSparePartTransaction=this.loadSparePartTransaction.bind(this);

  }

  componentDidMount () {
    // let user = this.props.user
    this.loadRetailerData()
    this.loadSpareParts()
  }

  loadRetailerData() {
    axios.get(APIURL + '/retailer/')
      .then(res => {
        this.setState({
          retailers: res.data
        })
      })
  }

  loadSpareParts() {
    this.setState({
      isLoading: true
    }, () => {
      axios.get(APIURL+'/spare_part/list').then(res => {
          this.setState({spare_parts: res.data,  isLoading: false})
      }).catch(error => {
          this.setState({spare_parts: [],  isLoading: false})
      })
    })
  }

  loadSparePartTransaction() {
    let spare_part_id = this.state.spare_part_id
    let retailer_id = this.state.retailer_id

    if (spare_part_id === 0 && retailer_id === 0) {
      AlertWarning('กรุณาเลือกข้อมูล');
      return
    }

    this.setState({
      isLoading: true
    }, () => {

      let dataReq  = {
        spare_part_id,
        retailer_id
      }

      if (spare_part_id !== 0 && retailer_id === 0) {
        // ตามรายการอะไหล่ที่เลือก
        axios.get(APIURL+'/spare_part/transaction/'+spare_part_id).then(res => {
          let spare_part = res.data.spare_part
          let transactions = res.data.transactions

          this.setState({
            transactions: transactions,
            isLoading: false,
            spare_part: spare_part,
            search_type: 'by_spare_part'
          });

        }).catch(error => {
          this.setState({transactions: [],  isLoading: false})
        })

      } else {
        // ตามร้านอะไหล่
        axios.post(APIURL+'/spare_part/transaction/criteria', dataReq).then(res => {

          let spare_part = res.data.spare_part
          let transactions = res.data.transactions
          // className={search_type==='by_retailer' ? '' : 'hidden'}

          this.setState({
            transactions: transactions,
            isLoading: false,
            spare_part: spare_part,
            search_type: 'by_retailer'
          });

        }).catch(error => {
          this.setState({transactions: [],  isLoading: false})
        })
      }
     //  axios.get(APIURL+'/spare_part/transaction/'+spare_part_id).then(res => {

    });
  }

  render () {

    let spare_part = this.state.spare_part
    let search_type = this.state.search_type
    let transactions = this.state.transactions

    let emptyRow = <tr><td colSpan={9} className='text-center text-danger'>ไม่พบข้อมูล</td></tr>
    let transactionRows = transactions.map((spare, index) => {

     let car_license_plate_new = spare.car_license_plate_new
     let car_license_plate_old = spare.car_license_plate_old
      let car_license_plate = car_license_plate_new === '' || car_license_plate_new === '-' ? car_license_plate_old : car_license_plate_new

      return <tr key={index}>
        <td>{index+1}</td>
        <td className={search_type==='by_retailer' ? '' : 'hidden'} >{spare.name}</td>
        <td>{spare.stock_type}</td>
        <td>{spare.stock_date}</td>
        <td>{spare.note}</td>
        <td>{spare.quantity}</td>
        <td>{spare.use_for}</td>
        <td>{car_license_plate}</td>
        <td>{spare.requestor}</td>
        <td>{spare.user_created}</td>
        <td>{spare.created_at}</td>
      </tr>
    })

    let tableRows = transactions.length === 0 ? emptyRow : transactionRows

    return (
      <div className="row">

        <Loading isLoading={this.state.isLoading} />

        <div className="col-lg-12">
          <div className="card card-accent-warning">
            <div className="card-header card-sparepart">
              <strong className="text-title">
                รายการซื้อเข้า-เบิก
              </strong>
            </div>
            <div className="card-block">
              <div className='row  mb-2'>
                <div className="col-1 text-right pt-1">
                  เลือกอะไหล่
                </div>
                <div className="col-4 form-inline">
                  <SelectSearch
                    options={this.state.spare_parts}
                    value={this.state.spare_part_id}
                    search={true}
                    name="spare_part_id"
                    placeholder="เลือกอะไหล่"
                    onChange={(val) => {

                      /*let spare_parts = this.state.spare_parts
                      let spare_part = spare_parts.filter(item => item.id === val)
                      let name = spare_part[0].name

                      let is_negative = false
                      if( name.trim()==='ค่า หัก ณ ที่จ่าย'){
                        is_negative = true
                      }*/
                      if (val!=='0') {
                        this.setState({
                          retailer_id: 0,
                          spare_part_id: val,
                        })
                      } else {
                        this.setState({
                          spare_part_id: val,
                        })
                      }

                    }}
                  />
                </div>
                <div className="col-3 form-inline">
                  <select className="form-control"
                          id="retailer_id"
                          name="retailer_id"
                          value={this.state.retailer_id}
                          style={{width: '100%'}}
                          onChange={(e) => {
                            let val = e.target.value
                            if (val!=='0') {
                              this.setState({
                                spare_part_id: 0,
                                retailer_id: val
                              })
                            } else {
                              this.setState({
                                retailer_id: val
                              })
                            }

                          }}
                  >
                    <option  value='0'>กรุณาเลือกร้านค้า</option>
                    {
                      this.state.retailers.map((retailer, index) => (
                        <option key={index} value={retailer.retailer_id}>{retailer.retailer_name}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-1 text-right pt-1">
                  <button
                    className="btn btn-sm btn-head-bar mr-1 ml-2"
                    onClick={() => this.loadSparePartTransaction()}
                    style={styles.buttonHeight}
                  >
                    ตกลง
                  </button>
                </div>
              </div>

              {
                this.state.spare_part === null ? null :
                  <>
                  <hr/>
                  <div className="row mb-2 bg-success ml-1 mr-1 p-2">

                    <div className="col-4">
                      ชื่ออะไหล่ : {spare_part.name}
                    </div>
                    <div className="col-4">
                      จำนวน {spare_part.quantity_in} {spare_part.unit}
                      &nbsp;&nbsp;&nbsp;
                      ต้นทุน {spare_part.cost} บาท
                      &nbsp;&nbsp;&nbsp;
                      ราคา {spare_part.price} บาท

                    </div>
                    <div className="col-2">
                      ร้านค้า : {spare_part.retailer_name === null ? '-' : spare_part.retailer_name}
                    </div>

                  </div>
                  </>
              }

              <div className='row  mb-2'>
                <div className="col-12">
                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <td>#</td>
                      <td className={search_type==='by_retailer' ? 'text-center text-nowrap' : 'hidden' } >ชื่ออะไหล่</td>
                      <td className="text-center text-nowrap">ประเภท</td>
                      <td className="text-center text-nowrap">วันที่</td>
                      <td className="text-center">รายการ</td>
                      <td className="text-center text-nowrap">จำนวน</td>
                      <td className="text-center text-nowrap">ใช้สำหรับ</td>
                      <td className="text-center text-nowrap">ทะเบียนรถ(ถ้ามี)</td>
                      <td className="text-center text-nowrap">ผู้ขอเบิก</td>
                      <td className="text-center text-nowrap">ผู้ทำรายการ</td>
                      <td className="text-center text-nowrap">ทำรายการเมื่อ</td>
                    </tr>
                    </thead>
                    <tbody>
                    { tableRows }
                    </tbody>
                  </table>
                </div>
              </div>




            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  buttonHeight: {
    height: 36,
    paddingTop: 0,
    paddingBottom:0,
    borderRadius: 5,
    display: 'inline',
    marginTop: -1,
    marginBottom: 0,
  }
}

export default HistorySparePart
