import React, {Component} from "react";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import CarReservationList from "./CarReservationList";
import CarReservationOutstanding from "./CarReservationOutstanding";
import CarReservationCalendar from "./CarReservationCalendar";
import {connect} from "react-redux";
import {checkGroupPermission, getGroupPermission} from "../../../services/grouppermission";

class  CarReservationTabs extends Component{
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            user: JSON.parse(this.props.user),
            menuID: [1, 18, 23,101, 104, 701, 702, 703, 704],
            grouppermissions: [],
        }
        this.toggleTab = this.toggleTab.bind(this)
        this.loadGroupPermission = this.loadGroupPermission.bind(this);
    }

    /*componentWillMount () {
        let user = JSON.parse(this.props.user);
        this.setState({
            user: user ,
            user_type : user.type
        })
    }*/

    componentDidMount() {
        this.loadGroupPermission()
    }

    loadGroupPermission() {
        let type = this.state.user.type
        let menuID = this.state.menuID
        getGroupPermission(type, menuID).then(res => {
            if(res.data.result === 'success') {
                this.setState({
                    grouppermissions: res.data.grouppermissions
                })
            }
        })
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {

        return (
            <div className="col-md-12 mb-4">
                <Nav tabs>

                    {
                        checkGroupPermission(701, this.state.grouppermissions).viewed ? (
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggleTab('1'); }}
                                >
                                    <i className="icon-options-vertical"/> ข้อมูลการจอง
                                </NavLink>
                            </NavItem>
                        ) : null
                    }

                    {
                        checkGroupPermission(702, this.state.grouppermissions).viewed ? (
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggleTab('2'); }}
                                >
                                    <i className="icon-calendar"/> ปฏิทินวันที่ออกรถ
                                </NavLink>
                            </NavItem>
                        ) : null
                    }

                    {
                        checkGroupPermission(703, this.state.grouppermissions).viewed ? (
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '3' })}
                                    onClick={() => { this.toggleTab('3'); }}
                                >
                                    <i className="icon-check"/> การจองคงค้าง(รถขายแล้ว)
                                </NavLink>
                            </NavItem>
                        ) : null
                    }

                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <CarReservationList
                            grouppermissions ={this.state.grouppermissions}
                        />
                    </TabPane>
                    <TabPane tabId="2">
                        <CarReservationCalendar
                            user={this.state.user}
                            grouppermissions ={this.state.grouppermissions}
                        />
                    </TabPane>
                    <TabPane tabId="3">
                        <CarReservationOutstanding
                            grouppermissions ={this.state.grouppermissions}
                        />
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    type: state.auth.type,
    token: state.auth.token,
    account_branch_id: state.auth.account_branch_id,
    isAuthenticated: state.auth.isAuthenticated
})

// export default CarReservationTabs
export default connect(mapStateToProps)(CarReservationTabs)
