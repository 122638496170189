import React, { Component, useState } from 'react'
import DebtorsListRow from '../DebtorsListRow/DebtorsListRow'
import DebtorsForm from '../DebtorsForm/DebtorsForm'
import axios from 'axios'
import { APIURL } from '../../../config/config'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { connect } from 'react-redux'
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import moment from 'moment/moment'
import { Link } from 'react-router-dom'
import { AlertError, AlertSuccess } from '../../Alert/Alert'
import Loading from '../../Loading'
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";
import DocUtil from '../../../utils/docUtil'
import { LineNotify } from '../../../config/lineNotify'
import { NumberUtil } from '../../../utils/number-util'
import { getGroupPermission } from '../../../services/grouppermission'
import Swal from 'sweetalert2'

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}


function DebtorBankEdit({ is_edit, bank_list, debtorEdit, onToggle, onSave }) {

  const [bank_id, setBankId] = useState(debtorEdit.bank_id);

  return (
    <Modal isOpen={is_edit} toggle={onToggle}
           className={'modal-warning '}>
      <ModalHeader toggle={onToggle}>แก้ไขข้อมูล: ช่องทางการรับชำระ</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            รายการ: {debtorEdit.debtors_name}
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12 ">
            ช่องทางการรับชำระ(ธนาคาร): {debtorEdit.bank_name === null ? '-' : debtorEdit.bank_name}
            <select className="form-control"
                    id="bank_id"
                    name="bank_id"
                    defaultValue={bank_id}
                    onChange={(e) => {
                      let val = Number(e.target.value)
                      setBankId(val)
                    }}
            >
              <option value={0}>ไม่ระบุ</option>
              {
                bank_list.map((bank, index) => {

                  // if (bank.bank_id === bank_id) {
                  // return <option key={index} value={bank.bank_id} selected={true}>{bank.bank_name}</option>
                  // }
                  return <option key={index} value={bank.bank_id}>{bank.bank_name}</option>

                })
              }
            </select>
          </div>
        </div>

      </ModalBody>
      <ModalFooter>

        <Button color="danger" onClick={onToggle}>ยกเลิก</Button>
        {' '}
        <Button color="warning"
                onClick={() => {
                  if (debtorEdit.bank_id === bank_id ) {
                    AlertError('กรุณาเลือกธนาคาร')
                  } else {
                    let data = {
                      debtors_id: debtorEdit.debtors_id,
                      bank_id: bank_id
                    }
                    onSave(data)
                  }
                }}
        >แก้ไข</Button>

      </ModalFooter>
    </Modal>
  )
}

function DebtorStatusEdit({is_edit, debtorEdit, debt_status_list, onToggle, onSave}) {

  const [debt_status, setDebtStatus] = useState(debtorEdit.debt_status);
  const [debt_status_note, setDebtStatusNote] = useState(debtorEdit.debt_status_note === null ? '' : debtorEdit.debt_status_note);

  return (
    <Modal isOpen={is_edit}
           toggle={onToggle}
           className={'modal-warning '}>
      <ModalHeader toggle={onToggle}>แก้ไขข้อมูล: สถานะหนี้ </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            รายการ: {debtorEdit.debtors_name}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 ">
            สถานะหนี้: {debtorEdit.debt_status === null ? '-' : DocUtil.getDebtStatusName(debtorEdit.debt_status)}
          </div>
          <div className="col-12 mt-2">
            <select className="form-control "
                    name="debt_status"
                    value={debt_status}
                    onChange={(e) => {
                      let val = e.target.value
                      setDebtStatus(val)
                    }}
            >
              <option value={0}>ไม่ระบุ</option>
              {
                debt_status_list.map((bank, index) => {

                  // if (bank.bank_id === bank_id) {
                  // return <option key={index} value={bank.bank_id} selected={true}>{bank.bank_name}</option>
                  // }
                  return <option key={index} value={bank.debt_status}>{bank.debt_status_name}</option>

                })
              }
            </select>
          </div>

          <div className="form-group col-12 mt-2">
            <label htmlFor="debt_status_note">หมายเหตุ</label>
            <input type="text"
                   className="form-control"
                   name="debt_status_note"
                   placeholder=""
                   maxLength={255}
                   value={debt_status_note}
                   onChange={(e)=>{
                     let val = e.target.value
                     setDebtStatusNote(val)
                   }}
            />
          </div>

        </div>

      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onToggle}>ยกเลิก</Button>
        {' '}
        <Button color="warning"
                onClick={() => {
                  if (debtorEdit.debt_status === debt_status && debt_status_note === '' ) {
                    AlertError('กรุณาเลือกสถานะ')
                  } else {
                    let data = {
                      debtors_id: debtorEdit.debtors_id,
                      debt_status: debt_status,
                      debt_status_note: debt_status_note,
                    }
                    onSave(data, debtorEdit, debt_status_list)
                  }
                }}
        >แก้ไข</Button>

      </ModalFooter>
    </Modal>
  )
}

const port = Number(window.env.PORT);

class DebtorsList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: {},
      user_type: '',
      lists: [],
      load_data: true,
      danger: false,
      toggle_debtors_add: false,
      paymentdetail: false,
      selectCustomer: '',
      customer_id: 0,
      debtors_id: 0,
      action: 'บันทึก',
      customer: '',
      debtors: '',
      car_id: '',
      car_sell_id: '',
      car: '',
      width: 0,
      height: 0,
      is_edit: false,
      debtor_edit: null,
      bank_list: [],
      isLoading: false,
      debtors_type_id: '',
      debtors_name: '',
      amount_of_debt: '',
      debtors_type : [],
      is_debtor_status_edit: false,
      debtor_status_edit: null,
      debt_status_normal: 0,
      debt_status_npl: 0,
      debt_status_request: 0,
      debt_status_complete: 0,
      debt_status_list: [],
      payData: null,
      menuID: [1001, 1002, 1003, 1004],
      grouppermissions: [],
    }
    this.toggleDebtors = this.toggleDebtors.bind(this);
    this.togglePaymentDetail = this.togglePaymentDetail.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.loadBankData = this.loadBankData.bind(this);
    this.onDebtorEditToggle = this.onDebtorEditToggle.bind(this);
    this.onDebtorStatusEditToggle = this.onDebtorStatusEditToggle.bind(this);
    this.onDebtorEditSave = this.onDebtorEditSave.bind(this);
    this.loadCarById = this.loadCarById.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.loadDebtorType = this.loadDebtorType.bind(this);
    this.debtStatusEdit = this.debtStatusEdit.bind(this);
    this.onDebtorStatusEditSave = this.onDebtorStatusEditSave.bind(this);
    this.loadGroupPermission = this.loadGroupPermission.bind(this);
    this.debtStatusCompleteEdit = this.debtStatusCompleteEdit.bind(this);
  }

  onDebtorEditToggle() {
    let is_edit = !this.state.is_edit

    if (is_edit === true) {
      this.setState({
        is_edit: is_edit,
      })
    } else {
      this.setState({
        is_edit: is_edit,
        debtor_edit: null
      })
    }
  }

  onDebtorStatusEditToggle() {
    let is_edit = !this.state.is_debtor_status_edit
    if (is_edit === true) {
      this.setState({
        is_debtor_status_edit: is_edit,
      })
    } else {
      this.setState({
        is_debtor_status_edit: is_edit,
        debtor_status_edit: null
      })
    }
  }

  onDebtorEditSave(data) {
    this.setState({
      isLoading: true
    }, () => {
      let uri = APIURL+'/debtors/bank'
      axios.put(uri, data).then(res => {
        let resData = res.data

        if (resData === 'success') {
          AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว')
          this.loadDebtorsData();
          this.setState({
            isLoading: false,
            is_edit: false,
            debtor_edit: null
          })
        }
      }).catch(error => {
        console.log('error: ' + error)
      })
    })
  }


  onDebtorStatusEditSave(data, debtorEdit, debt_status_list) {
    this.setState({
      isLoading: true
    }, () => {

      // let debt_status_list = this.state.debt_status_list
      let debt_status = data.debt_status

      if (debt_status === 'request') {
        // send to line notify
        let payData = this.state.payData
        let debt_status_select = debt_status_list.filter(debt => {
          return debt.debt_status === debt_status
        })
        let debt_status_name = debt_status_select[0].debt_status_name
        let debtors_name = debtorEdit.debtors_name
        let debtors_type_name = debtorEdit.debtors_type_name
        let customer_mobile = debtorEdit.customer_mobile
        let fullname = debtorEdit.customer_name+' '+debtorEdit.customer_lastname

        let message = '⭐️ รายการ'+debt_status_name+' ⭐\n'+
          '✔ หัวข้อ : ' + debtors_name +'\n' +
          '✔ ประเภทหนี้ : ' + debtors_type_name +'\n' +
          '✔ ลูกค้า : ' + fullname +'\n' +
          '✔ เบอร์โทร : ' + customer_mobile +'\n' +
          '✔ ยอดค้างชำระ : ' + NumberUtil.addCommasZeroInt(String(payData.remain)) +'\n' +
          '✔ ยอดเงินที่ชำระแล้ว : ' + NumberUtil.addCommasZeroInt(String(payData.amount_pay_sum)) +'\n' +
          '✔ ยอดหนี้ : ' + NumberUtil.addCommasZeroInt(String(payData.amount_of_debt))

        LineNotify.sendDebtPayNotify(message)
      }

      let uri = APIURL+'/debtors/debt_status'
      // data
      axios.put(uri, data).then(res => {
        let resData = res.data
        if (resData === 'success') {
          AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
          this.loadDebtorsData();
          this.setState({
            isLoading: false,
            is_debtor_status_edit: false,
            debtor_status_edit: null,
            payData: null
          });
        }
      }).catch(error => {
        console.log('error: ' + error)
      })
    })
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentWillMount () {
    // const queryString = require('query-string');
    let user = JSON.parse(this.props.user);
    this.setState({
      user: user ,
      user_type : user.type
    })
  }

  componentDidMount () {
    CarsUtil.removeCarSelect()
    CarSellUtil.removeCarSellSelect()

    this.mounted = true
    const queryString = require('query-string')
    let query = queryString.parse(this.props.location.search)

    this.loadGroupPermission();

    let debt_status_list = DocUtil.getDebtStatusList(this.state.user_type)
    // console.log('user_type=', this.state.user_type)
    this.setState({
      debt_status_list: debt_status_list
    })

    if (Object.keys(query).length > 0) {
      // data from query parameter
      // {car_id: '188', customer_id: '77', debtors_type_id: '2'}
      this.setState({
        car_id: query.car_id,
        customer_id: query.customer_id,
        debtors_type_id: query.debtors_type_id,
        debtors_name: query.debtors_name,
        amount_of_debt: query.amount_of_debt,
        car_sell_id: query.car_sell_id,
      }, () => {
        this.loadCarById(true);
      })

    } else {
      this.loadDebtorsData()
      this.loadBankData()
    }

  }

  loadGroupPermission() {
    let user_type = this.state.user_type
    let menuID = this.state.menuID

    getGroupPermission(user_type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  loadCarById(is_open_modal){
    //var that = this
    axios.get(APIURL + '/car/'+this.state.car_id)
      .then(res => {
        let car = res.data[0]
        this.setState({
          car: car,
        }, () => this.toggleDebtors())
      }).catch(error => {
      // alert("error"+error)
      this.setState({car: {}})
    })
  }

  loadBankData() {
    axios.get( APIURL + '/bank/').then(res => {
      this.setState({
        bank_list: res.data
      })
    }).catch(error => {
      console.log('error: ' + error)
    })
  }

  loadDebtorsData () {
    this.setState({
      isLoading: true
    }, () => {
      axios.get(APIURL + '/debtors_detail/sum/')
        .then(res => {
          if(res.status === 200) {
            let data = res.data.sort((a, b) => (a.debtors_id < b.debtors_id) ? 1 : -1)
            this.setState({lists: data,  isLoading: false})
          }
        }).catch(error => {
        this.setState({lists: []})
      })
    })


    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  toggleDebtors () {
    this.setState({
      toggle_debtors_add: !this.state.toggle_debtors_add
    })
    this.loadDebtorsData()
  }

  togglePaymentDetail () {
    this.setState({
      paymentdetail: !this.state.paymentdetail
    })
    this.loadDebtorsData()
  }

  addPaymentDetail (debtors) {
    this.setState({debtors_id: debtors.debtors_id})
    this.togglePaymentDetail()
  }

  loadDebtorType() {
    // debtors_type
    axios.get( APIURL + '/debtors_type/').then(res => {
      this.setState({
        debtors_type: res.data
      })
    }).catch(error => {

    })
  }

  onSearch(){
    let search_text = this.refs.input_search.value
    let debt_status_normal = this.state.debt_status_normal
    let debt_status_npl = this.state.debt_status_npl
    let debt_status_request = this.state.debt_status_request
    let debt_status_complete = this.state.debt_status_complete

    if(search_text==='' &&
      debt_status_normal === 0 &&
      debt_status_npl === 0 &&
      debt_status_complete === 0 &&
      debt_status_request === 0
    ) {
      return;
    }

    let data = {
      keyword: search_text,
      debt_status_normal,
      debt_status_npl,
      debt_status_complete,
      debt_status_request
    }

    // axios.get(APIURL + '/debtors_detail/search/'+search_text)
    this.setState({
      isLoading: true
    }, () => {
      axios.post(APIURL + '/debtors_detail/search', data)
        .then(res => {
          if(res.status === 200) {
            let data = res.data.sort((a, b) => (a.debtors_id < b.debtors_id) ? 1 : -1)
            this.setState({lists: data, isLoading: false})
          }
        }).catch(error => {
        console.log('error: ' + error)
        this.setState({lists: []})
      })
    })

  }

  deleteDebtors(debtor_del) {

    if(window.confirm('ท่านต้องการลบ '+debtor_del.debtors_name +'\nยอดหนี้ '+debtor_del.amount_of_debt.toLocaleString()+' ใช่หรือไม่? (ลบแล้วไม่สามารถกู้คืนได้)')) {
      let debtorList = this.state.lists.filter(debtors => debtors.debtors_id !== debtor_del.debtors_id)

      axios.delete(APIURL + '/debtors/'+debtor_del.debtors_id)
        .then(res => {

          if(res.status === 200) {
            this.setState({lists: debtorList})
          }
        }).catch(error => {
        this.setState({lists: []})
        console.log('error: ' + error)
      })

    }
  }

 bankEdit(debtor) {
    // open modal for edit
   this.setState({
     debtor_edit: debtor,
     is_edit: true
   })
 }

  debtStatusEdit(debtor, payData) {

    this.setState({
      debtor_status_edit: debtor,
      payData: payData,
      is_debtor_status_edit: true
    })
  }

  debtStatusCompleteEdit(debtor) {
    // "customer_name": "ชยา",
    // "customer_lastname": "วงวิไล",
    let fullname = debtor.customer_name +' '+debtor.customer_lastname
    let debtors_name = debtor.debtors_name

    Swal.fire({
      title: 'ยืนยันการปิดหนี้ '+debtors_name+' ของ'+fullname,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        let uri = APIURL+'/debtors/debt_close'
        let data = {
          debtors_id: debtor.debtors_id
        }
        this.setState({
          isLoading: true,
        }, () => {
          axios.put(uri, data).then(res => {
            let resData = res.data
            if (resData === 'success') {
              AlertSuccess('ปิดหนี้เรียบร้อยแล้ว');
              this.loadDebtorsData();
              this.setState({
                isLoading: false,
                is_debtor_status_edit: false,
                debtor_status_edit: null,
                payData: null
              });
            }
          })
        })

      }
    })


  }

  printDebtors(debtor) {
    let debtors_id = debtor.debtors_id
    let car_sell_id = debtor.car_sell_id

    axios.get(APIURL + '/debtors_detail/debtors_id/' + debtors_id)
      .then(res => {
        if(res.status === 200) {
          let debtorsDetail = res.data
          axios.get(APIURL + '/car_sell/' + car_sell_id)
            .then(res2 => {
              var car_sell = res2.data
              this.printDebtorsPdf(debtor, debtorsDetail, car_sell)
            })
        }
      }).catch(error => {
        console.log('error: ' + error)
    })

  }

  printDebtorsPdf(debtor, debtorsDetail, car_sell){

    let debtors_name = debtor.debtors_name
    let date_pay_start = "จ่ายทุกวันที่ " + moment( debtor.date_pay_start).format('DD')
    let customer_mobile = debtor.customer_mobile
    let customer_name = debtor.customer_name+' '+debtor.customer_lastname
    // var debtors_other = debtor.debtors_other

    var car_sell_user = car_sell.length > 0 ? car_sell[0].car_sell_user : '-'
    var promotion_name = car_sell.length > 0 ? car_sell[0].promotion_name : '-'

    // return;

    var row1 = {
      columns: [
        { width: '10%',
          text: 'รายการ', style: 'textBoldLeft'
        },
        { width: '90%', style: 'textNormal',
          text: debtors_name,
        },
      ],
      columnGap: 20,
      lineHeight:1
    }

    var row2 = {
      columns: [
        { width: '10%',
          text: 'ชื่อผู้ซื้อ', style: 'textBoldLeft'
        },
        { width: '40%', style: 'textNormal',
          text: customer_name + ' ' + customer_mobile,
        },
        { width: '20%',
          text: 'โปรโมชั่น', style: 'textBoldLeft'
        },
        { width: '30%', style: 'textNormal',
          text: promotion_name,
        },
      ],
      columnGap: 20,
      lineHeight:1
    }
    var row3 = {
      columns: [
        { width: '10%',
          text: 'เซลล์', style: 'textBoldLeft'
        },
        {
          width: '40%', style: 'textNormal',
          text: car_sell_user,
        },
        { width: '20%',
          text: 'วันที่จ่ายค่างวด', style: 'textBoldLeft'
        },
        {
          width: '30%', style: 'textNormal',
          text: date_pay_start,
        }
      ],
      columnGap: 20,
      lineHeight:1.5
    }


    var  deptAllRows = []
    var tableHeader = [
      { style: 'textBold', text: 'งวดที่',},
      { style: 'textBold', text: 'กำหนดชำระ',},
      { style: 'textBold', text: 'จำนวนเงิน',},
      { style: 'textBold', text: 'วันที่ชำระ',},
      { style: 'textBold', text: 'ผู้รับเงิน',},
    ];
    deptAllRows.push(tableHeader);

    debtorsDetail.forEach((debtor, index) => {
      var order = debtor.debtors_detail_order
      var date_dept = moment(debtor.date_dept).format('DD-MM-YYYY')
      var amount_dept = debtor.amount_dept.toLocaleString()
      var amount_pay = debtor.amount_pay === 0 ? ' ' : debtor.amount_pay.toLocaleString()
      var date_pay =  debtor.date_pay === '0000-00-00' ? ' ' : moment(debtor.date_pay).format('DD-MM-YYYY')

      var row = []

      var itemOrder = {
        text: order,
        style: 'textCenter'
      };
      var itemDateDept = {
        text: date_dept,
        style: 'textCenter'
      };
      var itemAmountDept = {
        text: amount_dept,
        style: 'textCenter'
      };

      var itemAmountPay = {
        text: amount_pay,
        style: 'textCenter'
      };
      var itemDatePay = {
        text: date_pay,
        style: 'textCenter'
      };


      row.push(itemOrder)
      row.push(itemDateDept)
      row.push(itemAmountDept)
      row.push(itemAmountPay)
      row.push(itemDatePay)

      deptAllRows.push(row);
    })

    var docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [40, 30, 40, 0],
      // background: {image: 'book', width: 600},
      content: [
        {text: 'ใบผ่อนชำระ', style: 'bodyhead', lineHeight: 1.3},
        row1,
        row2,
        row3,
        {
          columns: [
            { width: '15%',
              text: port === 9016 ? ' การชำระเงิน ' : '', style: 'textBoldLeft'
            },
            {
              width: '85%', style: 'textNormal',
              text: port === 9016  ? ' โอนเข้าบัญชีธนาคารไทยพาณิชย์ เลขที่บัญชี 698-2-15783-0 ชื่อบัญชีนายปฐมพงศ์ ประเทืองไพศรี ' : ''
            },
          ],
          columnGap: 20,
          lineHeight:1
        },
        {text: ' ', style: 'bodyhead', lineHeight: 0.5},
        {
          table: {
            headerRows: 1,
            widths: ['10%','20%', '20%', '*', '*'],
            body: deptAllRows
          }
        },
      ],
      styles: {
        bodyhead: {
          fontSize: 18,
          bold: true,
          alignment: 'center'
        },
        textNormal: {
          fontSize: 16,
        },
        textBold: {
          fontSize: 16,
          bold: true,
          alignment: 'center'
        },
        textBoldRight: {
          fontSize: 16,
          bold: true,
          alignment: 'right'
        },
        textBoldLeft: {
          fontSize: 16,
          bold: true,
          alignment: 'left'
        },
        textCenter: {
          fontSize: 16,
          alignment: 'center'
        }
      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    }
    pdfMake.createPdf(docDefinition).open();
  }

  onRefresh(){
    this.refs.input_search.value = ''
    this.setState({
      debt_status_normal: 0,
      debt_status_npl: 0,
      debt_status_complete: 0,
    }, () => this.loadDebtorsData())

  }

  render () {

    let width = this.state.width
    let width_box = 180
    if (width <= 980){
      width_box = 112
    }

    return (
      <div className="card">
        <div className="card-block">
          <div className="">

            <Loading isLoading={this.state.isLoading} />

            {
              this.state.debtor_edit !== null ? (
                <DebtorBankEdit
                  is_edit={this.state.is_edit}
                  debtorEdit={this.state.debtor_edit}
                  bank_list={this.state.bank_list}
                  onToggle={() => this.onDebtorEditToggle()}
                  onSave = {(data) => this.onDebtorEditSave(data)}
                />
              ) : null
            }

            {
              this.state.debtor_status_edit !== null ? (
                <DebtorStatusEdit
                  is_edit={this.state.is_debtor_status_edit}
                  debtorEdit={this.state.debtor_status_edit}
                  debt_status_list={this.state.debt_status_list}
                  onToggle={() => this.onDebtorStatusEditToggle()}
                  onSave = {(data, debtorEdit, debt_status_list) => {
                    this.onDebtorStatusEditSave(data, debtorEdit, debt_status_list)
                  }}
                />
              ) : null
            }

            <div className="card card-accent-info">
                <div className="card-header card-customer">
                  <div className="row">
                    <div className="form-group form-inline col-12">
                      <div className="row">
                        <strong className="text-title ml-1 mt-2"> ข้อมูลบัญชีลูกหนี้ </strong>
                          <input
                            ref="input_search"
                            style={styles.inputSearch}
                            className="form-control mr-2"
                            placeholder="ค้นหาตามชื่อ เบอร์โทร หรือทะเบียนรถใหม่"
                            type="text"
                          />

                          {/*<select className="form-control mr-1"
                                  style={{...styles.selectFilter, width: width_box}}
                                  name="debtors_type_id"
                                  value={this.state.debtors_type_id}
                                  onChange={(e) => {
                                    this.setState({debtors_type_id: e.target.value}, () => this.onSearch())
                                  }}
                          >
                            <option value="">ประเภทลูกหนี้</option>
                            {debtors_type_list}
                          </select>*/}

                          &nbsp;
                          <button
                            className="btn btn-sm btn-head-bar mr-1"
                            onClick={() => this.onSearch()}
                            style={styles.buttonHeight}
                          >
                            <i className="icon-search"/>ค้นหา
                          </button>

                          <button className="btn btn-head-bar"
                                  style={styles.buttonHeight}
                                  onClick={this.onRefresh.bind(this)}

                          >
                            <i className="icon-refresh"/>
                          </button>

                          {
                            this.state.user.type === 'admin' ||
                            this.state.user.type === 'manager' ||
                            this.state.user.type === 'account' ||
                            this.state.user.type === 'accountx' ||
                            this.state.user.type === 'debtcollector'
                                ? (
                              <Link to="/report/debtors">
                                <button className="btn btn-head-bar float-right mt-1 ml-2 mr-2" style={{backgroundColor: '#4dbd74'}}>
                                  <i className="icon-doc"/>&nbsp; รายงานลูกหนี้
                                </button>
                              </Link>
                            ) : null
                          }

                          <button type="button"
                                  onClick={this.toggleDebtors}
                                  className="btn btn-head-bar float-right mt-1 ">
                            <i className="icon-plus"/>&nbsp; เพิ่มข้อมูลลูกหนี้
                          </button>


                          {
                            this.state.toggle_debtors_add === true ? (
                              <Modal isOpen={this.state.toggle_debtors_add} toggle={this.toggleDebtors}
                                     className={'modal-lg'}
                                     style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
                              >
                                <ModalBody>
                                  <DebtorsForm
                                    // onLoadDebtors ={this.loadCarData}
                                    car_id={this.state.car_id}
                                    customer_id={this.state.customer_id}
                                    debtors_type_id={this.state.debtors_type_id}
                                    debtors_name={this.state.debtors_name}
                                    amount_of_debt={this.state.amount_of_debt}
                                    car={this.state.car}
                                    car_sell_id={this.state.car_sell_id}
                                    onToggle={()=>{
                                      this.setState({
                                        car_id : '',
                                        customer_id : '',
                                        debtors_type_id : '',
                                        debtors_name : '',
                                        amount_of_debt : '',
                                        car : '',
                                        car_sell_id : ''
                                      })
                                      this.toggleDebtors()
                                    }}

                                  />
                                </ModalBody>
                              </Modal>
                            ) : null
                          }
                        </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group form-inline col-12 text-title">
                      <strong className="text-title">สถานะหนี้ : </strong> &nbsp;&nbsp;
                      <input
                        type="checkbox"
                        className="form-control"
                        name={'car_status_id'}
                        value={this.state.debt_status_normal}
                        checked={this.state.debt_status_normal === 1}
                        onChange={(e) => {
                          let val = Number(e.target.value) === 1 ? 0 : 1
                          this.setState({debt_status_normal: val})
                        }}
                      /> &nbsp;&nbsp; ปกติ
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        type="checkbox"
                        className="form-control"
                        name={'car_status_id'}
                        value={this.state.debt_status_npl}
                        checked={this.state.debt_status_npl === 1}
                        onChange={(e) => {
                          let val = Number(e.target.value) === 1 ? 0 : 1
                          this.setState({debt_status_npl: val})
                        }}
                      /> &nbsp;&nbsp; หนี้เสีย
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        type="checkbox"
                        className="form-control"
                        name={'car_status_id'}
                        value={this.state.debt_status_request}
                        checked={this.state.debt_status_request === 1}
                        onChange={(e) => {
                          let val = Number(e.target.value) === 1 ? 0 : 1
                          this.setState({debt_status_request: val})
                        }}
                      />
                      &nbsp;&nbsp; ขอปิดหนี้
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        type="checkbox"
                        className="form-control"
                        name={'car_status_id'}
                        value={this.state.debt_status_complete}
                        checked={this.state.debt_status_complete === 1}
                        onChange={(e) => {
                          let val = Number(e.target.value) === 1 ? 0 : 1
                          this.setState({debt_status_complete: val})
                        }}
                      />
                      &nbsp;&nbsp;
                      จ่ายครบปิดหนี้

                    </div>
                  </div>

                </div>

                <DebtorsListRow
                  debtors_list={this.state.lists}
                  debtorsCallbacks={
                    {
                      add: this.addPaymentDetail.bind(this),
                      del: this.deleteDebtors.bind(this),
                      print: this.printDebtors.bind(this),
                      bankEdit: this.bankEdit.bind(this),
                      debtStatusEdit: this.debtStatusEdit.bind(this),
                      debtStatusCompleteEdit: this.debtStatusCompleteEdit.bind(this),
                    }
                  }
                  user_type={this.state.user_type}
                  grouppermissions={this.state.grouppermissions}
                />

              </div>
          </div>
        </div>
      </div>
    )
  }

}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
  inputSearch: {
    marginLeft: 20,
    width: 300,
    display: 'inline'
  },

  buttonHeight: {
    height: 36,
    paddingTop: 0,
    paddingBottom:0,
    borderRadius:5,
    display: 'inline',
    marginTop: 5,
    marginBottom: -8,
  },

  selectFilter: {
    marginTop: 5,
    marginBottom: -8,
    borderRadius:5,
    display: 'inline'
  },

  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(DebtorsList)
