import React, { Component } from 'react'
import axios from 'axios'
import { APIURL } from '../../config/config'
import { Modal, ModalBody } from 'reactstrap'
import Switch from 'react-switch'
import userUtil from '../../utils/userUtil'
import { AlertSuccess, AlertWarning } from '../Alert/Alert'

class AccountBranchPermission extends Component{
  constructor (props) {
    super(props)
    this.state = {
      branchs: [],
      width: 0,
      height: 0,
      branch_permissions: [],
      actionType: 'บันทึก'
    }

    this.loadBranchData = this.loadBranchData.bind(this)
    this.loadBranchPermission = this.loadBranchPermission.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.onChangeSwitchPermission = this.onChangeSwitchPermission.bind(this)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  async componentDidMount () {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    // load branch
    await this.loadBranchData()
    await this.loadBranchPermission()
    // load branch_permission
  }

  async loadBranchData() {
    await axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branchs: res.data})
      }).catch(error => {
      this.setState({branchs: []})
    })
  }

  async loadBranchPermission() {
    let account = this.props.account
    let user = account.user
    await axios.get(APIURL + '/branch_permission/'+user)
      .then(res => {
        let branch_permissions = res.data
        let branchs = this.state.branchs
        if (branchs.length > branch_permissions.length) {
          let branch_permission_checks = branchs.map(branch => {
               let checks = branch_permissions.filter(branch_permission => branch_permission.branch_id === branch.branch_id)
               if (checks.length === 1) {
                 return checks[0]
               } else {
                 return {
                   id: 0,
                   branch_id: branch.branch_id,
                   user: branch_permissions[0].user,
                   is_allowed: 0,
                   branch_name: branch.branch_name,
                   status: 'new'
                 }
               }
            })
          branch_permissions = branch_permission_checks
        }

        this.setState({branch_permissions: branch_permissions})
      }).catch(error => {
        this.setState({branch_permissions: []})
      })
  }


  onChangeSwitchPermission(checked,item){
    let branch_permissions = this.state.branch_permissions
    let item_update = item

    let item_index = branch_permissions.findIndex(data => data.branch_id=== item.branch_id)

    if(checked === false){
      item_update.is_allowed = 0
    }else {
      item_update.is_allowed = 1
    }

    if (item_update.status !== 'new') {
      item_update.status = 'edit'
    }

    branch_permissions[item_index] = item_update

    this.setState({
      branch_permissions: branch_permissions
    })

  }

  updateBranchPermissions() {
    let branch_permissions = this.state.branch_permissions
    let branch_permission_exists = branch_permissions.filter(item => item.status === 'new' || item.status === 'edit')

    if (branch_permission_exists.length === 0) {
      AlertWarning('ไม่พบการเปลี่ยนสิทธิ์สาขา');
      return
    }

    let url = APIURL+'/branch_permission'
    axios.post(url, branch_permissions).then(async res => {
      let data = res.data

      if (data === 'success'){
        AlertSuccess('บันทึกสิทธิ์สาขาแล้ว')
        await this.loadBranchPermission();
      }
    })
  }

  render () {
    let isOpen = this.props.isOpen
    let width = this.state.width
    let actionType = this.state.actionType
    // let branchs = this.state.branchs
    let branch_permissions = this.state.branch_permissions
    let account = this.props.account

    // const emptyBody = <tr><td colSpan={3} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    let tableBranchPermission = branch_permissions.map((item, index) => {

      return (
        <tr key={index}>
          <td className="text-center">{index+1}</td>
          <td className="text-left text-nowrap">{item.branch_name}</td>
          <td className="text-center text-nowrap">

            { item.is_allowed === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'}
            <br/>

            <Switch height = {20} width ={48}
                    onChange={(checked) => this.onChangeSwitchPermission(checked, item)}
                    checked={item.is_allowed === 1}
            />
          </td>
        </tr>
      );
    })


    return (
      <>
        <Modal isOpen={isOpen}
               toggle={this.props.toggleModal}
               className={'modal-md ' + this.props.className}
               style={width<=980 ?{maxWidth:400}:{maxWidth:600}}
        >
          <ModalBody>
            <div className={actionType === 'แก้ไข' ? "card card-accent-warning" : "card card-accent-primary" }>
              <div className="card-header">
                <strong className="text-title">{actionType}ข้อมูลสิทธิ์สาขา</strong>
              </div>

              <div className="card-block">
                <div className="row">
                  <div className="col-12">
                    user: {account.user} &nbsp; ชื่อ-นามสกุล {account.fullname}
                    &nbsp;| สิทธิ์ : { userUtil.convertUserTypeToText(account.type) }
                  </div>
                  <div className="col-12 mt-2">
                    <table className="table table-striped table-responsive">
                      <thead>
                      <tr>
                        <th style={{width: 10}} className="text-center text-nowrap">#</th>
                        <th style={{width: 50}} className="text-left text-nowrap">ชื่อสาขา</th>
                        <th style={{width: 50}} className="text-center text-nowrap">สิทธิ์</th>
                      </tr>
                      </thead>
                      <tbody>
                       {tableBranchPermission}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="card-footer text-right">
                <button type="button" onClick={() => this.props.toggleModal()} className="btn btn-sm btn-danger mr-2">
                  <i className="fa fa-refresh"/> ยกเลิก</button>

                <button type="button"
                        className={actionType==="แก้ไข"? "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}
                        onClick={() => this.updateBranchPermissions()}
                >
                  <i className={actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/> {actionType}</button>
              </div>

            </div>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

export default AccountBranchPermission
