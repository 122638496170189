import React, { Component } from 'react';
import {connect} from "react-redux";
import axios from "axios";
import { APIURL, IMAGE_FULL_URL } from '../../../config/config';
import Loading from "../../Loading";
import * as moment from 'moment';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import { AlertSuccess } from '../../Alert/Alert';
import FinancePendingEditModal from '../FinancePendingEditModal';
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";

const port = window.env.PORT;


class FinancePendingList extends Component {
    constructor (props) {
        super(props)
        this.state = {
            user: {},
            isLoading: false,
            car_id: null,
            finance_pendings: [],
            isFinancePendingEditModal: false
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.loadFinancePendingByCarId = this.loadFinancePendingByCarId.bind(this);
        this.deleteFinancialPending = this.deleteFinancialPending.bind(this);
        this.toggleFinancePendingEditModal = this.toggleFinancePendingEditModal.bind(this);
        this.onFinancePendingReload = this.onFinancePendingReload.bind(this);
        this.loadFinancePendingAll = this.loadFinancePendingAll.bind(this);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentWillMount() {
        this.setState({
            user: JSON.parse(this.props.user)
        });
    }

    componentDidMount () {
        CarsUtil.removeCarSelect()
        CarSellUtil.removeCarSellSelect()

        const queryString = require('query-string')
        let car = queryString.parse(this.props.location.search)

        if (Object.keys(car).length !== 0) {
            this.setState({ car_id: car.car_id }, () => {
                this.loadFinancePendingByCarId(car.car_id)
            })
        } else {
            this.loadFinancePendingAll()
        }

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    loadFinancePendingByCarId (car_id) {
        axios.get(APIURL + '/finance_pending/' + car_id)
          .then(res => {
              let finance_pendings = res.data
              this.setState({
                  finance_pendings: finance_pendings,
              })
          }).catch(error => {
            // alert('พบข้อผิดพลาด: ' + error)
            console.log('พบข้อผิดพลาด: ', error)
            this.setState({car: {}})
        })
    }

    loadFinancePendingAll () {
        axios.get(APIURL + '/finance_pending')
          .then(res => {
              let finance_pendings = res.data
              this.setState({
                  finance_pendings: finance_pendings,
              })
          }).catch(error => {
            // alert('พบข้อผิดพลาด: ' + error)
            console.log('พบข้อผิดพลาด: ', error)
            this.setState({car: {}})
        })
    }

    onRefresh(){
        this.refs.input_search.value = ''
        this.loadFinancePendingAll()
        // this.loadData()
        // this.loadCarSellData(this.state.page)
    }

    onSearch(){
        let search_text = this.refs.input_search.value
        if(search_text.length < 3) {
            if(search_text===''){
                this.loadFinancePendingAll()
                return;
            }
            return
        }

        this.setState({
            isLoading: true,
            finance_pendings: []
        }, () => {
            axios.get(APIURL + '/finance_pending/search/'+search_text)
                .then(res => {
                    this.setState({
                        finance_pendings: res.data,
                        is_search: true,
                        isLoading: false
                    })
                }).catch(error => {
                  console.log('error:', error)
                  this.setState({finance_pendings: []})
            })
        })
    }

    deleteFinancialPending(data) {
        const { car_id } = this.state
        let finance_pending_id = data.finance_pending_id
        let car_name = data.car_name
        let customer_name = data.customer_name + ' ' + data.customer_lastname

        Swal.fire({
            title: 'ยืนยันการลบการจัด รถ '+car_name+'\nลูกค้า '+ customer_name ,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
        }).then((result) => {
            if (result.value) {
                let url = APIURL + '/finance_pending/'+finance_pending_id
                axios.delete( url).then(res => {
                    if(res.status === 200) {
                        AlertSuccess('ลบข้อมูลเรียบร้อยแล้ว')
                        if (car_id !== null) {
                            // load by car_id
                            this.loadFinancePendingByCarId()
                        } else {
                            // load all
                        }
                    }
                }).catch(error => {
                    console.log('error:', error)
                })
            }
        })

    }

    toggleFinancePendingEditModal() {
        this.setState({
            isFinancePendingEditModal: !this.state.isFinancePendingEditModal
        })
    }

    onFinancePendingReload(car_id) {
        const queryString = require('query-string')
        let car = queryString.parse(this.props.location.search)

        if (Object.keys(car).length !== 0) {
            this.setState({
                isFinancePendingEditModal: false
            }, () => {
                this.loadFinancePendingByCarId(car.car_id)
            })
        } else {
            this.setState({
                isFinancePendingEditModal: false
            }, () => {
                this.loadFinancePendingAll()
            })
        }
        // if (car_id !== null) {
        //     // reload after edit
        //     this.setState({
        //         isFinancePendingEditModal: false
        //     }, () =>  this.loadFinancePendingByCarId(car_id))
        // } else {
        //
        // }
    }

    render () {

        const { finance_pendings } = this.state
        let width = this.state.width

        let imgWidth = ''
        if ( width >= 1340) {
            imgWidth = '220px'
        } else if (width >= 1024) {
            imgWidth = '180px'
        } else if (width >= 980) {
            imgWidth = '160px'
        } else {
            imgWidth = '140px'
        }

        let tbodyEmpty = <tr><td colSpan={6}> ไม่พบข้อมูล </td></tr>

        let tbodyData = finance_pendings.map((data, index) => {

            let image_url = ''
            if (data.car_upload_filename.includes('https')) {
                image_url = data.car_upload_filename
            } else {
                image_url = IMAGE_FULL_URL + data.car_upload_filename
            }
            let order = index + 1

            return (
              <tr className="text-center" key={index}>
                  <td className="text-center">{order}</td>
                  <td className="text-center">
                      {moment(data.finance_date).locale('th').format('LL')}
                  </td>
                  <td>
                      <img src={image_url}
                           style={{'cursor' : 'pointer', 'width': imgWidth, 'height': 'auto'}}
                           alt="car"/>
                      <hr/>
                      {data.car_name}
                      <br/>
                      {data.car_license_plate_new} &nbsp;&nbsp; {data.province_name_new}  <br/>
                      (ทะเบียนเดิม: {data.car_license_plate_old})
                  </td>
                  <td className="text-left">
                      ลูกค้า : {data.customer_name}&nbsp;&nbsp;&nbsp;{data.customer_lastname} <br/>
                      สาขาที่จัด : {data.branch_name}  <br/>
                      พนักงานที่จัด : {data.employee_name}  {data.employee_lastname}<br/>
                      ไฟแนนซ์ : {data.bank_name} ({data.finance_contract})<br/>
                      ยอดจัด : {data.rate_finance} <br/>
                      ยอดดาวน์ : {data.sum_down === 0 ? '-' : data.sum_down} <br/>
                      ค่างวด : {data.monthly_installment === 0 ? '-' : data.monthly_installment} <br/>
                      รายละเอียดการจัด :  <br/> {data.finance_detail}

                      {
                          data.finance_comment === null ? null : (
                            <>
                                <hr/>
                                หมายเหตุการจัด :
                                <br/>
                            </>
                          )
                      }

                      {
                         data.finance_comment === null ? null : parse(data.finance_comment)
                      }
                  </td>
                  <td>
                      <span className="badge"
                            style={{backgroundColor: data.finance_status_color}}>
                      {data.finance_status_name}
                      </span>
                      <br/>
                      ผู้ทำรายการ : {data.finance_pending_user} <br/>
                      เมื่อ : {moment(data.finance_pending_date).format('DD-MM-YYYY HH:mm:ss')} <br/>

                  </td>
                  <td>
                      <button
                        onClick={() => {
                            this.setState({
                                isFinancePendingEditModal: true,
                                finance_pending: data
                            })
                        }}
                        type="button"
                        className={'btn btn-warning btn-sm btn-block'}
                      >
                          <i className="icon-note">
                              &nbsp;แก้ไข
                          </i>
                      </button>

                      <button
                        onClick={() => this.deleteFinancialPending(data)}
                        type="button"
                        className={'btn btn-danger btn-sm btn-block'}
                      >
                          <i className="icon-trash">&nbsp;ลบ</i>
                      </button>

                  </td>

              </tr>
            );
        })

        let tbodyList = finance_pendings.length === 0 ? tbodyEmpty : tbodyData

        return (

            <div className="card">

                <Loading isLoading={this.state.isLoading} />

                <FinancePendingEditModal
                  isModal={this.state.isFinancePendingEditModal}
                  toggleModal={this.toggleFinancePendingEditModal}
                  finance_pending={this.state.finance_pending}

                  onReload={this.onFinancePendingReload}

                />

                <div className="card-block">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="card card-accent-primary">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-8">
                                            <strong className="text-title"> ข้อมูลรอจัดไฟแนนซ์ </strong>
                                            <input
                                                ref="input_search"
                                                style={styles.inputSearch}
                                                className="form-control"
                                                placeholder="ค้นหา ทะเบียน หรือ ชื่อผู้ซื้อ (3ตัวอักษรขึ้นไป)"
                                                type="text"
                                            />&nbsp;&nbsp;&nbsp;

                                            <button
                                                className="btn btn-sm btn-head-bar"
                                                onClick={this.onSearch.bind(this)}
                                                style={styles.buttonHeight}
                                            >
                                                <i className="icon-search"/>&nbsp; ค้นหา
                                            </button>
                                            &nbsp;

                                            <button className="btn btn-head-bar"
                                                    style={styles.buttonHeight}
                                                    onClick={this.onRefresh.bind(this)}
                                            >
                                                <i className="icon-refresh"/>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <span className="text-title float-right"> รถที่ตัดขายออก จะไม่ดึงข้อมูลมาแสดง </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-block">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th style={{width: 10}} className="text-center">#</th>
                                                <th style={{width: 140}}  className="text-center">วันที่จัด</th>
                                                <th style={{width: 280}} className="text-center">ข้อมูลรถ</th>
                                                <th className="text-center">ข้อมูลการจัด</th>
                                                <th className="text-center">สถานะจัด</th>
                                                <th className="text-center">จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {tbodyList}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )

    }

}

const styles = {
    inputSearch: {
        marginLeft: 10,
        width: 250,
        display: 'inline'
    },

    buttonHeight: {
        height: 36,
        paddingTop:0,
        paddingBottom:0,
        display: 'inline',
        marginTop: '-0.35rem'
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(FinancePendingList);
