import React, { Component } from 'react'
import { APIURL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL, IMAGE_THUMB_URL } from '../../../config/config'
import axios from "axios"
import DatePicker from 'react-datepicker'
import NumberFormat from "react-number-format"
import {connect} from "react-redux"
import moment from 'moment'

class CarClaimItems  extends Component{
    constructor (props) {
        super(props)
        this.state = {
            user: {},
            carClaim: {},
            mechanic_name: '',
            repair_date: new Date(),
            qty: 0, //nk ไม่ใช้งาน
            repair_item: '',
            amount: '',
            total: '',
            repair_item_list: [],
            /*repair_item_list: [
                {repair_timestamp: 1575913171175, qty: 1, repair_item: "เปลี่ยนคอมแอร์", amount: 300},
                {repair_timestamp: 1575913192335, qty: 1, repair_item: "เปลี่ยนดรายเออร์", amount: 100},
                {repair_timestamp: 1575913226851, qty: 1, repair_item: "ถอดตู้แอร์", amount: 500},
                {repair_timestamp: 1575913226951, qty: 1, repair_item: "แว๊ค+เติมน้ำยาแอร์", amount: 500}
            ]*/
            guarantee_list: [
                {guarantee_id: 1, guarantee_name: 'ไม่รับประกัน' },
                {guarantee_id: 2, guarantee_name: '3 เดือน' },
                {guarantee_id: 3, guarantee_name: '6 เดือน' },
                {guarantee_id: 4, guarantee_name: '1 ปี' },
            ],
            guarantee_name: 'ไม่รับประกัน',
            mechanic_list: [],
            car_claim_item_id: '',
            form_status: 'add',
            itemSelectedUpdate: {},
            indexUpdate: null
        }

        this.handleRepairDate = this.handleRepairDate.bind(this);
        this.onChangeQtyHandle = this.onChangeQtyHandle.bind(this);
        this.onChangeRepairItemHandle = this.onChangeRepairItemHandle.bind(this);
        this.onChangeAmountHandle = this.onChangeAmountHandle.bind(this);
        this.onChangeGuaranteeNameHandle = this.onChangeGuaranteeNameHandle.bind(this);
        this.onChangeClearDataHandle = this.onChangeClearDataHandle.bind(this);
        this.onSaveDataHandle = this.onSaveDataHandle.bind(this);
        this.selectedUpdateRepairItem = this.selectedUpdateRepairItem.bind(this);
        this.editRepairItem = this.editRepairItem.bind(this);
    }

    componentWillMount () {
        this.setState({
            user: JSON.parse(this.props.user),
        });
    }

    componentDidMount() {
        const queryString = require('query-string');
        let claim = queryString.parse(this.props.location.search);
        this.loadCarClaim(claim.claim_id)
        this.loadMechanicCenterList(claim.claim_id)
    }

    loadCarClaim(claim_id) {
        let uri = APIURL + '/car_claim/claim_id/' + claim_id
        axios.get(uri)
            .then(res => {
                this.setState({carClaim: res.data})
            }).catch(error => {
            //alert('error' + error)
            console.log (error)
            this.setState({carClaim: []})
        })
    }

    loadCarClaimItems(claim_id){
        let uri = APIURL + '/car_claim_item/claim_id/' + claim_id
        axios.get(uri)
            .then(res => {
                if(res.data.length > 0) {

                    var car_claim_item_detail =  res.data.map(item => {
                        return {...item, item_status: 'old'}
                    })

                    this.setState({
                        repair_item_list: car_claim_item_detail,
                        car_claim_item_id: res.data[0].car_claim_item_id,
                        total: res.data[0].total,
                    })
                }

            }).catch(error => {
            //alert('error' + error)
            console.log (error)
            this.setState({repair_item_list: []})
        })
    }

    loadMechanicCenterList(claim_id) {
        let uri = APIURL + '/car_claim_item/mechanic'
        axios.get(uri)
            .then(res => {
                this.setState({mechanic_list: res.data}, () => this.loadCarClaimItems(claim_id))
            }).catch(error => {
            //alert('error' + error)
            console.log (error)
            this.setState({mechanic_list: []})
        })
    }

    handleRepairDate(date){
        this.setState({
            repair_date: date,
        })
    }

    onChangeQtyHandle(e){
        this.setState({
            qty: e.currentTarget.value,
        })
    }

    onChangeRepairItemHandle(e){
        this.setState({
            repair_item: e.currentTarget.value,
        })
    }

    onChangeAmountHandle(e){
        this.setState({
            amount: e.currentTarget.value,
        })
    }

    onChangeGuaranteeNameHandle(e){
        this.setState({
            guarantee_name: e.currentTarget.value,
        })
    }

    onChangeClearDataHandle(){
        this.setState({
            mechanic_name: '',
            repair_date: new Date(),
            qty: 0,
            repair_item: '',
            amount: '',
            total: '',
            repair_item_list: [],
            guarantee_name: 'ไม่รับประกัน',
        })
    }

    addRepairItem() {
        let repairItemList = this.state.repair_item_list
        // let claim_id = this.state.carClaim.car_claim_id

        let repair_date = moment(this.state.repair_date).format("YYYY-MM-DD")
        let qty = parseInt(this.state.qty)
        let repair_item = this.state.repair_item
        let amount = parseFloat(this.state.amount)
        let guarantee_name = this.state.guarantee_name
        let center_id = this.state.mechanic_name

        // let total = parseFloat(this.state.total)


        if(isNaN(qty) || repair_item==='' || isNaN(amount) || center_id ==='') {
            alert('กรุณากรอกข้อมูลให้ครบถ้วน')
            return
        }

        const timestamp = Date.now();

        let newItem = {
            repair_timestamp: timestamp,
            repair_date: repair_date,
            qty: qty,
            repair_item: repair_item,
            amount: amount,
            guarantee_name: guarantee_name,
            center_id: center_id,
            item_status: 'add_new'
        }

        repairItemList.push(newItem)

        var  total = 0
        if(repairItemList.length === 1){
            total = amount
        }else{
            total  = repairItemList.map(item => item.item_status !== 'delete' ? item.amount : 0).reduce((prev, amount) => prev + amount, 0)
        }

        this.setState({
            repair_item_list: repairItemList,
            total: total,
            qty: 0,
            repair_item: '',
            amount: '',
            mechanic_name: '',
            guarantee_name: 'ไม่รับประกัน',
        })
    }

    removeRepairItem(itemSelected) {

        if(window.confirm('ยืนยันการลบรายการ : '+itemSelected.repair_item+ ' ('+itemSelected.amount+' บาท)')) {

            let repairItemList = this.state.repair_item_list;
            let indexRemove = repairItemList.indexOf(itemSelected);
            if (indexRemove !== -1) {
                // repairItemList.splice(indexRemove, 1);
                var removeItem = repairItemList[indexRemove]

                if (removeItem.item_status === 'add_new') {
                    repairItemList.splice(indexRemove, 1);
                } else {
                    var car_claim_item_detail = repairItemList.map(item => {
                        return itemSelected.car_claim_item_detail_id === item.car_claim_item_detail_id ? {
                            ...item,
                            item_status: 'delete'
                        } : item
                    })
                    repairItemList = car_claim_item_detail;
                }

                let total = repairItemList.map(item => item.item_status !== 'delete' ? item.amount : 0).reduce((prev, amount) => prev + amount, 0)

                this.setState({
                    repair_item_list: repairItemList,
                    total: total
                });
            }
        }
    }

    selectedUpdateRepairItem(itemSelected){

        let repairItemList = this.state.repair_item_list;
        let indexUpdate = repairItemList.indexOf(itemSelected);

        this.setState({
            form_status: 'edit',
            itemSelectedUpdate: itemSelected,
            indexUpdate: indexUpdate,
            repair_date: new Date(itemSelected.repair_date),
            qty: 0,
            repair_item: itemSelected.repair_item ,
            amount: itemSelected.amount,
            guarantee_name: itemSelected.guarantee_name,
            mechanic_name: itemSelected.center_id,
        })

    }

    editRepairItem() {



        let repairItemList = this.state.repair_item_list;
        let itemSelected = this.state.itemSelectedUpdate

        let repair_date = moment(this.state.repair_date).format("YYYY-MM-DD")
        let qty = parseInt(this.state.qty)
        let repair_item = this.state.repair_item
        let amount = parseFloat(this.state.amount)
        let guarantee_name = this.state.guarantee_name
        let center_id = this.state.mechanic_name

        let repairItemListUpdate = repairItemList.map(item => {
            return itemSelected.car_claim_item_detail_id === item.car_claim_item_detail_id ? {
                ...item,
                repair_date: repair_date,
                qty: qty,
                repair_item: repair_item,
                amount: amount,
                guarantee_name: guarantee_name,
                center_id: center_id,
                item_status: 'update'
            } : item
        })

        let total = repairItemListUpdate.map(item => item.item_status !== 'delete' ? item.amount : 0).reduce((prev, amount) => prev + amount, 0)

        this.setState({
            repair_item_list: repairItemListUpdate,
            total: total,
            qty: 0,
            repair_date: new Date(),
            repair_item: '',
            amount: '',
            mechanic_name: '',
            guarantee_name: 'ไม่รับประกัน',
            form_status: 'add'
        });
    }

    onSaveDataHandle(){

        let car_claim_id = this.state.carClaim.car_claim_id
        let total = this.state.total
        let repair_item_list = this.state.repair_item_list
        let user = this.state.user.user
        let car_claim_item_id = this.state.car_claim_item_id

        let data = {
            car_claim_id: car_claim_id,
            total: total,
            repair_item_list: repair_item_list,
            car_claim_item_id: car_claim_item_id,
            user: user
        }

        if(car_claim_item_id === '') { // add new all item

            axios.post(APIURL + '/car_claim_item', data)
                .then(res => {
                    if (res.status === 200) {
                        alert('บันทึกรายการซ่อมเรียบร้อยแล้ว');
                        this.setState({
                            repair_item_list: []
                        }, () => {
                            let target = '/car-claim/list'
                            this.props.history.push(target)
                        });

                    }
                })
        }else{
            axios.post(APIURL + '/car_claim_item/update', data)
                .then(res => {
                    if (res.status === 200) {
                        alert('บันทึกรายการซ่อมเรียบร้อยแล้ว');
                        this.setState({
                            repair_item_list: []
                        }, () => {
                            let target = '/car-claim/list'
                            this.props.history.push(target)
                        });

                    }
                })
        }

    }

    repairItemListTotal(){
        if(this.state.total==='') return null

        return (
            <tr>
                <td className="text-right"  colSpan={5}><strong>รวมเป็นเงิน</strong></td>
                <td className="text-center" >{this.state.total.toLocaleString()}</td>
                <td>&nbsp;</td>
            </tr>
        )
    }

    render() {
        let carClaim = this.state.carClaim
        let mechanicList = this.state.mechanic_list
        let car_upload_filename = ( typeof carClaim.car_upload_filename === undefined ) ? 'noimage.png' : carClaim.car_upload_filename

        let repairItemList = this.state.repair_item_list.map((item,index) =>{

            if(item.item_status === 'delete') return  null

            // eslint-disable-next-line
            let center = mechanicList.find(center => center.center_id == item.center_id) // === หาไม่เจอ

            return (
                <tr key={index}>
                    <td className="text-center">{index+1}</td>
                    <td className="text-center">{moment(item.repair_date).format('DD-MM-YYYY')}</td>
                    {/*<td className="text-center">{item.qty}</td>*/}
                    <td >{item.repair_item}</td>
                    <td className="text-center" >{item.amount}</td>
                    <td className="text-center" >{item.guarantee_name}</td>
                    <td className="text-center" >{center.center_name}</td>
                    <td>
                        <button className="btn btn-sm btn-danger btn-md-width"
                                onClick={()=>this.removeRepairItem(item)}
                        >
                            <i className="fa fa-remove"></i> ลบ
                        </button>

                        <button className="btn btn-sm btn-warning btn-md-width"
                                onClick={()=>this.selectedUpdateRepairItem(item)}
                        >
                            <i className="fa fa-pencil-square"></i> แก้ไข
                        </button>

                    </td>
                </tr>
            )
        });

       let repairItemListTotal = this.repairItemListTotal()


        /*let guarantee_list = this.state.guarantee_list.map((guarantee, index) => {
            return (
                <option key={index} value={guarantee.guarantee_name}> {guarantee.guarantee_name} </option>
            )
        })*/

        let mechanic_list = this.state.mechanic_list.map((center, index) => {
            return (
                <option key={index} value={center.center_id}> {center.center_name} </option>
            )
        })

        let image_url = ''
        // className="img-cars"

        if (Object.keys(carClaim).length !== 0) {
            if (carClaim.car_upload_filename !== null) {
                if (carClaim.car_upload_filename.includes('https')) {
                    image_url = carClaim.car_upload_filename
                } else {
                    image_url = IMAGE_FULL_URL + carClaim.car_upload_filename
                }
            } else {
                image_url = IMAGE_NOPHOTO_URL
            }
        }

        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card card-accent-info">
                        <div className="card-header">
                            <strong>รายการซ่อมเคลม</strong>
                        </div>
                        <div className="card-block">

                            <div className="row pt-3" style={{backgroundColor: '#eceff1'}}>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td className="text-center" width="30%">
                                                {
                                                    image_url !== '' ?  <img id={'img_'+carClaim.car_claim_id} src={image_url} style={{width: 200}} alt="car"/> : null
                                                }
                                                <br/>
                                                <div className="col-12 mt-2">
                                                    <strong>
                                                         {/*<span className="text-danger ">
                                                          หมดรับประกันรถวันที่ { moment(carClaim.car_sell_date).add(90, 'days').format('LL')}
                                                         </span>*/}
                                                    </strong>
                                                </div>
                                            </td>
                                            <td>
                                                <strong>ข้อมูลการเคลม </strong><br/>
                                                วันที่ออกใบเคลม: {carClaim.claim_date} <br/>
                                                ลูกค้ารับรถ: {carClaim.claim_name} <br/>
                                                เบอร์โทร: {carClaim.claim_mobile} <br/>
                                            </td>
                                            <td>
                                                <strong>ข้อมูลรถ</strong><br/>
                                                ทะเบียน: {carClaim.car_license_plate_new} <br/>
                                                รถยี่ห้อ {carClaim.car_brand_name} รุ่น {carClaim.car_model_name} สี {carClaim.car_color_name} <br/>
                                                <strong>รายการเคลม </strong><br/>
                                                {carClaim.claim1_list1} <br/>
                                                {carClaim.claim1_list2} <br/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <hr/>

                            <div className="row">
                                <div className="form-group col-sm-2">
                                    <label htmlFor="repair_date">วันที่ซ่อม</label>
                                    <DatePicker
                                        name="repair_date"
                                        selected={this.state.repair_date}
                                        onChange={this.handleRepairDate}
                                        dateFormat="yyyy-MM-dd"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                    />
                                </div>
                                <div className="form-group col-sm-1 hidden">
                                    <label htmlFor="qty">จำนวน</label>
                                    <input name="qty"
                                           value={this.state.qty || ''}
                                           type="text"
                                           className="form-control hidden"
                                           placeholder="จำนวน"
                                           onChange={this.onChangeQtyHandle}/>
                                    <NumberFormat
                                        className="form-control"
                                        prefix={''}
                                        placeholder="จำนวน"
                                        min={0}
                                        max={100}
                                        step={1}
                                        size={3}
                                        value={this.state.qty || ''}
                                        allowNegative={false}
                                        onValueChange = {(values) => {
                                            const {value} = values;
                                            this.setState({qty: value})
                                        }}
                                    />
                                </div>

                                <div className="form-group col-sm-3">
                                    <label htmlFor="repair_item">ชื่อรายการซ่อม</label>
                                    <input name="repair_item"
                                           value={this.state.repair_item || ''}
                                           type="text"
                                           className="form-control"
                                           placeholder="ชื่อรายการซ่อม"
                                           onChange={this.onChangeRepairItemHandle}/>
                                </div>

                                <div className="form-group col-sm-2">
                                    <label htmlFor="amount">จำนวนเงิน</label>
                                    <input name="amount"
                                           value={this.state.amount || ''}
                                           type="text"
                                           className="form-control hidden"
                                           placeholder="จำนวนเงิน"
                                           onChange={this.onChangeAmountHandle}/>

                                    <NumberFormat
                                        className="form-control"
                                        thousandSeparator={true}
                                        prefix={'฿'}
                                        placeholder="จำนวนเงิน"
                                        min={0}
                                        max={1000000000}
                                        step={1000}
                                        size={10}
                                        allowNegative={false}
                                        value={this.state.amount || ''}
                                        onValueChange = {(values) => {
                                            const {value} = values;
                                            this.setState({amount: value})
                                        }}
                                    />
                                </div>

                                <div className="form-group col-sm-2">
                                    {/*
                                    <label htmlFor="guarantee_name">การันตีอุปกรณ์</label>
                                    <select
                                        className="form-control"
                                        name="guarantee_name"
                                        value={this.state.guarantee_name}
                                        onChange={(e)=>{
                                            if(this.state.user.type === 'admin')
                                                this.setState({guarantee_name: e.target.value});
                                        }}
                                    >
                                        {guarantee_list}
                                    </select>*/}
                                    <label htmlFor="guarantee_name">การันตีอุปกรณ์</label>
                                    <input name="guarantee_name"
                                           value={this.state.guarantee_name || ''}
                                           type="text"
                                           className="form-control"
                                           placeholder="การันตีอุปกรณ์"
                                           onChange={this.onChangeGuaranteeNameHandle}/>
                                </div>

                                <div className="form-group col-sm-2">
                                    <label htmlFor="mechanic_name">ศูนย์ซ่อม/อู่ช่าง </label>
                                    {/*<input name="mechanic_name"
                                           type="text"
                                           className="form-control"
                                           placeholder="ช่างผู้ซ่อม"
                                           value={this.state.mechanic_name}
                                           onChange={(event)=>this.setState({mechanic_name: event.currentTarget.value})}
                                    />*/}
                                    <select
                                        className="form-control"
                                        name="mechanic_name"
                                        value={this.state.mechanic_name}
                                        onChange={(e)=>{
                                            // if(this.state.user.type === 'admin')
                                                this.setState({mechanic_name: e.target.value});
                                        }}
                                    >
                                        <option value="">--เลือกศูนย์ซ่อม/อู่ช่าง--</option>
                                        {mechanic_list}
                                    </select>
                                </div>


                            </div>

                            <div className="row mb-2">
                                <div className="col-12 text-center ">
                                    <button
                                        name="add_item"
                                        className={this.state.form_status === 'add' ? "btn btn-outline-primary" : "hidden"}
                                        onClick={()=>this.addRepairItem()}
                                    >เพิ่มรายการ</button>

                                    <button
                                        name="add_item"
                                        className={this.state.form_status === 'edit' ? "btn btn-outline-warning" : "hidden"}
                                        onClick={()=>this.editRepairItem()}
                                    >แก้ไขรายการ</button>


                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th className="text-center">วันที่ซ่อม</th>
                                            {/*<th className="text-center">จำนวน</th>*/}
                                            <th>ชื่อรายการซ่อม</th>
                                            <th className="text-center">จำนวนเงิน</th>
                                            <th className="text-center">การันตีอุปกรณ์</th>
                                            <th className="text-center">ศูนย์ซ่อม/อู่ช่าง</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr className={this.state.repair_item_list.length===0? '': 'hidden'} >
                                            <td colSpan={8} className="text-center text-danger">
                                                ไม่พบรายการซ่อม
                                            </td>
                                        </tr>
                                        {repairItemList}
                                        {repairItemListTotal}
                                        </tbody>
                                    </table>
                                </div>
                            </div>



                        </div>

                        <div className={this.state.repair_item_list.length===0? "hidden" : "card-footer text-right"}>
                            <button type="reset"
                                    className="btn btn-sm btn-danger"
                                    onClick={this.onChangeClearDataHandle}
                            >
                                <i className="fa fa-refresh"></i> ยกเลิก
                            </button>
                            <button type="submit"
                                    className="btn btn-sm btn-primary"
                                    onClick={this.onSaveDataHandle}
                            >
                                <i className="fa fa-save"></i>
                                &nbsp;บันทึก
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps) (CarClaimItems)