import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import { APIURL } from '../../config/config';
import Swal from 'sweetalert2';
import { AlertError, AlertSuccess, AlertWarning } from '../Alert/Alert';
import { Modal, ModalBody } from 'reactstrap';
import PreviewImage from '../../views/PreviewImage';
import {checkGroupPermission} from "../../services/grouppermission";
import { save } from 'save-file';
import Loading from '../Loading';
import ReactPaginate from 'react-paginate';
// import styled from 'styled-components';

const suggestionHighlightedDivStyle = { backgroundColor: '#5bc0de' };
const suggestionDivStyle = { padding: '10px'};

const unitList = [
  { id: 'ชุด',    name: 'ชุด' },
  { id: 'ชิ้น',    name: 'ชิ้น' },
  { id: 'กล่อง',  name: 'กล่อง' },
  { id: 'โหล',   name: 'โหล' },
  { id: 'เครื่อง', name: 'เครื่อง' },
  { id: 'ถัง',    name: 'ถัง' },
  { id: 'ขวด',   name: 'ขวด' },
  { id: 'ลูก',    name: 'ลูก' },
  { id: 'คู่',     name: 'คู่' },
]

/*
const MyPaginate = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: 'active', // default to "selected"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;

  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`; */

const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : unitList.filter(lang =>
    lang.name.toLowerCase().slice(0, inputLength) === inputValue
  );
};

// When suggestion is clicked, Autosuggest needs to populate the input
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <span>
    {suggestion.name}
  </span>
);

class SparePartList extends Component {

  constructor (props){
    super(props)
    this.state = {
      actionType: 'บันทึก',
      suggestions: [],
      id: '',
      name: '',
      price: 0,
      cost: 0,
      quantity: 0,
      unit: '',
      sparePartList: [],
      note: '',
      s3key: '',
      preview_image_url: '',
      preview_image_select: false,
      retailers: [],
      retailer_id: 0,
      barcode: '',
      search_spare: '',
      isLoading: false,
      //
      itemPerPage: 30,
      itemCount: 0,
      pageCount: 0,
      currentPageIndex: 0
    }
    this.onSparePartSubmit = this.onSparePartSubmit.bind(this)
    this.loadSparePart = this.loadSparePart.bind(this)
    this.onDeleteSparePart= this.onDeleteSparePart.bind(this)
    this.onPreUpdateSparePart= this.onPreUpdateSparePart.bind(this)
    this.resetFormState= this.resetFormState.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.resetState = this.resetState.bind(this)
    this.handleUploadFile = this.handleUploadFile.bind(this)
    this.onPreviewSelected = this.onPreviewSelected.bind(this)
    this.togglePreviewImage = this.togglePreviewImage.bind(this)
    this.loadRetailerData = this.loadRetailerData.bind(this)
    this.searchSparePart = this.searchSparePart.bind(this)
    this.exportSparePart = this.exportSparePart.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
  }

  resetState() {
    this.setState({
      actionType: 'บันทึก',
      id: '',
      name: '',
      price: 0,
      quantity: 0,
      unit: '',
      note: '',
      barcode: ''
    })
  }

  componentDidMount() {
    this.mounted = true
    this.loadSparePart();
    this.loadRetailerData();
  }

  componentWillUnmount() {
    this.mounted = false
  }

  loadRetailerData() {
    axios.get(APIURL + '/retailer/')
      .then(res => {
        this.setState({
          retailers: res.data
        })
      })
  }

  componentWillReceiveProps(nextProps){
      if(nextProps.reloadSparePart !== this.props.reloadSparePart){
        if(nextProps.reloadSparePart){
          this.loadSparePart();
          this.loadRetailerData();
        }
      }
  }

  handleChange(event) {
    let name  = event.target.name;
    let value = event.target.value;
    if(name === 'name'){
      this.setState({name: value})
    }else if(name === 'price'){
      this.setState({price: value})
    }else if(name === 'note'){
      this.setState({note: value})
    }else if(name === 'barcode'){
      this.setState({barcode: value})
    }
  }

  loadSparePart() {
    this.setState({
      isLoading: true
    }, () => {
      const page = this.state.currentPageIndex + 1
      const itemPerPage = this.state.itemPerPage

      let data = {
        page: page,
        itemPerPage: itemPerPage
      }
      // axios.get(APIURL+'/spare_part').then(res => {
      axios.post(APIURL+'/spare_part/criteria', data).then(res => {
        if(res.status === 200) {
          if(this.mounted) {

            const sparePartList = res.data.sparePartList

            const itemCount = res.data.itemCount
            const pageCount = Math.ceil(itemCount / itemPerPage);

            this.setState({
              sparePartList: sparePartList,
              search_spare: '',
              isLoading: false,
              itemCount: itemCount,
              pageCount: pageCount,
            }, () => {
              if (this.props.reloadSparePart) {
                this.props.onReloadSparePartFinish();
              }
            });
          }
        }
      })
    })
  }

  handlePageClick(event) {
    // const itemPerPage = this.state.itemPerPage
    // const itemCount = this.state.itemCount
    const index = event.selected
    this.setState({
      currentPageIndex: index,
    }, () => this.loadSparePart())

    // console.log('event.selected=', event.selected)
    // const newOffset = (event.selected * itemPerPage) % itemCount;
    // console.log(`Items requested page number ${event.selected}, which is offset ${newOffset}`);
  }

  exportSparePart() {
    this.setState({
      isLoading: true
    }, () => {

      axios.get(APIURL+'/spare_part/excel').then(async res => {

        if(res.status === 200) {
          const excelBuffer = res.data.excelBuffer
          const currentDatetime = res.data.currentDatetime
          const fileName = 'รายการสต๊อกอะไหล่_' + currentDatetime + '.xlsx'

          this.setState({
            isLoading: false
          })

          await save(excelBuffer, fileName)

        }
      });

    })

  }

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };
  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onChange = (event, { newValue }) => {
    this.setState({
      unit: newValue
    });
  };

  onSparePartSubmit(e){
    e.preventDefault()
    let name = e.target.name.value
    let retailer_id = e.target.retailer_id.value
    let price = e.target.price.value
    let cost = e.target.cost.value
    let quantity = e.target.quantity.value
    let unit = e.target.unit.value
    let note = e.target.note.value
    let user = this.props.user.user
    let barcode = e.target.barcode.value

    if(name===''){
      AlertWarning('กรุณากรอกชื่ออะไหล่/วัสดุอุปกรณ์');
      return;
    }

    let data = {
      name: name,
      price: price,
      cost: cost,
      quantity: quantity,
      unit: unit,
      type: 'warehourse',
      user: user,
      note: note,
      retailer_id: retailer_id,
      barcode: barcode
    }

    // e.target.retailer_id.value = '0'
    // return

    e.target.name.value = ''
    e.target.price.value = ''
    e.target.cost.value = ''
    e.target.quantity.value = ''
    e.target.unit.value = ''
    e.target.note.value = ''
    e.target.retailer_id.value = '0'
    e.target.barcode.value = ''

    this.setState({
      name: '',
      price: 0,
      cost: 0,
      quantity: 0,
      unit: '',
      note: '',
      s3key: '',
      retailer_id: 0,
      barcode: ''
    })
    // add
    if(this.state.actionType === 'บันทึก'){
      // TODO: add data with file
      console.log(e.target.spare_part_image)
      console.log(e.target.spare_part_image.files.length)
      console.log(data)
      // return

      if(e.target.spare_part_image.files.length === 0){
        // no file
        axios.post(APIURL+'/spare_part', data)
          .then(res => {
            if(res.status === 200){
              AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
              this.loadSparePart();
            }
          })
          .catch(err => {
            console.log('err: ', err)
          })
      } else {
        // with file
        //       name: name,
        //       price: price,
        //       cost: cost,
        //       quantity: quantity,
        //       unit: unit,
        //       type: 'warehourse',
        //       user: user,
        //       note: note,
        //       retailer_id: retailer_id,
        //       barcode: barcode
        const data = new FormData();
        data.append('file', e.target.spare_part_image.files[0], e.target.spare_part_image.files[0].name);
        data.append('name', name);
        data.append('price', price);
        data.append('cost', cost);
        data.append('quantity', quantity);
        data.append('unit', unit);
        data.append('type', 'warehourse');
        data.append('user', user);
        data.append('note', note);
        data.append('retailer_id', retailer_id);
        data.append('barcode', barcode);

        // console.log('file name: ', e.target.spare_part_image.files[0].name)
        axios.post(APIURL+'/spare_part/with_file', data)
          .then(res => {
            if(res.status === 200){
              AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
              this.loadSparePart();
            }
          })
          .catch(err => {
            console.log('err: ', err)
          })

      }

    }else if(this.state.actionType === 'แก้ไข'){
      //edit
        let id = e.target.id.value
        axios.put(APIURL+'/spare_part/'+id, data)
        .then(res => {
          if(res.status === 200){
            AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
            this.loadSparePart();
          }
        })
        .catch(err => {
          console.log('err: ', err)
        })
    }
  }

  onDeleteSparePart(sparePart){
    let id = sparePart.id
    axios.delete(APIURL+'/spare_part/'+id)
      .then(res => {
        if(res.data.delete){
          AlertSuccess('ลบเรียบร้อยแล้ว');
          this.resetFormState();
          this.loadSparePart();
        }else{
          alert(res.data.message);
        }
      })
  }

  onPreUpdateSparePart(sparePart) {
    // let id = sparePart.id
    this.setState({
      actionType: 'แก้ไข',
      id: sparePart.id,
      name: sparePart.name,
      price: sparePart.price,
      cost: sparePart.cost,
      unit: sparePart.unit,
      note: sparePart.note,
      s3key: sparePart.s3key,
      retailer_id: sparePart.retailer_id === null ? 0 : sparePart.retailer_id,
      barcode: sparePart.barcode,
    }, () => {
      this.refs.spare_part_image.value = '';
    });
  }

  resetFormState(){
    this.setState({
      actionType: 'บันทึก',
      id: '',
      name: '',
      price: 0,
      quantity: 0,
      unit: '',
      s3key: '',
      barcode: ''
    });
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  handleUploadFile (event) {
    event.preventDefault();
    // let input_file_name = event.target.name
    let id = this.state.id
    let s3key = this.state.s3key
    let name = this.state.name

    if ( id === '') {
      return
    }

    let that = this

    const data = new FormData();
    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('id', id)
    data.append('s3key', s3key)

    Swal.fire({
      title: 'ต้องการเพิ่มภาพ/เปลี่ยนภาพ อะไหล่ '+name + ' ใช่หรือไม่? ',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {

        axios.post(APIURL+'/spare_part/image', data)
          .then(function(response){
            if(response.status === 200) {
              AlertSuccess('เพิ่มภาพ/เปลี่ยนภาพเรียบร้อยแล้ว');
              // that.resetFormState();
              that.loadSparePart();
            }
          })
          .catch(function(error){
            console.log('error:' + error.response);
            // alert("error:"+error.response);
          });
      }
    })
  }

  searchSparePart() {
    let search_spare = this.state.search_spare
    let search_text = search_spare.length > 0 ? search_spare.trim() : ''
    if (search_text.length === 0) {
      AlertError('กรุณาระบุชื่อรายการอะไหล่');
      return
    }

    axios.get(APIURL+'/spare_part/searchlist/'+search_text).then(res => {
      if(res.status === 200) {
        this.setState({
          sparePartList: res.data,
          itemCount: 0,
          pageCount: 0,
          currentPageIndex: 0
        });
      }
    })
  }

  render() {
    const inputProps = {
      placeholder: 'ชื่อหน่วย',
      value: this.state.unit ,
      onChange: this.onChange ,
      type: 'search',
    };

    let sparePartList = this.state.sparePartList.map((sparePart, index) => {

      const page = this.state.currentPageIndex
      const itemPerPage = this.state.itemPerPage
      const start_index = (page * itemPerPage) + (index+1)

      return (
        <tr key={index+1}>
          <td>{start_index}</td>
          <td>
            ชื่อ: {sparePart.name}
            <br/>
            {
              sparePart.retailer_name === null ? '' : (
                <>
                  ร้าน: {sparePart.retailer_name}
                  <br/>
                </>
              )
            }

            {
              sparePart.s3key === '' ? null : (<>
                  <br/>
                  <img
                    src={sparePart.s3url}
                    style={{'cursor' : 'pointer', 'width': '48px', 'height': 'auto'}}
                    onClick={()=>this.onPreviewSelected(sparePart.s3url)}
                    alt="เอกสาร"
                  />
                </>
              )
            }
          </td>
          <td>
            ราคา : <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              value={sparePart.price}/>
              <br/>
            ต้นทุน :  <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              value={sparePart.cost}/>
          </td>
          <td className="text-center">{sparePart.quantity_in}</td>
          <td>{sparePart.unit}</td>
          <td>{sparePart.barcode}</td>
          <td>{sparePart.note}</td>
          <td>

            {
              checkGroupPermission(201, this.props.grouppermissions).modified ? (
                  <>
                    <button
                        className="btn btn-sm btn-warning btn-md-width "
                        onClick={()=>this.onPreUpdateSparePart(sparePart)}
                    >
                      <i className="fa fa-edit"/> แก้ไข
                    </button>
                    <br/>
                  </>
              ) : null
            }

            {
              checkGroupPermission(201, this.props.grouppermissions).deleted ? (
                  <button
                      className="btn btn-sm btn-danger btn-md-width mt-2"
                      onClick={()=>this.onDeleteSparePart(sparePart)}
                  >
                    <i className="fa fa-remove"/> ลบ
                  </button>
              ) : null
            }

          </td>
        </tr>
      )
    })

    return (
      <div className="row">

        <Loading isLoading={this.state.isLoading} />

        <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
          <ModalBody>
            <PreviewImage
              imageUrl={this.state.preview_image_url}
            />
          </ModalBody>
        </Modal>

        <div
            className={
              checkGroupPermission(201, this.props.grouppermissions).created ||
              checkGroupPermission(201, this.props.grouppermissions).modified ? "col-lg-8" : "col-lg-12"
            }

        >
          <div className="card card-accent-warning">
            <div className="card-header card-sparepart">
              <div className="row">
                <div className="col-9">
                  <strong className="text-title">รายการอะไหล่ </strong>
                  <input
                    name="search_spare"
                    style={styles.search}
                    className="form-control ml-1  mr-1 mb-1"
                    placeholder="ค้นหาอะไหล่"
                    type="text"
                    value={this.state.search_spare}
                    onChange={(e) => {
                      let val = e.target.value
                      this.setState({
                        search_spare: val
                      })
                    }}
                  />

                  <button
                    className="btn btn-sm btn-head-bar mr-1 ml-2"
                    onClick={() => this.searchSparePart()}
                    style={styles.buttonHeight}
                  >
                    <i className="icon-search"/>ค้นหา
                  </button>

                  <button
                    className="btn btn-sm btn-head-bar"
                    onClick={() => this.loadSparePart()}
                    style={styles.buttonHeight}
                  >
                    <i className="icon-refresh"/>
                  </button>

                </div>
                <div className="col-3">
                  {
                    checkGroupPermission(201, this.props.grouppermissions).printed ? (
                      <button
                        type="button"
                        className="btn btn-sm btn-success mr-1 ml-2 float-right"
                        style={{width: 100, marginTop: 5}}
                        onClick={() =>  this.exportSparePart() }
                      > <i className="icon-cloud-download">
                        &nbsp;Excel</i>
                      </button>
                    ) : null
                  }

                </div>

              </div>

            </div>
            <div className="card-block" style={styles.overflowY}>
              <table className="table table-striped">
                <thead>
                <tr>
                  <td>#</td>
                  <td className="text-center text-nowrap">ชื่ออะไหล่รถ</td>
                  <td className="text-center text-nowrap">ราคาต่อหน่วย/ต้นทุน</td>
                  <td className="text-center">จำนวน</td>
                  <td className="text-center text-nowrap">หน่วย</td>
                  <td className="text-center text-nowrap">บาร์โค้ด</td>
                  <td className="text-center text-nowrap">หมายเหตุ</td>
                  <td>&nbsp;</td>
                </tr>
                </thead>
                <tbody>
                {sparePartList}

                </tbody>
              </table>

              {
                this.state.itemCount < this.state.itemPerPage ?
                  null
                  :
                 /* <MyPaginate
                    pageCount={20}
                    onPageChange={this.handlePageClick}
                    forcePage={this.state.page}
                  />*/
                  <nav aria-label="Page navigation comments" className="mt-4">
                    <ReactPaginate
                          previousLabel="previous"
                          nextLabel="next"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={this.state.pageCount}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={4}
                          onPageChange={this.handlePageClick}
                          containerClassName="pagination justify-content-center"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          activeClassName="active"
                          // eslint-disable-next-line no-unused-vars
                          hrefBuilder={(page, pageCount, selected) =>
                            page >= 1 && page <= pageCount ? `/page/${page}` : '#'
                          }
                          hrefAllControls
                          forcePage={this.state.currentPageIndex}
                          onClick={(clickEvent) => {
                            // console.log('onClick', clickEvent);
                            // Return false to prevent standard page change,
                            // return false; // --> Will do nothing.
                            // return a number to choose the next page,
                            // return 4; --> Will go to page 5 (index 4)
                            // return nothing (undefined) to let standard behavior take place.
                          }}
                        />
                  </nav>
              }

            </div>
          </div>
        </div>

        {
          checkGroupPermission(201, this.props.grouppermissions).created ||
          checkGroupPermission(201, this.props.grouppermissions).modified ?
              (
                  <div className="col-lg-4">
                    <div className={ this.state.actionType==="แก้ไข"? "card card-accent-warning" :"card card-accent-warning"}>
                      <form action=""
                            method="post"
                            onSubmit={this.onSparePartSubmit}
                            encType="multipart/form-data"
                      >
                        <div className="card-header card-sparepart flex-row justify-content-between">
                          <strong className="text-title">{this.state.actionType==="แก้ไข"? "แก้ไข" : "เพิ่ม" }รายการอะไหล่</strong>
                          &nbsp;&nbsp;
                          {
                            this.state.actionType==="แก้ไข" ? (
                                <button
                                    className="btn btn-sm btn-primary ml-3"
                                    onClick={() => this.resetState()}
                                >
                                  <i className="icon-refresh"/>
                                </button>
                            ) : null
                          }

                        </div>
                        <div className="card-block">
                          <div className="form-group">
                            <label htmlFor="name">ชื่ออะไหล่/วัสดุอุปกรณ์ </label>
                            <input name="name"
                                   type="text"
                                   className="form-control"
                                   placeholder="ชื่ออะไหล่/วัสดุอุปกรณ์ "
                                   value={this.state.name}
                                   onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="name">ชื่อร้านค้า (เพิ่มร้านค้าที่แท็บข้อมูลร้านค้า) </label>
                            <select className="form-control"
                                    id="retailer_id"
                                    name="retailer_id"
                                    value={this.state.retailer_id}
                                    onChange={(e) => {
                                      let val = e.target.value
                                      this.setState({
                                        retailer_id: val
                                      })
                                    }}
                            >
                              <option  value='0'>ไม่ระบุ</option>
                              {
                                this.state.retailers.map((retailer, index) => (
                                    <option key={index} value={retailer.retailer_id}>{retailer.retailer_name}</option>
                                ))
                              }
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="name">ราคาขายต่อหน่วย</label>
                            <input name="price"
                                   type="text"
                                   className="form-control hidden"
                                   placeholder="ราคาต่อหน่วย "
                                   value={this.state.price}
                                   onChange={this.handleChange}
                            />
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ราคาขายต่อหน่วย"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                value={this.state.price}
                                onValueChange = {(values) => {
                                  const {value} = values;
                                  this.setState({price: value})
                                }}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="cost">ราคาทุนต่อหน่วย (ราคากลาง)</label>
                            <input name="cost"
                                   type="text"
                                   className="form-control hidden"
                                   placeholder="ต้นทุนต่อหน่วย "
                                   value={this.state.cost}
                                   onChange={this.handleChange}
                            />
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ต้นทุนต่อหน่วย"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                value={this.state.cost}
                                onValueChange = {(values) => {
                                  const {value} = values;
                                  this.setState({cost: value})
                                }}
                            />
                          </div>

                          <div className={this.state.actionType==='แก้ไข'? "hidden" : "form-group" }>
                            <label htmlFor="name">จำนวนเริ่มต้น </label>
                            <input name="quantity"
                                   type="text"
                                   className="form-control hidden"
                                   placeholder="จำนวนเริ่มต้น "
                                   value={this.state.quantity}
                                   onChange={this.handleChange}
                            />
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="จำนวนเริ่มต้น"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                value={this.state.quantity}
                                onValueChange = {(values) => {
                                  const {value} = values;
                                  this.setState({quantity: value})
                                }}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="center_name">หน่วย</label>
                            <input type="text"
                                   className="form-control hidden"
                                   value={this.state.unit}
                                   name="unit"
                                   placeholder="hidden"
                                   onChange={this.handleChange}
                            />
                            <Autosuggest
                                suggestions={this.state.suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                                theme={{
                                  input: 'form-control',
                                  suggestion: suggestionDivStyle,
                                  suggestionsList: 'list-unstyled ',
                                  suggestionHighlighted: suggestionHighlightedDivStyle
                                }}

                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="barcode">เลขบาร์โค้ด </label>
                            <input name="barcode"
                                   type="text"
                                   className="form-control"
                                   placeholder="กรอกเลขบาร์โค้ด"
                                   value={this.state.barcode}
                                   onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="note">หมายเหตุ</label>
                            <textarea
                                id="note"
                                name="note"
                                className="form-control"
                                value={this.state.note}
                                onChange={this.handleChange}
                                rows="3"
                                placeholder="หมายเหตุ"
                                maxLength={255}
                            />
                          </div>

                          <div className={ "form-group" }>
                            <label htmlFor="note">ไฟล์อะไหล่</label>
                            {
                              this.state.actionType === 'แก้ไข' ?
                                <input
                                  className="form-control"
                                  accept="image/*"
                                  type="file"
                                  id="spare_part_image"
                                  name="spare_part_image"
                                  ref="spare_part_image"
                                  onChange={this.handleUploadFile}
                                />
                                :
                                <input
                                  className="form-control"
                                  accept="image/*"
                                  type="file"
                                  id="spare_part_image"
                                  name="spare_part_image"
                                  ref="spare_part_image"
                                  onChange={()=> {

                                  }}
                                />
                            }

                          </div>
                        </div>

                        <div className="card-footer text-right">
                          <button type="reset"
                                  className="btn btn-sm btn-danger mr-2"
                                  onClick={this.resetFormState}
                          >
                            <i className="fa fa-refresh"/> ยกเลิก
                          </button>
                          <button type="submit"
                                  className={this.state.actionType==="แก้ไข"? "btn btn-sm btn-warning" :"btn btn-sm btn-primary"}
                          >
                            <i className={this.state.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/>
                            &nbsp;{this.state.actionType}
                          </button>

                          <input type="hidden" name="id" value={this.state.id}/>

                        </div>
                      </form>
                    </div>
                  </div>
              ) : null
        }

      </div>
    )
  }
}

const styles = {
  search: {
    marginLeft: 0,
    marginTop: 4,
    marginBottom: -8,
    width: 180,
    borderRadius: 5,
    display: 'inline'
  },
  buttonHeight: {
    height: 36,
    paddingTop: 0,
    paddingBottom:0,
    borderRadius: 5,
    display: 'inline',
    marginTop: -1,
    marginBottom: 0,
  },

  overflowY: {
    overflow: 'scroll',
  }
}

export default SparePartList
