import React, { Component } from 'react'


export  default class BranchListRow extends Component{

    /*constructor(props){
        super(props)
    }*/

    render(){
        var branchList;

        if (this.props.branch_list.length === 0){
            branchList = <tr><td colSpan={5} className="text-center"><span className="text-danger">ไม่พบข้อมูล</span></td></tr>
        }else {

            branchList = this.props.branch_list.map((branch, index) => {
              let tax_id = branch.tax_id
              let signatory = branch.signatory
                return (
                    <tr key={index}>
                        <td className="text-center">{ index+1 }</td>
                        <td className="text-center">
                          { branch.branch_name } <br/>
                          ({branch.branch_type})
                        </td>
                        <td>
                          ที่อยู่: { branch.branch_address } <br/>
                          เบอร์โทร: {branch.branch_tel} <br/>
                          เลขที่ผู้เสียภาษี: {tax_id} <br/>
                          ผู้มีอำนาจลงนาม: {signatory} <br/>
                        </td>

                        <td className="text-right">
                            <button className="btn btn-sm btn-warning btn-md-width mb-2"
                                    onClick={this.props.branchsCallbacks.edit.bind(null,branch)}
                                    style={styles.button}
                            >
                                <i className="fa fa-edit"/> แก้ไข
                            </button>

                            <button className="btn btn-sm btn-danger btn-md-width"
                                    onClick={this.props.branchsCallbacks.delete.bind(null,branch)}
                                    style={styles.button}
                            >
                                <i className="fa fa-remove"/> ลบ
                            </button>
                        </td>
                    </tr>
                )
            });
        }

        return (
            <div className="card card-accent-info">
                <div className="card-header">
                    <strong className="text-title">ข้อมูลสาขา</strong>
                </div>
                <div className="card-block">
                    <table className="table table-striped table-responsive">
                        <thead>
                        <tr>
                            <th
                              className="text-center"
                              // style={{width: 10}}
                            >#</th>
                            <th
                              className="text-center"
                              // style={{width: 50}}
                            >สาขา</th>
                            <th className="text-center">รายละเอียด</th>
                            <th className="text-center" style={{width: 50}}/>
                        </tr>
                        </thead>
                        <tbody>
                        { branchList }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    }
}