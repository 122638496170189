import React, { Component } from 'react'

class CustomerOldListRow extends Component {
    render () {
        let customerList = this.props.customer_list.map((customer,index)=>{
            return (
                <tr key={index}>
                    <td className="text-center">{index+1}</td>
                    <td>
                        <strong> ชื่อ-นามสกุล : </strong> { customer.customer_name }  { customer.customer_lastname } <br/>
                        เลขบัตรประชาชน : { customer.customer_card_id } <br/>
                        เบอร์โทรศัพท์ : { customer.customer_mobile } <br/>
                    </td>
                    <td>
                        <strong> ชื่อรถ : </strong> {customer.car_name} <br/>
                        <strong> ทะเบียนรถ : </strong> {customer.car_license_plate_new}  จังหวัด{customer.province_name_new !== null  && customer.province_name_new  !=='0' ? customer.province_name_new : 'ไม่ระบุ'}<br/>
                        <strong> หมายเหตุ : </strong> {customer.old_car_note === '' ? '-' : customer.old_car_note}
                    </td>
                    <td>
                        <strong> วันออกรถ : </strong>{customer.old_car_departure_date !== null  && customer.old_car_departure_date  !=='0000-00-00' ? customer.old_car_departure_date : '-'} <br/>
                        จัดไฟแนนซ์กับธนาคาร : {customer.bank_name !== null  && customer.bank_name  !=='0' ? customer.bank_name : 'ไม่ระบุ'} <br/>
                        <strong> วันครบกำหนดต่อภาษี :  {customer.car_date_tax !== null  && customer.car_date_tax  !=='0000-00-00' ? customer.car_date_tax : '-'} </strong> <br/>
                        <strong> วันครบกำหนดต่อประกัน : {customer.insurance_end_date !== null  && customer.insurance_end_date  !=='0000-00-00' ? customer.insurance_end_date : '-'}  </strong>
                    </td>
                    <td className="text-center">
                        <button className="btn btn-sm btn-warning btn-md-width mb-2"
                                style={styles.button}
                                onClick={this.props.customerCallbacks.show.bind(null,customer)}
                        >
                            <i className="fa fa-edit"/> แก้ไข</button>
                        <br/>
                        {/*<button className="btn btn-sm btn-danger btn-md-width mb-2"
                                onClick={this.props.customerCallbacks.delete.bind(null,customer)}
                                style={styles.button}
                        >
                            <i className="fa fa-remove"/> ลบ</button>*/}
                    </td>
                </tr>
            )
        });

        let tbEmpty = (
            <tr><td colSpan={5} className="text-danger text-center">ไม่พบข้อมูล</td></tr>
        )

        let tbBody = this.props.customer_list.length===0 ? tbEmpty : customerList

        return (
            <div className="card-block">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-center" style={{width: 400}}>ข้อมูลลูกค้า</th>
                        <th className="text-center" style={{width: 500}}>ข้อมูลรถ</th>
                        <th className="text-center" style={{width: 400}}>รายละเอียด</th>
                        <th className="text-center" style={{width: 180}}>จัดการ</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tbBody}
                    </tbody>
                </table>
            </div>
        )
    }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    }
}

export default CustomerOldListRow